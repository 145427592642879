import { useEmailFromJWT } from "shared/hooks";

import PageHeaderWrapper from "features/ui/PageHeaderWrapper";
import Title from "features/ui/Title";

import Bookmarks from "./Bookmarks/Bookmarks";
import { LANDING_PAGE_TITLE } from "./constants";

const LandingPage = () => {
  const email = useEmailFromJWT();
  return (
    <>
      <PageHeaderWrapper>
        <Title text={`${LANDING_PAGE_TITLE}${email}`} />
      </PageHeaderWrapper>
      <Bookmarks />
    </>
  );
};
export default LandingPage;
