interface Props {
  children: React.ReactNode;
}

const PageHeaderWrapper = ({ children }: Props) => {
  return (
    <div className="flex justify-between items-center mb-5">{children}</div>
  );
};

export default PageHeaderWrapper;
