import { IssueTypes } from "shared/types";

import CardHeader from "pages/Issues/Details/CardHeader";
import { getPopulationString } from "pages/Issues/utils";

import Card from "features/ui/Card";

import IssueRelationshipPopulationStatistics from "./IssueRelationshipPopulationStatistics";

interface Props {
  issue: IssueTypes;
  withComparisonPopulation: boolean;
  timePeriod: number;
}

const AT_RISK_TITLE = `Signal Events Related to Claims: ${getPopulationString(
  "atRisk"
)}`;
const PER_POPULATION_TITLE = "Signal Events Related to Claims";

const IssueRelationshipStatistics = (props: Props) => {
  return (
    <Card classNames="mb-3">
      <CardHeader
        title={
          props.withComparisonPopulation ? PER_POPULATION_TITLE : AT_RISK_TITLE
        }
        showActions={false}
      />
      <div className="space-y-3">
        <IssueRelationshipPopulationStatistics population="atRisk" {...props} />
        {props.withComparisonPopulation && (
          <IssueRelationshipPopulationStatistics
            population="comparison"
            {...props}
          />
        )}
      </div>
    </Card>
  );
};

export default IssueRelationshipStatistics;
