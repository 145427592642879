import { CountRequest, CountResponse } from "shared/api/api";
import { useAPI, usePaginatedAPI } from "shared/api/hooks";

import * as api from "./api";

export const useListServiceRecords = (args: api.ListServiceRecordsRequest) =>
  usePaginatedAPI<typeof args, api.ServiceRecord[]>(
    api.listServiceRecordsRequestURI,
    args
  );

export const useListServiceRecordsCount = (args: CountRequest) =>
  useAPI<typeof args, CountResponse>(
    api.listServiceRecordsCountRequestURI,
    args
  );

export const useGetCustomRecord = (args: api.GetCustomRecordRequest) =>
  useAPI<typeof args, api.CustomRecord>(api.getCustomRecordRequestURI, args);
