import { useFlags } from "launchdarkly-react-client-sdk";

import { PermissionEntry } from "shared/api/api";
import { EntityWithPermissions, PermissionID } from "shared/types";

import GeneralPermission from "./GeneralPermission";
import GroupPermissionsTableWrapper from "./GroupPermissionsTableWrapper";
import MemberPermission from "./MemberPermission";
import UserPermissionsTable from "./UserPermissionsTable";
import { isHighestPermission } from "./utils";

interface Props {
  entity: EntityWithPermissions;
  entityId?: string;
  generalPermission: PermissionEntry;
  userPermissions: PermissionEntry[];
  groupPermissions: PermissionEntry[];
  memberAccess: PermissionID;
  myEmail: string;
  onUpdateEmail: (id: string, email: string) => void;
  onUpdateGeneralPermission: (access: PermissionID) => void;
  onUpdateUserPermission: (id: string, access: PermissionID) => void;
  onAddUserPermission: () => void;
  onRemoveUserPermission: (id: string) => void;
  onUpdateGroup: (id: string, groupID: string) => void;
  onUpdateGroupPermission: (id: string, access: PermissionID) => void;
  onAddGroupPermission: () => void;
  onRemoveGroupPermission: (id: string) => void;
  onUpdateMemberAccess: (access: PermissionID) => void;
  canEdit: boolean;
  includeGroupMemberPermissions: boolean;
}

const PermissionsTable = ({
  entity,
  entityId,
  generalPermission,
  userPermissions,
  groupPermissions,
  memberAccess,
  myEmail,
  onUpdateEmail,
  onUpdateGeneralPermission,
  onUpdateUserPermission,
  onAddUserPermission,
  onRemoveUserPermission,
  onUpdateGroup,
  onUpdateGroupPermission,
  onAddGroupPermission,
  onRemoveGroupPermission,
  onUpdateMemberAccess,
  canEdit,
  includeGroupMemberPermissions,
}: Props) => {
  const { rbacGroups } = useFlags();
  const shownUserPermissions = userPermissions.filter((x) => x.shown);
  const generalAccess = generalPermission.access;

  return (
    <div className="space-y-5 py-5">
      {canEdit && (
        <GeneralPermission
          permission={generalPermission}
          onUpdatePermission={onUpdateGeneralPermission}
        />
      )}
      {canEdit && rbacGroups && includeGroupMemberPermissions && (
        <MemberPermission
          access={memberAccess}
          generalAccess={generalAccess}
          onUpdateAccess={onUpdateMemberAccess}
        />
      )}
      {!isHighestPermission(generalAccess) && (
        <UserPermissionsTable
          generalAccess={generalAccess}
          canEdit={canEdit}
          myEmail={myEmail}
          userPermissions={userPermissions}
          onUpdateEmail={onUpdateEmail}
          onAddPermission={onAddUserPermission}
          onUpdatePermission={onUpdateUserPermission}
          onRemovePermission={onRemoveUserPermission}
        />
      )}
      {!isHighestPermission(generalAccess) && rbacGroups && (
        <GroupPermissionsTableWrapper
          entity={entity}
          entityId={entityId}
          generalAccess={generalAccess}
          canEdit={canEdit}
          groupPermissions={groupPermissions}
          onUpdateGroup={onUpdateGroup}
          onUpdatePermission={onUpdateGroupPermission}
          onAddPermission={onAddGroupPermission}
          onRemovePermission={onRemoveGroupPermission}
        />
      )}
      {!isHighestPermission(generalAccess) &&
        shownUserPermissions.length === 0 && (
          <div className="py-4 text-gray-400 text-sm text-center">
            No additional user permissions applied.
          </div>
        )}
    </div>
  );
};

export default PermissionsTable;
