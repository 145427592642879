import { Sort } from "shared/types";

import { TableCellValue } from "features/ui/Table/TableBodyCell";

import { RowData } from "./Table";

// DEFAULT_SORT is applied to the column when it's clicked when sortable but
// is not defined in sortBy by default
const DEFAULT_SORT = "desc";

// curryIfDefined returns a curried version of the func with args if the func is defined
// otherwise it returns undefined
export const curryIfDefined = <T extends any>(
  func: ((...args: T[]) => void) | undefined,
  ...args: T[]
): ((...args: T[]) => void) | undefined => {
  if (!func) return undefined;
  return () => func(...args);
};

export const isSelectedRow = (
  rowId: string,
  selectedRowIds: Record<string, boolean> = {}
) => {
  return Boolean(selectedRowIds[rowId]);
};

export const getRowId = (row: RowData, index: number) =>
  [Object.values(row), index].join(".");

export const tableBodyCellKey = (data: RowData, accessor: string) =>
  `${Object.values(data).join("-")}:${accessor}`;

export const toggleSort = (sort?: Sort) =>
  !sort ? DEFAULT_SORT : sort === "asc" ? "desc" : "asc";

export const getValueByAccessor = (
  accessor: string,
  rowData: RowData
): TableCellValue => {
  return accessor
    .split(".")
    .reduce(
      (o, i) =>
        o && o.hasOwnProperty(i) && o[i] !== undefined && o[i] !== null
          ? o[i]
          : null,
      rowData
    ) as TableCellValue;
};
