import {
  ASSOCIATED_SIGNAL_EVENTS_TAB_KEY,
  ASSOCIATED_SIGNAL_EVENTS_TAB_TITLE,
  ASSOCIATED_VEHICLES_TAB_KEY,
  ASSOCIATED_VEHICLES_TAB_TITLE,
  BY_VEHICLE_AGE_TAB_TITLE,
  BY_VEHICLES_AGE_TAB_KEY,
  TOP_CONTRIBUTORS_TAB_KEY,
  TOP_CONTRIBUTORS_TAB_TITLE,
} from "pages/constants";

export const INSPECTIONS_FILTER_LABEL = "Inspection Filters";

export const PAGE_TITLE = "Inspection Analytics";
export const INSPECTIONS_PAGE_KEY = "inspAn";
export const INSPECTIONS_VEHICLES_PAGE_KEY = "inspAnVehicles";

export const INSPECTIONS_TAB_KEY = "inspections";
const INSPECTIONS_TAB_TITLE = "Inspections";

export const INSPECTION_ID_QUERY_KEY = "inspectionId";

export const INSPECTION_ANALYTICS_TABS = {
  [INSPECTIONS_TAB_KEY]: INSPECTIONS_TAB_TITLE,
  [BY_VEHICLES_AGE_TAB_KEY]: BY_VEHICLE_AGE_TAB_TITLE,
  [TOP_CONTRIBUTORS_TAB_KEY]: TOP_CONTRIBUTORS_TAB_TITLE,
  [ASSOCIATED_VEHICLES_TAB_KEY]: ASSOCIATED_VEHICLES_TAB_TITLE,
  [ASSOCIATED_SIGNAL_EVENTS_TAB_KEY]: ASSOCIATED_SIGNAL_EVENTS_TAB_TITLE,
};
