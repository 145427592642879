import { addWeeks, format } from "date-fns";

import { API_DATE_FORMAT } from "shared/constants";

export const getVINTimelineParamsFromAssociatedVehicles = (
  vehicleManufacturedAt: string | null,
  firstOccurrenceDate: string,
  lastOccurrenceDate: string
) => ({
  dateFrom: vehicleManufacturedAt || firstOccurrenceDate,
  dateTo: format(addWeeks(lastOccurrenceDate, 3), API_DATE_FORMAT),
});
