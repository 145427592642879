import { PageHandler } from "duck/graph/PageHandler";
import { StringSetter } from "duck/graph/types";
import { z } from "zod";
import { DynamicStructuredTool, tool } from "@langchain/core/tools";

import { getFiltersKey } from "features/ui/Filters/utils";

const updateVehiclesFilterSchema = z.object({
  vehiclesFilter: z
    .string()
    .optional()
    .describe(
      "Filter string for vehicles. Use empty string to clear/reset filter. Call retrieveVehicleAttributes to get the list of available attributes."
    ),
});

type UpdateVehiclesFilter = z.infer<typeof updateVehiclesFilterSchema>;

type ValidateFilterFunction = (filters: {
  vehiclesFilter: string;
}) => Promise<void>;

const getUpdateVehiclesFilterTool = (
  pageHandler: PageHandler,
  pageKey: string,
  validateFilterFunction: ValidateFilterFunction,
  setEphemeralMessage: StringSetter
): DynamicStructuredTool => {
  const updateVehiclesFilter = async ({
    vehiclesFilter,
  }: UpdateVehiclesFilter): Promise<string> => {
    setEphemeralMessage("update vehicles filter");
    console.debug("Updating vehicles filters", { vehiclesFilter });
    const filterQueryString = vehiclesFilter || "";

    if (validateFilterFunction) {
      await validateFilterFunction({ vehiclesFilter: filterQueryString });
    }

    pageHandler.updateFilter(filterQueryString, getFiltersKey(pageKey));
    return "Queued update vehicles filter";
  };

  return tool(updateVehiclesFilter, {
    name: "queueUpdateVehiclesFilter",
    description:
      "Call this tool to queue an action that updates the vehicles filters given a text query. Only call this for vehicle attributes.",
    schema: updateVehiclesFilterSchema,
  });
};

export default getUpdateVehiclesFilterTool;
