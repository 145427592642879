import { MouseEvent, useCallback } from "react";
import DuckMessagesContextWrapper from "duck/context/DuckMessagesContextWrapper";
import { useFlags } from "launchdarkly-react-client-sdk";
import { MdExpandMore, MdFullscreen } from "react-icons/md";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Stack,
} from "@mui/material";

import DuckOpenContent from "./DuckOpenContent";
import DuckSummary from "./DuckSummary";
import DuckErrorBoundary from "./error/DuckErrorBoundary";
import { useDuckVisibility } from "./hooks";
import { getDuckHeight, hasAllEnvVarsForViaDuck } from "./utils";

interface DuckProps {
  isDraggable?: boolean;
  setIsDraggable?: (draggable: boolean) => void;
  forceOpen?: boolean;
}

const Duck = ({
  isDraggable = false,
  setIsDraggable,
  forceOpen,
}: DuckProps) => {
  const { viaDuck } = useFlags();
  const supportsDuck = hasAllEnvVarsForViaDuck();

  const { isDuckVisible, setIsDuckVisible } = useDuckVisibility(forceOpen);

  const handleAccordionChange = useCallback(
    (event: MouseEvent<SVGElement>) => {
      event.stopPropagation();
      setIsDuckVisible(!isDuckVisible);
    },
    [isDuckVisible, setIsDuckVisible]
  );

  const handlePopoutClick = useCallback(() => {
    setIsDraggable && setIsDraggable(!isDraggable);
  }, [isDraggable, setIsDraggable]);

  if (!viaDuck || !supportsDuck) {
    return null;
  }

  // If Duck is docked, expanding is going up.
  // If Duck is floating, expanding is going down.
  // The accordion automatically rotates the icon vertically 180 degrees
  // to change from expand to contract.
  const expandIcon = isDraggable ? (
    <MdExpandMore
      onClick={handlePopoutClick}
      className="rotate-180"
      cursor="pointer"
    />
  ) : (
    <MdExpandMore
      onClick={handleAccordionChange}
      className="rotate-180"
      cursor="pointer"
    />
  );

  // This is a toggle icon for the popout functionality
  const popoutIcon = isDraggable ? undefined : (
    <MdFullscreen className="text-gray-600" />
  );

  const height = getDuckHeight(isDraggable, isDuckVisible);

  return (
    <DuckErrorBoundary>
      <DuckMessagesContextWrapper>
        <Accordion
          expanded={isDuckVisible}
          sx={{
            backgroundColor: "#cbd5e1",
            "&.MuiAccordion-root": {
              borderTopRightRadius: "0.75rem",
            },
            height: { height },
            display: "flex",
            flexDirection: "column",
            position: "relative",
          }}
          disableGutters
        >
          <AccordionSummary
            expandIcon={expandIcon}
            style={{
              cursor: "default",
              paddingLeft: "1.5rem",
              flexShrink: 0,
            }}
          >
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="space-between"
              width="100%"
            >
              <DuckSummary />
              <IconButton
                onClick={handlePopoutClick}
                size="small"
                sx={{
                  borderRadius: "0.375rem",
                }}
              >
                {popoutIcon}
              </IconButton>
            </Stack>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              // It was tricky to get the AccordionDetails to fill the remaining space in the Accordion.
              // Manually setting the top value is not awesome but that is not likely to change frequently.
              // And it works.
              padding: "0rem",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              position: "absolute",
              top: "48px",
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            <DuckOpenContent />
          </AccordionDetails>
        </Accordion>
      </DuckMessagesContextWrapper>
    </DuckErrorBoundary>
  );
};

export default Duck;
