export interface SectionTitleProps {
  children: string | React.ReactNode;
}

const SectionTitle = ({ children }: SectionTitleProps) => {
  return (
    <h3 className="text-xl mb-3" data-testid="v-section-title">
      {children}
    </h3>
  );
};

export default SectionTitle;
