import { KeyboardEvent } from "react";

import { removeNonDigitCharacters } from "shared/utils";

import InputFilter from "features/ui/Filters/FilterTypes/InputFilter";
import { FilterOperator, FilterSchemaItem } from "features/ui/Filters/types";
import { onlyAllowPositiveIntegersOnKeyDown } from "features/ui/Filters/utils";

interface Props {
  currentFilterSchema: FilterSchemaItem;
  selectedOperatorId: FilterOperator;
  inputValueOne?: string;
  inputValueTwo?: string;
  onInputOneChange: (value: string) => void;
  onInputTwoChange: (value: string) => void;
  onlyAllowPositiveIntegers?: boolean;
  disabled?: boolean;
}

const ONE_INPUT_OPERATORS: Partial<FilterOperator[]> = [
  FilterOperator.LESS_THAN,
  FilterOperator.GREATER_THAN,
  FilterOperator.GREATER_OR_EQUAL,
  FilterOperator.LESS_OR_EQUAL,
];

const TWO_INPUTS_OPERATORS: Partial<FilterOperator[]> = [
  FilterOperator.BETWEEN,
];

const NumberFilters = ({
  currentFilterSchema: { fieldName },
  selectedOperatorId,
  inputValueOne,
  inputValueTwo,
  onInputOneChange,
  onInputTwoChange,
  onlyAllowPositiveIntegers,
  disabled,
}: Props) => {
  const onKeyDown = (event: KeyboardEvent) =>
    onlyAllowPositiveIntegers && onlyAllowPositiveIntegersOnKeyDown(event);

  const onChangeFilter = (input: string) => {
    if (onlyAllowPositiveIntegers) {
      return removeNonDigitCharacters(input);
    }
    return input;
  };

  const handleOnInputOneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = onChangeFilter(e.target.value);
    onInputOneChange(value);
  };

  const handleOnInputTwoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = onChangeFilter(e.target.value);
    onInputTwoChange(value);
  };

  return (
    <>
      {(ONE_INPUT_OPERATORS.includes(selectedOperatorId) && (
        <InputFilter
          fieldName={fieldName}
          type="number"
          value={inputValueOne}
          onKeyDown={onKeyDown}
          onChange={handleOnInputOneChange}
          min={onlyAllowPositiveIntegers ? 0 : undefined}
          disabled={disabled}
        />
      )) ||
        (TWO_INPUTS_OPERATORS.includes(selectedOperatorId) && (
          <div className="flex space-x-3">
            <InputFilter
              fieldName={fieldName}
              type="number"
              value={inputValueOne}
              onKeyDown={onKeyDown}
              onChange={handleOnInputOneChange}
              disabled={disabled}
            />
            <InputFilter
              fieldName={fieldName}
              type="number"
              value={inputValueTwo}
              onKeyDown={onKeyDown}
              onChange={handleOnInputTwoChange}
              disabled={disabled}
            />
          </div>
        ))}
    </>
  );
};

export default NumberFilters;
