import Skeleton from "react-loading-skeleton";

import { VehicleGenericCategory } from "shared/api/api";
import {
  useListTransportCategories,
  useListVehicleCategories,
} from "shared/api/hooks";

import RadioButtonGroup, {
  Option,
  RadioSelectOption,
} from "features/ui/RadioButtonGroup";

export interface InferredCategoriesOnly {
  vehicleCategoryID?: string | null;
  transportCategoryID?: string | null;
}

interface Props {
  inferredCategories?: InferredCategoriesOnly;
  selectedCategories?: InferredCategoriesOnly;
  setSelected: (args: InferredCategoriesOnly) => void;
}

const formatCategories = (
  { ID, name, description }: VehicleGenericCategory,
  inferredId?: Option
): RadioSelectOption => {
  return {
    id: ID,
    value: (
      <div>
        {name}
        {inferredId === ID && (
          <span className="text-xs text-gray-300 ml-1">(inferred)</span>
        )}
      </div>
    ),
    info: description,
  };
};

const ServiceScheduleSelections = ({
  inferredCategories,
  selectedCategories,
  setSelected,
}: Props) => {
  const { data: vehicleCategoriesList, isLoading: isLoadingMSL } =
    useListVehicleCategories();
  const { data: transportCategoriesList, isLoading: isLoadingTCL } =
    useListTransportCategories();

  const loadingOptions = isLoadingMSL && isLoadingTCL;

  const formattedVehicleCategories = vehicleCategoriesList?.map((vc) =>
    formatCategories(vc, inferredCategories?.vehicleCategoryID || undefined)
  );
  const formattedTransportCategories = transportCategoriesList?.map((t) =>
    formatCategories(t, inferredCategories?.transportCategoryID || undefined)
  );

  const defaultVehicleCategory =
    inferredCategories &&
    formattedVehicleCategories?.find(
      (o) => o.id === inferredCategories.vehicleCategoryID
    );

  const defaultTransportCategory =
    inferredCategories &&
    formattedTransportCategories?.find(
      (o) => o.id === inferredCategories.transportCategoryID
    );

  // get selected from formatted and default to default
  const selectedVehicleCategory =
    formattedVehicleCategories?.find(
      (o) => o.id === selectedCategories?.vehicleCategoryID
    ) || defaultVehicleCategory;

  // get selected from formatted and default to default
  const selectedTransportCategory =
    formattedTransportCategories?.find(
      (o) => o.id === selectedCategories?.transportCategoryID
    ) || defaultTransportCategory;

  const setSelectedVehicleCategory = (opt: string) => {
    setSelected({
      vehicleCategoryID: opt || "",
      transportCategoryID: selectedTransportCategory?.id.toString() || "",
    });
  };
  const setSelectedTransportCategory = (opt: string) => {
    setSelected({
      vehicleCategoryID: selectedVehicleCategory?.id.toString() || "",
      transportCategoryID: opt || "",
    });
  };

  const hasFormattedData =
    formattedVehicleCategories && formattedTransportCategories;

  return (
    <div className="flex-none">
      {!(
        inferredCategories?.vehicleCategoryID &&
        inferredCategories?.transportCategoryID
      ) && (
        <div className="text-sm text-gray-500 max-w-xs mb-10">
          Service schedule could not be inferred from the data available.
        </div>
      )}
      {(!loadingOptions && hasFormattedData && (
        <div className="sm:flex lg:block space-y-8 sm:space-x-8 lg:space-x-0 sm:space-y-0 lg:space-y-8">
          <RadioButtonGroup
            // Explicitly cast to help TS. It won't be undefined because of hasFormattedData
            options={formattedVehicleCategories as RadioSelectOption[]}
            label="Vehicle Category"
            selected={selectedVehicleCategory?.id.toString()}
            onSelect={setSelectedVehicleCategory}
          />
          <RadioButtonGroup
            // Explicitly cast to help TS. It won't be undefined because of hasFormattedData
            options={formattedTransportCategories as RadioSelectOption[]}
            selected={selectedTransportCategory?.id.toString()}
            onSelect={setSelectedTransportCategory}
            label="Transport Category"
          />
        </div>
      )) || (
        <div className="w-48 min-w-full">
          <div className="mb-10">
            <Skeleton className="mb-2" width="50%" />
            <Skeleton count={4} />
          </div>
          <Skeleton className="mb-2" width="50%" />
          <Skeleton count={4} />
        </div>
      )}
    </div>
  );
};

export default ServiceScheduleSelections;
