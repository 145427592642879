import { KeyboardEvent } from "react";

import { removeNonDigitCharacters } from "shared/utils";

import InputFilter from "features/ui/Filters/FilterTypes/InputFilter";
import { onlyAllowPositiveIntegersOnKeyDown } from "features/ui/Filters/utils";

import {
  DURATION_FIELDS_LABELS,
  DurationUnit,
  getValueFromDuration,
  setValueToDuration,
} from "./utils";

interface Props {
  fieldName: string;
  value: string;
  disabled?: boolean;
  onChange: (duration: string) => void;
}

const DurationFilter = ({ fieldName, value, disabled, onChange }: Props) => {
  const onKeyDown = (event: KeyboardEvent) =>
    onlyAllowPositiveIntegersOnKeyDown(event);

  const handleChange =
    (unit: DurationUnit) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const newDuration = setValueToDuration(
        value,
        unit,
        removeNonDigitCharacters(e.target.value)
      );
      onChange(newDuration);
    };

  return (
    <div className="flex space-x-2 items-center">
      {DURATION_FIELDS_LABELS.map(({ name, label }) => (
        <InputFilter
          key={name}
          fieldName={`${fieldName}-${name}`}
          type="number"
          value={getValueFromDuration(value, name)}
          onKeyDown={onKeyDown}
          onChange={handleChange(name)}
          min={0}
          disabled={disabled}
          helperText={label}
          placeholder=""
        />
      ))}
    </div>
  );
};

export default DurationFilter;
