import React from "react";
import { useParams } from "react-router-dom";

import Title from "features/ui/Title";

import FleetInfo from "./FleetInfo";
import FleetViewTabs from "./FleetViewTabs";

type FleetViewParams = {
  fleetName: string;
};

const FleetView = () => {
  const { fleetName } = useParams<FleetViewParams>();
  const fleet = decodeURIComponent(fleetName!);

  return (
    <>
      <Title text={fleet} />
      <FleetInfo fleetName={fleet} />
      <FleetViewTabs fleetName={fleet} />
    </>
  );
};

export default FleetView;
