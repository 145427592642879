import {
  APIListValuesRequest,
  APIListValuesResponse,
  APIPaginatedRequest,
  ExportRequest,
  getFetcher,
  ListAttributesRequest,
  patchFetcher,
} from "shared/api/api";
import { clientV1 } from "shared/api/axios";
import { Issue, IssueMetricsRequest } from "shared/api/issues/api";
import { OrderedValue } from "shared/api/orderedValues/api";
import { Group } from "shared/api/rbac/api";
import { createURL } from "shared/api/utils";
import { SUGGESTED_ISSUES_ROUTE } from "shared/constants";

export interface SuggestedIssue {
  ID: string;
  statusObj?: OrderedValue;
  notes: string | null;
  discovered: string;
  updated: string;
  createdAt: string;
  updatedAt: string;
  assignedGroupID: string | null;
  assignedGroup: Group | null;
  atRiskPopulationSize: number | null;
  atRiskPopulationFilter: string;
  comparisonPopulationFilter?: string;
  claimFilter?: string;
  signalEventOccurrencesFilter?: string;
  confidence: number | null;
  published: boolean;
  subgroups: string[];
  associationStrengthTrend: number | null;
  IPTV: number | null;
  anomalyIDs: string[];
  claimTrend: number | null;
  numClaims: number | null;
  percentDTCsMILOnly: number | null;
  percentIPTVChange: number | null;
  signalEventTrend: number | null;
  promotedTo: Issue[];
}

interface UpdateSuggestedIssueRequest {
  ID: string;
  notes?: string;
  status?: string;
}

// region Update Suggested Issue
const updateSuggestedIssueURI = (IDs: string): string =>
  clientV1.getUri({
    method: "PATCH",
    url: createURL([SUGGESTED_ISSUES_ROUTE, IDs]),
  });

export const updateSuggestedIssue = ({
  ID,
  ...args
}: UpdateSuggestedIssueRequest) =>
  patchFetcher<SuggestedIssue>(updateSuggestedIssueURI(ID), args);
// endregion

// region List Suggested Issues
export interface ListSuggestedIssueRequest
  extends APIPaginatedRequest,
    IssueMetricsRequest {}

export const listSuggestedIssuesURI = (
  params: ListSuggestedIssueRequest
): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([SUGGESTED_ISSUES_ROUTE]),
    params,
  });

export const listSuggestedIssueCountURI = (
  params: ListSuggestedIssueRequest
): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([SUGGESTED_ISSUES_ROUTE, "count"]),
    params,
  });
// endregion

// region Get Suggested Issue Details
export interface GetSuggestedIssueRequest {
  id: string;
}

export const getSuggestedIssueURI = ({
  id,
}: GetSuggestedIssueRequest): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([SUGGESTED_ISSUES_ROUTE, id]),
  });

const listSuggestedIssuesValuesRequestURI = ({
  fieldName,
  ...params
}: APIListValuesRequest): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([SUGGESTED_ISSUES_ROUTE, "values", fieldName]),
    params,
  });

export const listSuggestedIssuesValues = (args: APIListValuesRequest) =>
  getFetcher<APIListValuesResponse>(listSuggestedIssuesValuesRequestURI(args));

export const listSuggestedIssuesAttributesRequestURI = (
  params: ListAttributesRequest
): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([SUGGESTED_ISSUES_ROUTE, "attributes"]),
    params,
  });

// region Export Suggested Issues
export const getSuggestedIssuesExport = (args: ExportRequest) =>
  getFetcher<Blob>(getSuggestedIssuesExportRequestURI(args), {
    responseType: "blob",
  });

const getSuggestedIssuesExportRequestURI = ({
  type = "xlsx",
  ...params
}: ExportRequest): string =>
  clientV1.getUri({
    method: "GET",
    url: createURL([SUGGESTED_ISSUES_ROUTE, "export", type]),
    params,
  });
// endregion
