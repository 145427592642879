import {
  APIListValuesRequest,
  APIListValuesResponse,
  APIPaginatedRequest,
  ExportRequest,
  getFetcher,
  ListAttributesRequest,
} from "shared/api/api";
import { clientV1 } from "shared/api/axios";
import { createURL } from "shared/api/utils";
import { Vehicle, VEHICLES_BASE_URL } from "shared/api/vehicles/api";

const FAILURE_MODES_BASE_ROUTE = "failureModes";
const RISK_MODEL_PREDICTIONS_BASE_ROUTE = "riskModelPredictions";

export enum RiskLabel {
  LOW = "Low",
  MEDIUM = "Medium",
  HIGH = "High",
}

export enum FailureModeStatus {
  ACTIVE = "active",
  ARCHIVED = "archived",
  UNDER_REVIEW = "under_review",
}

export enum FailureModeType {
  CONSUMABLE = "consumable",
  DURABLE = "durable",
}

export interface FailureMode {
  ID: string;
  name: string;
  description: string | null;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;
  type: "consumable" | "durable";
  vehiclesFilter: string;
  failuresRepairsFilter: string | null;
  repairsCustomRecordsFilter: string | null;
  deduplicateWindow: number | null;
  failuresSignalEventOccurrencesFilter: string | null;
  repairsSignalEventOccurrencesFilter: string | null;
  repairsInspectionsFilter: string | null;
  repeatFailureAllowed: boolean;
  recallRepairCost: number | null;
  reactiveRepairCost: number | null;
  currencyCode: "USD" | "EUR" | null;
  failuresClaimsFilter: string | null;
  repairsRepairsFilter: string | null;
  status: FailureModeStatus;
  failuresInspectionsFilter: string | null;
  failuresCustomRecordsFilter: string | null;
  repairsClaimsFilter: string | null;
  deduplicateEvents: boolean;
  proactiveRepairCost: number | null;
  inferenceRunDate: string | null;
}

export interface ListFailureModesRequest extends APIPaginatedRequest {
  skipRequest?: boolean;
}

export interface GetFailureModeRequest {
  id: string;
}

export const listFailureModes = (args: ListFailureModesRequest) =>
  getFetcher<FailureMode[]>(listFailureModesRequestURI(args));

export const listFailureModesRequestURI = (
  params: ListFailureModesRequest
): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([FAILURE_MODES_BASE_ROUTE]),
    params,
  });

export const getFailureModeRequestURI = ({
  id,
  ...params
}: GetFailureModeRequest): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([FAILURE_MODES_BASE_ROUTE, id]),
    params,
  });

export const countFailureModesRequestURI = (
  params: ListFailureModesRequest
): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([FAILURE_MODES_BASE_ROUTE, "count"]),
    params,
  });

// attributes
export const listFailureModeAttributesRequestURI = (
  params: ListAttributesRequest
): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([FAILURE_MODES_BASE_ROUTE, "attributes"]),
    params,
  });

// values
export const listFailureModesValues = (args: APIListValuesRequest) =>
  getFetcher<APIListValuesResponse>(listFailureModesValuesRequestURI(args));

const listFailureModesValuesRequestURI = ({
  fieldName,
  ...params
}: APIListValuesRequest): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([FAILURE_MODES_BASE_ROUTE, "values", fieldName]),
    params,
  });

export const getFailureModesExport = (args: ExportRequest) =>
  getFetcher<Blob>(getFailureModesExportRequestURI(args), {
    responseType: "blob",
  });

const getFailureModesExportRequestURI = ({
  type = "xlsx",
  ...params
}: ExportRequest): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([FAILURE_MODES_BASE_ROUTE, "export", type]),
    params,
  });

export interface RiskModel {
  AUC: number | null;
  ID: string;
  createdAt: string;
  createdBy: string;
  currencyCode: "USD" | "EUR" | null;
  expectedCost: number | null;
  expectedCostDenominator: number | null;
  expectedCostSaved: number | null;
  nominalFalseNegative: number | null;
  nominalFalsePositive: number | null;
  nominalPrecision: number | null;
  nominalRecall: number | null;
  nominalTrueNegative: number | null;
  nominalTruePositive: number | null;
  updatedAt: string;
  updatedBy: string;
}

export const getFailureModeRiskModelRequestURI = ({
  id,
  ...params
}: GetFailureModeRequest): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([FAILURE_MODES_BASE_ROUTE, id, "riskModel"]),
    params,
  });

export enum FailureModeEventType {
  PROACTIVE_REPAIR = "Proactive Repair",
  FAILURE = "Failure",
}

export interface ListFailureModesEventsRequest extends APIPaginatedRequest {
  id: string;
  vehiclesFilter?: string;
}

export const getFailureModeEventsCountRequestURI = ({
  id,
  ...params
}: ListFailureModesEventsRequest): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([FAILURE_MODES_BASE_ROUTE, id, "events", "count"]),
    params,
  });

export interface APIListValuesFailureModeEventRequest
  extends APIListValuesRequest {
  id: string;
}
export const listFailureModeEventsValues = (
  args: APIListValuesFailureModeEventRequest
) =>
  getFetcher<APIListValuesResponse>(
    listFailureModeEventsValuesRequestURI(args)
  );

const listFailureModeEventsValuesRequestURI = ({
  fieldName,
  id,
  ...params
}: APIListValuesFailureModeEventRequest): string =>
  clientV1.getUri({
    method: "get",
    url: createURL(["failureModes", id, "events", "values", fieldName]),
    params,
  });

export interface APIListValuesFailureModeRiskModelPredictionRequest
  extends APIListValuesRequest {
  id: string;
}
export const listFailureModeRiskModelPredictionsValues = (
  args: APIListValuesFailureModeRiskModelPredictionRequest
) =>
  getFetcher<APIListValuesResponse>(
    listFailureModeRiskModelPredictionsValuesRequestURI(args)
  );

const listFailureModeRiskModelPredictionsValuesRequestURI = ({
  fieldName,
  id,
  ...params
}: APIListValuesFailureModeEventRequest): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([
      "failureModes",
      id,
      "riskModelPredictions",
      "values",
      fieldName,
    ]),
    params,
  });

export interface APIListValuesVehicleRiskModelPredictionRequest
  extends APIListValuesRequest {
  vin: string;
}

export const listVehicleRiskModelPredictionsValues = (
  args: APIListValuesVehicleRiskModelPredictionRequest
) =>
  getFetcher<APIListValuesResponse>(
    listVehicleRiskModelPredictionsValuesRequestURI(args)
  );

const listVehicleRiskModelPredictionsValuesRequestURI = ({
  fieldName,
  vin,
  ...params
}: APIListValuesVehicleRiskModelPredictionRequest): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([
      VEHICLES_BASE_URL,
      vin,
      "riskModelPredictions",
      "values",
      fieldName,
    ]),
    params,
  });

export interface FailureModeEventSource {
  ID: string;
  type: string;
  timestamp: string;
}

export interface FailureModeEvent {
  ID: string;
  VIN: string;
  date: string;
  type: FailureModeEventType;
  sources: FailureModeEventSource[];
}

export const listFailureModeEventsRequestURI = ({
  id,
  ...params
}: ListFailureModesEventsRequest): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([FAILURE_MODES_BASE_ROUTE, id, "events"]),
    params,
  });

export interface FailureModeEventsTimelineBucket {
  month: string;
  proactiveRepairs: number;
  failures: number;
  totalRepairs: number;
  proactiveRepairsPTV: number;
  failuresPTV: number;
  totalRepairsPTV: number;
}

export interface FailureModeEventsTimeline {
  monthlyCounts: FailureModeEventsTimelineBucket[];
  totalCounts: {
    proactiveRepairs: number;
    failures: number;
    totalRepairs: number;
    proactiveRepairsPTV: number;
    failuresPTV: number;
    totalRepairsPTV: number;
    totalVehicles: number;
  };
}

export const getFailureModeEventsTimelineURI = ({
  id,
  ...params
}: ListFailureModesEventsRequest): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([FAILURE_MODES_BASE_ROUTE, id, "eventsTimeline"]),
    params,
  });

export interface ListFailureModesPredictionsRequest
  extends APIPaginatedRequest {
  id: string;
  vehiclesFilter?: string;
}

export interface ListVehicleRiskModelPredictionsRequest
  extends APIPaginatedRequest {
  vin: string;
}

export interface RiskModelPrediction {
  VIN: string;
  failureModeID: string;
  riskStatus: string;
  riskScore: string;
  riskLabel: RiskLabel;
  repairDetails: string | null;
  repairByDate: string | null;
  vehicle: Vehicle | null;
  failureMode: FailureMode | null;
}

export const listFailureModeRiskModelPredictionsRequestURI = ({
  id,
  ...params
}: ListFailureModesEventsRequest): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([FAILURE_MODES_BASE_ROUTE, id, "riskModelPredictions"]),
    params,
  });

export const listVehicleRiskModelPredictionsRequestURI = ({
  vin,
  ...params
}: ListVehicleRiskModelPredictionsRequest): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([VEHICLES_BASE_URL, vin, "riskModelPredictions"]),
    params,
  });

export const listVehicleRiskModelPredictionsCountRequestURI = ({
  vin,
  ...params
}: ListVehicleRiskModelPredictionsRequest): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([VEHICLES_BASE_URL, vin, "riskModelPredictions", "count"]),
    params,
  });

export const listFailureModeRiskModelPredictionsCountRequestURI = ({
  id,
  ...params
}: ListFailureModesEventsRequest): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([
      FAILURE_MODES_BASE_ROUTE,
      id,
      "riskModelPredictions",
      "count",
    ]),
    params,
  });

// Repair attributes
export const listRiskModelPredictionAttributesRequestURI = (
  params: ListAttributesRequest
): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([RISK_MODEL_PREDICTIONS_BASE_ROUTE, "attributes"]),
    params,
  });

export const getFailureModeRiskModelPredictionsExport = (args: ExportRequest) =>
  getFetcher<Blob>(getFailureModeRiskModelPredictionsRequestURI(args), {
    responseType: "blob",
  });

const getFailureModeRiskModelPredictionsRequestURI = ({
  IDs,
  type = "xlsx",
  ...params
}: ExportRequest): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([
      FAILURE_MODES_BASE_ROUTE,
      ...IDs,
      "riskModelPredictions",
      "export",
      type,
    ]),
    params,
  });

export const getVehicleRiskModelPredictionsExport = (args: ExportRequest) =>
  getFetcher<Blob>(getVehicleRiskModelPredictionsRequestURI(args), {
    responseType: "blob",
  });

const getVehicleRiskModelPredictionsRequestURI = ({
  IDs,
  type = "xlsx",
  ...params
}: ExportRequest): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([
      VEHICLES_BASE_URL,
      ...IDs,
      "riskModelPredictions",
      "export",
      type,
    ]),
    params,
  });

export const getFailureModeEventsExport = (args: ExportRequest) =>
  getFetcher<Blob>(getFailureModeEventsRequestURI(args), {
    responseType: "blob",
  });

const getFailureModeEventsRequestURI = ({
  IDs,
  type = "xlsx",
  ...params
}: ExportRequest): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([
      FAILURE_MODES_BASE_ROUTE,
      ...IDs,
      "events",
      "export",
      type,
    ]),
    params,
  });

// Events attributes
export const listFailureModeEventsAttributesRequestURI = (
  params: ListAttributesRequest
): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([FAILURE_MODES_BASE_ROUTE, "events", "attributes"]),
    params,
  });
