import { useEffect, useState } from "react";

import { useSignalEventOccurrencesFiltersSchema } from "shared/hooks";

import { SIGNAL_EVENTS_FILTER_LABEL } from "pages/SignalEventsAnalytics/constants";
import { SignalEventsAnalyticsTabsProps } from "pages/SignalEventsAnalytics/SignalEventsAnalyticsTabs";

import DropdownSelect from "features/ui/DropdownSelect";
import { DEFAULT_EMPTY_RELATES_FILTER } from "features/ui/Filters/FilterTypes/RelatesFilter/constants";
import RelatesFilterActions from "features/ui/Filters/FilterTypes/RelatesFilter/RelatesFilterForm/RelatesFilterActions";
import RelatesTimeWindowForm from "features/ui/Filters/FilterTypes/RelatesFilter/RelatesFilterForm/RelatesTimeWindowForm";
import FilterSelector from "features/ui/Filters/FilterWizard/FilterSelector";
import { getPendingFiltersKey } from "features/ui/Filters/FilterWizard/utils";
import { useFilterSortState } from "features/ui/Filters/hooks";
import { RelatesFilterState } from "features/ui/Filters/types";
import { getFilterLabel } from "features/ui/Filters/utils";

import AssociatedSignalEventsTable from "./AssociatedSignalEventsTable";
import { SE_ASSOCIATED_SE_PAGE_KEY } from "./constants";

const PENDING_FILTERS_ASSOCIATED_SE_LS_KEY = getPendingFiltersKey(
  SE_ASSOCIATED_SE_PAGE_KEY
);

const AssociatedSignalEvents = ({
  signalEventsFiltersFilterSortState,
  vehiclesFilters,
  onBadRequest,
}: SignalEventsAnalyticsTabsProps) => {
  const associatedSignalEventsFilterSortState = useFilterSortState({
    pageKey: SE_ASSOCIATED_SE_PAGE_KEY,
    defaultFilterValues: DEFAULT_EMPTY_RELATES_FILTER.filters,
    pendingFiltersLocalStorageKey: PENDING_FILTERS_ASSOCIATED_SE_LS_KEY,
  });

  const initialRelatesFilter: RelatesFilterState = {
    operator: DEFAULT_EMPTY_RELATES_FILTER.operator,
    options:
      associatedSignalEventsFilterSortState.relatedSignalEventsFilter
        ?.options ?? DEFAULT_EMPTY_RELATES_FILTER.options,
    filters: associatedSignalEventsFilterSortState.filters,
  };
  const [appliedRelatesFilter, setAppliedRelatesFilter] =
    useState<RelatesFilterState>(initialRelatesFilter);
  const [relatesFilter, setRelatesFilter] =
    useState<RelatesFilterState>(initialRelatesFilter);

  useEffect(() => {
    setRelatesFilter({
      ...relatesFilter,
      filters: associatedSignalEventsFilterSortState.filters,
    });
    setAppliedRelatesFilter({
      ...appliedRelatesFilter,
      filters: associatedSignalEventsFilterSortState.filters,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [associatedSignalEventsFilterSortState.filters]);

  const onUpdateRelatesFilter = (row: RelatesFilterState) => {
    setRelatesFilter(row);
  };

  const onApply = () => {
    setAppliedRelatesFilter(relatesFilter);
    if (
      associatedSignalEventsFilterSortState.manageRelatedSignalEventsFilterChange
    ) {
      associatedSignalEventsFilterSortState.manageRelatedSignalEventsFilterChange(
        relatesFilter
      );
    }
  };

  const onCancel = () => {
    const defaultRelatesFilter = {
      operator: DEFAULT_EMPTY_RELATES_FILTER.operator,
      options: DEFAULT_EMPTY_RELATES_FILTER.options,
      filters: associatedSignalEventsFilterSortState.filters,
    };
    setRelatesFilter(defaultRelatesFilter);
    setAppliedRelatesFilter(defaultRelatesFilter);

    if (
      associatedSignalEventsFilterSortState.manageRelatedSignalEventsFilterChange
    ) {
      associatedSignalEventsFilterSortState.manageRelatedSignalEventsFilterChange(
        defaultRelatesFilter
      );
    }
  };

  const signalEventsFilterLabel = getFilterLabel(
    SIGNAL_EVENTS_FILTER_LABEL,
    associatedSignalEventsFilterSortState.filters
  );

  const signalEventsOccurrencesFiltersSchema =
    useSignalEventOccurrencesFiltersSchema();

  return (
    <>
      <div className="mt-4 space-y-2">
        <div className="flex flex-wrap leading-10 space-x-2 items-end mb-6">
          <span>Associated Signal Event defined by</span>
          <DropdownSelect
            testId="associated-signal-events-filters-dropdown"
            label={signalEventsFilterLabel}
            buttonClass="mt-6 h-[38px] mr-4"
            content={
              <FilterSelector
                schema={signalEventsOccurrencesFiltersSchema}
                filterSortState={associatedSignalEventsFilterSortState}
                title={SIGNAL_EVENTS_FILTER_LABEL}
                testId="associated-signal-event-filters"
                pendingFiltersKey={PENDING_FILTERS_ASSOCIATED_SE_LS_KEY}
              />
            }
          />
          <RelatesTimeWindowForm
            relatesState={relatesFilter}
            onUpdate={onUpdateRelatesFilter}
            baseEntityText={"Base Signal Event"}
            inFilterSelector={false}
          />
          <RelatesFilterActions
            relatesState={relatesFilter}
            appliedRelatesFilter={appliedRelatesFilter}
            onApply={onApply}
            onCancel={onCancel}
          />
        </div>
        <AssociatedSignalEventsTable
          onBadRequest={onBadRequest}
          signalEventsFiltersFilterSortState={
            signalEventsFiltersFilterSortState
          }
          vehiclesFilters={vehiclesFilters}
          relatesFilter={appliedRelatesFilter}
        />
      </div>
    </>
  );
};

export default AssociatedSignalEvents;
