import { SortBy } from "shared/types";

export const PAGE_TITLE = "Service Recommendations";
export const PAGE_KEY = "serviceRecommendations";
export const DEFAULT_SORT: SortBy = { createdAt: "desc" };
export const ITEMS_PER_PAGE = 15;

export const SP_RECOMMENDATIONS_VEHICLE_FILTERS_LS_KEY =
  "serviceRecommendationsVehiclesFilters";
export const SP_RECOMMENDATIONS_FILTERS_LS_KEY = "serviceRecommendationFilters";
export const SP_RECOMMENDATIONS_FILTER_LABEL = "Service Recommendation Filters";
