import { AppConfig, AuthScopes, PagesConfig } from "config/config";

import pagesConfig from "./pagesConfig.json";

export const tenant = "paccar";
export const host = `${tenant}.cloud.viaduct.ai`;

const brandingConfig = {
  feedbackStyles: {
    backgroundColor: "#4781D8",
  },
};

const prodAuthConfig = {
  name: "PACCAR",
  idp: "0oa22md9en5RAemzN5d6",
  clientId: "0oa6ek3mttVJBmTkH5d7",
  issuer: "https://viaduct-cloud.okta.com/oauth2/ausd1p5hj5BgR6neP5d6",
  scopes: ["openid", "email", "offline_access"] as AuthScopes[],
  postLogoutRedirectUri: "https://powertrain.paccar.cloud/logout",
};

const prodAPIConfig = {
  url: `https://api.${host}/v0`,
};

const featureFlagsProd = {
  launchDarklyClientId: "61dc442a4fad7b146dcda837",
};

export const prodAppConfig: AppConfig = {
  environment: "prod",
  pages: pagesConfig as PagesConfig,
  branding: brandingConfig,
  analytics: {
    heap: { id: "2946143066" },
  },
  featureFlags: featureFlagsProd,
  auth: prodAuthConfig,
  api: prodAPIConfig,
  isIframed: true,
};
