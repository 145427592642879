import React from "react";
import Skeleton from "react-loading-skeleton";

import { Vehicle } from "shared/api/vehicles/api";
import { useVehicle } from "shared/api/vehicles/hooks";
import { getTenantMileageUnit } from "shared/utils";

import {
  useVehicleImage,
  useVehicleTransportCategoryDisplayName,
} from "pages/VINView/hooks";

import APIError from "features/ui/APIError";

import InfoCardsData from "./InfoCardsData";

interface Props {
  vin: string;
}

export interface VINViewVehicle extends Vehicle {
  inferredTransportCategoryName?: string;
}

const VehicleInfo = ({ vin }: Props) => {
  const inferredTransportCategoryName =
    useVehicleTransportCategoryDisplayName(vin);
  const { data, error, isLoading } = useVehicle({
    vin,
    mileageUnit: getTenantMileageUnit(),
  });
  const TruckImage = useVehicleImage(data);

  // we add async data from our hook. Data label/key defined in infoCardsDataMap
  const dataModified = data
    ? { ...data, inferredTransportCategoryName }
    : undefined;

  return (
    <div className="md:flex md:space-x-16">
      <div className="flex flex-col items-start min-w-[24rem] max-w-md">
        <div
          className="pb-4 text-3xl font-extrabold max-w-full break-words"
          data-testid="vin"
        >
          {vin}
        </div>
        {!isLoading && !error && <img src={TruckImage} alt="Vehicle" />}
        {isLoading && (
          <div className="w-full">
            <Skeleton height={170} width="100%" />
          </div>
        )}
      </div>

      <div className="flex-1 min-w-0">
        {!error && (
          <div className="flex flex-col space-y-4">
            <InfoCardsData data={dataModified} />
          </div>
        )}
        {error && <APIError error={error} />}
      </div>
    </div>
  );
};

export default VehicleInfo;
