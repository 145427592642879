import Button from "features/ui/Button";
import {
  FILTER_INDENT_GROUP_MARGIN_PX,
  FILTER_INDENT_MARGIN_PX,
} from "features/ui/Filters/FilterBuilder/constants";
import AnyAllSelect, {
  AnyAll,
} from "features/ui/Filters/FilterBuilder/FilterGroup/AnyAllSelect";
import FilterRow from "features/ui/Filters/FilterBuilder/FilterRow";
import { OnRowDataChangeHandle } from "features/ui/Filters/FilterBuilder/FilterRow/FilterRow";
import RowActions from "features/ui/Filters/FilterBuilder/FilterRow/RowActions";
import {
  FilterGroupState,
  FilterRowState,
} from "features/ui/Filters/FilterBuilder/types";
import { SchemaEntry } from "features/ui/Table";

interface Props {
  schema: SchemaEntry<string>[];
  id: string;
  depth: number;
  children: (FilterRowState | FilterGroupState)[];
  onNewRow: (id: string) => void;
  onNewGroup: (id: string) => void;
  onDelete?: (id: string) => void;
  anyAll: AnyAll;
  onGroupOperatorChange: (id: string, anyAll: AnyAll) => void;
  onRowDataChange: OnRowDataChangeHandle;
  onResetToDefault?: () => void;
  disabled?: boolean;
  attributePlaceholder?: string;
}

const FilterGroup = ({
  schema,
  id,
  depth,
  children,
  onNewRow,
  onNewGroup,
  onDelete,
  anyAll,
  onGroupOperatorChange,
  onRowDataChange,
  onResetToDefault,
  disabled,
  attributePlaceholder,
}: Props) => {
  const childrenLength = children.length;

  return (
    <div
      style={
        depth === 0
          ? undefined
          : {
              marginLeft: `${FILTER_INDENT_GROUP_MARGIN_PX}px`,
              paddingLeft: `${
                FILTER_INDENT_MARGIN_PX - FILTER_INDENT_GROUP_MARGIN_PX
              }px`,
              background: `rgba(0,0,0, ${depth * 3}%)`,
            }
      }
      className="mt-1 mb-2 pb-2 pt-1 rounded-sm"
      data-testid="filter-group"
    >
      <div
        className="flex items-center justify-between group/group"
        data-testid="group-actions"
      >
        <AnyAllSelect
          selected={anyAll}
          onChange={(selected) => onGroupOperatorChange(id, selected.id)}
          disabled={disabled}
        />
        {depth === 0 && onResetToDefault && (
          <Button
            variant="text"
            size="small"
            color="primary"
            onClick={onResetToDefault}
            className="text-nowrap"
            data-testid="reset-all-to-default"
            disabled={disabled}
          >
            Reset all to default
          </Button>
        )}
        {depth > 0 && (
          <RowActions
            id={id}
            onNewRow={onNewRow}
            onNewGroup={onNewGroup}
            onDelete={onDelete}
            disabled={disabled}
          />
        )}
      </div>
      {/* Each group can contain any combination of rows or groups */}
      {children.map((child) => {
        // Do not allow deleting the last child
        const disableDelete = childrenLength === 1;

        if (child.type === "row") {
          return (
            <FilterRow
              key={child.id}
              onNewRow={onNewRow}
              onNewGroup={onNewGroup}
              onDelete={onDelete}
              onRowDataChange={onRowDataChange}
              disableDelete={disableDelete}
              schema={schema}
              disabled={disabled}
              attributePlaceholder={attributePlaceholder}
              {...child}
            />
          );
        } else {
          return (
            <FilterGroup
              schema={schema}
              key={child.id}
              id={child.id}
              depth={depth + 1}
              children={child.children}
              onNewRow={onNewRow}
              onNewGroup={onNewGroup}
              onDelete={childrenLength > 1 ? onDelete : undefined}
              anyAll={child.anyAll}
              onGroupOperatorChange={onGroupOperatorChange}
              onRowDataChange={onRowDataChange}
              disabled={disabled}
              attributePlaceholder={attributePlaceholder}
            />
          );
        }
      })}
    </div>
  );
};

export default FilterGroup;
