export const riskGroupDescription = (
  <ul className="text-left list-disc px-5 py-2">
    <li className="mb-2">
      <span className="font-semibold">High risk</span>: Vehicle is one of the
      highest-risk vehicles for this Failure Mode. It is recommended that these
      vehicles be considered for proactive repair.
    </li>
    <li className="mb-2">
      <span className="font-semibold">Medium risk</span>: Vehicle has shown only
      moderate risk factors for this Failure Mode. It is recommended that these
      vehicles be monitored for failure because vehicle risk profiles can change
      over time.
    </li>
    <li>
      <span className="font-semibold">Low risk</span>: Vehicle is one of the
      lowest-risk vehicles for this Failure Mode. No action is recommended for
      these vehicles at this time.
    </li>
  </ul>
);

export const riskStatusDescription = (
  <ul className="text-left list-disc px-5 py-2">
    <li className="mb-2">
      <span className="font-semibold">Active</span>: The vehicle has up-to-date
      data and is receiving predictions.
    </li>
    <li className="mb-2">
      <span className="font-semibold">No Recent Data</span>: The vehicle has not
      had a data reading for the last 90 days. Depending on the Failure Mode it
      may still be able to receive predictions.
    </li>
    <li className="mb-2">
      <span className="font-semibold">Inactive</span>: The vehicle has already
      had a repair for the Failure Mode, therefore it is not receiving new
      predictions.
    </li>
  </ul>
);

export const riskMultipleDescription = (
  <div className="text-left">
    Shows how many times a vehicle is more likely to fail than the average
    vehicle that is eligible for the same failure.
  </div>
);

export const repairStatusDescription = (
  <ul className="text-left list-disc px-5 py-2">
    <li className="mb-2">
      <span className="font-semibold">Natural Failure</span>: The vehicle has
      experienced a natural failure for the Failure Mode. The outcome may be
      that a claim was filed, or a part was replaced.
    </li>
    <li className="mb-2">
      <span className="font-semibold">Campaign Repair</span>: The vehicle has
      received a repair due to the campaign identified by the campaign code.
    </li>
  </ul>
);

export const warrantyActiveDescription = (
  <div className="text-left">
    Shows whether the specific vehicle part is still eligible for warranty. The
    value is calculated based on the vehicle's service duration and mileage.
    <br /> <br />
    An empty cell means that there was not enough data to calculate the warranty
    status.
  </div>
);
