import { useEffect, useState } from "react";
import classNames from "classnames";
import { MdClose as CloseIcon } from "react-icons/md";
import { Dialog, DialogContent, IconButton } from "@mui/material";

export interface ModalProps {
  isOpened?: boolean;
  triggerElement: JSX.Element | string;
  triggerElementClassNames?: string;
  children: JSX.Element[];
  showCloseIcon?: boolean;
  modalClassNames?: string;
  onOpen?: () => void;
}
const Modal = ({
  isOpened = false,
  triggerElement,
  triggerElementClassNames,
  children,
  showCloseIcon = false,
  modalClassNames,
  onOpen,
}: ModalProps) => {
  const [modalOpen, setModalOpen] = useState(isOpened);

  useEffect(() => {
    setModalOpen(isOpened);
  }, [isOpened]);

  const onModalOpen = () => {
    setModalOpen(true);
    onOpen && onOpen();
  };

  return (
    <>
      <span
        className={classNames(
          "relative cursor-pointer",
          triggerElementClassNames
        )}
        onClick={onModalOpen}
      >
        {triggerElement}
      </span>
      <Dialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        fullWidth
        maxWidth="md"
      >
        {showCloseIcon && (
          <IconButton
            onClick={() => setModalOpen(false)}
            data-testid="modal-close-button"
            style={{ position: "absolute", top: "5px", right: "5px" }}
          >
            <CloseIcon />
          </IconButton>
        )}
        <DialogContent>{children}</DialogContent>
      </Dialog>
    </>
  );
};

export default Modal;
