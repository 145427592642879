import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { HiChevronDown } from "react-icons/hi";
import { Paper, PopoverOrigin } from "@mui/material";
import { ButtonProps } from "@mui/material/Button";
import Menu from "@mui/material/Menu";

import { TestProps } from "shared/types";

import Button from "features/ui/Button";
import { getFilterMenuWrapperClass } from "features/ui/Filters/FilterBuilder/utils";
import { Option, SelectOption } from "features/ui/Select";

export interface DropdownSelectOption<T = Option>
  extends SelectOption<T>,
    TestProps {
  href?: string;
  download?: string;
  onClick?: () => void;
  classNames?: string;
}

export interface DropdownSelectProps<T extends DropdownSelectOption>
  extends TestProps {
  open?: boolean;
  label?: string;
  size?: ButtonProps["size"];
  icon?: React.ReactElement;
  options?: T[]; // specify either options or content - options override the content
  content?: React.ReactNode; // options override the content
  onOpen?: (isOpen: boolean) => void;
  onSelect?: (value: T) => void;
  color?: ButtonProps["color"];
  buttonComponent?: React.ReactNode; // if specified, uses custom element instead of Button component to click which ignores label, labelSize, icon, kind & onOpen
  buttonClass?: string;
  variant?: ButtonProps["variant"];
  hasAdvancedFilters?: boolean;
  disabled?: ButtonProps["disabled"];
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
}

const defaultActiveButtonClass = "text-left";

const DropdownSelect = <T extends DropdownSelectOption>({
  open,
  label,
  size,
  icon = <HiChevronDown />,
  options,
  content,
  onOpen,
  onSelect,
  color = "secondary",
  variant = "outlined",
  buttonComponent,
  buttonClass = "",
  testId,
  hasAdvancedFilters,
  disabled,
  anchorOrigin = {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin = {
    vertical: "top",
    horizontal: "left",
  },
}: DropdownSelectProps<T>) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const showContent = Boolean(!options && content);

  const [isOpen, setIsOpen] = useState(anchorEl ? true : false);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
    onOpen && onOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    onOpen && onOpen(false);
    setAnchorEl(null);
  };

  // use "open" prop to forcefully "close" the dropdown
  // we can't forcefully open it as we might not have anchorEl ready (if open was a default on 1st render)
  useEffect(() => {
    if (open === false) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const defaultButton = (
    <Button
      color={color || "secondary"}
      variant={variant}
      size={size}
      className={buttonClass || defaultActiveButtonClass}
      endIcon={icon}
      disabled={disabled}
    >
      {label}
    </Button>
  );

  const CTA = (
    <div
      data-testid={testId}
      aria-controls={isOpen ? "dropdown-menu" : undefined}
      aria-haspopup="true"
      aria-expanded={isOpen ? "true" : undefined}
      onClick={disabled ? undefined : handleClick}
      className={classNames({
        "pointer-events-none opacity-50": buttonComponent && disabled,
      })}
    >
      {buttonComponent ? buttonComponent : defaultButton}
    </div>
  );

  const applyCustomClasses = hasAdvancedFilters !== undefined;

  return (
    <div>
      {CTA}
      <Menu
        id="dropdown-menu"
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorEl={anchorEl}
        MenuListProps={{
          sx: { py: 0, zIndex: 999 },
          "aria-labelledby": "dropdown-menu",
        }}
        marginThreshold={0}
        className={
          applyCustomClasses
            ? getFilterMenuWrapperClass(hasAdvancedFilters)
            : undefined
        }
        slotProps={
          applyCustomClasses
            ? {
                paper: {
                  sx: {
                    width: "100%",
                    maxWidth: "100%",
                  },
                },
              }
            : undefined
        }
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        // needed so FilterSelector properly takes full-height when put inside the content
        transitionDuration={0}
      >
        <Paper
          className={classNames("flex flex-col !text-sm", {
            "!max-h-72 overflow-y-auto": options,
          })}
        >
          {showContent && (
            <div
              onKeyDown={(event: any) => {
                // needed so that Menu doesn't default to "go-to MenuItem logic" when we type ..
                event.stopPropagation();
              }}
            >
              {content}
            </div>
          )}
          {options &&
            options.map((option) => (
              <Button
                key={option.id}
                color="secondary"
                variant="text"
                size={size}
                onClick={() => {
                  // close popover before calling onSelect so it acts more like a dropdown select
                  handleClose();
                  onSelect && onSelect(option);
                }}
                href={option.href}
                fullWidth
                className={classNames(
                  "!justify-start !px-4 !py-2 font-normal text-right",
                  option.classNames
                )}
                testId={option.testId}
                download={option.download}
              >
                {option.value}
              </Button>
            ))}
        </Paper>
      </Menu>
    </div>
  );
};

export default DropdownSelect;
