import { VEHICLES_COLLECTION_FILTER } from "shared/filterDefinitions";
import { SortBy } from "shared/types";

export const GLOBAL_FILTERS = [VEHICLES_COLLECTION_FILTER];
export const INDEX_WHERE_FM_COLUMNS_START = 2;
export const VEHICLES_PAGE_KEY = "vehicles";

export const VEHICLES_PER_PAGE = 15;
export const STICKY_FIRST_COLUMN = true;

export const MAX_VISIBLE_RPOS = 2;
export const DEFAULT_SORT: SortBy = { mileage: "desc" };
export const TITLE = "Vehicles";
