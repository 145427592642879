import { useState } from "react";
import classNames from "classnames";
import { ParseResult } from "papaparse";
import { MdClose as CloseIcon } from "react-icons/md";
import { useCSVReader } from "react-papaparse";

import Button from "features/ui/Button";
import Checkbox from "features/ui/Checkbox/Checkbox";

import styles from "./Step1.module.css";
import VINPreview from "./VINPreview";

interface Props {
  vins: string[];
  onVinsChange: (vins: string[]) => void;
  onHasHeaderChange: (hasHeader: boolean) => void;
  hasHeaderRow: boolean;
}

interface ReactPapaParseArguments {
  getRootProps: () => Object;
  acceptedFile: File;
  ProgressBar: React.FunctionComponent;
  getRemoveFileProps: () => Object;
}

const Step1 = ({
  vins,
  onVinsChange,
  onHasHeaderChange,
  hasHeaderRow,
}: Props) => {
  const { CSVReader } = useCSVReader();
  const [zoneHover, setZoneHover] = useState(false);
  const [fileName, setFileName] = useState("");

  const showFileUpload = vins.length === 0;

  const handleOnDrop = ({ data }: ParseResult<string[]>, file?: any) => {
    if (file && file.name) {
      setFileName(file.name);
    }
    // assume first column is "vin" for now
    const vins = data.map((row) => row[0]).filter((v) => v.trim());

    onVinsChange(vins);
  };

  /* TODO: Handle file upload errors */
  const handleOnError = (err: any, file: any, inputElem: any, reason: any) => {
    console.warn(err, file, inputElem, reason);
  };

  const resetFileUpload = () => {
    onVinsChange([]);
    setFileName("");
  };

  return (
    <CSVReader
      accept="text/csv, .csv"
      onUploadAccepted={handleOnDrop}
      onUploadRejected={handleOnError}
      onDragOver={(event: DragEvent) => {
        event.preventDefault();
        setZoneHover(true);
      }}
      onDragLeave={(event: DragEvent) => {
        event.preventDefault();
        setZoneHover(false);
      }}
    >
      {({
        getRootProps,
        acceptedFile,
        ProgressBar,
        getRemoveFileProps,
      }: ReactPapaParseArguments) =>
        !showFileUpload && acceptedFile ? (
          <div>
            <div className={styles.fileContainer}>
              Selected file: <strong className="ml-2">{fileName}</strong>
              <div className="ml-auto" {...getRemoveFileProps()}>
                <Button
                  label="Reset"
                  color="secondary"
                  title="Remove file"
                  endIcon={<CloseIcon />}
                  size="small"
                  onClick={resetFileUpload}
                />
              </div>
            </div>
            <ProgressBar />
            <VINPreview vins={vins} />
            <div className="py-2">
              <Checkbox
                checked={hasHeaderRow}
                onChange={onHasHeaderChange}
                label="This file has header row"
              />
            </div>
          </div>
        ) : (
          <div
            {...getRootProps()}
            className={classNames(styles.zone, {
              [styles.zoneHover]: zoneHover,
            })}
            data-testid="zone-collection-upload"
          >
            <span>
              - Drag and drop or click to upload a CSV file
              <br />- The first column must contain VINs
            </span>
          </div>
        )
      }
    </CSVReader>
  );
};

export default Step1;
