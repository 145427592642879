import Skeleton from "react-loading-skeleton";

import { Claim } from "shared/api/claims/api";
import { Vehicle } from "shared/api/vehicles/api";
import { useVehicle } from "shared/api/vehicles/hooks";
import useVehiclesSchema from "shared/schemas/vehiclesSchema";
import { getTenantMileageUnit } from "shared/utils";

import CardHeader from "pages/Issues/Details/CardHeader";

import Card from "features/ui/Card";
import LabelValuePairs from "features/ui/LabelValuePairs";
import { SchemaEntry } from "features/ui/Table";

interface Props {
  data: Claim;
}

const CARD_TITLE = "Dealer Details";

const CardDealerDetails = ({ data }: Props) => {
  const {
    data: vehicleData,
    isLoading,
    error,
  } = useVehicle({ vin: data.VIN, mileageUnit: getTenantMileageUnit() });
  const { schema } = useVehiclesSchema();

  if (isLoading) return <Skeleton height={300} />;

  if (error) return <div>Error</div>;

  if (!vehicleData) return <div>No data</div>;

  return (
    <Card>
      <CardHeader title={CARD_TITLE} />
      <LabelValuePairs
        schema={schema as SchemaEntry<keyof Vehicle>[]}
        data={vehicleData}
        fields={[
          "saleDealer.name",
          "saleDealer.city",
          "saleDealer.provinceCode",
          "saleDealer.countryCode",
          "saleDealer.contact",
          "lastKnownDealer.name",
          "lastKnownDealer.city",
          "lastKnownDealer.provinceCode",
          "lastKnownDealer.countryCode",
          "lastKnownDealer.contact",
        ]}
      />
    </Card>
  );
};

export default CardDealerDetails;
