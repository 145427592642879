import { Stepper } from "react-form-stepper";
import { StepDTO } from "react-form-stepper/dist/components/Step/StepTypes";

import styles from "./FormStepper.module.css";

interface Props {
  steps: StepDTO[];
  activeStep: number;
}

const STYLE_CONFIG = {
  activeBgColor: "#659cef",
  activeTextColor: "white",
  completedBgColor: "#659cef",
  completedTextColor: "white",
  inactiveBgColor: "lightgray",
  inactiveTextColor: "white",
  size: 30,
  circleFontSize: 20,
  labelFontSize: 14,
  borderRadius: 50,
  fontWeight: "medium",
};

const FormStepper = ({ steps, activeStep }: Props) => {
  return (
    <Stepper
      className={styles.stepper}
      steps={steps}
      // gotta define whole styleConfig object to set 1 color, what the? (#659cef)
      styleConfig={STYLE_CONFIG}
      activeStep={activeStep}
    />
  );
};

export default FormStepper;
