import React, { useState } from "react";
import { HiChevronDown } from "react-icons/hi";

import { deleteGroup, Group } from "shared/api/rbac/api";

import { toFEGroupMemberAccess } from "pages/Groups/utils";

import DeleteAction from "features/ui/DeleteAction";
import DropdownSelect, {
  DropdownSelectOption,
} from "features/ui/DropdownSelect/DropdownSelect";
import PermissionsSettingsAction from "features/ui/PermissionsDialog/PermissionsSettingsAction";

import { routes } from "services/routes";

type Props = {
  group: Group;
  groupRequestKeys?: string[];
};

const PERMISSIONS_OPTION_ID = "permissions";
const LABEL = "Manage Group";
const ENTITY = "group";
const DELETE_OPTION_ID = "delete_" + ENTITY;

const OPTIONS: DropdownSelectOption[] = [
  {
    id: PERMISSIONS_OPTION_ID,
    value: "Manage Permissions",
    testId: PERMISSIONS_OPTION_ID,
  },
  {
    id: DELETE_OPTION_ID,
    value: "Delete",
    classNames: "text-red-500",
    testId: DELETE_OPTION_ID,
  },
];

const GroupActions = ({ group, groupRequestKeys }: Props) => {
  const [selectedAction, setSelectedAction] = useState("");

  return (
    <>
      <DropdownSelect
        label={LABEL}
        options={OPTIONS}
        icon={<HiChevronDown />}
        onSelect={({ id }) => setSelectedAction(id as string)}
        testId="group-actions-dropdown"
      />
      {selectedAction === PERMISSIONS_OPTION_ID && (
        <PermissionsSettingsAction
          entity={ENTITY}
          entityId={group.ID}
          entityName={group.name}
          permissions={group.access}
          memberAccess={toFEGroupMemberAccess(group.memberAccess)}
          canEdit={group.canEdit}
          showButton={false}
          defaultOpen={true}
          onClose={() => setSelectedAction("")}
          entityRequestKeys={groupRequestKeys}
        />
      )}
      {selectedAction === DELETE_OPTION_ID && (
        <DeleteAction
          data={group}
          entityName={ENTITY}
          deleteCallback={deleteGroup}
          showButton={false}
          defaultOpen={true}
          onClose={() => setSelectedAction("")}
          onSuccessNavigateToRoute={routes.groups}
          entityRequestKeys={groupRequestKeys}
        />
      )}
    </>
  );
};

export default GroupActions;
