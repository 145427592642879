import { useState } from "react";
import Skeleton from "react-loading-skeleton";

import { useSignalEventsOccurrencesMetricsHistory } from "shared/api/signalEvents/hooks";

import { prepareMetricsHistoryDataByTimestamp } from "pages/ClaimAnalytics/tabPages/Claims/utils";
import { SIGNAL_EVENTS_TAB_KEY } from "pages/SignalEventsAnalytics/constants";
import { SignalEventsFiltersProps } from "pages/SignalEventsAnalytics/SignalEventsAnalyticsTabs";

import APIError from "features/ui/APIError";
import Card from "features/ui/Card";
import ChartActions from "features/ui/charts/Actions/ChartActions";
import { ChartActionsWrap } from "features/ui/charts/Actions/ChartActionsWrap";
import {
  ChartAction,
  SelectedChartOptions,
} from "features/ui/charts/Actions/types";
import LineChart from "features/ui/charts/LineChart";
import {
  getAxisLabel,
  getDefaultActions,
  getSelectedOptionId,
} from "features/ui/charts/utils";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";

import { ChartTooltip } from "./ChartTooltip";
import { getSignalEventChartActions, Y_AXIS_OPTIONS } from "./utils";

const CHART_HEIGHT_PX = 400;
export const SIGNAL_EVENT_CHART_OPTIONS_KEY =
  "signalEventsAnalyticsSignalEventsChartOptions";

const actions: ChartAction[] = getSignalEventChartActions();

const SignalEventsChart = ({
  signalEventsFilters,
  vehiclesFilters,
  chartSettings,
  manageChartSettingsChange,
  onBadRequest,
}: SignalEventsFiltersProps) => {
  const initialSelectedOptions: SelectedChartOptions[] =
    chartSettings &&
    chartSettings[SIGNAL_EVENTS_TAB_KEY] &&
    chartSettings[SIGNAL_EVENTS_TAB_KEY][SIGNAL_EVENT_CHART_OPTIONS_KEY]
      ? chartSettings[SIGNAL_EVENTS_TAB_KEY][SIGNAL_EVENT_CHART_OPTIONS_KEY]
      : getDefaultActions(actions);

  const [selectedOptions, setSelectedOptions] = useState<
    SelectedChartOptions[]
  >(initialSelectedOptions);

  const handleOptionChange = (newSelectedOptions: SelectedChartOptions[]) => {
    setSelectedOptions(newSelectedOptions);
    if (manageChartSettingsChange) {
      manageChartSettingsChange(
        newSelectedOptions,
        SIGNAL_EVENT_CHART_OPTIONS_KEY
      );
    }
  };

  const { data, isLoading, error } = useSignalEventsOccurrencesMetricsHistory({
    signalEventOccurrencesFilter: getFiltersQuery(signalEventsFilters),
    vehiclesFilter: getFiltersQuery(vehiclesFilters),
  });

  if (error) {
    return <APIError error={error} onBadRequest={onBadRequest} />;
  }

  if (isLoading) {
    return (
      <Card>
        <Skeleton height={CHART_HEIGHT_PX} />
      </Card>
    );
  }

  if (!data) {
    return (
      <Card>
        <div className="text-gray-500">No data available</div>
      </Card>
    );
  }

  const formattedChartData = prepareMetricsHistoryDataByTimestamp(data);

  const yAxisKey = getSelectedOptionId(selectedOptions, "y");
  const yAxisLabel = getAxisLabel(actions, "y", yAxisKey);

  const chartLines = [
    {
      key: yAxisKey,
      label: yAxisLabel,
    },
  ];

  return (
    <Card>
      <ChartActionsWrap>
        <ChartActions
          actions={actions}
          selectedOptions={selectedOptions}
          onOptionChange={handleOptionChange}
        />
      </ChartActionsWrap>
      <LineChart
        enableZoom={true}
        height={400}
        width="100%"
        data={formattedChartData}
        xAxisKey="ts"
        yAxisLines={chartLines}
        xAxisLabel="Occurrence Date"
        tooltipProps={{
          content: (props: any) => (
            <ChartTooltip {...props} yAxisOptions={Y_AXIS_OPTIONS} />
          ),
        }}
      />
    </Card>
  );
};

export default SignalEventsChart;
