import { format } from "date-fns";

import { FailureModeCampaignEventsTimelineBucket } from "shared/api/v0_failureModes/api";
import { MONTH_YEAR_FULL, MONTH_YEAR_SHORT } from "shared/constants";

import AreaChart from "features/ui/charts/AreaChart";

export interface FailureModeCampaignEventsRollingGraphProps {
  data: FailureModeCampaignEventsTimelineBucket[];
}

export interface GraphDataBucket {
  ts: number;
  countRepairs: number;
  countFailures: number;
}

interface FailureModeCampaignEventsGraphProps {
  data: GraphDataBucket[];
}

const formatTooltipLabel = (label: string): string => {
  switch (label) {
    case "countFailures":
      return "Failures";
    case "countRepairs":
      return "Repairs";
    default:
      return label;
  }
};

const HEIGHT_PX = 300;

export const FailureModeEventsGraph = ({
  data,
}: FailureModeCampaignEventsGraphProps) => {
  const areas = [
    { key: "countRepairs", label: "Campaign Repairs" },
    { key: "countFailures", label: "On The Road Failures", color: "#F08080" },
  ];

  if (data.length === 0) {
    return <h3 className="text-gray-400 text-sm">Not enough data.</h3>;
  }

  return (
    <AreaChart
      data={data}
      areas={areas}
      width="100%"
      height={HEIGHT_PX}
      xAxisKey="ts"
      xAxisProps={{
        tickFormatter: (unixTime) => format(unixTime, MONTH_YEAR_SHORT),
      }}
      tooltipProps={{
        formatter: (value: any, name: any) => [value, formatTooltipLabel(name)],
        labelFormatter: (unixTime: number) => format(unixTime, MONTH_YEAR_FULL),
      }}
    />
  );
};
