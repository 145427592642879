import { getCriteriaUpdatedText } from "./utils";

interface Props {
  hasChangedVehicleFilters: boolean;
  hasChangedClaimFilters: boolean;
  hasChangedSignalEventFilters: boolean;
  issueName: string;
}

const ModalText = ({
  hasChangedVehicleFilters,
  hasChangedClaimFilters,
  hasChangedSignalEventFilters,
  issueName,
}: Props) => {
  const criteriaUpdatedText = getCriteriaUpdatedText({
    hasChangedVehicleFilters,
    hasChangedClaimFilters,
    hasChangedSignalEventFilters,
  });

  return (
    <div>
      You ve changed the filter criteria on this page. Do you want to save these
      filters to Issue {issueName}?
      <div className="mt-3 text-xs">
        Changes will update the {criteriaUpdatedText} for the issue.
      </div>
    </div>
  );
};

export default ModalText;
