import { BreadcrumbMatch } from "use-react-router-breadcrumbs";

import { useIssue } from "shared/api/issues/hooks";

interface Props {
  match: BreadcrumbMatch;
}

const IssueBreadcrumb = ({ match }: Props) => {
  const {
    data: issue,
    isLoading,
    error,
  } = useIssue({
    id: match.params.id as string,
  });

  if (isLoading) {
    return <></>;
  }

  if (error && match.params.id) {
    return <>{match.params.id}</>;
  }

  return <>{issue?.name}</>;
};

export default IssueBreadcrumb;
