import { format } from "date-fns-tz";
import { TooltipProps } from "recharts";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";

import { Sensor, SensorReadingsTimelineGrouping } from "shared/api/sensors/api";
import { DATE_WITH_TIME_FORMAT } from "shared/constants";
import { formatNumber } from "shared/utils";

import {
  getLabelForPointValueSensor,
  getPointValueSensorFromID,
} from "./utils";

interface CustomProps {
  grouping: SensorReadingsTimelineGrouping;
  pointValueSensors?: Sensor[];
}

const PointValueSensorChartTooltip = ({
  payload,
  label,
  grouping,
  pointValueSensors,
}: TooltipProps<ValueType, NameType> & CustomProps) => (
  <div className="flex flex-col bg-white shadow px-2 py-3 text-xs">
    <div>
      <span className="font-bold">Date:</span>{" "}
      {label && format(label, DATE_WITH_TIME_FORMAT)}
    </div>
    <div>
      {payload?.map(({ name, color, value, payload }) => (
        <div key={name}>
          <div>
            <span style={{ color }} className="font-bold">
              {getLabelForPointValueSensor(
                grouping,
                getPointValueSensorFromID(payload?.ID || "", pointValueSensors)
              ) ||
                payload?.displayName ||
                name}
            </span>
            : {formatNumber(value as number)} {payload?.unit}
          </div>
          {payload?.description && <div>({payload?.description})</div>}
          {payload?.trigger && (
            <div>
              <span className="font-bold">Trigger:</span> {payload.trigger}
            </div>
          )}
        </div>
      ))}
    </div>
  </div>
);

export default PointValueSensorChartTooltip;
