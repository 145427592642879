import { randomID } from "shared/utils";

export const matchesQuery = (str: string = "", query: string) =>
  str
    .toLowerCase()
    .replace(/\s+/g, "")
    .includes(query.toLowerCase().replace(/\s+/g, ""));

export const getPendingFiltersKey = (pageKey: string) => `${pageKey}-pending`;

export const getPageKeyFromPendingFiltersKey = (
  pendingFilterKey: string
): string => {
  // we need a key, if it doesn't exist create one
  if (!pendingFilterKey) {
    return randomID();
  }

  return pendingFilterKey.endsWith("-pending")
    ? pendingFilterKey.slice(0, -8)
    : pendingFilterKey;
};
