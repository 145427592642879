import { useEffect, useState } from "react";

import { useClaimsSchema } from "shared/schemas/claimsSchema";
import { useGroupBySelectOptions } from "shared/schemas/hooks";

import { ClaimAnalyticsProps } from "pages/ClaimAnalytics/ClaimAnalyticsTabs";
import {
  GROUP_BY_ATTRIBUTE_KEY,
  TOP_CONTRIBUTORS_TAB_KEY,
} from "pages/constants";
import { useExposures, useTopContributorsExposureOptions } from "pages/hooks";
import TopContributorsGroupBySelector from "pages/shared/TopContributorsGroupBySelector";
import {
  buildNewTopContributorsSelectedOptions,
  getDefaultTopContributorChartActions,
  getTopContributorsChartActions,
} from "pages/utils";

import { SelectedChartOptions } from "features/ui/charts/Actions/types";
import { getDefaultActions } from "features/ui/charts/utils";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { SelectOption } from "features/ui/Select";

import { useTopContributorsChartYAxisOptions } from "./hooks";
import TopChart from "./TopChart";
import TopContributorsTable from "./TopContributorsTable";
import { getInitialSelectedGroupByAttribute } from "./utils";

export const DEFAULT_GROUP_BY_ATTRIBUTE = "laborCode";
const DEFAULT_GROUP_BY_ATTRIBUTE_LABEL = "Labor code";
export const DEFAULT_GROUP_BY_SELECT_OPTION: SelectOption = {
  id: DEFAULT_GROUP_BY_ATTRIBUTE,
  value: DEFAULT_GROUP_BY_ATTRIBUTE_LABEL,
};

export const CLAIM_ANALYTICS_TOP_CONTRIBUTORS_CHART_OPTIONS_KEY =
  "claimAnalyticsTopContChartOptions";
// We manage the state of the group by options with a separate key from the rest of
// the chart options because they are not part of the chart options, and are set through
// a different mechanism.
export const CLAIM_ANALYTICS_TOP_CONTRIBUTORS_GROUP_BY_OPTIONS_KEY =
  "claimAnalyticsTopContGroupByOptions";

const TopContributors = ({
  claimsFiltersFilterSortState,
  vehiclesFiltersFilterSortState,
}: ClaimAnalyticsProps) => {
  const { attributes } = useClaimsSchema();

  const exposures = useTopContributorsExposureOptions("claim");

  const yAxisOptions = useTopContributorsChartYAxisOptions();

  const defaultActions = getDefaultTopContributorChartActions(
    attributes,
    CLAIM_ANALYTICS_TOP_CONTRIBUTORS_CHART_OPTIONS_KEY,
    yAxisOptions,
    exposures
  );

  const [actions, setActions] = useState(defaultActions);

  const claimsFilters = claimsFiltersFilterSortState?.filters;
  const vehiclesFilters = vehiclesFiltersFilterSortState?.filters;

  const groupBySelectOptions = useGroupBySelectOptions("claim");
  const [generalFilters, setGeneralFilters] = useState<
    FilterGroupState | undefined
  >();

  const initialSelectedGroupByAttribute: SelectOption =
    getInitialSelectedGroupByAttribute(
      claimsFiltersFilterSortState?.chartSettings,
      DEFAULT_GROUP_BY_SELECT_OPTION,
      groupBySelectOptions
    );

  const [selectedGroupByAttribute, setSelectedGroupByAttribute] = useState(
    initialSelectedGroupByAttribute
  );

  const handleGroupByAttributeChange = (groupByAttribute: SelectOption) => {
    setSelectedGroupByAttribute(groupByAttribute);
    if (claimsFiltersFilterSortState?.manageChartSettingsChange) {
      claimsFiltersFilterSortState.manageChartSettingsChange(
        [
          {
            id: GROUP_BY_ATTRIBUTE_KEY,
            optionId: groupByAttribute.id,
          },
        ],
        CLAIM_ANALYTICS_TOP_CONTRIBUTORS_GROUP_BY_OPTIONS_KEY
      );
    }
  };

  const initialSelectedOptions: SelectedChartOptions[] =
    claimsFiltersFilterSortState?.chartSettings &&
    claimsFiltersFilterSortState.chartSettings[TOP_CONTRIBUTORS_TAB_KEY] &&
    claimsFiltersFilterSortState.chartSettings[TOP_CONTRIBUTORS_TAB_KEY][
      CLAIM_ANALYTICS_TOP_CONTRIBUTORS_CHART_OPTIONS_KEY
    ]
      ? claimsFiltersFilterSortState.chartSettings[TOP_CONTRIBUTORS_TAB_KEY][
          CLAIM_ANALYTICS_TOP_CONTRIBUTORS_CHART_OPTIONS_KEY
        ]
      : getDefaultActions(actions);

  const [selectedOptions, setSelectedOptions] = useState<
    SelectedChartOptions[]
  >(initialSelectedOptions);

  const handleSelectedOptionsChange = (
    newSelectedOptions: SelectedChartOptions[]
  ) => {
    setSelectedOptions(newSelectedOptions);
    if (claimsFiltersFilterSortState?.manageChartSettingsChange) {
      claimsFiltersFilterSortState.manageChartSettingsChange(
        newSelectedOptions,
        CLAIM_ANALYTICS_TOP_CONTRIBUTORS_CHART_OPTIONS_KEY
      );
    }
  };

  const {
    previousExposure,
    currentExposure,
    currentExposureBuckets,
    selectedExposureForAPI,
    selectedExposureBucketForAPI,
  } = useExposures(attributes, selectedOptions);

  useEffect(() => {
    // handle scenario when exposure is changed which means that we need to change exposureBucket options
    if (previousExposure.current !== currentExposure) {
      previousExposure.current = currentExposure;

      setActions(
        getTopContributorsChartActions(
          yAxisOptions,
          exposures,
          currentExposureBuckets,
          currentExposure
        )
      );

      handleSelectedOptionsChange(
        buildNewTopContributorsSelectedOptions(
          selectedOptions,
          currentExposureBuckets
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions]);

  const resetState = () => {
    setSelectedGroupByAttribute(DEFAULT_GROUP_BY_SELECT_OPTION);
    claimsFiltersFilterSortState?.resetFilterSortState();
    vehiclesFiltersFilterSortState?.resetFilterSortState();
  };

  return (
    <>
      <TopContributorsGroupBySelector
        groupBySelectOptions={groupBySelectOptions}
        selectedGroupByAttribute={selectedGroupByAttribute}
        setSelectedGroupByAttribute={handleGroupByAttributeChange}
      />
      <TopChart
        selectedGroupByAttribute={selectedGroupByAttribute}
        vehiclesFilters={vehiclesFilters}
        claimsFilters={claimsFilters}
        filters={generalFilters}
        onClaimsFiltersChange={claimsFiltersFilterSortState?.manageFilterChange}
        onVehiclesFiltersChange={
          vehiclesFiltersFilterSortState?.manageFilterChange
        }
        actions={actions}
        selectedOptions={selectedOptions}
        setSelectedOptions={handleSelectedOptionsChange}
        selectedByVehicleAgeExposure={selectedExposureForAPI}
        selectedByVehicleAgeExposureBucket={selectedExposureBucketForAPI}
        onBadRequest={resetState}
      />
      <TopContributorsTable
        selectedGroupByAttribute={selectedGroupByAttribute}
        vehiclesFilters={vehiclesFilters}
        claimsFilters={claimsFilters}
        onFiltersUpdated={setGeneralFilters}
        onBadRequest={resetState}
        selectedByVehicleAgeExposure={selectedExposureForAPI}
        selectedByVehicleAgeExposureBucket={selectedExposureBucketForAPI}
      />
    </>
  );
};

export default TopContributors;
