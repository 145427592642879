import { useEffect, useState } from "react";

import { useCustomLocalStorageState } from "shared/hooks";
import { useGroupBySelectOptions } from "shared/schemas/hooks";
import { useInspectionsSchema } from "shared/schemas/inspectionsSchema";
import { EventTypeEnum } from "shared/types";

import { useExposures, useTopContributorsExposureOptions } from "pages/hooks";
import { InspectionAnalyticsProps } from "pages/InspectionAnalytics/InspectionAnalyticsTabs";
import { getTopContributorsChartYAxisOptions } from "pages/shared/topContributorsChartActions";
import TopContributorsGroupBySelector from "pages/shared/TopContributorsGroupBySelector";
import {
  buildNewTopContributorsSelectedOptions,
  getDefaultTopContributorChartActions,
  getTopContributorsChartActions,
} from "pages/utils";

import { SelectedChartOptions } from "features/ui/charts/Actions/types";
import { getDefaultActions } from "features/ui/charts/utils";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { SelectOption } from "features/ui/Select";

import TopChart from "./TopChart";
import TopContributorsTable from "./TopContributorsTable";

const DEFAULT_GROUP_BY_ATTRIBUTE = "status";
const DEFAULT_GROUP_BY_ATTRIBUTE_LABEL = "Status";
const DEFAULT_GROUP_BY_SELECT_OPTION: SelectOption = {
  id: DEFAULT_GROUP_BY_ATTRIBUTE,
  value: DEFAULT_GROUP_BY_ATTRIBUTE_LABEL,
};
export const GROUP_BY_ATTRIBUTE_CHART_NULL_REPLACEMENT = "Empty";
const GROUP_BY_ATTRIBUTE_LOCAL_STORAGE_KEY = "inspAnTopContrGroupByAttribute";

const CHART_KEY = "inspAnTopContrChartOptions";

const TopContributors = ({
  inspectionsFiltersFilterSortState,
  vehiclesFiltersFilterSortState,
}: InspectionAnalyticsProps) => {
  const { attributes } = useInspectionsSchema();

  const exposures = useTopContributorsExposureOptions("inspection");

  const yAxisOptions = getTopContributorsChartYAxisOptions(
    EventTypeEnum.INSPECTION,
    ["IPTV", "numEvents", "numVehicles"]
  );
  const defaultActions = getDefaultTopContributorChartActions(
    attributes,
    CHART_KEY,
    yAxisOptions,
    exposures
  );

  const [actions, setActions] = useState(defaultActions);

  const inspectionsFilters = inspectionsFiltersFilterSortState?.filters;
  const vehiclesFilters = vehiclesFiltersFilterSortState?.filters;

  const groupBySelectOptions = useGroupBySelectOptions("inspection");
  const [generalFilters, setGeneralFilters] = useState<
    FilterGroupState | undefined
  >();

  const [selectedGroupByAttribute, setSelectedGroupByAttribute] =
    useCustomLocalStorageState<SelectOption>(
      GROUP_BY_ATTRIBUTE_LOCAL_STORAGE_KEY,
      {
        defaultValue: DEFAULT_GROUP_BY_SELECT_OPTION,
      }
    );

  const [selectedOptions, setSelectedOptions] = useCustomLocalStorageState<
    SelectedChartOptions[]
  >(CHART_KEY, {
    defaultValue: getDefaultActions(actions),
  });

  const {
    previousExposure,
    currentExposure,
    currentExposureBuckets,
    selectedExposureForAPI,
    selectedExposureBucketForAPI,
  } = useExposures(attributes, selectedOptions);

  useEffect(() => {
    // handle scenario when exposure is changed which means that we need to change exposureBucket options
    if (previousExposure.current !== currentExposure) {
      previousExposure.current = currentExposure;

      setActions(
        getTopContributorsChartActions(
          yAxisOptions,
          exposures,
          currentExposureBuckets,
          currentExposure
        )
      );

      setSelectedOptions(
        buildNewTopContributorsSelectedOptions(
          selectedOptions,
          currentExposureBuckets
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions]);

  const resetState = () => {
    setSelectedGroupByAttribute(DEFAULT_GROUP_BY_SELECT_OPTION);
    inspectionsFiltersFilterSortState?.resetFilterSortState();
    vehiclesFiltersFilterSortState?.resetFilterSortState();
    setSelectedOptions(getDefaultActions(actions));
  };

  return (
    <>
      <TopContributorsGroupBySelector
        groupBySelectOptions={groupBySelectOptions}
        selectedGroupByAttribute={selectedGroupByAttribute}
        setSelectedGroupByAttribute={setSelectedGroupByAttribute}
      />
      <TopChart
        selectedGroupByAttribute={selectedGroupByAttribute}
        vehiclesFilters={vehiclesFilters}
        inspectionsFilters={inspectionsFilters}
        filters={generalFilters}
        onInspectionsFiltersChange={
          inspectionsFiltersFilterSortState?.manageFilterChange
        }
        onVehiclesFiltersChange={
          vehiclesFiltersFilterSortState?.manageFilterChange
        }
        actions={actions}
        selectedOptions={selectedOptions}
        setSelectedOptions={setSelectedOptions}
        selectedByVehicleAgeExposure={selectedExposureForAPI}
        selectedByVehicleAgeExposureBucket={selectedExposureBucketForAPI}
        onBadRequest={resetState}
      />
      <TopContributorsTable
        selectedGroupByAttribute={selectedGroupByAttribute}
        vehiclesFilters={vehiclesFilters}
        inspectionsFilters={inspectionsFilters}
        onFiltersUpdated={setGeneralFilters}
        onBadRequest={resetState}
        selectedByVehicleAgeExposure={selectedExposureForAPI}
        selectedByVehicleAgeExposureBucket={selectedExposureBucketForAPI}
      />
    </>
  );
};

export default TopContributors;
