import { useEffect, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { mutate } from "swr";

import {
  newGroup,
  NewGroupRequest,
  updateGroup,
  UpdateGroupRequest,
} from "shared/api/rbac/api";
import NewCTA from "shared/components/NewCTA";
import { useEmailFromJWT } from "shared/hooks";
import { toTitleCase } from "shared/utils";

import { DEFAULT_DATA, GroupFormData } from "pages/Groups/utils";

import { Step } from "features/ui/Stepper";
import StepperModal, {
  ActionType,
  getTextForActionType,
} from "features/ui/StepperModal";

import { routes } from "services/routes";

import StepAccess from "./StepAccess";
import StepAddMembers from "./StepAddMembers";
import StepNameGroup from "./StepNameGroup";
import { ActionCallback, prepareRequestData } from "./utils";

const CTA_TEXT = "New Group";

const ACTIONS: Record<ActionType, typeof newGroup | typeof updateGroup> = {
  create: newGroup,
  update: updateGroup,
};

const getCallback = <T extends ActionType>(aType: T): ActionCallback<T> => {
  return ACTIONS[aType] as ActionCallback<T>;
};

const STEPS: Step[] = [
  {
    label: "Name Group",
    content: StepNameGroup,
    validation: ({ name }: Record<string, any>) => name.length >= 5,
  },
  {
    label: "Add Members",
    content: StepAddMembers,
    validation: ({ users }: Record<string, any>) => users.length > 0,
  },
  {
    label: "Control Visibility and Access",
    content: StepAccess,
  },
];

interface Props {
  action: ActionType;
  groupRequestKey?: string;
  groupData?: GroupFormData;
  step?: number;
  showButton?: boolean;
  defaultOpen?: boolean;
  onlyAllowCurrentStep?: boolean;
  open?: boolean;
  onClose?: () => void;
  onFinally?: () => void;
}

const CreateGroup = ({
  action,
  groupData = DEFAULT_DATA,
  groupRequestKey,
  step,
  showButton = true,
  defaultOpen = false,
  onlyAllowCurrentStep = action === "update",
  open,
  onClose,
  onFinally,
}: Props) => {
  const navigate = useNavigate();
  const myEmail = useEmailFromJWT();
  const [modalOpen, setModalOpen] = useState(open || defaultOpen);

  useEffect(() => {
    if (open) {
      onHandleOpen();
    } else {
      onHandleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const [data, setData] = useState<GroupFormData>(groupData);

  const stepperTitle = `${toTitleCase(action)} a group`;
  const [isLoading, setIsLoading] = useState(false);

  const onHandleOpen = () => {
    setData(groupData);
    setModalOpen(true);
  };

  const onHandleClose = () => {
    setModalOpen(false);
    onClose && onClose();
    setData(groupData);
  };

  const onHandleSuccess = (groupID?: string) => {
    toast.success("Group saved successfully");

    if (action === "create" && groupID) {
      navigate(generatePath(routes.group, { id: groupID }));
    }
    if (groupRequestKey) {
      mutate(groupRequestKey);
    }
  };

  const onDataUpdated = (update: Partial<GroupFormData>) => {
    setData({ ...data, ...update });
  };

  const onHandleError = (err: Error) => {
    toast.error("Saving group failed");
    console.log(err);
  };

  const onHandleFinally = () => {
    setData(DEFAULT_DATA);
    setModalOpen(false);
    setIsLoading(false);
    onFinally && onFinally();
  };

  const handleGroupCreateOrUpdate = () => {
    setIsLoading(true);
    const requestData = prepareRequestData(data, action, myEmail);

    if (action === "update") {
      getCallback(action)(requestData as UpdateGroupRequest)
        .then(() => {
          onHandleSuccess();
        })
        .catch(onHandleError)
        .finally(onHandleFinally);
    } else {
      getCallback(action)(requestData as NewGroupRequest)
        .then(({ data: { ID } }) => {
          onHandleSuccess(ID);
        })
        .catch(onHandleError)
        .finally(onHandleFinally);
    }
  };

  return (
    <>
      {showButton && (
        <NewCTA
          onClick={onHandleOpen}
          label={CTA_TEXT}
          testId="new-group-cta"
        />
      )}
      <StepperModal
        steps={STEPS}
        disableUsingLocalStorage={true}
        step={step}
        isOpen={modalOpen}
        onClose={onHandleClose}
        nonLinear={false}
        title={stepperTitle}
        data={data}
        onDataUpdated={onDataUpdated}
        lastStepActionLabel={getTextForActionType(action)}
        lastStepCallback={handleGroupCreateOrUpdate}
        lastStepCallbackIsLoading={isLoading}
        action={action}
        onlyAllowCurrentStep={onlyAllowCurrentStep}
        closeOnOutsideClick={false}
      />
    </>
  );
};

export default CreateGroup;
