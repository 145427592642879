import { formatNumber } from "shared/utils";

import VINTable, { VINTableProps } from "./VINTable";

const InvalidVins = ({ vins }: VINTableProps) => (
  <div className="mt-3 text-sm border-t pt-3 text-gray-500">
    We found {formatNumber(vins.length, 0)} invalid VINs<sup>*</sup>, which were
    excluded from the collection.
    <br />
    The first few are listed below:
    <VINTable vins={vins} />
    <div className="text-xs text-gray-400 mt-2">
      <sup>*</sup>Invalid VINs are incorrectly formatted or do not exist in
      Viaduct.
    </div>
  </div>
);

export default InvalidVins;
