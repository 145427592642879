import { useEffect } from "react";
import Skeleton from "react-loading-skeleton";

import { IssueRepairEfficacyVINTimeline } from "shared/api/issues/api";
import { useGetIssueRepairEfficacyVINTimeline } from "shared/api/issues/hooks";
import {
  IssueTypes,
  IssueVehiclePopulation,
  ReoccurrenceProps,
} from "shared/types";

import {
  getBaseAPIRoute,
  getChartValues,
  getIssueCombinedID,
  prepareScatteredChartLineDefinitions,
} from "pages/Issues/utils";

import APIError from "features/ui/APIError";
import ScatterChart from "features/ui/charts/ScatterChart/ScatterChart";

import CustomTick from "./CustomTick";
import ReoccurrenceProceedingAClaimChartTooltip from "./ReoccurrenceProceedingAClaimChartTooltip";
import { prepareDataForScatteredChart } from "./utils";

interface Props extends ReoccurrenceProps {
  issue: IssueTypes;
  population: IssueVehiclePopulation;
  onDataLoad: (data: IssueRepairEfficacyVINTimeline[]) => void;
  dataFilter?: Record<string, any>;
}

const LETTER_WIDTH = 4;
const LETTER_HEIGHT = 50;

const X_AXIS_KEY = "daysSinceClaim";
const X_AXIS_LABEL = "Days Since Claim";
const Y_AXIS_KEY = "yVIN";
const Y_AXIS_LABEL = "VIN";
const Z_AXIS_KEY = "count";
const Z_AXIS_LABEL = "";
const MAX_CHART_HEIGHT = 500;

const ReoccurrenceProceedingAClaimTimelineChart = ({
  issue,
  population,
  seOccurrenceWithin,
  seReoccurrenceStartingFrom,
  seReoccurrenceUpTo,
  onDataLoad,
  dataFilter,
}: Props) => {
  const { updatedAt } = issue;

  const { data, isLoading, error } = useGetIssueRepairEfficacyVINTimeline({
    IDs: getIssueCombinedID(issue),
    vehiclePopulation: population,
    baseRoute: getBaseAPIRoute(issue),
    signalEventsTimeWindow: seOccurrenceWithin,
    signalEventsReoccurrenceTimeWindowFrom: seReoccurrenceStartingFrom,
    signalEventsReoccurrenceTimeWindowTo: seReoccurrenceUpTo,
    updatedAt,
  });

  useEffect(() => {
    if (!isLoading && data) {
      onDataLoad(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  if (isLoading) {
    return <Skeleton height={400} />;
  }

  if (error) {
    return <APIError error={error} />;
  }

  if (!data || data.length === 0) {
    return <div className="py-4 text-gray-400 text-sm">No data.</div>;
  }

  let filteredData = data;
  if (dataFilter) {
    const field = Object.keys(dataFilter)[0];
    const fieldValues = dataFilter[field] as string[];
    if (fieldValues && fieldValues.length > 0) {
      filteredData = data.filter((x: Record<string, any>) =>
        fieldValues.includes(x[field])
      );
    }
  }

  const lineDefinitions = prepareScatteredChartLineDefinitions(data);
  const { chartEntries, minDaysSinceClaim, maxDaysSinceClaim } =
    prepareDataForScatteredChart(filteredData, lineDefinitions);

  const yAxisValues = getChartValues("VIN", data, [], false) as string[];
  const longestVIN = Math.max(...yAxisValues.map((x) => x.length));
  // add 4 to longest VIN value because y-axis values might be similar to `${VIN}/3`
  const chartOffset = Math.max(50, (longestVIN + 4) * LETTER_WIDTH);
  const chartHeight = Math.max(400, yAxisValues.length * LETTER_HEIGHT);

  const ticks = [];
  for (let i = minDaysSinceClaim; i <= Math.ceil(maxDaysSinceClaim); i++) {
    ticks.push(i);
  }

  return (
    <ScatterChart
      height={chartHeight}
      maxHeight={MAX_CHART_HEIGHT}
      legendConfig={{ labels: lineDefinitions }}
      margin={{
        top: 20,
        right: 20,
        bottom: 10,
        left: chartOffset,
      }}
      xAxisKey={X_AXIS_KEY}
      xAxisLabel={X_AXIS_LABEL}
      yAxisKey={Y_AXIS_KEY}
      yAxisLabel={Y_AXIS_LABEL}
      zAxisKey={Z_AXIS_KEY}
      zAxisLabel={Z_AXIS_LABEL}
      rows={chartEntries}
      tooltipProps={{
        content: ReoccurrenceProceedingAClaimChartTooltip,
      }}
      showYAxis={chartEntries.length > 0}
      xAxisProps={{
        domain: ["dataMin", "dataMax + 1"],
        ticks,
      }}
      yAxisProps={{
        tick: <CustomTick />,
      }}
    />
  );
};

export default ReoccurrenceProceedingAClaimTimelineChart;
