import { useCallback, useMemo } from "react";
import { generatePath, Link } from "react-router-dom";

import { getSortFilter } from "shared/api/utils";
import {
  getFailureModeVehiclesExport,
  Predictions,
} from "shared/api/v0_failureModes/api";
import {
  useFailureModes,
  useFailureModeVehiclesCount,
  useListFailureModeVehicles,
} from "shared/api/v0_failureModes/hooks";
import { Vehicle } from "shared/api/vehicles/api";
import { MAX_ROWS_DOWNLOAD_LIMIT } from "shared/constants";
import { VEHICLES_COLLECTION_FILTER } from "shared/filterDefinitions";
import useVehiclesSchema from "shared/schemas/vehiclesSchema";
import { SortBy } from "shared/types";
import { formatNumber, getTenantMileageUnit } from "shared/utils";

import SingleFleetLink from "pages/Fleets/SingleFleetLink";
import RiskBadge from "pages/Vehicles/RiskBadge";
import {
  formatFailureModeToTableSchema,
  getAccessorsForVisibleFMColumns,
  getFailureModePredictionAccessor,
} from "pages/Vehicles/utils";

import APIError from "features/ui/APIError";
import DownloadAction from "features/ui/DownloadAction";
import Filters from "features/ui/Filters";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";
import FiltersOverview from "features/ui/Filters/FiltersOverview/FiltersOverview";
import { useFilterSortState } from "features/ui/Filters/hooks";
import RiskDistribution from "features/ui/RiskDistribution";
import { OnSortParams, SchemaEntry } from "features/ui/Table";
import PaginatedTable from "features/ui/Table/PaginatedTable";
import TableCount from "features/ui/Table/TableCount";

import { routes } from "services/routes";

const VEHICLES_PER_PAGE = 15;

const GLOBAL_FILTERS = [VEHICLES_COLLECTION_FILTER];

interface Props {
  failureModeID: string;
  removeTableHeaderFilters?: boolean;
  initialVisibleFailureModeColumns?: string[];
}

const getPredictionsFormatted = (predictions: Predictions): Predictions => {
  if (!predictions) return {};
  return Object.keys(predictions).reduce((prevVal, ID) => {
    return {
      ...prevVal,
      [ID]: {
        ...predictions[ID],
        riskGroup: <RiskBadge risk={predictions[ID].riskGroup} />,
      },
    };
  }, {});
};

const FailureModeVehicles = ({ failureModeID }: Props) => {
  const pageKey = `failure_mode_vehicles_${failureModeID}`;

  const { getSchemaForAccessor } = useVehiclesSchema();

  const initialVisibleFailureModeColumns = [
    failureModeID,
    "riskGroup",
    "riskStatus",
    "riskMultiple",
    "repairStatus",
    "repairDetails",
  ];

  const FAILURE_MODE_RISK_GROUP_ACCESSOR = getFailureModePredictionAccessor(
    failureModeID,
    "riskGroup"
  );

  const DEFAULT_SORT: SortBy = { [FAILURE_MODE_RISK_GROUP_ACCESSOR]: "desc" };

  const {
    manageFilterChange,
    resetFilters,
    filters,
    sort,
    manageOnSortChange,
    initialized: filtersInitialized,
    resetFilterSortState,
  } = useFilterSortState({
    pageKey,
    defaultSort: DEFAULT_SORT,
    defaultFailureModeColumns: initialVisibleFailureModeColumns,
  });

  const { data: failureModesData } = useFailureModes({});
  const failureModesSchemas = failureModesData?.map((fm) =>
    formatFailureModeToTableSchema(fm, false)
  );

  const failureModesSchemasAll = failureModesSchemas?.flat() || [];

  const showHideColumnsOptionsFailureModeProps = [
    { label: "Risk Group", value: "riskGroup" },
    { label: "Risk Status", value: "riskStatus" },
    { label: "Risk Multiple", value: "riskMultiple" },
    { label: "Repair Status", value: "repairStatus" },
    { label: "Repair Details", value: "repairDetails" },
  ];

  const allFailureModesIds =
    (failureModesData && failureModesData.map(({ ID }) => ID)) || [];

  const additionalColumnsOptionsValues =
    showHideColumnsOptionsFailureModeProps.map(({ value }) => value);

  const visibleFailureModesColumns = getAccessorsForVisibleFMColumns(
    allFailureModesIds,
    initialVisibleFailureModeColumns,
    additionalColumnsOptionsValues
  );

  const shownFailureModes = failureModesSchemasAll?.filter(({ accessor }) => {
    return visibleFailureModesColumns.includes(accessor);
  });

  const DEFAULT_SCHEMA: (SchemaEntry | undefined)[] = [
    getSchemaForAccessor("VIN"),
    ...(shownFailureModes || []),
    getSchemaForAccessor("mileage"),
    getSchemaForAccessor("fleet"),
    getSchemaForAccessor("vehicleMake"),
    getSchemaForAccessor("vehicleModel"),
    getSchemaForAccessor("vehicleModelYear"),
    getSchemaForAccessor("engineModel"),
  ];

  const schema: SchemaEntry[] = DEFAULT_SCHEMA.filter(Boolean) as SchemaEntry[];

  const handleSorting = ({ accessor, sort }: OnSortParams) => {
    // only allow sorting by one column at the time
    manageOnSortChange({ [accessor]: sort });
  };

  const requestParams = {
    id: failureModeID,
    filter: getFiltersQuery(filters),
    sort: getSortFilter(sort),
    limit: VEHICLES_PER_PAGE,
    mileageUnit: getTenantMileageUnit(),
  };

  const { data, isLoading, headers, error, ...paginationData } =
    useListFailureModeVehicles(requestParams);

  const {
    isLoading: countIsLoading,
    data: countData,
    error: countError,
  } = useFailureModeVehiclesCount({
    id: failureModeID,
    filter: requestParams.filter,
    mileageUnit: getTenantMileageUnit(),
  });

  const formatRow = useCallback((vehicle: Vehicle) => {
    const { VIN, predictions, mileage, fleet } = vehicle;
    const pathname = generatePath(routes.vinView, {
      vin: encodeURIComponent(VIN),
    });

    const predictionFormatted = getPredictionsFormatted(predictions);

    return {
      ...vehicle,
      VIN: (
        <Link to={pathname} className="text-metabase-blue hover:underline">
          {VIN}
        </Link>
      ),
      mileage: formatNumber(mileage, 0),
      predictions: predictionFormatted,
      fleet: (
        <SingleFleetLink
          fleetName={fleet}
          className="text-metabase-blue hover:underline"
        />
      ),
    };
  }, []);

  // re-format the data - but only when relevant data changes
  const formattedData = useMemo(() => data?.map(formatRow), [data, formatRow]);

  const downloadDisabled = !formattedData || formattedData.length === 0;

  return (
    <div data-testid="failure-mode-vehicles">
      <Filters
        initialized={filtersInitialized}
        schema={GLOBAL_FILTERS}
        onFilterChange={manageFilterChange}
        filters={filters}
        horizontal
      />
      <div className="mb-5">
        <RiskDistribution filters={filters} failureModeID={failureModeID} />
      </div>
      <div className="flex items-center mb-1">
        <FiltersOverview
          filters={filters}
          tableSchema={schema}
          onFiltersReset={resetFilters}
          filtersToHide={GLOBAL_FILTERS.map((f) => f.fieldName)}
        />
        <TableCount
          extraClasses="ml-auto"
          count={countData?.count as number}
          entityName="vehicle"
          isLoading={countIsLoading}
          error={!!countError}
        />
        <DownloadAction
          disabled={downloadDisabled}
          downloadFunc={(args) =>
            getFailureModeVehiclesExport({ failureModeID, ...args })
          }
          fileName="vehicles"
          requestParams={{
            ...requestParams,
            limit: MAX_ROWS_DOWNLOAD_LIMIT,
          }}
          count={countData?.count as number}
          entityName="vehicle"
          filters={filters}
        />
      </div>
      {!error && (
        <PaginatedTable
          {...paginationData}
          data={formattedData}
          schema={schema}
          isLoading={isLoading}
          loadingRows={VEHICLES_PER_PAGE}
          sortBy={sort}
          onSort={handleSorting}
          filtersInitialized={filtersInitialized}
          onFiltersReset={resetFilters}
          onFilterChange={manageFilterChange}
          filters={filters}
          pageKey={pageKey}
          dense
        />
      )}
      {error && <APIError error={error} onBadRequest={resetFilterSortState} />}
    </div>
  );
};

export default FailureModeVehicles;
