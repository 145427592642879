import { EntityAttribute } from "shared/api/api";
import { CLAIM_DEALER_ACCESSOR } from "shared/schemas/constants";

export const getClaimDealerIdValue = (ID: string) =>
  `${CLAIM_DEALER_ACCESSOR}.${ID}`;

export const getClaimDealerDisplayNameValue = (
  displayName: string,
  claimDealerAttribute?: EntityAttribute
) => `${claimDealerAttribute?.displayName} ${displayName}`;
