import { MdClose as CloseIcon } from "react-icons/md";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactElement;
}

const VehicleServiceEventDialog = ({ isOpen, onClose, children }: Props) => (
  <Dialog
    open={isOpen}
    onClose={onClose} // prevent clicking outside to close the dialog (or Esc key)
    data-testid="modal-new-collection"
  >
    <DialogTitle className="flex justify-end">
      <IconButton
        onClick={onClose}
        data-testid="vin-service-event-dialog-close"
        size="small"
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent>
      <DialogContentText>{children}</DialogContentText>
    </DialogContent>
  </Dialog>
);

export default VehicleServiceEventDialog;
