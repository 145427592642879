import React from "react";

import { IssueTypes, ReoccurrenceProps } from "shared/types";

import Board from "features/ui/Board";

import ReoccurrenceBarChartWrapper from "./Charts/ReoccurrenceBarChartWrapper";
import ReoccurrenceByAttributeChartWrapper from "./Charts/ReoccurrenceByAttributeChartWrapper";
import ReoccurrenceByPopulationChart from "./Charts/ReoccurrenceByPopulationChart";
import ReoccurrenceProceedingAClaimChart from "./Charts/ReoccurrenceProceedingAClaimChart";

interface Props extends ReoccurrenceProps {
  issue: IssueTypes;
  withComparisonPopulation: boolean;
}

const IssueRepairEfficacyCharts = (props: Props) => {
  const reoccurrenceBoardElements = [
    [<ReoccurrenceByAttributeChartWrapper {...props} />],
    [<ReoccurrenceByPopulationChart {...props} />],
  ];

  const barChartBoard = [[<ReoccurrenceBarChartWrapper {...props} />]];

  const proceedingChartBoard = [
    [<ReoccurrenceProceedingAClaimChart {...props} />],
  ];

  return (
    <div className="space-y-3">
      <Board elements={reoccurrenceBoardElements} />
      <Board elements={barChartBoard} />
      <Board elements={proceedingChartBoard} />
    </div>
  );
};

export default IssueRepairEfficacyCharts;
