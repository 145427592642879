import { useListVehiclesCount } from "shared/api/vehicles/hooks";
import useVehiclesSchema from "shared/schemas/vehiclesSchema";

import FilterQueryPresentation from "features/ui/Filters/FilterBuilder/FilterQueryPresentation";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";
import { hasSomeFiltersAppliedFilterGroupState } from "features/ui/Filters/utils";
import TableCount from "features/ui/Table/TableCount";

interface Props {
  collectionName: string;
  collectionType: string;
  filters: FilterGroupState;
}

const Step3 = ({ collectionName, collectionType, filters }: Props) => {
  const filtersQuery = getFiltersQuery(filters);
  const { schema } = useVehiclesSchema();

  const { isLoading, data, error } = useListVehiclesCount({
    filter: filtersQuery,
  });

  return (
    <div>
      <h3 className="text-lg font-semibold mb-3">Please review details:</h3>

      {collectionType === "static" && (
        <div className="mb-4">
          <h4 className="mb-1">Number of vehicles:</h4>
          <TableCount
            isLoading={isLoading}
            count={data?.count}
            error={!!error}
            entityName="vehicle"
            extraClasses="font-bold text-gray-500 text-sm"
          />
        </div>
      )}
      <div className="mb-4">
        <h4 className="mb-1">Collection name:</h4>
        <strong>{collectionName}</strong>
      </div>
      <div className="mb-4">
        <h4 className="mb-1">Collection type:</h4>
        <strong className="capitalize">{collectionType}</strong>
      </div>
      {hasSomeFiltersAppliedFilterGroupState(filters) && (
        <div className="mb-4">
          <h4 className="mb-1">Filters selected:</h4>
          <FilterQueryPresentation filter={filtersQuery} tableSchema={schema} />
        </div>
      )}
    </div>
  );
};

export default Step3;
