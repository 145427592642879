import { useEffect, useState } from "react";
import qs from "qs";
import { generatePath, Link } from "react-router-dom";
import { toast } from "react-toastify";

import { Collection } from "shared/api/api";
import {
  useListVehicleCollections,
  useListVehicleCollectionsCount,
} from "shared/api/hooks";
import { getSortFilter } from "shared/api/utils";
import { SHORT_DATE_FORMAT } from "shared/constants";
import {
  DATE_FILTER_GENERIC,
  VEHICLE_COLLECTION_FILTER_KEY,
  VEHICLE_COLLECTIONS_CREATED_BY_FILTER,
} from "shared/filterDefinitions";
import { SortBy } from "shared/types";
import { formatDate, mutateMultipleSWRRequestKeys } from "shared/utils";

import {
  COLLECTIONS_PER_PAGE,
  VEHICLE_COLLECTION_ENTITY_TYPE,
} from "pages/Collections/constants";
import DeleteCollectionModal from "pages/Collections/DeleteCollectionModal";
import NewCollectionModal from "pages/Collections/NewCollectionModal/NewCSVCollectionModal";
import RowActions from "pages/Collections/RowActions";
import { VEHICLES_PAGE_KEY } from "pages/Vehicles/constants";

import APIError from "features/ui/APIError";
import FilterQueryPresentation from "features/ui/Filters/FilterBuilder/FilterQueryPresentation";
import {
  filterStateToFilterGroupState,
  getFiltersQuery,
} from "features/ui/Filters/FilterBuilder/utils";
import FiltersOverview from "features/ui/Filters/FiltersOverview/FiltersOverview";
import { useFilterSortState } from "features/ui/Filters/hooks";
import { FilterOperator } from "features/ui/Filters/types";
import { getFiltersKey } from "features/ui/Filters/utils";
import { OnSortParams, SchemaEntry } from "features/ui/Table";
import PaginatedTable from "features/ui/Table/PaginatedTable";
import { DataType } from "features/ui/Table/TableBodyCell";
import TableCount from "features/ui/Table/TableCount";

import { useQuery } from "services/hooks";
import { routes } from "services/routes";

const DEFAULT_SORT: SortBy = { updatedAt: "asc" };

interface Query {
  newCollectionModal?: boolean;
}

const collectionEntityType = VEHICLE_COLLECTION_ENTITY_TYPE;

export const SCHEMA: SchemaEntry[] = [
  {
    label: "Name",
    accessor: "name",
    dataType: DataType.JSX,
  },
  {
    label: "Type",
    accessor: "type",
    dataType: DataType.STRING,
  },
  {
    label: "Filter",
    accessor: "filter",
    dataType: DataType.JSX,
  },
  {
    label: "Created",
    accessor: "createdAt",
    dataType: DataType.DATE_WITH_TIME_UTC,
    sortable: true,
    filter: DATE_FILTER_GENERIC({
      label: "Created",
      fieldName: "createdAt",
      filterDataType: DataType.DATE_WITH_TIME_UTC,
    }),
  },
  {
    label: "Created By",
    accessor: "createdBy",
    sortable: true,
    dataType: DataType.STRING,
    filter: VEHICLE_COLLECTIONS_CREATED_BY_FILTER,
  },
  {
    label: "",
    accessor: "action",
    dataType: DataType.JSX,
    align: "right",
  },
];

const CollectionsTable = () => {
  const PAGE_KEY = `collections-${collectionEntityType}`;
  const ON_DELETE_SUCCESS_MESSAGE = `Successfully deleted your ${collectionEntityType} collection.`;
  const PAGE_KEY_FOR_TO_LINK = VEHICLES_PAGE_KEY;

  const {
    manageFilterChange,
    resetFilters,
    filters,
    sort,
    manageOnSortChange,
    initialized: filtersInitialized,
    resetFilterSortState,
  } = useFilterSortState({
    pageKey: PAGE_KEY,
    defaultSort: DEFAULT_SORT,
  });

  const query: Query = useQuery();

  const { data, isLoading, error, requestKey, ...paginationData } =
    useListVehicleCollections({
      limit: COLLECTIONS_PER_PAGE,
      filter: getFiltersQuery(filters),
      sort: getSortFilter(sort),
    });

  const {
    data: countData,
    isLoading: countIsLoading,
    error: countError,
    requestKey: countRequestKey,
  } = useListVehicleCollectionsCount({
    filter: getFiltersQuery(filters),
  });

  const handleSorting = ({ accessor, sort }: OnSortParams) => {
    // only allow sorting by one column at the time
    manageOnSortChange({ [accessor]: sort });
  };

  const [newCollectionModalOpen, setNewCollectionModalOpen] = useState(false);

  useEffect(() => {
    if (!query.newCollectionModal) return;
    setNewCollectionModalOpen(true);
  }, [query.newCollectionModal]);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteCollectionId, setDeleteCollectionId] = useState("");
  const [deleteCollectionName, setDeleteCollectionName] = useState("");

  const requestKeys = [requestKey, countRequestKey];

  const formatRow = (collection: Collection) => {
    const { ID, name, filter, createdAt, ...otherData } = collection;
    const pathname = generatePath(routes.vehicles);
    const search = qs.stringify({
      [getFiltersKey(PAGE_KEY_FOR_TO_LINK)]: getFiltersQuery(
        filterStateToFilterGroupState({
          [VEHICLE_COLLECTION_FILTER_KEY]: {
            values: [ID],
            operator: FilterOperator.IN,
          },
        })
      ),
    });

    const handleDeleteAction = (id: string, name: string) => {
      setDeleteCollectionId(id);
      setDeleteCollectionName(name);
      setDeleteModalOpen(true);
    };

    return {
      name: (
        <Link
          to={{ pathname, search }}
          className="text-metabase-blue hover:underline"
        >
          {name}
        </Link>
      ),
      filter: filter && (
        <FilterQueryPresentation filter={filter} tableSchema={SCHEMA} />
      ),
      createdAt: formatDate(createdAt, SHORT_DATE_FORMAT, true),
      action: (
        <RowActions
          collection={collection}
          onDelete={handleDeleteAction}
          requestKeys={requestKeys}
        />
      ),
      ...otherData,
    };
  };

  const formattedData = data?.map(formatRow);

  return (
    <>
      <div className="py-2 mb-3 text-sm">
        Create new {collectionEntityType} collection on the{" "}
        <Link
          to={routes.vehicles}
          className="text-blue-400 hover:text-blue-300"
        >
          {collectionEntityType}s page
        </Link>{" "}
        {collectionEntityType === "vehicle" && (
          <span>
            or create it by uploading a CSV{" "}
            <span
              className="text-blue-400 hover:text-blue-300 cursor-pointer"
              onClick={() => setNewCollectionModalOpen(true)}
              data-testid="btn-new-collection"
            >
              here
            </span>
          </span>
        )}
        .
      </div>
      <div className="flex space-x-3 justify-between items-center mb-3">
        <FiltersOverview
          filters={filters}
          tableSchema={SCHEMA}
          onFiltersReset={resetFilters}
        />
        <TableCount
          extraClasses="ml-auto self-end"
          count={countData?.count as number}
          entityName="collection"
          isLoading={countIsLoading}
          error={!!countError}
        />
      </div>
      {!error && (
        <PaginatedTable
          {...paginationData}
          pageKey={PAGE_KEY}
          data={formattedData}
          filtersInitialized={filtersInitialized}
          filters={filters}
          schema={SCHEMA}
          isLoading={isLoading}
          loadingRows={COLLECTIONS_PER_PAGE}
          sortBy={sort}
          onFiltersReset={resetFilters}
          onFilterChange={manageFilterChange}
          onSort={handleSorting}
        />
      )}
      {formattedData && formattedData.length === 0 && (
        <div className="py-4 text-gray-400 text-sm">
          No {collectionEntityType} collections yet.
        </div>
      )}
      {error && <APIError error={error} onBadRequest={resetFilterSortState} />}
      <NewCollectionModal
        isOpen={newCollectionModalOpen}
        onClose={() => setNewCollectionModalOpen(false)}
        onSubmit={() => mutateMultipleSWRRequestKeys(requestKeys)}
      />
      <DeleteCollectionModal
        collectionId={deleteCollectionId}
        collectionName={deleteCollectionName}
        isOpen={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onSubmit={() => {
          toast.success(ON_DELETE_SUCCESS_MESSAGE);
          mutateMultipleSWRRequestKeys(requestKeys);
        }}
      />
    </>
  );
};
export default CollectionsTable;
