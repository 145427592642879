import { useState } from "react";

import { ClaimsFiltersProps } from "pages/ClaimAnalytics/ClaimAnalyticsTabs";
import { BY_VEHICLES_AGE_TAB_KEY } from "pages/constants";
import { useByVehicleAgeOptions } from "pages/hooks";

import { SelectedChartOptions } from "features/ui/charts/Actions/types";
import { getDefaultActions } from "features/ui/charts/utils";

import ByVehicleAgeChart from "./ByVehicleAgeChart";
import ByVehicleAgeTable from "./ByVehicleAgeTable";
import { useByVehicleAgeChartActions } from "./hooks";

export const BY_VEHICLE_AGE_CHART_OPTIONS_KEY =
  "claimAnalyticsByVehicleAgeChartOptions";

const ByVehicleAge = ({
  claimsFilters,
  vehiclesFilters,
  onBadRequest,
  chartSettings,
  manageChartSettingsChange,
}: ClaimsFiltersProps) => {
  const actions = useByVehicleAgeChartActions();

  const initialSelectedOptions: SelectedChartOptions[] =
    chartSettings &&
    chartSettings[BY_VEHICLES_AGE_TAB_KEY] &&
    chartSettings[BY_VEHICLES_AGE_TAB_KEY][BY_VEHICLE_AGE_CHART_OPTIONS_KEY]
      ? chartSettings[BY_VEHICLES_AGE_TAB_KEY][BY_VEHICLE_AGE_CHART_OPTIONS_KEY]
      : getDefaultActions(actions);

  const [selectedOptions, setSelectedOptions] = useState<
    SelectedChartOptions[]
  >(initialSelectedOptions);

  const handleSelectedOptionsChange = (
    newSelectedOptions: SelectedChartOptions[]
  ) => {
    setSelectedOptions(newSelectedOptions);
    if (manageChartSettingsChange) {
      manageChartSettingsChange(
        newSelectedOptions,
        BY_VEHICLE_AGE_CHART_OPTIONS_KEY
      );
    }
  };

  const byVehicleAgeData = useByVehicleAgeOptions(selectedOptions, actions);

  return (
    <div className="mt-5">
      <ByVehicleAgeChart
        claimsFilters={claimsFilters}
        vehiclesFilters={vehiclesFilters}
        onBadRequest={onBadRequest}
        actions={actions}
        byVehicleAgeData={byVehicleAgeData}
        selectedOptions={selectedOptions}
        setSelectedOptions={handleSelectedOptionsChange}
      />
      <ByVehicleAgeTable
        claimsFilters={claimsFilters}
        vehiclesFilters={vehiclesFilters}
        onBadRequest={onBadRequest}
        byVehicleAgeData={byVehicleAgeData}
      />
    </div>
  );
};

export default ByVehicleAge;
