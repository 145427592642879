import { BreadcrumbMatch } from "use-react-router-breadcrumbs";

import { useCalculatedAttribute } from "shared/api/calculatedAttributes/hooks";

interface Props {
  match: BreadcrumbMatch;
}

const CalculatedAttributeBreadcrumb = ({ match }: Props) => {
  const { data, isLoading, error } = useCalculatedAttribute({
    id: match.params.id as string,
    skipRequest: match.params.id === "new",
  });
  if (isLoading) {
    return <></>;
  }

  if (error && match.params.id) {
    return <>{match.params.id}</>;
  }

  return <>{data?.name}</>;
};

export default CalculatedAttributeBreadcrumb;
