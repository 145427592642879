import { PageHandler } from "duck/graph/PageHandler";
import { StructuredTool, tool } from "@langchain/core/tools";

import { VIN_VIEW_SERVICE_RECORDS_TAB_KEY } from "pages/VINView/constants";

import {
  GoToVinViewTabWithVin,
  goToVinViewTabWithVinSchema,
  setRouteValue,
} from "./utils";

const getGoToServiceRecordsTabTool = (
  pageHandler: PageHandler
): StructuredTool => {
  const goToServiceRecordsTab = async ({ vin }: GoToVinViewTabWithVin) => {
    console.debug(`[VIN]: Navigating to ServiceRecords tab with vin ${vin}`);
    await setRouteValue(vin, pageHandler);

    pageHandler.navigateToTab(VIN_VIEW_SERVICE_RECORDS_TAB_KEY);
    return "queued navigation to Service Records tab";
  };

  return tool(goToServiceRecordsTab, {
    name: "queueGoToServiceRecordsTab",
    description:
      "Call this tool to queue a navigation to the Service Records tab",
    schema: goToVinViewTabWithVinSchema,
  });
};

export default getGoToServiceRecordsTabTool;
