import { useState } from "react";

import { PermissionEntry } from "shared/api/api";
import { useEmailFromJWT } from "shared/hooks";
import { EntityWithPermissions, PermissionID } from "shared/types";

import PermissionsTable from "./PermissionsTable";
import {
  addUserPermission,
  DEFAULT_GENERAL_PERMISSION,
  DEFAULT_MEMBER_ACCESS,
  formatGeneralPermission,
  formatGroupPermissions,
  formatPermissions,
  formatUserPermissions,
  getHigherPermission,
  getHigherPermissionsList,
  isHighestPermission,
  newGroupPermission,
  newUserPermission,
} from "./utils";

interface Props {
  entity: EntityWithPermissions;
  entityId?: string;
  permissions: PermissionEntry[];
  canEdit: boolean;
  onPermissionsUpdate: (
    permissions: PermissionEntry[],
    memberAccess: PermissionID
  ) => void;
  memberAccess?: PermissionID;
}

const PermissionsTableWrapper = ({
  entity,
  entityId,
  permissions,
  canEdit,
  onPermissionsUpdate,
  memberAccess = DEFAULT_MEMBER_ACCESS,
}: Props) => {
  const myEmail = useEmailFromJWT();

  const [generalPermission, setGeneralPermission] = useState(
    permissions.find((x) => x.everyone) || DEFAULT_GENERAL_PERMISSION
  );

  const [currentMemberAccess, setCurrentMemberAccess] = useState(
    getHigherPermission(generalPermission.access, memberAccess)
  );

  const [userPermissions, setUserPermissions] = useState(
    formatUserPermissions(
      permissions,
      generalPermission.access,
      myEmail,
      canEdit
    )
  );

  const [groupPermissions, setGroupPermissions] = useState(
    formatGroupPermissions(permissions, generalPermission.access, canEdit)
  );

  const onUpdateGeneralPermission = (access: PermissionID) => {
    const newGeneralPermission = { ...generalPermission, access };

    // When general permission is changed, default new user/group permission is set to first higher permission
    const permissionTypes = getHigherPermissionsList(access);
    let newUserPermissions = [...userPermissions];
    let newGroupPermissions = [...groupPermissions];
    if (permissionTypes.length > 0) {
      newUserPermissions = userPermissions.map((obj) =>
        !obj.ID
          ? { ...obj, access: permissionTypes[0].id as PermissionID }
          : obj
      );
      newGroupPermissions = groupPermissions.map((obj) =>
        !obj.ID
          ? { ...obj, access: permissionTypes[0].id as PermissionID }
          : obj
      );
    }

    // When general permission is changed, member access is also changed if needed
    const higherPermission = getHigherPermission(
      newGeneralPermission.access,
      memberAccess
    );
    setCurrentMemberAccess(higherPermission);

    onPermissionsChanged(
      newGeneralPermission,
      newUserPermissions,
      newGroupPermissions,
      myEmail,
      higherPermission
    );
  };

  const onUpdateUserPermission = (id: string, access: PermissionID) => {
    let newUserPermissions = userPermissions.map((obj) =>
      obj.ID === id ? { ...obj, access } : obj
    );
    onPermissionsChanged(
      generalPermission,
      newUserPermissions,
      groupPermissions,
      myEmail
    );
  };

  const onAddUserPermission = () => {
    const newUserPermissions = addUserPermission(userPermissions);

    if (canEdit && !isHighestPermission(generalPermission.access)) {
      const higherPermissions = getHigherPermissionsList(
        generalPermission.access
      );
      if (higherPermissions) {
        newUserPermissions.unshift(
          newUserPermission("", higherPermissions[0].id as PermissionID)
        );
      }
    }

    onPermissionsChanged(
      generalPermission,
      newUserPermissions,
      groupPermissions,
      myEmail
    );
  };

  const onRemoveUserPermission = (id: string) => {
    const newUserPermissions = userPermissions.filter(({ ID }) => ID !== id);
    onPermissionsChanged(
      generalPermission,
      newUserPermissions,
      groupPermissions,
      myEmail
    );
  };

  const onPermissionsChanged = (
    generalPermission: PermissionEntry,
    userPermissions: PermissionEntry[],
    groupPermissions: PermissionEntry[],
    myEmail: string,
    memberAccess?: PermissionID
  ) => {
    const formattedGeneralPermission = formatGeneralPermission([
      generalPermission,
    ]);
    const formattedUserPermissions = formatUserPermissions(
      userPermissions,
      generalPermission.access,
      myEmail,
      canEdit
    );
    const formattedGroupPermissions = formatGroupPermissions(
      groupPermissions,
      generalPermission.access,
      canEdit
    );

    setGeneralPermission(formattedGeneralPermission);
    setUserPermissions(formattedUserPermissions);
    setGroupPermissions(formattedGroupPermissions);

    const newPermissions = formatPermissions(
      [
        formattedGeneralPermission,
        ...formattedUserPermissions,
        ...formattedGroupPermissions,
      ],
      myEmail,
      canEdit
    );

    onPermissionsUpdate(newPermissions, memberAccess || currentMemberAccess);
  };

  const onUpdateEmail = (id: string, email: string) => {
    const newUserPermissions = userPermissions.map((obj) =>
      obj.ID === id ? { ...obj, email } : obj
    );
    onPermissionsChanged(
      generalPermission,
      newUserPermissions,
      groupPermissions,
      myEmail
    );
  };

  const onUpdateGroup = (id: string, groupID: string) => {
    const newGroupPermissions = groupPermissions.map((obj) =>
      obj.ID === id ? { ...obj, groupID } : obj
    );
    onPermissionsChanged(
      generalPermission,
      userPermissions,
      newGroupPermissions,
      myEmail
    );
  };

  const onUpdateGroupPermission = (id: string, access: PermissionID) => {
    let newGroupPermissions = groupPermissions.map((obj) =>
      obj.ID === id ? { ...obj, access } : obj
    );
    onPermissionsChanged(
      generalPermission,
      userPermissions,
      newGroupPermissions,
      myEmail
    );
  };

  const onAddGroupPermission = () => {
    const addedGroupID = groupPermissions.find(
      (x) => !x.ID && x.groupID
    )?.groupID;
    const groupAlreadyAdded = groupPermissions.find(
      (x) => x.ID && x.groupID === addedGroupID
    );
    if (groupAlreadyAdded) {
      return;
    }

    const newGroupPermissions = groupPermissions.map((obj) =>
      !obj.ID ? { ...obj, ID: obj.groupID as string } : obj
    );

    if (canEdit && !isHighestPermission(generalPermission.access)) {
      const higherPermissions = getHigherPermissionsList(
        generalPermission.access
      );
      if (higherPermissions) {
        newGroupPermissions.unshift(
          newGroupPermission("", higherPermissions[0].id as PermissionID)
        );
      }
    }

    onPermissionsChanged(
      generalPermission,
      userPermissions,
      newGroupPermissions,
      myEmail
    );
  };

  const onRemoveGroupPermission = (id: string) => {
    const newGroupPermissions = groupPermissions.filter(({ ID }) => ID !== id);
    onPermissionsChanged(
      generalPermission,
      userPermissions,
      newGroupPermissions,
      myEmail
    );
  };

  const onUpdateMemberAccess = (access: PermissionID) => {
    setCurrentMemberAccess(access);
    onPermissionsChanged(
      generalPermission,
      userPermissions,
      groupPermissions,
      myEmail,
      access
    );
  };

  return (
    <PermissionsTable
      entity={entity}
      entityId={entityId}
      generalPermission={generalPermission}
      userPermissions={userPermissions}
      groupPermissions={groupPermissions}
      memberAccess={currentMemberAccess}
      myEmail={myEmail}
      onUpdateEmail={onUpdateEmail}
      onUpdateGeneralPermission={onUpdateGeneralPermission}
      onUpdateUserPermission={onUpdateUserPermission}
      onAddUserPermission={onAddUserPermission}
      onRemoveUserPermission={onRemoveUserPermission}
      onUpdateGroup={onUpdateGroup}
      onUpdateGroupPermission={onUpdateGroupPermission}
      onAddGroupPermission={onAddGroupPermission}
      onRemoveGroupPermission={onRemoveGroupPermission}
      onUpdateMemberAccess={onUpdateMemberAccess}
      canEdit={canEdit}
      includeGroupMemberPermissions={entity === "group"}
    />
  );
};

export default PermissionsTableWrapper;
