import { useCustomLocalStorageState } from "shared/hooks";
import { IssueTypes } from "shared/types";

import Board from "features/ui/Board";

import AnalyzeWithComparisonPopButton from "./AnalyzeWithComparisonPopButton";
import IssueRepairEfficacyCharts from "./RepairEfficacy/IssueRepairEfficacyCharts";
import IssueRepairEfficacyStatistics from "./RepairEfficacy/IssueRepairEfficacyStatistics";
import ReoccurrenceTimePeriodModal from "./RepairEfficacy/ReoccurrenceTimePeriodModal";
import { getChartOptionsKey } from "./utils";

interface Props {
  issue: IssueTypes;
  withComparisonPopulation: boolean;
  onToggleWithComparisonPopulation: () => void;
}

const TIME_PERIOD_DROPDOWN_TITLE =
  "Time Period Defining Related/Reoccurring Signals & Claims";

const IssueRepairEfficacyTab = ({
  issue,
  withComparisonPopulation,
  onToggleWithComparisonPopulation,
}: Props) => {
  const { ID, comparisonPopulationFilter } = issue;

  const seOccWithinOptionKey = getChartOptionsKey("seOccWithin", ID);
  const [seOccurrenceWithin, setSeOccurrenceWithin] =
    useCustomLocalStorageState<number>(seOccWithinOptionKey, {
      defaultValue: 14,
    });

  const seOccStartingFromKey = getChartOptionsKey("seOccStartingFrom", ID);
  const [seReoccurrenceStartingFrom, setSeReoccurrenceStartingFrom] =
    useCustomLocalStorageState<number>(seOccStartingFromKey, {
      defaultValue: 1,
    });

  const seOccUpToKey = getChartOptionsKey("seOccUpTo", ID);
  const [seReoccurrenceUpTo, setSeReoccurrenceUpTo] =
    useCustomLocalStorageState<number>(seOccUpToKey, { defaultValue: 30 });

  const reoccurrenceParams = {
    seOccurrenceWithin,
    seReoccurrenceStartingFrom,
    seReoccurrenceUpTo,
  };

  const boardElements = [
    [
      <IssueRepairEfficacyStatistics
        issue={issue}
        withComparisonPopulation={withComparisonPopulation}
        {...reoccurrenceParams}
      />,
    ],
  ];

  const onConfirmTimePeriods = (
    seOccurrenceWithin: number,
    seReoccurrenceStartingFrom: number,
    seReoccurrenceUpTo: number
  ) => {
    setSeOccurrenceWithin(seOccurrenceWithin);
    setSeReoccurrenceStartingFrom(seReoccurrenceStartingFrom);
    setSeReoccurrenceUpTo(seReoccurrenceUpTo);
  };

  return (
    <>
      <div className="flex justify-end space-x-2">
        <ReoccurrenceTimePeriodModal
          title={TIME_PERIOD_DROPDOWN_TITLE}
          onConfirm={onConfirmTimePeriods}
          {...reoccurrenceParams}
        />
        <AnalyzeWithComparisonPopButton
          comparisonPopulationFilter={comparisonPopulationFilter || undefined}
          onClick={onToggleWithComparisonPopulation}
          value={withComparisonPopulation}
        />
      </div>
      <Board elements={boardElements} />
      <IssueRepairEfficacyCharts
        issue={issue}
        withComparisonPopulation={withComparisonPopulation}
        {...reoccurrenceParams}
      />
    </>
  );
};

export default IssueRepairEfficacyTab;
