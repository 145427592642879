import { validateApiRequest } from "duck/graph/nodes/utils";

import {
  ClaimFiltersRequest,
  getClaimsMetricsRequestURI,
} from "shared/api/claims/api";

/**
 * Validate the filter that is passed in. If the validation fails, an error will be thrown.
 *
 * @param params The parameters contains optional properties for claimsFilter, vehiclesFilter,
 * and signalEventOccurrencesFilter. Typically, one of those will be set when this is called.
 * @throws If validation fails, an error will be thrown. The error is unusually wordy
 * because it is intended for consumption by the agent, which benefits from the detail.
 */
export const validateFilter = async (
  params: ClaimFiltersRequest
): Promise<void> => {
  await validateApiRequest(params, getClaimsMetricsRequestURI);
};
