import { useState } from "react";
import { AiOutlineShareAlt as ShareIcon } from "react-icons/ai";
import { IconButton } from "@mui/material";

import { useTimeout } from "shared/hooks";
import { copyToClipboard } from "shared/utils";

import Tooltip from "./Tooltip";

const HIDE_TOOLTIP_AFTER_MS = 2000;
const TOOLTIP_TEXT = "URL Copied to clipboard!";
const TOOLTIP_ERROR_TEXT = "Failed to copy URL to clipboard!";

const CopyURLToClipboardAction = () => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipText, setTooltipText] = useState(TOOLTIP_TEXT);

  const copyToClipboardAndNotify = () => {
    copyToClipboard(window.location.href).then((ok) => {
      if (ok) {
        setTooltipText(TOOLTIP_TEXT);
      } else {
        setTooltipText(TOOLTIP_ERROR_TEXT);
      }
      setShowTooltip(true);
    });
  };

  // hide the tooltip after a delay when showTooltip value changes
  useTimeout(() => setShowTooltip(false), HIDE_TOOLTIP_AFTER_MS, [showTooltip]);

  const tooltipContent = <div className="text-xs">{tooltipText}</div>;

  return (
    <Tooltip content={tooltipContent} trigger="click" visible={showTooltip}>
      <IconButton
        onClick={copyToClipboardAndNotify}
        data-testid="copy-url-to-clipboard"
      >
        <ShareIcon />
      </IconButton>
    </Tooltip>
  );
};

export default CopyURLToClipboardAction;
