import { useState } from "react";
import { AiOutlineGroup as CollectionsIcon } from "react-icons/ai";
import { generatePath, Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { newCollectionFromVINS } from "shared/api/api";

import { getSuccessMessage } from "pages/Collections/NewCollectionForm/FilterForm/FilterCollectionForm";
import FormStepper from "pages/Collections/NewCollectionForm/FormStepper";
import styles from "pages/Collections/NewCollectionForm/NewCollectionForm.module.css";

import { routes } from "services/routes";

import FormNavigation from "./FormNavigation";
import InvalidVins from "./InvalidVins";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";

const NAME_MIN_LENGTH = 5;
const NAME_MAX_LENGTH = 120;

interface Props {
  onSubmit?: () => void;
}

// TODO: allow the selection of the “VIN” column (we can suggest a default if we can infer VIN column) in UI
const NewCSVCollectionForm = ({ onSubmit }: Props) => {
  const navigate = useNavigate();

  const [collectionName, setCollectionName] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [vinListUploaded, setVinListUploaded] = useState<string[]>([]);
  const [invalidVins, setInvalidVins] = useState<string[]>([]);
  const [error, setError] = useState<Error>();

  const [isLoading, setIsLoading] = useState(false);
  const [hasHeaderRow, setHasHeaderRow] = useState(false);

  const [submitted, setSubmitted] = useState(false);

  const nameValid =
    collectionName.length >= NAME_MIN_LENGTH &&
    collectionName.length <= NAME_MAX_LENGTH;

  // remove duplicates & header row if it exists
  const vinsToUse = Array.from(
    new Set(
      hasHeaderRow
        ? vinListUploaded.slice(1, vinListUploaded.length)
        : vinListUploaded
    )
  );

  const steps = [
    {
      label: "Upload CSV",
      content: (
        <Step1
          vins={vinsToUse}
          onVinsChange={setVinListUploaded}
          hasHeaderRow={hasHeaderRow}
          onHasHeaderChange={setHasHeaderRow}
        />
      ),
    },
    {
      label: "Collection Name",
      content: (
        <Step2
          collectionName={collectionName}
          onCollectionNameChange={setCollectionName}
          nameValid={nameValid}
        />
      ),
    },
    {
      label: "Review & Submit",
      content: <Step3 vins={vinsToUse} collectionName={collectionName} />,
    },
  ];

  const createNewCollection = () => {
    setInvalidVins([]);
    setIsLoading(true);
    setSubmitted(false);

    newCollectionFromVINS({
      name: collectionName,
      vins: vinsToUse,
    })
      .then(({ data: { invalidVINs, ID } }) => {
        setInvalidVins(invalidVINs);
        toast.success(getSuccessMessage(collectionName));
        navigate(generatePath(routes.collections, { id: ID }));
        onSubmit && onSubmit();
      })
      .catch((error: Error) => {
        setError(error);
      })
      .finally(() => {
        setSubmitted(true);
        setIsLoading(false);
      });
  };

  return (
    <>
      {!submitted && <FormStepper steps={steps} activeStep={activeStep} />}
      {!submitted && (
        <div className={styles["stepsWrap"]}>
          {steps.map(({ label, content }, i) => {
            return activeStep === i && <div key={label}>{content}</div>;
          })}
        </div>
      )}
      {!isLoading && (
        <div className="py-3">
          <h3>
            {submitted && !error && (
              <div>
                <span className="text-blue-400">Successfully created</span> new
                collection "{collectionName}".
                {invalidVins.length > 0 && <InvalidVins vins={invalidVins} />}
              </div>
            )}
            {error && (
              <div className="text-gray-500">
                {error.message}.<br />
                Please try again later or contact us directly.
              </div>
            )}
          </h3>
        </div>
      )}
      <div className="text-sm">
        Alternatively, you can create a collection from filters applied on{" "}
        <Link to={routes.vehicles} className="underline hover:text-gray-500">
          Vehicles page
        </Link>{" "}
        by clicking this icon: <CollectionsIcon className="inline" size={15} />.
      </div>
      {!submitted && (
        <FormNavigation
          activeStep={activeStep}
          stepsLength={steps.length}
          collectionName={collectionName}
          collectionNameValid={nameValid}
          vins={vinsToUse}
          isLoading={isLoading}
          onStepChange={setActiveStep}
          onFormSubmit={createNewCollection}
        />
      )}
    </>
  );
};

export default NewCSVCollectionForm;
