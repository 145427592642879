import {
  CountResponse,
  EntityAttribute,
  ListAttributesRequest,
} from "shared/api/api";
import { DEFAULT_ATTRIBUTES_SWR_CONFIG } from "shared/api/constants";
import { useAPI, usePaginatedAPI } from "shared/api/hooks";

import * as api from "./api";

export const useListServiceRecommendations = (
  args: api.ListServiceRecommendationsRequest
) =>
  usePaginatedAPI<typeof args, api.ServiceRecommendation[]>(
    api.listServiceRecommendationsRequestURI,
    args
  );

export const useListServiceRecommendationsCount = (
  args: api.ListServiceRecommendationsRequest
) =>
  useAPI<typeof args, CountResponse>(
    api.listServiceRecommendationsCountRequestURI,
    args
  );

export const useServiceRecommendationsAttributes = (
  args: ListAttributesRequest
) =>
  useAPI<typeof args, EntityAttribute[]>(
    api.listServiceRecommendationsAttributesRequestURI,
    args,
    DEFAULT_ATTRIBUTES_SWR_CONFIG
  );
