import Skeleton from "react-loading-skeleton";

import { useFleets } from "shared/api/hooks";

import APIError from "features/ui/APIError";

interface Props {
  fleetName: string;
}

const FleetInfo = ({ fleetName }: Props) => {
  const filter = `name=eq:"${fleetName}"`;
  const { data, isLoading, error } = useFleets({
    filter,
  });

  const fleet = data ? data[0] : undefined;

  if (!isLoading && !fleet) {
    return (
      <div className="py-4 text-gray-400 text-sm">
        No data for "{fleetName}".
      </div>
    );
  }

  return (
    <div className="mt-2 py-4">
      {error && <APIError error={error} />}
      <div className="flex space-x-8">
        <div>
          <div className="text-sm text-gray-500">Total Trucks</div>
          <div className="text-2xl font-semibold text-blue-500">
            {fleet?.numVehicles !== undefined ? (
              <span data-testid="fleet-number-vehicles">
                {fleet?.numVehicles}
              </span>
            ) : (
              <Skeleton width={60} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FleetInfo;
