import { DEFAULT_FILTER_TYPE } from "features/ui/Filters/constants";
import OperatorsDropdown from "features/ui/Filters/SingleFilter/OperatorsDropdown";
import { FilterOperator, FilterType } from "features/ui/Filters/types";
import {
  getFirstAvailableSelectedOperator,
  operatorToSelectOption,
} from "features/ui/Filters/utils";
import { SelectOption } from "features/ui/Select";
import { SchemaEntry } from "features/ui/Table";

interface Props {
  selected: FilterOperator | null;
  onChange: (selected: FilterOperator) => void;
  attributeSchema?: SchemaEntry<string>;
  disabled?: boolean;
}

const OperatorSelect = ({
  selected,
  onChange,
  attributeSchema,
  disabled,
}: Props) => {
  const currentAttributeType: FilterType =
    attributeSchema?.filter?.filterType || DEFAULT_FILTER_TYPE;

  const currentSelectedOperator = getFirstAvailableSelectedOperator(
    selected,
    currentAttributeType,
    attributeSchema
  );

  const selectedOperatorOption: SelectOption<FilterOperator> =
    operatorToSelectOption(currentAttributeType, currentSelectedOperator);

  const currentAttributeFilterProperties = attributeSchema?.filter;

  return (
    <OperatorsDropdown
      type={currentAttributeType}
      selected={selectedOperatorOption}
      onChange={(selected) => onChange(selected.id)}
      className="max-w-56"
      disabled={disabled}
      {...currentAttributeFilterProperties}
    />
  );
};

export default OperatorSelect;
