import { SyntheticEvent } from "react";
import { AiOutlineCheck as CheckIcon } from "react-icons/ai";
import { Virtuoso } from "react-virtuoso";

import Accordion from "features/ui/Accordion";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import SingleFilter from "features/ui/Filters/SingleFilter/SingleFilter";
import { FilterChangeCallback } from "features/ui/Filters/types";
import { SchemaEntry } from "features/ui/Table";

interface Props {
  schema: SchemaEntry<string>[];
  activeFilterKeys: string[];
  expanded: string | false;
  filters: FilterGroupState;
  handleAccordionChange: (
    panel: string
  ) => (event: SyntheticEvent, isExpanded: boolean) => void;
  onFilterChange: FilterChangeCallback;
  onResetFilters: (fieldNames?: string[]) => void;
  filtersInitialized?: boolean;
  onFilterValuesOrOperatorChange: FilterChangeCallback;
  defaultFilters?: FilterGroupState;
  pageKey: string;
}

const VirtualizedFiltersList = ({
  schema,
  activeFilterKeys,
  expanded,
  filters,
  handleAccordionChange,
  onFilterChange,
  onResetFilters,
  filtersInitialized,
  onFilterValuesOrOperatorChange,
  defaultFilters,
  pageKey,
}: Props) => {
  const formattedSchemas = schema.map(({ label, accessor, filter }) => {
    const isSelected = activeFilterKeys.includes(accessor);
    const title = (
      <div className="flex items-center space-x-3">
        {(isSelected && <CheckIcon size={20} className="text-blue-400" />) || (
          <div className="w-5"></div>
        )}
        <span className="capitalize">{filter?.label || label}</span>
      </div>
    );

    return (
      <Accordion
        key={accessor}
        id={accessor}
        title={title}
        expanded={expanded === accessor}
        onChange={handleAccordionChange(accessor)}
      >
        <SingleFilter
          filter={filter}
          activeFilters={filters}
          onFilterChange={onFilterChange}
          onFiltersReset={onResetFilters}
          filtersInitialized={filtersInitialized}
          onValueOrOperatorChange={onFilterValuesOrOperatorChange}
          hideCTA
          defaultFilters={defaultFilters}
          pageKey={pageKey}
        />
      </Accordion>
    );
  });

  return (
    <Virtuoso
      data={formattedSchemas}
      itemContent={(index: number) => formattedSchemas[index]}
    />
  );
};

export default VirtualizedFiltersList;
