import { APIPaginatedRequest, patchFetcher } from "shared/api/api";
import { clientV1 } from "shared/api/axios";
import { createURL } from "shared/api/utils";
import { BOOKMARKS_ROUTE } from "shared/constants";

export interface Bookmark {
  ID: string;
  createdAt: string;
  updatedAt: string;
  title: string;
  description?: string;
  path: string;
}

export interface ListBookmarksRequest extends APIPaginatedRequest {
  refreshKey: boolean;
}
export interface DeleteBookmarkRequest {
  id: string;
}
export interface UpdateBookmarkRequest {
  ID: string;
  title: string;
  description?: string;
  path: string;
}

export const listBookmarksRequestURI = (params: ListBookmarksRequest): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([BOOKMARKS_ROUTE]),
    params,
  });

const updateBookmarkRequestURI = ({
  ID,
  ...params
}: UpdateBookmarkRequest): string =>
  clientV1.getUri({
    method: "PATCH",
    url: createURL([BOOKMARKS_ROUTE, ID]),
    params,
  });

export const updateBookmark = (args: UpdateBookmarkRequest) =>
  patchFetcher<Bookmark>(updateBookmarkRequestURI(args), args);

const deleteBookmarkRequestURI = ({ id }: DeleteBookmarkRequest): string =>
  clientV1.getUri({
    method: "DELETE",
    url: createURL([BOOKMARKS_ROUTE, id]),
  });

export const deleteBookmark = (args: DeleteBookmarkRequest) =>
  clientV1.delete(deleteBookmarkRequestURI(args));
