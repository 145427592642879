import { Issue } from "shared/api/issues/api";
import { IssueTypes } from "shared/types";

import { getPopulationString, isSuggestedIssue } from "pages/Issues/utils";

import { AccordionProps } from "features/ui/Accordion";
import AccordionGroup from "features/ui/AccordionGroup";

import { CreateIssueProps } from "./CardActions";
import CardPopulation from "./CardPopulation";

interface Props {
  issue: IssueTypes;
  createIssueProps?: CreateIssueProps;
}

const AT_RISK_POP_STEP_INDEX = 0;
const COMPARISON_POP_STEP_INDEX = 1;

const CardPopulations = ({ issue, createIssueProps }: Props) => {
  const { atRiskPopulationFilter, comparisonPopulationFilter } = issue;
  const canEdit = isSuggestedIssue(issue) ? false : (issue as Issue).canEdit;

  const atRiskCreateIssueProps = createIssueProps && {
    ...createIssueProps,
    step: AT_RISK_POP_STEP_INDEX,
  };
  const comparisonCreateIssueProps = createIssueProps && {
    ...createIssueProps,
    step: COMPARISON_POP_STEP_INDEX,
  };

  const accordionGroupData: AccordionProps[] = [
    {
      title: (
        <span className="ml-2 font-semibold text-base">
          {getPopulationString("atRisk")}
        </span>
      ),
      children: (
        <div className="mx-2">
          <CardPopulation
            canEdit={canEdit}
            populationFilter={atRiskPopulationFilter}
            createIssueProps={atRiskCreateIssueProps}
          />
        </div>
      ),
      id: "at-risk_population",
      expanded: true,
    },
    {
      title: (
        <span className="ml-2 font-semibold text-base">
          {getPopulationString("comparison")}
        </span>
      ),
      children: (
        <div className="mx-2">
          <CardPopulation
            canEdit={canEdit}
            populationFilter={comparisonPopulationFilter || undefined}
            createIssueProps={comparisonCreateIssueProps}
            buttonLabel="+ Comparison Population"
          />
        </div>
      ),
      id: "comparison_population",
      expanded: true,
    },
  ];

  return (
    <AccordionGroup
      data={accordionGroupData}
      allowMultiOpen
      arrowPosition="left"
    />
  );
};

export default CardPopulations;
