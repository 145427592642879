import { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import Button from "@mui/material/Button";

import {
  Bookmark as BookmarkModel,
  UpdateBookmarkRequest,
} from "shared/api/bookmarks/api";

import Input from "features/ui/Input";
import TextArea from "features/ui/TextArea";

interface UpdateBookmarkProps {
  bookmark: BookmarkModel;
  onConfirm: (bookmark: UpdateBookmarkRequest) => void;
  onDelete: () => void;
  onCancel: () => void;
}

const ManageBookmark = ({
  bookmark,
  onConfirm,
  onDelete,
  onCancel,
}: UpdateBookmarkProps) => {
  const [title, setTitle] = useState(bookmark.title);
  const [description, setDescription] = useState(bookmark.description);
  const [path, setPath] = useState(bookmark.path);
  const elementRef = useRef<HTMLDivElement>(null);

  // scroll to the bookmark form on render
  useEffect(() => {
    const { current } = elementRef;
    if (current != null) {
      current.scrollIntoView({ behavior: "auto" });
    }
  });

  const handleConfirm = () => {
    const updatedBookmark: UpdateBookmarkRequest = {
      title,
      description,
      path,
      ID: bookmark.ID,
    };
    onConfirm(updatedBookmark);
  };

  return (
    <div
      ref={elementRef}
      className={classNames(
        "flex flex-col gap-3 min-h-70 max-h-80 min-w-96 max-w-80 bg-white p-2 rounded items-start"
      )}
    >
      <Input
        label={"Title"}
        value={title}
        onChange={({ target: { value } }) => setTitle(value)}
        testId="bookmark-description"
        tabIndex={1}
        sx={{
          ".MuiInputBase-input": {
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
          },
        }}
      />

      <Input
        label="URL"
        value={path}
        onChange={({ target: { value } }) => setPath(value)}
        testId="bookmark-description"
        tabIndex={1}
        sx={{
          ".MuiInputBase-input": {
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
          },
        }}
      />
      <TextArea
        className="text-sm"
        label="Help text"
        value={description}
        onChange={({ target: { value } }) => setDescription(value)}
        testId="bookmark-description"
        tabIndex={1}
        rows={7}
        sx={{
          ".MuiInputBase-input": {
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
          },
        }}
      />
      <div className={"w-full flex flex-row justify-end gap-2"}>
        <Button size={"small"} variant={"contained"} onClick={handleConfirm}>
          Save
        </Button>
        <Button
          size={"small"}
          variant={"outlined"}
          onClick={onDelete}
          color={"secondary"}
        >
          Delete
        </Button>
        <Button
          size={"small"}
          variant={"outlined"}
          onClick={onCancel}
          color={"secondary"}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default ManageBookmark;
