import {
  DOCUMENT_RETRIEVAL_DISTANCE_THRESHOLD,
  DOCUMENT_RETRIEVAL_K,
  DOCUMENTS_SOURCE,
} from "duck/graph/constants";
import { GraphStateType } from "duck/graph/state";
import getRespondToUserTool from "duck/graph/tools/getRespondToUserTool";
import { DuckGraphParams } from "duck/graph/types";
import { ToolNode } from "@langchain/langgraph/prebuilt";

import getCaptureScreenshotAgentNode from "./CaptureScreenshotAgent/getNode";
import getClaimAnalyticsAgentNodes from "./ClaimAnalyticsAgent/getNodes";
import getRagAgentNode from "./RagAgent/getNode";
import getRejectClarifyNode from "./RejectClarifyAgent/getNode";
import getSignalEventAnalyticsAgentNodes from "./SignalEventAnalyticsAgent/getNodes";
import getSupervisorAgentNode from "./SupervisorAgent/getNode";
import { createDocumentRetrievalNode } from "./utils";
import getVehiclesAgentNodes from "./VehiclesAgent/getNodes";
import getVinViewAgentNodes from "./VinViewAgent/getNodes";

const getDocumentRetrievalNode = (params: DuckGraphParams) =>
  createDocumentRetrievalNode(
    DOCUMENTS_SOURCE,
    DOCUMENT_RETRIEVAL_K,
    DOCUMENT_RETRIEVAL_DISTANCE_THRESHOLD,
    params.uiHandlers.setEphemeralMessage
  );

const getRespondToUserToolNode = (
  params: DuckGraphParams
): ToolNode<GraphStateType> =>
  new ToolNode<GraphStateType>([
    getRespondToUserTool(
      params.uiHandlers.setAgentResponse,
      params.uiHandlers.setEphemeralMessage
    ),
  ]);

export {
  getClaimAnalyticsAgentNodes,
  getRagAgentNode,
  getSupervisorAgentNode,
  getDocumentRetrievalNode,
  getRejectClarifyNode,
  getRespondToUserToolNode,
  getCaptureScreenshotAgentNode,
  getSignalEventAnalyticsAgentNodes,
  getVinViewAgentNodes,
  getVehiclesAgentNodes,
};
