import { HiOutlineX } from "react-icons/hi";
import { DialogTitle, IconButton } from "@mui/material";

const CLOSE_BUTTON_SIZE = "1.5rem";

interface Props {
  vin: string;
  failureModeName: string;
  closeModal: () => void;
}

const SimilarityModalTitle = ({ vin, failureModeName, closeModal }: Props) => {
  return (
    <DialogTitle className="flex justify-between">
      <span>
        Vehicles Similar to{" "}
        <span className="text-blue-400 font-bold">{vin}</span> that have
        experienced <span>{failureModeName}</span> Failures
      </span>
      <IconButton
        onClick={closeModal}
        data-testid="similar-vehicles-modal-close"
        size="small"
      >
        <HiOutlineX size={CLOSE_BUTTON_SIZE} />
      </IconButton>
    </DialogTitle>
  );
};

export default SimilarityModalTitle;
