import { useState } from "react";
import classNames from "classnames";
import { AiOutlineSearch as SearchIcon } from "react-icons/ai";

import CardHeader from "pages/Issues/Details/CardHeader";

import Card from "features/ui/Card";
import ChipList from "features/ui/ChipList";
import FilterDescriptionTable from "features/ui/Filters/other/FilterDescriptionTable";
import Input from "features/ui/Input";
import Modal from "features/ui/Modal";

interface SignalEventProps {
  signalEventIDs: string[];
  title: string;
}

export const SignalEventsColumn = ({
  signalEventIDs = [],
  title,
}: SignalEventProps) => {
  return (
    <div className="flex space-x-1 cursor-pointer">
      <Modal
        triggerElement={
          <ChipList
            title="Signal Events"
            values={signalEventIDs}
            clickableChips={true}
          />
        }
        showCloseIcon={true}
        children={[
          <SignalEventsDescriptionsPopup
            title={title}
            key="SignalEventsColumn"
            cardClassNames="h-full"
            signalEventIDs={signalEventIDs}
          />,
        ]}
      />
    </div>
  );
};

interface Props {
  title: string;
  cardClassNames?: string;
  signalEventIDs: string[];
}

const SignalEventsDescriptionsPopup = ({
  title,
  signalEventIDs,
  cardClassNames,
}: Props) => {
  const [searchString, setSearchString] = useState("");

  return (
    <Card classNames={classNames("!p-5", cardClassNames)}>
      <CardHeader title={title} />
      {signalEventIDs.length > 0 && (
        <>
          <div className="flex justify-end mb-3">
            <Input
              label=""
              onChange={({ target: { value } }) => {
                setSearchString(value);
              }}
              type="text"
              placeholder="Search for signals"
              icon={<SearchIcon />}
              testId="search-signal-events"
            />
          </div>
          <FilterDescriptionTable
            values={signalEventIDs}
            searchString={searchString}
            fieldName="signalEvents"
          />
        </>
      )}
      {signalEventIDs.length === 0 && (
        <div className="text-gray-400 text-sm">No data.</div>
      )}
    </Card>
  );
};
