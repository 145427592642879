import { promptNames } from "duck/graph/nodes/types";
import { AgentNodes, getAgentNodes } from "duck/graph/nodes/utils";
import { DuckGraphParams } from "duck/graph/types";
import { NodeNames } from "duck/graph/utils";

import getTools from "./tools/getTools";

const getNodes = (params: DuckGraphParams): Promise<AgentNodes> =>
  // We deliberately do not send a route value to getAgentNodes because
  // we do not yet know what the route would be because we don't have
  // a VIN yet. We could try to extract the VIN from the pathname, but that would
  // severly limit the utility of the agent because it would be unable to navigate
  // to different VIN's. Instead, we will set the route value later, after the agent
  // has provided a VIN. The agent will be able to get the VIN from various sources,
  // including the pathname,  the user's utterance, message history, or a screenshot.
  getAgentNodes({
    params,
    getTools,
    promptName: promptNames.VIN_VIEW_AGENT,
    name: NodeNames.VIN_VIEW,
  });

export default getNodes;
