import qs from "qs";

import { getFiltersKey } from "features/ui/Filters/utils";

import { routes } from "services/routes";

import {
  PAGE_KEY,
  SP_RECOMMENDATIONS_FILTERS_LS_KEY,
  SP_RECOMMENDATIONS_VEHICLE_FILTERS_LS_KEY,
} from "./constants";

export const constructLinkToRecommendationsPage = (
  filterQuery: string,
  vehiclesFilterQuery: string
) => ({
  pathname: routes.serviceRecommendations,
  search: qs.stringify(
    {
      [getFiltersKey(SP_RECOMMENDATIONS_FILTERS_LS_KEY)]: filterQuery,
      [getFiltersKey(PAGE_KEY)]: "",
      [getFiltersKey(SP_RECOMMENDATIONS_VEHICLE_FILTERS_LS_KEY)]:
        vehiclesFilterQuery,
    },
    { arrayFormat: "indices" }
  ),
});
