import { ContextProviderName } from "shared/contexts/ContextWrappers";
import { useAttributesContext } from "shared/contexts/GenericAttributesContextWrapper";
import { FAILURE_MODES_GENERIC_FILTER } from "shared/filterDefinitions";

import { SchemaEntry } from "features/ui/Table";

import { SchemaEntryOverride, UseSchema } from "./types";
import {
  getAttributeMap,
  getDisplayLabelFunction,
  getFilterFunction,
  getSchemaEntryFunction,
  getSchemaForAccessorFunction,
  getSchemaFromAttributes,
} from "./utils";

export const useFailureModeSchema = (
  accessorsToHide: string[] = []
): UseSchema => {
  const attributes = useAttributesContext(ContextProviderName.FailureMode);

  const attributeMap = getAttributeMap(attributes);

  const getDisplayLabel = getDisplayLabelFunction(attributeMap);

  const overrides: SchemaEntryOverride = {
    status: {
      filterOverride: {
        disableArbitraryText: true,
      },
    },
  };

  const getFilter = getFilterFunction(
    FAILURE_MODES_GENERIC_FILTER,
    attributeMap
  );

  const getSchemaEntry = getSchemaEntryFunction(getFilter, attributeMap);

  const schema: SchemaEntry[] = getSchemaFromAttributes(
    attributes,
    getSchemaEntry,
    overrides,
    undefined,
    accessorsToHide
  );

  const getSchemaForAccessor = getSchemaForAccessorFunction(schema);

  return {
    schema,
    attributes,
    getDisplayLabel,
    attributeMap,
    whitelist: Object.keys(attributeMap || {}),
    getSchemaEntry,
    getSchemaForAccessor,
  };
};
