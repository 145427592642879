import { useCallback, useMemo } from "react";
import { generatePath, Link } from "react-router-dom";

import {
  getVehicleRiskModelPredictionsExport,
  RiskModelPrediction,
} from "shared/api/failureModes/api";
import {
  useVehicleRiskModelPredictions,
  useVehicleRiskModelPredictionsCount,
} from "shared/api/failureModes/hooks";
import { APIFilter, getSortFilter } from "shared/api/utils";
import { MAX_ROWS_DOWNLOAD_LIMIT } from "shared/constants";
import { VEHICLE_RISK_MODEL_PREDICTIONS_GENERIC_FILTER } from "shared/filterDefinitions";
import { useRiskModelPredictionsSchema } from "shared/schemas/riskModelPredictionsSchema";
import { SortBy } from "shared/types";

import StatusBadge from "pages/FailureModes/StatusBadge";

import APIError from "features/ui/APIError";
import DownloadAction from "features/ui/DownloadAction";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";
import FiltersOverview from "features/ui/Filters/FiltersOverview";
import { useFilterSortState } from "features/ui/Filters/hooks";
import { FilterSchemaItem } from "features/ui/Filters/types";
import { OnSortParams } from "features/ui/Table";
import PaginatedTable from "features/ui/Table/PaginatedTable";
import TableCount from "features/ui/Table/TableCount";

import { routes } from "services/routes";

interface Props {
  vin: string;
}

const RESULTS_PER_PAGE = 100;
const DEFAULT_SORT: SortBy = { riskLabel: "desc" };

const NON_ARCHIVED_FAILURE_MODES_STATIC_FILTER: APIFilter[] = [
  {
    name: "failureMode.status",
    value: ["archived"],
    op: "nin",
  },
];

const VehicleRiskModelResults = ({ vin }: Props) => {
  const pageKey = `vehicle_risk_model_predictions_table_${vin}`;

  const { schema } = useRiskModelPredictionsSchema(
    (item: FilterSchemaItem) =>
      VEHICLE_RISK_MODEL_PREDICTIONS_GENERIC_FILTER(vin, item),
    ["VIN", "vehicle", "failureModeID"],
    [],
    ["status", "description", "createdAt", "updatedAt", "ID"],
    false,
    true
  );

  const {
    manageFilterChange,
    resetFilters,
    filters,
    initialized: filtersInitialized,
    sort,
    manageOnSortChange,
    resetFilterSortState,
  } = useFilterSortState({
    pageKey,
    defaultSort: DEFAULT_SORT,
    disableUsingQuery: true,
  });

  const filter = getFiltersQuery(
    filters,
    NON_ARCHIVED_FAILURE_MODES_STATIC_FILTER
  );

  const { data, isLoading, error, ...paginationData } =
    useVehicleRiskModelPredictions({
      vin,
      filter,
      sort: getSortFilter(sort),
      limit: RESULTS_PER_PAGE,
    });

  const {
    data: countData,
    isLoading: countIsLoading,
    error: countError,
  } = useVehicleRiskModelPredictionsCount({
    vin,
    filter,
  });

  const formatRow = useCallback((riskModelPrediction: RiskModelPrediction) => {
    const pathname = generatePath(routes.failureModeV1, {
      id: riskModelPrediction.failureModeID || null,
    });

    return {
      ...riskModelPrediction,
      failureMode: {
        ...riskModelPrediction.failureMode,
        name: (
          <Link
            to={{ pathname }}
            className="text-metabase-blue hover:underline"
          >
            {riskModelPrediction.failureMode?.name}
          </Link>
        ),
      },
      riskStatus: (
        <StatusBadge status={riskModelPrediction?.riskStatus ?? ""} />
      ),
    };
  }, []);

  const handleSorting = ({ accessor, sort }: OnSortParams) => {
    // only allow sorting by one column at the time
    manageOnSortChange({ [accessor]: sort });
  };

  const formattedData = useMemo(() => data?.map(formatRow), [data, formatRow]);

  const downloadDisabled = !formattedData || formattedData.length === 0;

  return (
    <div className="py-4">
      <div className="mb-10">
        <h3 className="text-xl mb-4">Active Risk Models</h3>
        <div className="flex space-x-3 items-center mb-1 mt-3 justify-between">
          <div>
            <FiltersOverview
              filters={filters}
              tableSchema={schema}
              onFiltersReset={resetFilters}
            />
          </div>
          <div className="flex space-x-3 items-center self-end">
            <TableCount
              extraClasses="ml-auto"
              count={countData?.count as number}
              entityName="result"
              isLoading={countIsLoading}
              error={!!countError}
            />
            <DownloadAction
              disabled={downloadDisabled}
              downloadFunc={getVehicleRiskModelPredictionsExport}
              fileName="vehicle_risk_model_results"
              requestParams={{
                IDs: [vin],
                filter,
                limit: MAX_ROWS_DOWNLOAD_LIMIT,
              }}
              count={countData?.count as number}
              entityName="result"
              filters={filters}
            />
          </div>
        </div>
        {!error && formattedData && (
          <PaginatedTable
            isLoading={isLoading}
            loadingRows={RESULTS_PER_PAGE}
            data={formattedData}
            schema={schema}
            sortBy={sort}
            onSort={handleSorting}
            filtersInitialized={filtersInitialized}
            onFilterChange={manageFilterChange}
            filters={filters}
            pageKey={pageKey}
            {...paginationData}
          />
        )}
        {error && (
          <APIError error={error} onBadRequest={resetFilterSortState} />
        )}
      </div>
    </div>
  );
};

export default VehicleRiskModelResults;
