import { IoMdStopwatch } from "react-icons/io";

import { DropdownSelectOption } from "features/ui/DropdownSelect/DropdownSelect";
import {
  RelatesFilterState,
  RelatesFilterWindowDirectionType,
} from "features/ui/Filters/types";

import RelatesFilterForm from "./RelatesFilterForm";
import RelatesFilterActions from "./RelatesFilterForm/RelatesFilterActions";

export interface RelatedFilterRowProps {
  relatesState: RelatesFilterState;
  appliedRelatesFilter?: RelatesFilterState;
  onUpdate: (newRelatesState: RelatesFilterState) => void;
  onApply?: () => void;
  onCancel?: () => void;
  baseEntityText: string;
  inFilterSelector?: boolean;
  windowDirectionOptions?: DropdownSelectOption<RelatesFilterWindowDirectionType>[];
  disableSelectingWindowDirection?: boolean;
  disableIsNotFilteredFilters?: boolean;
}

const RelatedFilterRow = ({
  relatesState,
  appliedRelatesFilter,
  onUpdate,
  onApply,
  onCancel,
  baseEntityText,
  inFilterSelector = true,
  windowDirectionOptions,
  disableSelectingWindowDirection,
  disableIsNotFilteredFilters,
}: RelatedFilterRowProps) => {
  const relatedSignalEventLabelProp = inFilterSelector
    ? {}
    : { relatedSignalEventLabel: "Associated Signal Event" };

  return (
    <div
      className="flex text-gray-500 justify-between mb-3"
      data-testid="relates-filter-row"
    >
      <div className="flex">
        {inFilterSelector && <IoMdStopwatch className="mr-1 mt-3" />}
        <div className="flex flex-wrap items-center md:space-y-1">
          <RelatesFilterForm
            relatesState={relatesState}
            onUpdate={onUpdate}
            baseEntityText={baseEntityText}
            inFilterSelector={inFilterSelector}
            windowDirectionOptions={windowDirectionOptions}
            disableSelectingWindowDirection={disableSelectingWindowDirection}
            disableIsNotFilteredFilters={disableIsNotFilteredFilters}
            {...relatedSignalEventLabelProp}
          />
          {!inFilterSelector && (
            <RelatesFilterActions
              relatesState={relatesState}
              appliedRelatesFilter={appliedRelatesFilter}
              onApply={onApply}
              onCancel={onCancel}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default RelatedFilterRow;
