import React from "react";
import Skeleton from "react-loading-skeleton";

import { useGetIssueRelationshipStatistics } from "shared/api/issues/hooks";
import { IssueTypes, IssueVehiclePopulation } from "shared/types";
import {
  roundNonZeroToNDecimals,
  roundPercentageToNDecimals,
} from "shared/utils";

import IssueStatistics, { LabelTexts } from "pages/Issues/IssueStatistics";
import { getBaseAPIRoute, getIssueCombinedID } from "pages/Issues/utils";

import APIError from "features/ui/APIError";

interface Props {
  issue: IssueTypes;
  population: IssueVehiclePopulation;
  withComparisonPopulation: boolean;
  timePeriod: number;
}

const IssueRelationshipPopulationStatistics = ({
  issue,
  population,
  withComparisonPopulation,
  timePeriod,
}: Props) => {
  const { updatedAt } = issue;
  const { data, isLoading, error } = useGetIssueRelationshipStatistics({
    IDs: getIssueCombinedID(issue),
    baseRoute: getBaseAPIRoute(issue),
    vehiclePopulation: population,
    signalEventsTimeWindow: timePeriod,
    updatedAt,
  });

  if (isLoading) {
    return <Skeleton height={200} />;
  }

  if (error) {
    return <APIError error={error} />;
  }

  if (!data) {
    return null;
  }

  const {
    percentOfClaimsWithPrecedingSignalEvent,
    claimRateForVehiclesWithSignalEvent,
  } = data;

  const labelTexts: LabelTexts[] = [
    {
      mainValue:
        percentOfClaimsWithPrecedingSignalEvent !== null
          ? `${roundPercentageToNDecimals(
              percentOfClaimsWithPrecedingSignalEvent
            )}%`
          : undefined,
      description: "of claims had a preceding signal event",
    },
    {
      mainValue:
        claimRateForVehiclesWithSignalEvent !== null
          ? `${roundNonZeroToNDecimals(
              claimRateForVehiclesWithSignalEvent
            )}/1000`
          : undefined,
      description: "Claims per vehicle with preceding signal event",
    },
  ];

  return (
    <IssueStatistics
      withComparisonPopulation={withComparisonPopulation}
      population={population}
      labelTexts={labelTexts}
    />
  );
};

export default IssueRelationshipPopulationStatistics;
