const SupportContent = ({ email }: { email: string }) => (
  <div data-testid="support-content">
    Please try later or contact{" "}
    <span>
      us via{" "}
      <a href={`mailto:${email}`} className="underline hover:text-blue-700">
        {email}
      </a>
    </span>
    .
  </div>
);

export default SupportContent;
