import { useListSignalEventsOccurrencesCount } from "shared/api/signalEvents/hooks";

import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";
import TableCount from "features/ui/Table/TableCount";

interface Props {
  filters: FilterGroupState;
  vehicleFilters: FilterGroupState;
  label: string;
}

const SignalEventOccurrencesCount = ({
  filters,
  vehicleFilters,
  label,
}: Props) => {
  const { isLoading, data, error } = useListSignalEventsOccurrencesCount({
    filter: getFiltersQuery(filters),
    vehiclesFilter: getFiltersQuery(vehicleFilters),
  });

  return (
    <TableCount
      prefix={`${label}: `}
      isLoading={isLoading}
      count={data?.count}
      entityName="signal event occurrence"
      error={Boolean(error)}
    />
  );
};
export default SignalEventOccurrencesCount;
