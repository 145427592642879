import {
  CountResponse,
  EntityAttribute,
  ListAttributesRequest,
} from "shared/api/api";
import { DEFAULT_ATTRIBUTES_SWR_CONFIG } from "shared/api/constants";
import { useAPI, usePaginatedAPI } from "shared/api/hooks";
import { CountRequestWithVehiclesFilter } from "shared/api/vehicles/api";

import {
  listCustomRecordAttributesRequestURI,
  listCustomRecordsCountRequestURI,
} from "./api";

export const useCustomRecordAttributes = (args: ListAttributesRequest) =>
  useAPI<typeof args, EntityAttribute[]>(
    listCustomRecordAttributesRequestURI,
    args,
    DEFAULT_ATTRIBUTES_SWR_CONFIG
  );

export const useListCustomRecordsCount = (
  args: CountRequestWithVehiclesFilter
) =>
  usePaginatedAPI<typeof args, CountResponse>(
    listCustomRecordsCountRequestURI,
    args
  );
