import {
  useClaimsFiltersSchema,
  useSignalEventOccurrencesFiltersSchema,
  useVehiclesFiltersSchema,
} from "shared/hooks";

import FilterQueryPresentation from "features/ui/Filters/FilterBuilder/FilterQueryPresentation";

interface PopulationProps {
  populationFilter?: string;
}

interface ClaimProps {
  claimFilter?: string;
}

interface SignalEventProps {
  signalEventOccurrencesFilter?: string;
}

export const PopulationFilterPresenter = ({
  populationFilter,
}: PopulationProps) => {
  const schema = useVehiclesFiltersSchema();
  if (!populationFilter) {
    return null;
  }

  return (
    <FilterQueryPresentation filter={populationFilter} tableSchema={schema} />
  );
};

export const ClaimFilterPresenter = ({ claimFilter }: ClaimProps) => {
  const schema = useClaimsFiltersSchema();

  if (!claimFilter) {
    return null;
  }

  return (
    <FilterQueryPresentation
      filter={claimFilter}
      tableSchema={schema}
      baseEntityText="Claims"
    />
  );
};

export const SignalEventFilterPresenter = ({
  signalEventOccurrencesFilter,
}: SignalEventProps) => {
  const schema = useSignalEventOccurrencesFiltersSchema();

  if (!signalEventOccurrencesFilter) {
    return null;
  }

  return (
    <FilterQueryPresentation
      filter={signalEventOccurrencesFilter}
      tableSchema={schema}
    />
  );
};
