import { useMemo, useState } from "react";
import classNames from "classnames";
import { MdClose as CloseIcon } from "react-icons/md";
import { RiFilter2Fill as FilterIcon } from "react-icons/ri";

import { APIFilter } from "shared/api/utils";

import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import {
  getFilterGroupStateTopLevelRowAttributes,
  updateOrAddRowFilterGroupState,
} from "features/ui/Filters/FilterBuilder/utils";
import SingleFilter from "features/ui/Filters/SingleFilter/SingleFilter";
import {
  FilterChangeCallback,
  FilterChangeProps,
  FilterSchemaItem,
} from "features/ui/Filters/types";
import Tooltip from "features/ui/Tooltip";

import styles from "./TableHeaderCell.module.css";

const ICON_SIZE = 15;
const CLOSE_ICON_SIZE = 21;
const DEFAULT_CLOSE_ON_OUTSIDE_CLICK = false;

interface Props {
  accessor: string;
  filter?: FilterSchemaItem;
  activeFilters?: FilterGroupState;
  staticFilters?: APIFilter[];
  filtersInitialized?: boolean;
  onFiltersReset?: (fieldNames?: string[]) => void;
  onFilterChange?: FilterChangeCallback;
  onShowFilterTooltip?: (show: boolean) => void;
  fixed?: boolean;
  pageKey: string;
}

const TableHeaderFilter = ({
  accessor,
  filter,
  activeFilters,
  staticFilters,
  filtersInitialized,
  onFiltersReset,
  onFilterChange,
  onShowFilterTooltip,
  fixed,
  pageKey,
}: Props) => {
  const [visible, setVisible] = useState(false);

  const fieldName = filter?.fieldName;

  const [filtersToShowApplied, setFiltersToShowApplied] =
    useState(activeFilters);

  const onValueAndOperatorChange = ({
    op_id: operator,
    values = [],
  }: FilterChangeProps) => {
    if (!fieldName) return;

    setFiltersToShowApplied((prev) => {
      // to prev.children array, add (or override) row with attribute = fieldName with operator & values
      const newFilter = updateOrAddRowFilterGroupState(prev, {
        id: fieldName,
        type: "row",
        attribute: fieldName,
        operator,
        values,
      });

      return newFilter;
    });
  };

  const hasActiveFilter = useMemo(() => {
    const activeFilterKeys =
      getFilterGroupStateTopLevelRowAttributes(activeFilters);

    return activeFilterKeys.some(
      (f) =>
        fieldName === f || (fieldName?.endsWith("_") && f.startsWith(fieldName))
    );
  }, [fieldName, activeFilters]);

  if (!fieldName || !onFilterChange) return null;

  const handleOnFilterChange = (props: FilterChangeProps) => {
    setVisible(false);
    onFilterChange(props);
  };

  const handleOnFiltersReset = () => {
    setVisible(false);
    onFiltersReset && onFiltersReset();
  };

  return (
    <div className={styles.filterIconWrap} data-testid={`filter-${fieldName}`}>
      <Tooltip
        trigger="click"
        onVisibleChange={(state: boolean) => {
          onShowFilterTooltip && onShowFilterTooltip(state);
          setVisible(state);
        }}
        offset={[-1, -1]}
        closeOnOutsideClick={DEFAULT_CLOSE_ON_OUTSIDE_CLICK}
        fixed={fixed}
        content={
          <>
            <div className="flex">
              <CloseIcon
                size={CLOSE_ICON_SIZE}
                className="ml-auto mr-1 text-gray-500"
                onClick={() => setVisible(false)}
                data-testid="close-filter-tooltip"
              />
            </div>
            <SingleFilter
              filter={filter}
              activeFilters={filtersToShowApplied}
              onFilterChange={handleOnFilterChange}
              onFiltersReset={handleOnFiltersReset}
              onValueOrOperatorChange={onValueAndOperatorChange}
              staticFilters={staticFilters}
              filtersInitialized={filtersInitialized}
              pageKey={pageKey}
            />
          </>
        }
        visible={visible}
        testId={`filter-${accessor}-tooltip`}
      >
        <FilterIcon
          size={ICON_SIZE}
          className={classNames({
            "text-blue-400": hasActiveFilter,
          })}
        />
      </Tooltip>
    </div>
  );
};

export default TableHeaderFilter;
