import { useState } from "react";

import { BY_VEHICLES_AGE_TAB_KEY } from "pages/constants";
import { useByVehicleAgeOptions } from "pages/hooks";
import { SignalEventsFiltersProps } from "pages/SignalEventsAnalytics/SignalEventsAnalyticsTabs";

import { SelectedChartOptions } from "features/ui/charts/Actions/types";
import { getDefaultActions } from "features/ui/charts/utils";

import ByVehicleAgeChart from "./ByVehicleAgeChart";
import ByVehicleAgeTable from "./ByVehicleAgeTable";
import { useGetByVehicleAgeChartActions } from "./hooks";

export const SIGNAL_EVENTS_BY_VEHICLE_AGE_CHART_OPTIONS_KEY =
  "signalEventsAnalyticsByVehicleAgeChartOptions";

const ByVehicleAge = ({
  signalEventsFilters,
  vehiclesFilters,
  onBadRequest,
  chartSettings,
  manageChartSettingsChange,
}: SignalEventsFiltersProps) => {
  const actions = useGetByVehicleAgeChartActions();

  const initialSelectedOptions: SelectedChartOptions[] =
    chartSettings &&
    chartSettings[BY_VEHICLES_AGE_TAB_KEY] &&
    chartSettings[BY_VEHICLES_AGE_TAB_KEY][
      SIGNAL_EVENTS_BY_VEHICLE_AGE_CHART_OPTIONS_KEY
    ]
      ? chartSettings[BY_VEHICLES_AGE_TAB_KEY][
          SIGNAL_EVENTS_BY_VEHICLE_AGE_CHART_OPTIONS_KEY
        ]
      : getDefaultActions(actions);

  const [selectedOptions, setSelectedOptions] = useState<
    SelectedChartOptions[]
  >(initialSelectedOptions);

  const handleSelectedOptionsChange = (
    newSelectedOptions: SelectedChartOptions[]
  ) => {
    setSelectedOptions(newSelectedOptions);
    if (manageChartSettingsChange) {
      manageChartSettingsChange(
        newSelectedOptions,
        SIGNAL_EVENTS_BY_VEHICLE_AGE_CHART_OPTIONS_KEY
      );
    }
  };

  const byVehicleAgeData = useByVehicleAgeOptions(selectedOptions, actions);

  return (
    <div className="mt-5">
      <ByVehicleAgeChart
        signalEventsFilters={signalEventsFilters}
        vehiclesFilters={vehiclesFilters}
        onBadRequest={onBadRequest}
        actions={actions}
        byVehicleAgeData={byVehicleAgeData}
        selectedOptions={selectedOptions}
        setSelectedOptions={handleSelectedOptionsChange}
      />
      <ByVehicleAgeTable
        signalEventsFilters={signalEventsFilters}
        vehiclesFilters={vehiclesFilters}
        onBadRequest={onBadRequest}
        byVehicleAgeData={byVehicleAgeData}
      />
    </div>
  );
};

export default ByVehicleAge;
