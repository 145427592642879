import Label from "features/ui/Label";

import { AttributeNameValueAction } from "./Definitions/AttributeNameValueAction";
import BooleanAction from "./Definitions/BooleanAction";
import DropdownAction from "./Definitions/DropdownAction";
import IssueMetricsAction from "./Definitions/IssueMetricsAction";
import LabelAction from "./Definitions/LabelAction";
import { ChartAction, SelectedChartOptions } from "./types";

interface Props {
  title?: string;
  actions: ChartAction[];
  selectedOptions: SelectedChartOptions[];
  onOptionChange: (selectedOptions: SelectedChartOptions[]) => void;
}

export const TITLE = "Chart Settings";

const ChartActions = ({
  title = TITLE,
  actions,
  selectedOptions,
  onOptionChange,
}: Props) => {
  return (
    <>
      {title && (
        <div className="mb-4 leading-none" data-testid="chart-actions-title">
          {title}
        </div>
      )}
      <div className="space-y-2">
        {actions.map((action) => {
          const { type, title: actionTitle } = action;

          if (type === "") {
            return <></>;
          }

          let actionValue = <LabelAction action={action} />;
          if (type === "dropdownSelect") {
            actionValue = (
              <DropdownAction
                action={action}
                selectedOptions={selectedOptions}
                onOptionChange={onOptionChange}
              />
            );
          } else if (type === "label") {
            actionValue = <LabelAction action={action} />;
          } else if (type === "attrNameValue") {
            actionValue = <AttributeNameValueAction action={action} />;
          } else if (type === "boolean") {
            actionValue = (
              <BooleanAction
                action={action}
                selectedOptions={selectedOptions}
                onOptionChange={onOptionChange}
              />
            );
          } else if (type === "issueMetric") {
            return (
              <IssueMetricsAction
                actions={actions}
                selectedOptions={selectedOptions}
                onOptionChange={onOptionChange}
              />
            );
          }

          return (
            <div>
              <div className="flex space-x-3 items-center">
                <div className="w-20 text-right">
                  <Label text={actionTitle} />
                </div>
                {actionValue}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ChartActions;
