import { MdGroup as GroupIcon } from "react-icons/md";
import { generatePath } from "react-router";
import { Link } from "react-router-dom";

import { Group } from "shared/api/rbac/api";

import NoAccessGroupCell from "pages/Groups/NoAccessGroupCell";

import { routes } from "services/routes";

interface Props {
  assignedGroupID: string | null;
  assignedGroup: Group | null;
}
const IssueGroupCell = ({ assignedGroupID, assignedGroup }: Props) => {
  if (assignedGroupID && !assignedGroup) {
    // If ID is present and the Group is not, it means that the user doesn't have
    // access to view the Group.
    return <NoAccessGroupCell />;
  }

  if (!assignedGroupID || !assignedGroup) {
    return null;
  }

  return (
    <div className="flex space-x-2 items-center">
      <GroupIcon size="1.5em" />{" "}
      <Link
        to={generatePath(routes.group, { id: assignedGroup.ID })}
        className="text-metabase-blue hover:underline"
      >
        {assignedGroup.name}
      </Link>
    </div>
  );
};

export default IssueGroupCell;
