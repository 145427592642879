import CollectionsTable from "pages/Collections/CollectionsTable";

import PageHeaderWrapper from "features/ui/PageHeaderWrapper";
import Title from "features/ui/Title/Title";

const Collections = () => {
  return (
    <>
      <PageHeaderWrapper>
        <Title text="Collections" docsSectionId="collections" />
      </PageHeaderWrapper>

      <CollectionsTable />
    </>
  );
};

export default Collections;
