import { useGetIssueRepairEfficacyReoccurrenceRateByAttribute } from "shared/api/issues/hooks";
import { useCustomLocalStorageState } from "shared/hooks";
import { useGroupByAttributes } from "shared/schemas/hooks";
import { IssueTypes, ReoccurrenceProps } from "shared/types";
import { camelCaseToTitle } from "shared/utils";

import { canShowDescription } from "pages/ClaimAnalytics/tabPages/TopContributors/utils";
import ChartWithComparisonPopulation from "pages/Issues/Charts/ChartWithComparisonPopulation";
import PopulationChart from "pages/Issues/Charts/PopulationChart";
import {
  getAxisKeyLabelFromActions,
  getChartOptionsKey,
} from "pages/Issues/utils";

import Card from "features/ui/Card";
import ChartActions from "features/ui/charts/Actions/ChartActions";
import { ChartActionsWrap } from "features/ui/charts/Actions/ChartActionsWrap";
import {
  ChartAction,
  SelectedChartOptions,
} from "features/ui/charts/Actions/types";
import {
  getDefaultActions,
  getSelectedOptionId,
} from "features/ui/charts/utils";
import DescriptionColumn from "features/ui/DescriptionColumn";

interface Props extends ReoccurrenceProps {
  withComparisonPopulation: boolean;
  issue: IssueTypes;
  actions: ChartAction[];
}

const TITLE = "Signal Event Reoccurrences";
const CHART_NAME = "RepairEfficacy_ReoccurrenceBar";

const ReoccurrenceBarChart = ({
  issue,
  actions,
  withComparisonPopulation,
  seOccurrenceWithin,
  seReoccurrenceStartingFrom,
  seReoccurrenceUpTo,
}: Props) => {
  const chartOptionsKey = getChartOptionsKey(CHART_NAME, issue.ID);
  const [selectedOptions, setSelectedOptions] = useCustomLocalStorageState<
    SelectedChartOptions[]
  >(chartOptionsKey, { defaultValue: getDefaultActions(actions) });

  const {
    axisKey: yAxisKey,
    axisValue: yAxisValue,
    valueFormatter,
    tooltipValueFormatter,
  } = getAxisKeyLabelFromActions(selectedOptions, actions, "y");

  const selectedGroupByOptionId = getSelectedOptionId(
    selectedOptions,
    "legend"
  );

  const PopulationsChart = withComparisonPopulation
    ? ChartWithComparisonPopulation
    : PopulationChart;

  const dataLoadHookParams = {
    signalEventsTimeWindow: seOccurrenceWithin,
    signalEventsReoccurrenceTimeWindowFrom: seReoccurrenceStartingFrom,
    signalEventsReoccurrenceTimeWindowTo: seReoccurrenceUpTo,
    groupBy: selectedGroupByOptionId,
  };

  const selectedGroupByAttributeDisplayName =
    useGroupByAttributes("signalEventOccurrence").find(
      ({ ID }) => ID === selectedGroupByOptionId
    )?.displayName || camelCaseToTitle(selectedGroupByOptionId);

  return (
    <Card>
      <ChartActionsWrap id={CHART_NAME} chartTitle={TITLE}>
        <ChartActions
          actions={actions}
          selectedOptions={selectedOptions}
          onOptionChange={setSelectedOptions}
        />
      </ChartActionsWrap>
      <PopulationsChart
        issue={issue}
        yAxisKey={yAxisKey}
        yAxisLabel={yAxisValue}
        chartType="DaysSinceClaim"
        chart={CHART_NAME}
        dataLoadHook={useGetIssueRepairEfficacyReoccurrenceRateByAttribute}
        timePeriod={seOccurrenceWithin}
        dataLoadHookParams={{ ...dataLoadHookParams }}
        valueFormatter={valueFormatter}
        tooltipValueFormatter={tooltipValueFormatter}
        groupByField="groupByAttributeValue"
        tooltipLabelFormatter={(value: any) => {
          const [firstPartOfValue] = value.split(" ");
          return (
            <div>
              {selectedGroupByAttributeDisplayName}: {value}
              {canShowDescription(selectedGroupByOptionId) && (
                <div>
                  Description:{" "}
                  <DescriptionColumn
                    fieldName={selectedGroupByOptionId}
                    fieldValue={firstPartOfValue}
                    textOnly
                  />
                </div>
              )}
            </div>
          );
        }}
      />
    </Card>
  );
};

export default ReoccurrenceBarChart;
