import { IssueTypes } from "shared/types";

import Board from "features/ui/Board";

import AnalyzeWithComparisonPopButton from "./AnalyzeWithComparisonPopButton";
import IssueClaimsCharts from "./Claims/IssueClaimsCharts";
import IssueClaimsStatistics from "./Claims/IssueClaimsStatistics";
import IssueClaimsTable from "./Claims/IssueClaimsTable";

interface Props {
  issue: IssueTypes;
  withComparisonPopulation: boolean;
  onToggleWithComparisonPopulation: () => void;
}

const IssueClaimsTab = ({
  issue,
  withComparisonPopulation,
  onToggleWithComparisonPopulation,
}: Props) => {
  const { comparisonPopulationFilter } = issue;

  const commonProps = { issue, withComparisonPopulation };

  const boardElements = [[<IssueClaimsStatistics {...commonProps} />]];

  return (
    <>
      <AnalyzeWithComparisonPopButton
        comparisonPopulationFilter={comparisonPopulationFilter || undefined}
        onClick={onToggleWithComparisonPopulation}
        value={withComparisonPopulation}
      />
      <Board elements={boardElements} />
      <IssueClaimsCharts {...commonProps} />
      <IssueClaimsTable {...commonProps} />
    </>
  );
};

export default IssueClaimsTab;
