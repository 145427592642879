import { useFlags } from "launchdarkly-react-client-sdk";
import qs from "qs";
import { Link } from "react-router-dom";

import { getFiltersKey } from "features/ui/Filters/utils";

import { routes } from "services/routes";
import * as config from "config/config";

import { SIGNAL_EVENTS_PAGE_KEY, VEHICLES_PAGE_KEY } from "./constants";

interface Props {
  displayName?: string;
  signalEventOccurrencesFilter?: string;
  populationFilter?: string | null;
  customSearchParams?: Record<string, any>;
  showNonLinkDisplayName?: boolean;
}

const SignalEventAnalyticsLink = ({
  displayName = "Explore in Signal Event Analytics",
  signalEventOccurrencesFilter,
  populationFilter,
  customSearchParams,
  showNonLinkDisplayName = true,
}: Props) => {
  const { signalEventsAnalytics } = useFlags();
  const { pages } = config.get();

  if (!pages.signalEventsAnalytics?.enabled || !signalEventsAnalytics) {
    if (showNonLinkDisplayName) {
      return <span>{displayName}</span>;
    } else {
      return null;
    }
  }

  const signalEventAnalyticsFilterKey = getFiltersKey(SIGNAL_EVENTS_PAGE_KEY);
  const vehicleFilterKey = getFiltersKey(VEHICLES_PAGE_KEY);

  const search = qs.stringify(
    {
      [signalEventAnalyticsFilterKey]: signalEventOccurrencesFilter,
      [vehicleFilterKey]: populationFilter,
      ...customSearchParams,
    },
    { arrayFormat: "indices" }
  );

  return (
    <Link
      to={{
        pathname: routes.signalEventAnalytics,
        search,
      }}
      className="text-metabase-blue hover:underline"
      data-testid="explore-signal-event-analytics"
    >
      {displayName}
    </Link>
  );
};

export default SignalEventAnalyticsLink;
