import { Card, CardHeader } from "@mui/material";

import { InspectionItem } from "shared/api/inspections/api";
import { useInspectionItemsSchema } from "shared/schemas/inspectionItemsSchema";

import Table from "features/ui/Table";

interface Props {
  items: InspectionItem[];
}

const CARD_TITLE = "Inspection Items";

const InspectionItems = ({ items }: Props) => {
  const { schema } = useInspectionItemsSchema();

  return (
    <Card>
      <CardHeader title={CARD_TITLE} />
      <Table data={items} schema={schema} />
    </Card>
  );
};
export default InspectionItems;
