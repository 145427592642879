import { useEffect, useRef, useState } from "react";

import Card from "features/ui/Card";
import InfoIcon from "features/ui/Icons/Info";

const BAR_HEIGHT = 6;
const DEFAULT_MIN_WIDTH = 1;
const MIN_WIDTH_BUFFER = 3;
const MAX_BAR_WIDTH = 100;

const VALUE_TEXT_PROPS = Object.freeze({
  x: 2,
  style: {
    fontSize: "4px",
  },
});

const CURRENT_VIN_BAR_PROPS = Object.freeze({
  x: 0,
  y: 1,
  height: BAR_HEIGHT,
});

const SIMILAR_VIN_BAR_PROPS = Object.freeze({
  x: 0,
  y: 9,
  height: BAR_HEIGHT,
});

const AVERAGE_BAR_PROPS = Object.freeze({
  y: 0,
  x: 50,
  rx: 0.5,
  width: 1,
  height:
    SIMILAR_VIN_BAR_PROPS.y +
    CURRENT_VIN_BAR_PROPS.height +
    CURRENT_VIN_BAR_PROPS.y +
    // add one to extend pass the current VIN bar
    1,
});

// getAdjustedWidth returns the width of the bar
const getAdjustedWidth = (value: number, min: number) => {
  return Math.max(min, value);
};

const formatBarValue = (value: number): string =>
  value.toLocaleString(undefined, {
    maximumFractionDigits: 0,
  }) + "%";

interface Props {
  name: string;
  description: string;
  similarVINPercentile: number;
  currentVINPercentile: number;
}

const SimilarityModalFactorCard = ({
  name,
  description,
  similarVINPercentile,
  currentVINPercentile,
}: Props) => {
  const [similarVINMinWidth, setSimilarVINMinWidth] =
    useState(DEFAULT_MIN_WIDTH);
  const [currentVINMinWidth, setCurrentVINMinWidth] =
    useState(DEFAULT_MIN_WIDTH);
  const similarVINValueTextEl = useRef(null);
  const currentVINValueTextEl = useRef(null);

  // keep the min width in-sync with the size of the value text
  useEffect(() => {
    if (!(similarVINValueTextEl.current && currentVINValueTextEl.current))
      return;

    setCurrentVINMinWidth(
      // @ts-ignore
      currentVINValueTextEl.current.getComputedTextLength() + MIN_WIDTH_BUFFER
    );

    setSimilarVINMinWidth(
      // @ts-ignore
      similarVINValueTextEl.current.getComputedTextLength() + MIN_WIDTH_BUFFER
    );
  }, [similarVINPercentile, currentVINPercentile]);

  const similarVINPercentileScaled = similarVINPercentile * MAX_BAR_WIDTH;
  const currentVINPercentileScaled = currentVINPercentile * MAX_BAR_WIDTH;

  const similarVINWidth = getAdjustedWidth(
    similarVINPercentileScaled,
    similarVINMinWidth
  );

  const currentVINWidth = getAdjustedWidth(
    currentVINPercentileScaled,
    currentVINMinWidth
  );

  return (
    <Card classNames="text-sm">
      <h4 className="font-bold text-gray-600">
        {name}
        {description && (
          <div className="inline-block">
            &nbsp; <InfoIcon text={description} size="sm" />
          </div>
        )}
      </h4>
      <svg viewBox="0 0 100 20" xmlns="http://www.w3.org/2000/svg">
        <rect
          {...CURRENT_VIN_BAR_PROPS}
          width={currentVINWidth}
          className="text-blue-300 stroke-current fill-current"
        />
        <text
          {...VALUE_TEXT_PROPS}
          ref={currentVINValueTextEl}
          y={CURRENT_VIN_BAR_PROPS.height + CURRENT_VIN_BAR_PROPS.y - 1.5}
          className="text-white fill-current font-bold"
        >
          {formatBarValue(currentVINPercentileScaled)}
        </text>
        <rect
          {...SIMILAR_VIN_BAR_PROPS}
          width={similarVINWidth}
          className="text-gray-600 stroke-current fill-current"
        />
        <text
          {...VALUE_TEXT_PROPS}
          y={SIMILAR_VIN_BAR_PROPS.height + SIMILAR_VIN_BAR_PROPS.y - 1.5}
          ref={similarVINValueTextEl}
          className="text-white fill-current font-bold"
        >
          {formatBarValue(similarVINPercentileScaled)}
        </text>
        <rect {...AVERAGE_BAR_PROPS} className="text-green-300 fill-current" />
      </svg>
    </Card>
  );
};
export default SimilarityModalFactorCard;
