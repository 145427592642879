import { PermissionEntry } from "shared/api/api";
import { PermissionID } from "shared/types";

import { toFEGroupMemberAccess } from "pages/Groups/utils";

import PermissionsTableWrapper from "features/ui/PermissionsDialog/PermissionsTableWrapper";
import { StepProps } from "features/ui/Stepper";

const StepAccess = ({ data, onDataUpdated }: StepProps) => {
  return (
    <>
      <div className="p-1">
        <div className="mb-2">
          Control who can see and edit this group. Group members are assigned
          view-only access by default and cannot have permissions removed.
          Visibility determines who can see and use this group when assigning
          permissions and access to other objects like Issues. Edit permissions
          gives the editor the ability to edit a group's name and description,
          membership, and permissions.
        </div>
      </div>
      <PermissionsTableWrapper
        entity="group"
        entityId={data?.ID}
        permissions={data?.acl}
        canEdit={true}
        onPermissionsUpdate={(
          permissions: PermissionEntry[],
          memberAccess: PermissionID
        ) => onDataUpdated && onDataUpdated({ acl: permissions, memberAccess })}
        memberAccess={toFEGroupMemberAccess(data?.memberAccess)}
      />
    </>
  );
};

export default StepAccess;
