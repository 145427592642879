import DropdownSelect from "features/ui/DropdownSelect";

import {
  CALCULATED_ATTRIBUTE_FUNCTION_OPTIONS,
  CalculatedAttributeFunction,
  DEFAULT_UNIT_PER_FUNCTION_TYPE,
} from "./constants";
import FunctionInput from "./FunctionInput/FunctionInput";
import { CalculatedAttributeParameters } from "./types";
import { getInputConfiguration } from "./utils";

interface Props {
  attribute: CalculatedAttributeParameters;
  onChange: (attribute: CalculatedAttributeParameters) => void;
  disabled?: boolean;
}

const dropdownLabel = (selectedOptionID?: string): string =>
  (CALCULATED_ATTRIBUTE_FUNCTION_OPTIONS.find(
    (option) => option.id === selectedOptionID
  )?.value as string) || "Select function";

const AttributeParameters = ({
  attribute,
  onChange,
  disabled = false,
}: Props) => (
  <div className="mb-4 w-full">
    <DropdownSelect
      disabled={disabled}
      options={CALCULATED_ATTRIBUTE_FUNCTION_OPTIONS}
      label={dropdownLabel(attribute.type)}
      onSelect={(selectedOption) => {
        onChange({
          ...attribute,
          type: selectedOption.id as string,
          unit: DEFAULT_UNIT_PER_FUNCTION_TYPE[
            selectedOption.id as CalculatedAttributeFunction
          ],
        });
      }}
      buttonClass="!py-[7px] text-left"
    />
    {attribute.type && (
      <div className="flex flex-col space-y-2 mt-4">
        {getInputConfiguration(attribute.type).map((config) => (
          <FunctionInput
            key={config.parameter}
            attribute={attribute}
            onChange={onChange}
            config={config}
            disabled={disabled}
          />
        ))}
      </div>
    )}
  </div>
);

export default AttributeParameters;
