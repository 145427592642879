import { validateApiRequest } from "duck/graph/nodes/utils";

import {
  CountRequestWithVehiclesFilter,
  listVehiclesCountRequestURI,
  listVehiclesRequestURI,
} from "shared/api/vehicles/api";

/**
 * Validate the filter that is passed in. If the validation fails, an error will be thrown.
 *
 * @param params The parameters contains optional properties for claimsFilter, vehiclesFilter,
 * and signalEventOccurrencesFilter. Typically, one of those will be set when this is called.
 * @throws If validation fails, an error will be thrown. The error is unusually wordy
 * because it is intended for consumption by the agent, which benefits from the detail.
 */
export const validateFilter = async (
  params: CountRequestWithVehiclesFilter
): Promise<void> => {
  await validateApiRequest(params, listVehiclesCountRequestURI);
};

const validateSortOrder = (sortOrder: string): void => {
  if (!["asc", "desc"].includes(sortOrder)) {
    throw new Error(
      `The sort order must be one of "asc" or "desc". The value of ${sortOrder} is not valid.`
    );
  }
};

export const validateSort = async (
  sortKeySuffix: string,
  sortOrder: string
): Promise<void> => {
  validateSortOrder(sortOrder);

  // Remove enclosing brackets if they exist
  const fieldName = sortKeySuffix.replace(/^\[|\]$/g, "");

  if (fieldName === sortKeySuffix) {
    throw new Error(
      `The name of the attribute to use for sorting must be enclosed in brackets ("[" and "]").
The value of ${sortKeySuffix} is not valid. Please add enclosing brackets.`
    );
  }

  if (!fieldName) {
    throw new Error(
      `The name of the attribute to use for sorting must not be empty.
The value of ${sortKeySuffix} is not valid.`
    );
  }

  await validateApiRequest(
    { sort: `${fieldName}:${sortOrder}`, mileageUnit: "mi", limit: 1 },
    listVehiclesRequestURI
  );
};
