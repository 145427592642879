import { Vehicle } from "shared/api/vehicles/api";
import useDealersSchema from "shared/schemas/dealerSchema";
import useVehiclesSchema from "shared/schemas/vehiclesSchema";
import { formatNumber } from "shared/utils";

import SingleFleetLink from "pages/Fleets/SingleFleetLink";

import StringList from "features/ui/StringList";

import InfoCard from "./InfoCard";
import { InfoCardMap, InfoValue, shouldDisplayInfoCard } from "./utils";

const RPOCodesModal = ({ value }: { value: InfoValue }) => {
  const { getDisplayLabel } = useVehiclesSchema();
  if (!Array.isArray(value) || value.length === 0) return null;

  return (
    <StringList
      title={getDisplayLabel("tags", "Tags")}
      values={value}
      maxVisible={3}
    />
  );
};

const FleetViewLink = ({ value }: { value: InfoValue }) => {
  return (
    <SingleFleetLink
      fleetName={value as string}
      className="underline hover:text-gray-500"
    />
  );
};

const NoDecimalsNumber = ({ value }: { value: InfoValue }) => {
  if (!value) return <span></span>;
  return <span>{formatNumber(value as number, 0)}</span>;
};

interface Props {
  data?: Vehicle;
}

const InfoCardsData = ({ data }: Props) => {
  const { getDisplayLabel, whitelist } = useVehiclesSchema();
  const { getDisplayLabel: getDealerDisplayLabel } = useDealersSchema();
  const infoCardMap: InfoCardMap[] = [
    {
      title: "Vehicle",
      data: [
        { label: getDisplayLabel("vehicleMake", "Make"), key: "vehicleMake" },
        {
          label: getDisplayLabel("vehicleModel", "Model"),
          key: "vehicleModel",
        },
        {
          label: getDisplayLabel("vehicleModelYear", "Year"),
          key: "vehicleModelYear",
        },
        {
          label: getDisplayLabel("vehicleManufacturedAt", "Manufactured Date"),
          key: "vehicleManufacturedAt",
          type: "date",
        },
        {
          label: getDisplayLabel(
            "manufacturePlantName",
            "Manufacture Plant Name"
          ),
          key: "manufacturePlantName",
        },
        {
          label: getDisplayLabel("vehicleGvwr", "GVWR"),
          key: "vehicleGvwr",
          description:
            "Gross Vehicle Weight Rating - maximum operating weight/mass of a vehicle as specified by the manufacturer including the vehicle's chassis, body, engine, engine fluids, fuel, accessories, driver, passengers and cargo but excluding that of any trailers.",
        },
        { label: getDisplayLabel("platform", "Platform"), key: "platform" },
        { label: getDisplayLabel("body", "Body"), key: "body" },
        { label: getDisplayLabel("country", "Country"), key: "country" },
        { label: getDisplayLabel("purpose", "Purpose"), key: "purpose" },
        { label: getDisplayLabel("company", "Company"), key: "company" },
        {
          label: getDisplayLabel(
            "vehicleDomicileFacilityCode",
            "Domicile Facility Code"
          ),
          key: "vehicleDomicileFacilityCode",
        },
        {
          label: getDisplayLabel("vehicleLocation", "Location"),
          key: "vehicleLocation",
        },
      ],
    },
    {
      title: "Engine",
      data: [
        {
          label: getDisplayLabel("engineModel", "Model"),
          key: "engineModel",
        },
        {
          label: getDisplayLabel("engineModelYear", "Model Year"),
          key: "engineModelYear",
        },
        {
          label: getDisplayLabel("engineHorsepower", "Horsepower"),
          key: "engineHorsepower",
        },
        {
          label: getDisplayLabel("engineManufacturedAt", "Build Date"),
          key: "engineManufacturedAt",
          type: "date",
        },
        {
          label: getDisplayLabel("engineSupplierCode", "Engine Supplier Code"),
          key: "engineSupplierCode",
        },
        {
          label: getDisplayLabel("engineNumber", "Engine Number"),
          key: "engineNumber",
        },
      ],
    },
    {
      title: "Sales",
      data: [
        {
          label: getDisplayLabel("fleet", "Fleet"),
          key: "fleet",
          type: "jsx",
          Component: FleetViewLink,
        },
        {
          label: getDisplayLabel("saleDealerName", "Dealer Name"),
          key: "saleDealerName",
        },
        {
          label: getDisplayLabel("saleIntendedService", "Vocation"),
          key: "saleIntendedService",
        },
        {
          label: getDealerDisplayLabel("name", "Name"),
          key: "saleDealer.name",
          whitelistKey: "saleDealer",
        },
        {
          label: getDealerDisplayLabel("city", "City"),
          key: "saleDealer.city",
          whitelistKey: "saleDealer",
        },
        {
          label: getDealerDisplayLabel("provinceCode", "Province Code"),
          key: "saleDealer.provinceCode",
          whitelistKey: "saleDealer",
        },
        {
          label: getDealerDisplayLabel("countryCode", "Country Code"),
          key: "saleDealer.countryCode",
          whitelistKey: "saleDealer",
        },
        {
          label: getDealerDisplayLabel("contact", "Contact"),
          key: "saleDealer.contact",
          whitelistKey: "saleDealer",
        },
      ],
    },
    {
      title: "Usage",
      data: [
        {
          label: getDisplayLabel("mileage", "Mileage"),
          key: "mileage",
          type: "jsx",
          Component: NoDecimalsNumber,
        },
        {
          label: getDisplayLabel("engineOnTimeTotal", "Engine Hours"),
          key: "engineOnTimeTotal",
          type: "jsx",
          Component: NoDecimalsNumber,
        },
        {
          label: getDisplayLabel(
            "vehicleStartedDrivingAt",
            "Started Driving Date"
          ),
          key: "vehicleStartedDrivingAt",
          type: "date",
        },
        {
          // We want to enforce "Service Duration" and not repeat the word "months" which already comes as a value suffix
          label: getDisplayLabel("", "Service Duration"),
          key: "serviceDurationInMonths",
          type: "number",
          suffix: "months",
        },
        {
          label: getDisplayLabel("fuelConsumption", "Fuel Consumption"),
          key: "fuelConsumption",
          type: "number",
          suffix: "mpg", // for now assuming that API always returns values in mpg / TODO ENG-3763
        },
        {
          label: getDisplayLabel("idleRate", "Idle Rate"),
          key: "idleRate",
          type: "percent",
        },
        {
          label: getDisplayLabel(
            "inferredTransportCategoryName",
            "Inferred Transport Category"
          ),
          description:
            "Inferred category is based on idle time, fuel consumption, and gross vehicle weight.",
          key: "inferredTransportCategoryName",
        },
      ],
    },
    {
      title: "Other",
      data: [
        {
          label: getDisplayLabel("productCode", "Product code"),
          key: "productCode",
        },
        {
          label: getDisplayLabel("infotainmentModel", "Infotainment model"),
          key: "infotainmentModel",
        },
        {
          label: getDisplayLabel("transmissionModel", "Transmission Model"),
          key: "transmissionModel",
        },
        {
          label: getDisplayLabel(
            "transmissionSupplierCode",
            "Transmission Supplier Code"
          ),
          key: "transmissionSupplierCode",
        },
        {
          label: getDisplayLabel("transmissionNumber", "Transmission Number"),
          key: "transmissionNumber",
        },
        {
          label: getDisplayLabel("brakeModel", "Brake model"),
          key: "brakeModel",
        },
        {
          label: getDisplayLabel("chassisDetail", "Chassis detail"),
          key: "chassisDetail",
        },
        {
          label: getDisplayLabel("hvacModel", "HVAC model"),
          key: "hvacModel",
        },
        {
          label: getDisplayLabel(
            "collisionSensorModel",
            "Collision sensor model"
          ),
          key: "collisionSensorModel",
        },
        {
          label: getDisplayLabel("tags", "Tags"),
          key: "tags",
          type: "jsx",
          Component: RPOCodesModal,
        },
      ],
    },
    {
      title: "Last Known Dealer Location",
      data: [
        {
          label: getDisplayLabel("lastKnownDealerCountry", "Country"),
          key: "lastKnownDealerCountry",
        },
        {
          label: getDisplayLabel("lastKnownDealerState", "State"),
          key: "lastKnownDealerState",
        },
        {
          label: getDisplayLabel("lastKnownDealerCity", "City"),
          key: "lastKnownDealerCity",
        },
        {
          label: getDealerDisplayLabel("name", "Name"),
          key: "lastKnownDealer.name",
          whitelistKey: "lastKnownDealer",
        },
        {
          label: getDealerDisplayLabel("city", "City"),
          key: "lastKnownDealer.city",
          whitelistKey: "lastKnownDealer",
        },
        {
          label: getDealerDisplayLabel("provinceCode", "Province Code"),
          key: "lastKnownDealer.provinceCode",
          whitelistKey: "lastKnownDealer",
        },
        {
          label: getDealerDisplayLabel("countryCode", "Country Code"),
          key: "lastKnownDealer.countryCode",
          whitelistKey: "lastKnownDealer",
        },
        {
          label: getDealerDisplayLabel("contact", "Contact"),
          key: "lastKnownDealer.contact",
          whitelistKey: "lastKnownDealer",
        },
      ],
    },
  ];
  return (
    <>
      {infoCardMap
        .filter((d) => shouldDisplayInfoCard(d.data, whitelist, data))
        .map((d) => (
          <InfoCard
            key={d.title}
            title={d.title}
            data={data}
            dataMap={d.data}
            whitelist={whitelist}
          />
        ))}
    </>
  );
};
export default InfoCardsData;
