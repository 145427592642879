import { useMemo } from "react";
import { generatePath } from "react-router";
import { Link } from "react-router-dom";

import {
  getInspectionsExport,
  Inspection,
  ListInspectionsRequest,
} from "shared/api/inspections/api";
import {
  useListInspections,
  useListInspectionsCount,
} from "shared/api/inspections/hooks";
import { applyAdditionalSorting, getSortFilter } from "shared/api/utils";
import { MAX_ROWS_DOWNLOAD_LIMIT } from "shared/constants";
import { useInspectionsSchema } from "shared/schemas/inspectionsSchema";
import { SortBy } from "shared/types";
import { getTenantMileageUnit } from "shared/utils";

import { InspectionsFiltersProps } from "pages/InspectionAnalytics/InspectionAnalyticsTabs";
import { VINEventTimelineDateLink } from "pages/VINView/ServiceRecords/VINEventTimelineDateLink";

import APIError from "features/ui/APIError";
import DownloadAction from "features/ui/DownloadAction";
import {
  getFiltersQuery,
  mergeFilterGroupStates,
} from "features/ui/Filters/FilterBuilder/utils";
import FiltersOverview from "features/ui/Filters/FiltersOverview/FiltersOverview";
import { useFilterSortState } from "features/ui/Filters/hooks";
import { OnSortParams } from "features/ui/Table";
import PaginatedTable from "features/ui/Table/PaginatedTable";
import TableCount from "features/ui/Table/TableCount";

import { routes } from "services/routes";

const PAGE_KEY = "inspAn-inspectionsTable";
const ROWS_PER_PAGE = 15;
const DEFAULT_SORT: SortBy = { date: "desc" };

export const formatRow = (inspection: Inspection) => {
  const { ID, VIN, externalID, externalURL, date } = inspection;

  return {
    ...inspection,
    ID: (
      <Link
        className="text-metabase-blue hover:underline"
        to={generatePath(routes.inspectionAnalyticsDetails, {
          id: ID,
        })}
      >
        Go to Inspection
      </Link>
    ),
    externalID: externalURL ? (
      <Link
        target="_blank"
        to={externalURL}
        className="text-metabase-blue hover:underline"
      >
        {externalID}
      </Link>
    ) : (
      externalID
    ),
    VIN: <VINEventTimelineDateLink VIN={VIN} date={date} />,
  };
};

const InspectionsTable = ({
  inspectionsFilters,
  vehiclesFilters,
  onBadRequest,
}: InspectionsFiltersProps) => {
  const { schema } = useInspectionsSchema();

  const {
    manageFilterChange,
    resetFilters,
    filters,
    initialized: filtersInitialized,
    sort,
    manageOnSortChange,
    resetFilterSortState,
  } = useFilterSortState({
    pageKey: PAGE_KEY,
    defaultSort: DEFAULT_SORT,
  });

  const handleSorting = ({ accessor, sort }: OnSortParams) => {
    // only allow sorting by one column at the time
    manageOnSortChange({ [accessor]: sort });
  };

  const requestParams: ListInspectionsRequest = {
    sort: getSortFilter(applyAdditionalSorting(sort)),
    filter: getFiltersQuery(
      mergeFilterGroupStates(filters, inspectionsFilters)
    ),
    vehiclesFilter: vehiclesFilters && getFiltersQuery(vehiclesFilters),
    limit: ROWS_PER_PAGE,
    analytics: true,
    mileageUnit: getTenantMileageUnit(),
  };

  const { data, isLoading, headers, error, ...paginationData } =
    useListInspections(requestParams);

  // re-format the data - but only when data changes
  const formattedData = useMemo(
    () => data?.map((entry) => formatRow(entry)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );
  const downloadDisabled = !formattedData || formattedData.length === 0;

  const {
    isLoading: countIsLoading,
    data: countData,
    error: countError,
  } = useListInspectionsCount({
    filter: requestParams.filter,
    vehiclesFilter: requestParams.vehiclesFilter,
    analytics: true,
  });

  return (
    <>
      <div className="flex items-center mt-3 mb-1">
        <FiltersOverview
          filters={filters}
          tableSchema={schema}
          onFiltersReset={resetFilters}
        />
        <TableCount
          extraClasses="ml-auto"
          count={countData?.count as number}
          entityName="inspection"
          isLoading={countIsLoading}
          error={!!countError}
        />
        <DownloadAction
          disabled={downloadDisabled}
          downloadFunc={getInspectionsExport}
          fileName="inspections"
          requestParams={{
            ...requestParams,
            limit: MAX_ROWS_DOWNLOAD_LIMIT,
          }}
          count={countData?.count as number}
          entityName="inspection"
          filters={mergeFilterGroupStates(
            filters,
            inspectionsFilters,
            vehiclesFilters
          )}
        />
      </div>
      {!error && (
        <PaginatedTable
          {...paginationData}
          data={formattedData}
          schema={schema}
          isLoading={isLoading}
          loadingRows={ROWS_PER_PAGE}
          sortBy={sort}
          onSort={handleSorting}
          filtersInitialized={filtersInitialized}
          onFiltersReset={resetFilters}
          onFilterChange={manageFilterChange}
          filters={filters}
          stickyFirstColumn={true}
          dense
        />
      )}
      {error && (
        <APIError
          error={error}
          onBadRequest={() => {
            resetFilterSortState();
            onBadRequest();
          }}
        />
      )}
      {!error && !isLoading && !formattedData?.length && (
        <div className="py-4 text-gray-400 text-sm">No results.</div>
      )}
    </>
  );
};

export default InspectionsTable;
