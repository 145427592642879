import { useAPI } from "shared/api/hooks";
import {
  getOrderedValueRequestURL,
  ListOrderedValuesRequest,
  listOrderedValuesRequestURI,
  OrderedValue,
} from "shared/api/orderedValues/api";

export const useOrderedValues = (args: ListOrderedValuesRequest) =>
  useAPI<typeof args, OrderedValue[]>(listOrderedValuesRequestURI, args);

export const useOrderedValue = (args: { id: string }) =>
  useAPI<typeof args, OrderedValue>(getOrderedValueRequestURL, args);
