import { IssueTypes } from "shared/types";

import Board from "features/ui/Board";

import OccurrencesByCalendarTimeChart from "./Charts/OccurrencesByCalendarTimeChart";
import OccurrencesByVehicleAgeChart from "./Charts/OccurrencesByVehicleAgeChart";
import RateByCalendarTimeChart from "./Charts/RateByCalendarTimeChart";
import RateByVehicleAgeChart from "./Charts/RateByVehicleAgeChart";

interface Props {
  issue: IssueTypes;
  withComparisonPopulation: boolean;
}

const IssueSignalEventsCharts = (props: Props) => {
  const boardElements: JSX.Element[][] = [
    [
      <OccurrencesByCalendarTimeChart {...props} />,
      <RateByCalendarTimeChart {...props} />,
    ],
    [
      <OccurrencesByVehicleAgeChart {...props} />,
      <RateByVehicleAgeChart {...props} />,
    ],
  ];

  return (
    <div className="mb-3">
      <Board elements={boardElements} />
    </div>
  );
};

export default IssueSignalEventsCharts;
