import classNames from "classnames";
import { DuckMessage as DuckMessageType } from "duck/context/DuckMessagesContextWrapper";
import ReactMarkdown from "react-markdown";

interface DuckMessageProps {
  message: DuckMessageType;
}

/**
 * The Markdown component by itself recognizes lists but does not properly render them.
 * It omits the discs and numbers from the lists. The `components` parameter sent to
 * the Markdown component allows us to override the default rendering of lists to make
 * them look correct. It is likely that the CSS of the project prevents the standard
 * rendering of lists from working as expected in markdown.
 */
const DuckMessage = ({ message: { author, message } }: DuckMessageProps) => {
  const isHuman = author === "human";

  return (
    <div
      className={classNames("w-full flex", {
        "justify-end": isHuman,
        "justify-start": !isHuman,
      })}
    >
      <div
        className={classNames("p-1", {
          "font-bold": isHuman,
          italic: isHuman,
          "text-right": isHuman,
          "text-left": !isHuman,
        })}
      >
        <ReactMarkdown
          components={{
            ul: ({ node, ...props }) => (
              <ul
                style={{ listStyleType: "disc", paddingLeft: "20px" }}
                {...props}
              />
            ),
            ol: ({ node, ...props }) => (
              <ol
                style={{ listStyleType: "decimal", paddingLeft: "20px" }}
                {...props}
              />
            ),
          }}
        >
          {message}
        </ReactMarkdown>
      </div>
    </div>
  );
};

export default DuckMessage;
