import { Rnd } from "react-rnd";

import {
  DUCK_POPPED_INITIAL_HEIGHT,
  DUCK_POPPED_INITIAL_WIDTH,
} from "./constants";
import Duck from "./Duck";

interface DraggableDuckProps {
  initialPosition: { x: number; y: number };
  setIsDraggable: (draggable: boolean) => void;
}

/**
 * This component wraps Duck in a draggable container.
 * It allows the user to drag Duck around on the screen.
 * @param props.initialPosition The initial position of the draggable Duck.
 * @param props.setIsDraggable Call to let the SidebarNav container
 * know that Duck should no longer be draggable.
 */
const DraggableDuck = ({
  initialPosition,
  setIsDraggable,
}: DraggableDuckProps) => (
  <Rnd
    default={{
      x: initialPosition.x,
      y: initialPosition.y,
      width: DUCK_POPPED_INITIAL_WIDTH,
      height: DUCK_POPPED_INITIAL_HEIGHT,
    }}
    minWidth={200}
    minHeight={200}
    bounds="window"
    style={{
      position: "fixed",
      zIndex: 99999,
    }}
  >
    <Duck isDraggable={true} setIsDraggable={setIsDraggable} forceOpen={true} />
  </Rnd>
);

export default DraggableDuck;
