import { FailureMode } from "shared/api/failureModes/api";
import { useVehiclesFiltersSchema } from "shared/hooks";
import { currencyValue, pluralize } from "shared/utils";

import {
  EVIDENCE_OF_FAILURE_TAB_KEY,
  EVIDENCE_OF_REPAIR_TAB_KEY,
  FAILURE_MODES_DEFINITION_TABS,
} from "pages/FailureModes/constants";
import EligibleVehicleCount from "pages/shared/EligibleVehicleCount";

import FilterQueryPresentation from "features/ui/Filters/FilterBuilder/FilterQueryPresentation";
import { filterBuilderQueryToFilterBuilderState } from "features/ui/Filters/FilterBuilder/utils";
import FormSection from "features/ui/FormSection";
import StatefulTabs from "features/ui/StatefulTabs";

import EvidenceTab from "./EvidenceTab";
import InfoTable from "./InfoTable";

interface Props {
  failureMode: FailureMode;
}

const DefinitionTab = ({ failureMode }: Props) => {
  const vehiclesFilterSchema = useVehiclesFiltersSchema();
  const vehicleFilters = filterBuilderQueryToFilterBuilderState(
    failureMode.vehiclesFilter
  );

  const tabs = [
    {
      key: EVIDENCE_OF_FAILURE_TAB_KEY,
      title: FAILURE_MODES_DEFINITION_TABS[EVIDENCE_OF_FAILURE_TAB_KEY],
      content: (
        <EvidenceTab
          claimFilters={failureMode.failuresClaimsFilter}
          repairFilters={failureMode.failuresRepairsFilter}
          customRecordFilters={failureMode.failuresCustomRecordsFilter}
          signalEventFilters={failureMode.failuresSignalEventOccurrencesFilter}
          inspectionFilters={failureMode.failuresInspectionsFilter}
          vehicleFilters={failureMode.vehiclesFilter}
        />
      ),
    },
    {
      key: EVIDENCE_OF_REPAIR_TAB_KEY,
      title: FAILURE_MODES_DEFINITION_TABS[EVIDENCE_OF_REPAIR_TAB_KEY],
      content: (
        <EvidenceTab
          claimFilters={failureMode.repairsClaimsFilter}
          repairFilters={failureMode.repairsRepairsFilter}
          customRecordFilters={failureMode.repairsCustomRecordsFilter}
          signalEventFilters={failureMode.repairsSignalEventOccurrencesFilter}
          inspectionFilters={failureMode.repairsInspectionsFilter}
          vehicleFilters={failureMode.vehiclesFilter}
        />
      ),
    },
  ];

  return (
    <>
      <FormSection
        title="Eligible Vehicles"
        extraTitleComponent={
          vehicleFilters && (
            <EligibleVehicleCount vehicleFilter={vehicleFilters} />
          )
        }
      >
        <FilterQueryPresentation
          filterState={vehicleFilters}
          tableSchema={vehiclesFilterSchema}
        />
      </FormSection>
      <FormSection title="Cost Assumptions">
        <InfoTable
          data={[
            {
              label:
                "Total cost (including intangibles) to repair after failure",
              value: `${currencyValue(failureMode.currencyCode, failureMode.reactiveRepairCost)}`,
            },
            {
              label:
                "Total cost (including intangibles) to repair prior to failure",
              value: `${currencyValue(failureMode.currencyCode, failureMode.proactiveRepairCost)}`,
            },
            {
              label: "Cost to recall vehicle outside of scheduled check-in",
              value: `${currencyValue(failureMode.currencyCode, failureMode.recallRepairCost)}`,
            },
          ]}
          labelClassName="w-96"
        />
      </FormSection>
      <FormSection
        title="Deduplication Timeframe"
        text="Not all evidence of an event will occur on the same date. Some may happen just before or shortly after."
      >
        <InfoTable
          data={[
            {
              label:
                "Timeframe within which multiple evidences will be considered a single event ",
              value: `${failureMode.deduplicateWindow} ${pluralize("day", failureMode.deduplicateWindow || 0)}`,
            },
          ]}
        />
      </FormSection>
      <StatefulTabs tabs={tabs} disableUsingQuery />
    </>
  );
};

export default DefinitionTab;
