import { useFlexibleTimePeriod } from "shared/hooks";
import { IssueTypes } from "shared/types";

import Board from "features/ui/Board";

import AnalyzeWithComparisonPopButton from "./AnalyzeWithComparisonPopButton";
import IssueRelationshipAssociatedSignalEvents from "./Relationship/IssueRelationshipAssociatedSignalEvents";
import IssueRelationshipCharts from "./Relationship/IssueRelationshipCharts";
import IssueRelationshipStatistics from "./Relationship/IssueRelationshipStatistics";
import SEClaimsTimePeriodDropdown from "./SEClaimsTimePeriodDropdown";

const SE_CLAIMS_TIME_PERIOD_KEY = "issueRelationshipTabSignalEventsTimeWindow";

interface Props {
  issue: IssueTypes;
  withComparisonPopulation: boolean;
  onToggleWithComparisonPopulation: () => void;
}

const IssueRelationshipTab = ({
  issue,
  withComparisonPopulation,
  onToggleWithComparisonPopulation,
}: Props) => {
  const [timePeriod, setTimePeriod] = useFlexibleTimePeriod(
    SE_CLAIMS_TIME_PERIOD_KEY
  );

  const { comparisonPopulationFilter } = issue;

  const commonProps = { issue, withComparisonPopulation, timePeriod };

  const boardElements = [[<IssueRelationshipStatistics {...commonProps} />]];

  return (
    <>
      <div className="flex justify-between space-x-2">
        <SEClaimsTimePeriodDropdown
          timePeriod={timePeriod}
          updateTimePeriod={setTimePeriod}
        />
        <AnalyzeWithComparisonPopButton
          comparisonPopulationFilter={comparisonPopulationFilter || undefined}
          onClick={onToggleWithComparisonPopulation}
          value={withComparisonPopulation}
        />
      </div>
      <Board elements={boardElements} />
      <IssueRelationshipCharts {...commonProps} />
      <IssueRelationshipAssociatedSignalEvents
        {...commonProps}
        vehiclePopulation="atRisk"
      />
      {withComparisonPopulation && (
        <IssueRelationshipAssociatedSignalEvents
          {...commonProps}
          vehiclePopulation="comparison"
        />
      )}
    </>
  );
};

export default IssueRelationshipTab;
