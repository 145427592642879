import { IssueTypes, ReoccurrenceProps } from "shared/types";

import CardHeader from "pages/Issues/Details/CardHeader";

import Card from "features/ui/Card";

import IssueRepairEfficacyPopulationStatistics from "./IssueRepairEfficacyPopulationStatistics";

interface Props extends ReoccurrenceProps {
  issue: IssueTypes;
  withComparisonPopulation: boolean;
}

const TITLE =
  "Repair Efficacy: Vehicles Experiencing Reoccurring Signal Events Post Claim";

const IssueRepairEfficacyStatistics = (props: Props) => {
  return (
    <Card classNames="mb-3">
      <CardHeader title={TITLE} showActions={false} />
      <div className="space-y-3">
        <IssueRepairEfficacyPopulationStatistics
          population="atRisk"
          {...props}
        />
        {props.withComparisonPopulation && (
          <IssueRepairEfficacyPopulationStatistics
            population="comparison"
            {...props}
          />
        )}
      </div>
    </Card>
  );
};

export default IssueRepairEfficacyStatistics;
