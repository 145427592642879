import classNames from "classnames";
import { useFlags } from "launchdarkly-react-client-sdk";

import { EventTypeEnum } from "shared/types";

interface Props {
  uniqueExposureValues: Set<number>;
  label: string;
  stickyFirstColumn?: boolean;
  eventType: EventTypeEnum;
}

const NUMBER_OF_NON_EXPOSURE_COLUMNS = 1;

// see prepareVehiclesByAgeTableSchemaAndData() to see the number of cols used
const DEFAULT_EXPOSURE_COLSPAN = 3;

const MISColumnHeaders = ({
  uniqueExposureValues,
  label,
  stickyFirstColumn = false,
  eventType,
}: Props) => {
  const { warrantyClaimsCost } = useFlags();

  // Claims have an extra column due to cost data (CPV)
  const hasPlusOneColumn = Boolean(
    warrantyClaimsCost && eventType === EventTypeEnum.CLAIM
  );

  const EXPOSURE_COLSPAN = hasPlusOneColumn
    ? DEFAULT_EXPOSURE_COLSPAN + 1
    : DEFAULT_EXPOSURE_COLSPAN;

  return (
    <>
      <th
        colSpan={NUMBER_OF_NON_EXPOSURE_COLUMNS}
        className={classNames({
          "sticky left-0 bg-white z-10": stickyFirstColumn,
        })}
      ></th>
      {Array.from(uniqueExposureValues).map((exposureBucket, index) => (
        <th
          key={exposureBucket + index}
          colSpan={EXPOSURE_COLSPAN}
          className="p-2 font-semibold text-gray-500 tracking-wider uppercase whitespace-nowrap cursor-default"
          style={{ fontSize: "0.6rem" }}
        >
          {label} {exposureBucket}
        </th>
      ))}
    </>
  );
};

export default MISColumnHeaders;
