import { IssueTypes } from "shared/types";

import Board from "features/ui/Board";

import OccurrencesByCalendarTimeChart from "./Charts/OccurrencesByCalendarTimeChart";
import OccurrencesByVehicleAgeChart from "./Charts/OccurrencesByVehicleAgeChart";

interface Props {
  issue: IssueTypes;
  withComparisonPopulation: boolean;
  timePeriod: number;
}

const IssueRelationshipCharts = (props: Props) => {
  const boardElements: JSX.Element[][] = [
    [<OccurrencesByCalendarTimeChart {...props} />],
    [<OccurrencesByVehicleAgeChart {...props} />],
  ];

  return (
    <div className="mb-3">
      <Board elements={boardElements} />
    </div>
  );
};

export default IssueRelationshipCharts;
