import { EventTypeEnum } from "shared/types";
import { pluralize } from "shared/utils";

import { YAxisValTopContributors } from "pages/constants";

import { DropdownSelectOption } from "features/ui/DropdownSelect/DropdownSelect";

type YAxisOption = {
  id: YAxisValTopContributors;
  value: string;
  label: string;
};

const yAxisOptions: Record<YAxisValTopContributors, YAxisOption> = {
  IPTV: {
    id: "IPTV",
    value: "Incidents per thousand vehicles",
    label: "Incidents per thousand vehicles",
  },
  CPV: {
    id: "CPV",
    value: "Cost per vehicle",
    label: "Cost per vehicle",
  },
  totalCost: {
    id: "totalCost",
    value: "Total Cost",
    label: "Total Cost",
  },
  numEvents: {
    id: "numEvents",
    value: "Total Events",
    label: "Total number of Events",
  },
  numVehicles: {
    id: "numVehicles",
    value: "Affected Vehicles",
    label: "Number of affected vehicles",
  },
};

export const getTopContributorsChartYAxisOptions = (
  entity: EventTypeEnum,
  optionIds: YAxisValTopContributors[]
): DropdownSelectOption<YAxisValTopContributors>[] =>
  optionIds.map((id) => {
    const option = yAxisOptions[id];
    return id === "numEvents"
      ? {
          ...option,
          value: `Total ${pluralize(entity)}`,
          label: `Total number of ${pluralize(entity)}`,
        }
      : option;
  });
