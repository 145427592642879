import { MdClose as CloseIcon } from "react-icons/md";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";

import { noop } from "shared/utils";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactElement;
}

const NewCollectionModalWrapper = ({ isOpen, onClose, children }: Props) => (
  <Dialog
    open={isOpen}
    onClose={noop} // prevent clicking outside to close the dialog (or Esc key)
    data-testid="modal-new-collection"
    fullWidth
    maxWidth="sm"
  >
    <DialogTitle className="flex justify-between">
      <span>Create New Collection</span>
      <IconButton
        data-testid="modal-new-collection-close-cta"
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent>{children}</DialogContent>
  </Dialog>
);

export default NewCollectionModalWrapper;
