import { AxiosResponse } from "axios";

import client from "shared/api/axios";

interface FeedbackItem {
  question: string;
  response: string;
}

interface FeedbackRequest {
  responses: FeedbackItem[];
}

interface FeedbackResponse {
  message: object;
}

export const sendFeedback = (args: FeedbackRequest) =>
  client
    .post<FeedbackRequest, AxiosResponse<FeedbackResponse>>("/feedback", args)
    .then(({ data }) => data);
