import { SWRConfiguration } from "swr";

import { SelectOption } from "features/ui/Select";

export const enum Granularity {
  WEEK = "week",
  MONTH = "month",
}

export const ageGranularityOptions: SelectOption[] = [
  {
    id: Granularity.WEEK,
    value: "Week",
  },
  {
    id: Granularity.MONTH,
    value: "Month",
  },
];

export const DEFAULT_ATTRIBUTES_SWR_CONFIG: SWRConfiguration = {
  refreshInterval: 600000, // Refresh every 10 minutes
  revalidateOnFocus: true, // Revalidate on focus only when conditions are met
  dedupingInterval: 600000, // Avoid duplicate requests within 10 minutes
};
