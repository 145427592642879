import { jwtDecode } from "jwt-decode";
import { useOktaAuth } from "@okta/okta-react";

import { useHealthcheck } from "shared/api/hooks";
import { JWT } from "shared/types";

import * as config from "config/config";

import styles from "./Settings.module.css";

const GeneralSettings = () => {
  const {
    api: { url: apiUrl },
  } = config.get();

  const { oktaAuth } = useOktaAuth();
  const accessToken = oktaAuth.getAccessToken() || "";
  const { tenant, sub: email }: JWT = jwtDecode(accessToken);

  const { data } = useHealthcheck();

  return (
    <div className="flex items-start space-x-3">
      <table>
        <tbody>
          <tr>
            <td className={styles.td}>User</td>
            <td className={styles.td}>{email}</td>
          </tr>
          <tr>
            <td className={styles.td}>Tenant</td>
            <td className={styles.td}>{tenant}</td>
          </tr>
          <tr>
            <td className={styles.td}>App Version</td>
            <td className={styles.td}>{process.env.REACT_APP_VERSION}</td>
          </tr>
        </tbody>
      </table>
      <table>
        <tbody>
          <tr>
            <td className={styles.td}>API Environment</td>
            <td className={styles.td}>{data?.env}</td>
          </tr>
          <tr>
            <td className={styles.td}>API URL</td>
            <td className={styles.td}>{apiUrl}</td>
          </tr>
          <tr>
            <td className={styles.td}>API Version</td>
            <td className={styles.td}>{data?.version}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default GeneralSettings;
