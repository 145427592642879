import { useFlags } from "launchdarkly-react-client-sdk";

import StatefulTabs, { Tab } from "features/ui/StatefulTabs";

import DeveloperPortal from "./DeveloperPortal";
import Product from "./Product/Product";
import ReleaseNotes from "./ReleaseNotes";

const DOCS_DEVELOPER_PORTAL_TAB_KEY = "developer-portal";
export const DOCS_PRODUCT_GUIDE_TAB_KEY = "product-guide";
export const DOCS_RELEASE_NOTES_TAB_KEY = "release-notes";

const Docs = () => {
  const { developerPortal, releaseNotes } = useFlags();

  const tabs: (Tab | undefined | boolean)[] = [
    developerPortal && {
      key: DOCS_DEVELOPER_PORTAL_TAB_KEY,
      title: "Developer Portal",
      content: <DeveloperPortal />,
    },
    {
      key: DOCS_PRODUCT_GUIDE_TAB_KEY,
      title: "Product Guide",
      content: <Product />,
    },
    releaseNotes && {
      key: DOCS_RELEASE_NOTES_TAB_KEY,
      title: "Release Notes",
      content: <ReleaseNotes />,
    },
  ];

  const filteredTabs = tabs.filter(Boolean) as Tab[];

  return (
    <div className="pt-4">
      <StatefulTabs tabs={filteredTabs} />
    </div>
  );
};

export default Docs;
