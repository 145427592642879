import { OrderedValue } from "./api";

export const transformOrderedValuesToSelectOptions = (
  orderedValues?: OrderedValue[]
) => {
  return (
    orderedValues?.map(({ ID, value }) => ({
      id: ID,
      value: value,
    })) || []
  );
};
