import {
  CountResponse,
  EntityAttribute,
  ListAttributesRequest,
  VehicleAgeTimeline,
} from "shared/api/api";
import { DEFAULT_ATTRIBUTES_SWR_CONFIG } from "shared/api/constants";
import { useAPI, usePaginatedAPI } from "shared/api/hooks";
import { CountRequestWithVehiclesFilter } from "shared/api/vehicles/api";

import {
  countAssociatedVehiclesRequestURI,
  GetRepairRequest,
  getRepairRequestURI,
  getRepairsByVehicleAgeTimelineRequestURI,
  getRepairsMetricsHistoryRequestURI,
  getRepairsMetricsRequestURI,
  getRepairsTopContributorsRequestURI,
  listAssociatedSignalEventsRequestURI,
  listAssociatedVehiclesRequestURI,
  listRepairAttributesRequestURI,
  listRepairsCountRequestURI,
  ListRepairsRequest,
  listRepairsRequestURI,
  Repair,
  RepairAssociatedSignalEvent,
  RepairAssociatedSignalEventsRequest,
  RepairAssociatedVehicle,
  RepairAssociatedVehiclesRequest,
  RepairFiltersRequest,
  RepairsByVehicleAgeAgeRequest,
  RepairsMetrics,
  RepairsMetricsHistory,
  RepairsTopContributor,
  RepairsTopContributorsRequest,
} from "./api";

export const useListRepairs = (args: ListRepairsRequest) =>
  usePaginatedAPI<typeof args, Repair[]>(listRepairsRequestURI, args);

export const useListRepairsCount = (args: CountRequestWithVehiclesFilter) =>
  usePaginatedAPI<typeof args, CountResponse>(listRepairsCountRequestURI, args);

export const useRepair = (args: GetRepairRequest) =>
  useAPI<typeof args, Repair>(getRepairRequestURI, args);

export const useRepairsMetrics = (args: RepairFiltersRequest) =>
  useAPI<typeof args, RepairsMetrics>(getRepairsMetricsRequestURI, args);

export const useRepairsMetricsHistory = (args: RepairFiltersRequest) =>
  useAPI<typeof args, RepairsMetricsHistory[]>(
    getRepairsMetricsHistoryRequestURI,
    args
  );

export const useRepairAttributes = (args: ListAttributesRequest) =>
  useAPI<typeof args, EntityAttribute[]>(
    listRepairAttributesRequestURI,
    args,
    DEFAULT_ATTRIBUTES_SWR_CONFIG
  );

export const useListRepairsTopContributors = (
  args: RepairsTopContributorsRequest
) =>
  usePaginatedAPI<typeof args, RepairsTopContributor[]>(
    getRepairsTopContributorsRequestURI,
    args
  );

export const useListRepairsAssociatedVehicles = (
  args: RepairAssociatedVehiclesRequest
) =>
  usePaginatedAPI<typeof args, RepairAssociatedVehicle[]>(
    listAssociatedVehiclesRequestURI,
    args
  );

export const useCountRepairsAssociatedVehicles = (
  args: RepairAssociatedVehiclesRequest
) =>
  useAPI<typeof args, CountResponse>(countAssociatedVehiclesRequestURI, args);

export const useListRepairsByVehicleAgeTimeline = (
  args: RepairsByVehicleAgeAgeRequest
) =>
  useAPI<typeof args, VehicleAgeTimeline[]>(
    getRepairsByVehicleAgeTimelineRequestURI,
    args
  );

export const useListRepairsAssociatedSignalEvents = (
  args: RepairAssociatedSignalEventsRequest
) =>
  usePaginatedAPI<typeof args, RepairAssociatedSignalEvent[]>(
    listAssociatedSignalEventsRequestURI,
    args
  );
