import Skeleton from "react-loading-skeleton";

import { PermissionEntry } from "shared/api/api";
import { useOrderedValues } from "shared/api/orderedValues/hooks";
import { transformOrderedValuesToSelectOptions } from "shared/api/orderedValues/utils";
import useIssuesSchema from "shared/schemas/issuesSchema";

import GroupDropdown from "pages/Issues/SuggestedIssues/Details/GroupDropdown";

import APIError from "features/ui/APIError";
import Input from "features/ui/Input/Input";
import Select from "features/ui/Select";
import { StepProps } from "features/ui/Stepper";
import TextArea from "features/ui/TextArea";

import StepMetadataACL from "./StepMetadataACL";

const NOTES_NUM_ROWS = 6;
const NAME_CHARACTER_LIMIT = 50;
const DESCRIPTION_CHARACTER_LIMIT = 300;
const SKELETON_HEIGHT = 30;

const StepMetadata = ({ data, onDataUpdated, action }: StepProps) => {
  const { getDisplayLabel } = useIssuesSchema();

  const {
    data: issueStatuses,
    isLoading: issueStatusesLoading,
    error: issueStatusesError,
  } = useOrderedValues({ valueType: "issueStatus" });

  const {
    data: issueSeverities,
    isLoading: issueSeveritiesLoading,
    error: issueSeveritiesError,
  } = useOrderedValues({ valueType: "issueSeverity" });

  if (issueStatusesError) {
    return <APIError error={issueStatusesError} />;
  }

  if (issueSeveritiesError) {
    return <APIError error={issueSeveritiesError} />;
  }

  const issueStatusOptions =
    transformOrderedValuesToSelectOptions(issueStatuses);
  const issueSeverityOptions =
    transformOrderedValuesToSelectOptions(issueSeverities);

  return (
    <div className="p-1">
      <div className="mt-1 mb-3">
        <Input
          label="Name Issue*"
          value={data?.name || ""}
          onChange={({ target: { value } }) =>
            onDataUpdated && onDataUpdated({ name: value })
          }
          placeholder="Name the issue (minimum 5 characters)"
          characterLimit={NAME_CHARACTER_LIMIT}
          testId="issue-name-input"
        />
      </div>
      <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 justify-between w-full gap-x-3.5 mb-3">
        <div className="md:w-1/3">
          {issueStatusesLoading && <Skeleton height={SKELETON_HEIGHT} />}
          {!issueStatusesLoading && (
            <Select
              label="Workflow Status"
              options={issueStatusOptions}
              selected={
                issueStatusOptions?.find(({ id }) => id === data?.statusID)!
              }
              onSelect={({ id }) =>
                onDataUpdated && onDataUpdated({ statusID: id.toString() })
              }
            />
          )}
        </div>
        <div className="md:w-1/3">
          {issueSeveritiesLoading && <Skeleton height={SKELETON_HEIGHT} />}
          {!issueSeveritiesLoading && (
            <Select
              options={issueSeverityOptions}
              label="Type"
              selected={
                issueSeverityOptions?.find(({ id }) => id === data?.severityID)!
              }
              onSelect={({ id }) =>
                onDataUpdated && onDataUpdated({ severityID: id.toString() })
              }
            />
          )}
        </div>
        <div className="md:w-1/3">
          <Input
            label="Assignee"
            value={data?.assignee || ""}
            onChange={({ target: { value } }) =>
              onDataUpdated && onDataUpdated({ assignee: value })
            }
            placeholder="Enter email"
            type="email"
          />
        </div>
      </div>
      <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 justify-between w-full gap-x-3.5 mb-3">
        <div className="md:w-1/2">
          <GroupDropdown
            assignedGroupID={data?.assignedGroupID || null}
            onUpdateSuggestedIssue={(field, value) => {
              onDataUpdated && onDataUpdated({ [field]: value });
            }}
          />
        </div>
        <div className="md:w-1/2">
          <Input
            label={getDisplayLabel("externalID", "External ID")}
            value={data?.externalID || ""}
            onChange={({ target: { value } }) =>
              onDataUpdated && onDataUpdated({ externalID: value })
            }
            placeholder={`Enter ${getDisplayLabel("externalID", "External ID")}`}
            type="text"
          />
        </div>
      </div>
      <div className="mb-3">
        <Input
          label="Description"
          value={data?.description || ""}
          onChange={({ target: { value } }) =>
            onDataUpdated && onDataUpdated({ description: value })
          }
          placeholder="Enter a description"
          characterLimit={DESCRIPTION_CHARACTER_LIMIT}
          testId="issue-description-input"
        />
      </div>
      <div className="mb-3">
        <TextArea
          label="Notes"
          value={data?.notes || ""}
          onChange={({ target: { value } }) =>
            onDataUpdated && onDataUpdated({ notes: value })
          }
          rows={NOTES_NUM_ROWS}
          testId="issue-notes-input"
        />
      </div>
      {action === "create" && (
        <StepMetadataACL
          permissions={data?.acl}
          onPermissionsUpdate={(permissions: PermissionEntry[]) =>
            onDataUpdated && onDataUpdated({ acl: permissions })
          }
        />
      )}
    </div>
  );
};

export default StepMetadata;
