import Skeleton from "react-loading-skeleton";

import { PermissionEntry } from "shared/api/api";
import { useListGroups } from "shared/api/rbac/hooks";
import { APIFilter } from "shared/api/utils";
import { EntityWithPermissions, PermissionID } from "shared/types";

import APIError from "features/ui/APIError";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";

import GroupPermissionsTable from "./GroupPermissionsTable";

interface Props {
  entity: EntityWithPermissions;
  entityId?: string;
  generalAccess: PermissionID;
  canEdit: boolean;
  groupPermissions: PermissionEntry[];
  onUpdateGroup: (id: string, groupID: string) => void;
  onUpdatePermission: (id: string, access: PermissionID) => void;
  onAddPermission: () => void;
  onRemovePermission: (id: string) => void;
}

const GroupPermissionsTableWrapper = ({
  entity,
  entityId,
  generalAccess,
  canEdit,
  groupPermissions,
  onUpdateGroup,
  onUpdatePermission,
  onAddPermission,
  onRemovePermission,
}: Props) => {
  // When adding group permissions to group do not offer itself as an option
  const staticFilters: APIFilter[] =
    entity === "group" && entityId
      ? [{ name: "ID", value: entityId, op: "neq" }]
      : [];
  const { isLoading, data, error } = useListGroups({
    filter: getFiltersQuery(undefined, staticFilters),
  });

  if (isLoading) {
    return (
      <div>
        <Skeleton height={100} />
      </div>
    );
  }

  if (error) {
    return <APIError error={error} />;
  }

  if (!data) {
    return null;
  }

  // Do not show group permissions table if there are no groups to choose from
  const appliedGroupPermissions = groupPermissions.filter(
    ({ groupID }) => groupID
  );
  if (appliedGroupPermissions.length === 0 && data.length === 0) {
    return null;
  }

  return (
    <GroupPermissionsTable
      generalAccess={generalAccess}
      groups={data}
      canEdit={canEdit}
      groupPermissions={groupPermissions}
      onUpdateGroup={onUpdateGroup}
      onUpdatePermission={onUpdatePermission}
      onAddPermission={onAddPermission}
      onRemovePermission={onRemovePermission}
    />
  );
};

export default GroupPermissionsTableWrapper;
