import { useLocation } from "react-router-dom";

import StatefulTabs from "features/ui/StatefulTabs";

import { routes } from "services/routes";

import IssuesPage from "./IssuesPage";
import SuggestedIssuesPage from "./SuggestedIssuesPage";

const IssuesTabs = () => {
  const { pathname } = useLocation();
  const defaultTab = pathname === routes.suggestedIssues ? 1 : 0;

  const tabs = [
    {
      key: "issues",
      title: "Issues",
      content: (
        <div className="mt-4">
          <IssuesPage />
        </div>
      ),
      testId: "issues_tab",
    },
    {
      key: "suggested_issues",
      title: "Suggested Issues",
      content: (
        <div className="mt-4">
          <SuggestedIssuesPage />
        </div>
      ),
      testId: "suggested_issues_tab",
    },
  ];

  return <StatefulTabs tabs={tabs} defaultTab={defaultTab} />;
};

export default IssuesTabs;
