import CustomAttributesSelect, {
  CustomAttribute,
} from "pages/CustomSignalEvents/Definition/CustomAttributes/CustomAttributeSelect";

import FormSection from "features/ui/FormSection";
import { SchemaEntry } from "features/ui/Table";

interface Props {
  customAttributes: CustomAttribute[];
  onChange: (customAttributes: CustomAttribute[]) => void;
  onCustomAttributeRemoved: (id: string) => void;
  baseEventType: string;
  baseEventTypeSchema: SchemaEntry<string>[] | undefined;
  disabled?: boolean;
  className?: string;
}

const CustomAttributeDefinitionsSection = ({
  customAttributes,
  onChange,
  onCustomAttributeRemoved,
  baseEventType,
  baseEventTypeSchema: schema,
  disabled,
  className,
}: Props) => (
  <FormSection
    title="Custom Attribute Definitions"
    text={`Define custom attributes (including any needed "intermediate
          calculations") to filter on in the next section. All custom attributes
          appear as columns in the Test VIN Events table below`}
    className={className}
  >
    <CustomAttributesSelect
      customAttributes={customAttributes}
      onChange={onChange}
      baseEventType={baseEventType}
      baseEventTypeSchema={schema}
      onCustomAttributeRemoved={onCustomAttributeRemoved}
      disabled={disabled}
    />
  </FormSection>
);

export default CustomAttributeDefinitionsSection;
