import { useFlags } from "launchdarkly-react-client-sdk";
import { TooltipProps } from "recharts";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";

import { VehicleAgeTimeline } from "shared/api/api";
import { EventTypeEnum } from "shared/types";
import { formatNumber, pluralize } from "shared/utils";

import { ByVehicleAgeData } from "pages/types";
import { formatDateBasedOnGranularity } from "pages/utils";

interface ChartTooltipProps {
  eventType: EventTypeEnum;
  yAxisKey: keyof VehicleAgeTimeline;
  yAxisLabel: string;
  activeLabel?: number | string;
}

export type ByVehicleAgeChartTooltipProps = TooltipProps<ValueType, NameType> &
  ChartTooltipProps;

const ByVehicleAgeChartTooltip = ({
  yAxisKey,
  yAxisLabel,
  eventType,
  active,
  payload,
  label,
  activeLabel,
}: ByVehicleAgeChartTooltipProps) => {
  const { warrantyClaimsCost } = useFlags();

  if (!active || !activeLabel) return null;

  const data = payload?.[0]?.payload;
  if (!data) return null;

  const activeData = data[activeLabel] as VehicleAgeTimeline;

  if (!activeData) return null;

  const { granularity, byVehicleAgeBirthdayValue, byVehicleAgeExposureValue } =
    data.byVehicleAgeData as ByVehicleAgeData;

  const date = formatDateBasedOnGranularity(label, granularity);

  const showCPV = Boolean(
    warrantyClaimsCost && activeData.cumulativeCPV !== null
  );

  return (
    <div className="flex flex-col bg-white shadow px-2 py-3 text-sm">
      <div>
        {byVehicleAgeBirthdayValue}: {date}
      </div>
      <div>
        {byVehicleAgeExposureValue}: {activeLabel}
      </div>
      <div>
        {yAxisLabel}: {formatNumber(activeData[yAxisKey] as number)}
      </div>
      {showCPV && activeData?.cumulativeCPV !== undefined && (
        <div>Cumulative CPV: {formatNumber(activeData.cumulativeCPV)}</div>
      )}
      <div>Vehicles: {activeData.numVehicles}</div>
      <div>
        {pluralize(eventType)}: {activeData.numEvents}
      </div>
    </div>
  );
};

export default ByVehicleAgeChartTooltip;
