import { FiPlus as AddIcon } from "react-icons/fi";
import { MdClose as CloseIcon } from "react-icons/md";
import { IconButton, Tab as MUITab, Tabs, TabsProps } from "@mui/material";

import { Tab } from "features/ui/StatefulTabs";

export interface DynamicTabsProps extends TabsProps {
  tabs: Tab[];
  activeTab?: number;
  onTabChange: (tab: number) => void;
  onTabAdd: () => void;
  onTabRemove: (index: number) => void;
  disabledInput?: boolean;
  extraComponent?: React.ReactNode;
}

const DynamicTabs = ({
  tabs,
  activeTab = 0,
  onTabChange,
  onTabAdd,
  onTabRemove,
  disabledInput = false,
  extraComponent,
}: DynamicTabsProps) => {
  const contents = tabs.map(({ content }) => content);

  const currentContent = contents[activeTab];

  return (
    <div className="mt-2">
      <div className="flex space-x-2 items-center mb-2">
        <Tabs
          value={activeTab}
          onChange={(e, val: number) => {
            onTabChange(val);
          }}
          variant="scrollable"
          scrollButtons="auto"
        >
          {tabs.map(
            ({ key, title, testId, disabled = false, disabledText }, index) => {
              const tabLabel = (
                <span>
                  {title}
                  {tabs.length > 1 && !disabledInput && (
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        onTabRemove(index);
                      }}
                      component="span"
                      data-testid="tab-remove-btn"
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                </span>
              );
              const tabTitle = disabled && disabledText ? disabledText : title;

              return (
                <MUITab
                  key={key}
                  label={tabLabel}
                  disabled={disabled}
                  data-testid={testId}
                  title={tabTitle}
                  disableRipple
                />
              );
            }
          )}
        </Tabs>
        {!disabledInput && (
          <IconButton
            className="inline-block"
            size="small"
            onClick={onTabAdd}
            data-testid="tab-add-btn"
          >
            <AddIcon />
          </IconButton>
        )}
        {extraComponent}
      </div>
      <div role="tabpanel">{currentContent}</div>
    </div>
  );
};

export default DynamicTabs;
