export const COLLECTIONS_PER_PAGE = 10;
export const ACTION_ICON_SIZE = 15;

export const VEHICLE_COLLECTION_ENTITY_TYPE = "vehicle";

// This is the field we use when making requests like
// - filter=VIN=in:collectionV1="d82a445d-9ba4-48d1-ab41-2c2ce57713f7"
export const VEHICLE_COLLECTION_API_FIELD_NAME = "VIN";

// This is the field we use when making a requests like
// - filter=VIN=in:collectionV1="d82a445d-9ba4-48d1-ab41-2c2ce57713f7"
export const VEHICLE_COLLECTION_REQUEST_KEY = "collectionV1";
