import { PageHandler } from "duck/graph/PageHandler";
import getCaptureScreenshotTool from "duck/graph/tools/getCaptureScreenshotTool";
import { DuckGraphParams } from "duck/graph/types";
import { StructuredTool } from "@langchain/core/tools";

import getGoToHealthTabTool from "./getGoToHealthTabTool";
import getGoToServiceRecommendationsTabTool from "./getGoToServiceRecommendationsTabTool";
import getGoToServiceRecordsTabTool from "./getGoToServiceRecordsTabTool";
import getGoToServiceScheduleTabTool from "./getGoToServiceScheduleTabTool";
import getGoToTimelineTabTool from "./goToTimelineTab/getGoToTimelineTabTool";

/**
 * @summary Get the tools for the vin view agent.
 * The tools are specific to vin view, and are bound to the UI handlers
 * for vin view.
 * @param params The data from the UI layer that is necessary for the tools to function.
 * @returns The full set of tools for the signal event analytics agent.
 */
const getTools = (
  params: DuckGraphParams,
  pageHandler: PageHandler
): StructuredTool[] => [
  getGoToHealthTabTool(pageHandler),
  getGoToServiceRecordsTabTool(pageHandler),
  getGoToServiceScheduleTabTool(pageHandler),
  getGoToServiceRecommendationsTabTool(pageHandler),
  getGoToTimelineTabTool(
    pageHandler,
    params.availableData.vinView?.sensorOptions,
    params.availableData.vinView?.timelineChartOptions,
    params.currentState.vinView?.selectedTimelineChartOptions,
    params.currentState.vinView?.selectedSensorsAndTriggers,
    params.currentState.vinView?.selectedDateRange,
    params.currentState.vinView?.selectedSignalEventFilters
  ),
  getCaptureScreenshotTool(params.uiHandlers.setEphemeralMessage),
];

export default getTools;
