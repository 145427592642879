import { VscCopy as CopyIcon } from "react-icons/vsc";
import { toast } from "react-toastify";
import { useOktaAuth } from "@okta/okta-react";

import { useAuthenticationToken } from "shared/api/hooks";
import { copyToClipboard } from "shared/utils";

import Button from "features/ui/Button";

const TOOLTIP_TEXT = "Access token copied to clipboard!";
const TOOLTIP_TEXT_RT = "Refresh token copied to clipboard!";

const CopyAuthButton = () => {
  const { oktaAuth } = useOktaAuth();
  const accessToken = oktaAuth.getAccessToken();

  const { data } = useAuthenticationToken();
  const refreshToken = data?.refreshToken;

  const copyToClipboardAndNotify = (token: string, callback: () => void) => {
    copyToClipboard(token).then((ok) => {
      if (ok) callback();
    });
  };

  return (
    <>
      <Button
        title="Click to copy access token"
        onClick={() =>
          copyToClipboardAndNotify(accessToken || "", () =>
            toast.info(TOOLTIP_TEXT, { toastId: "copy-auth-button" })
          )
        }
        endIcon={<CopyIcon />}
      >
        Access Token
      </Button>

      {refreshToken && (
        <Button
          title="Click to copy refresh token"
          onClick={() =>
            copyToClipboardAndNotify(refreshToken, () =>
              toast.info(TOOLTIP_TEXT_RT, { toastId: "copy-auth-button-rt" })
            )
          }
          endIcon={<CopyIcon />}
        >
          Refresh Token
        </Button>
      )}
    </>
  );
};

export default CopyAuthButton;
