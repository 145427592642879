import { useEffect, useMemo, useState } from "react";
import { ReferenceArea } from "recharts";
import { AxisDomain } from "recharts/types/util/types";

import { DEFAULT_Y_AXIS_ID } from "features/ui/charts/constants";
import ResetZoomButton from "features/ui/charts/shared/ResetZoomButton";
import SetDateToZoom from "features/ui/charts/shared/SetDateToZoom";
import { ZoomProps } from "features/ui/charts/types";

interface ZoomChartState {
  left: string | number;
  right: string | number;
  refAreaLeft: string | number;
  refAreaRight: string | number;
}

const getAxisDomains = (from: number | string, to: number | string) => {
  const fromToUse = typeof from === "number" ? from : parseInt(from);
  const toToUse = typeof to === "number" ? to : parseInt(to);

  return {
    left: fromToUse,
    right: toToUse,
  };
};

const emptyState = {
  isZoomedIn: undefined,
  handleZoomOut: undefined,
  handleMouseDown: undefined,
  handleMouseMove: undefined,
  handleZoom: undefined,
  zoomYAxisDomain: undefined,
  zoomXAxisDomain: undefined,
  initialState: undefined,
  resetZoomButton: null,
  zoomReferenceArea: null,
};

const initialState: ZoomChartState = {
  left: "auto",
  right: "auto",
  refAreaLeft: "",
  refAreaRight: "",
};

export const useZoom = ({
  enableZoom,
  onZoom,
  zoomOverride,
  onZoomOut,
  onZoomReferenceAreaChange,
  zoomOutControlMarginSide,
  onSyncDateWithZoom,
}: ZoomProps) => {
  const [state, setState] = useState(initialState);

  // when zoomOverride changes, apply it to state
  useEffect(() => {
    if (zoomOverride) {
      setState({
        ...state,
        left: zoomOverride.left,
        right: zoomOverride.right,
      });
    } else {
      setState(initialState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoomOverride, initialState]);

  const isZoomedIn =
    state.left !== initialState.left || state.right !== initialState.right;

  const handleZoomOut = () => {
    setState(initialState);
    onZoomOut && onZoomOut();
  };

  const zoomReferenceArea = useMemo(() => {
    if (!state.refAreaLeft || !state.refAreaRight || !enableZoom) return null;

    return (
      <ReferenceArea
        x1={state.refAreaLeft}
        x2={state.refAreaRight}
        strokeOpacity={0.3}
        yAxisId={DEFAULT_Y_AXIS_ID}
      />
    );
  }, [state, enableZoom]);

  // when zoomReferenceArea changes, call onZoomReferenceAreaChange
  useEffect(() => {
    onZoomReferenceAreaChange && onZoomReferenceAreaChange(zoomReferenceArea);
  }, [zoomReferenceArea, onZoomReferenceAreaChange]);

  if (!enableZoom) return emptyState;

  const handleZoom = () => {
    let { refAreaLeft, refAreaRight } = state;

    if (refAreaLeft === refAreaRight || refAreaRight === "") {
      setState({
        ...state,
        refAreaLeft: "",
        refAreaRight: "",
      });
      return;
    }

    if (refAreaLeft > refAreaRight)
      [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];

    const { left, right } = getAxisDomains(refAreaLeft, refAreaRight);

    setState({
      refAreaLeft: "",
      refAreaRight: "",
      left,
      right,
    });

    onZoom && onZoom({ left, right });
  };

  const handleMouseDown = (e: any) =>
    setState({ ...state, refAreaLeft: e?.xValue || "" });

  const handleMouseMove = (e: any) =>
    state.refAreaLeft && setState({ ...state, refAreaRight: e?.xValue || "" });

  return {
    initialState,
    isZoomedIn,
    handleZoomOut,
    handleMouseDown,
    handleMouseMove,
    handleZoom,
    zoomXAxisDomain: [state.left, state.right] as AxisDomain,
    zoomReferenceArea,
    resetZoomButton: (
      <>
        {onSyncDateWithZoom && (
          <SetDateToZoom
            isZoomedIn={isZoomedIn}
            onClick={() =>
              onSyncDateWithZoom({ left: state.left, right: state.right })
            }
            marginSide={zoomOutControlMarginSide}
          />
        )}
        <ResetZoomButton
          atBottom={Boolean(!onSyncDateWithZoom)}
          isZoomedIn={isZoomedIn}
          handleZoomOut={handleZoomOut}
          marginSide={zoomOutControlMarginSide}
        />
      </>
    ),
  };
};
