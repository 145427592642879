import { APISuccessResponse } from "shared/api/api";
import {
  Group,
  NewGroupRequest,
  UpdateGroupRequest,
} from "shared/api/rbac/api";

import { toApiGroupMemberAccess } from "pages/Groups/utils";
import { removeFalsyFromRecord } from "pages/Issues/CreateIssue/utils";

import { calculatePermissionToSubmit } from "features/ui/PermissionsDialog/utils";
import { ActionType } from "features/ui/StepperModal";

type ActionProps<T> = T extends "create"
  ? NewGroupRequest
  : T extends "update"
    ? UpdateGroupRequest
    : never;

export type ActionCallback<T> = T extends "create"
  ? (d: NewGroupRequest) => Promise<APISuccessResponse<Group>>
  : T extends "update"
    ? (d: UpdateGroupRequest) => Promise<APISuccessResponse<Group>>
    : never;

export const prepareRequestData = (
  data: Record<string, any>,
  action: ActionType,
  myEmail: string
): ActionProps<ActionType> => {
  const { ID, name, description, users, acl, memberAccess } = data;

  let requestData = {};
  if (action === "create") {
    requestData = {
      name,
      description,
      users: users.map((x: Record<string, string>) => x.email),
      acl: calculatePermissionToSubmit(acl, [myEmail]),
      memberAccess: toApiGroupMemberAccess(memberAccess),
    };
  } else {
    requestData = {
      ID,
      name,
      description,
    };
  }

  return removeFalsyFromRecord(requestData, action) as ActionProps<
    typeof action
  >;
};
