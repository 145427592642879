import { SuggestedIssue } from "shared/api/suggestedIssues/api";

import CardClaims from "pages/Issues/Details/CardClaims";
import CardPopulations from "pages/Issues/Details/CardPopulations";
import CardSignalEvents from "pages/Issues/Details/CardSignalEvents";
import { IssueRequestKeys } from "pages/Issues/types";

import Board from "features/ui/Board";

import SuggestedIssueCardOverview from "./Details/SuggestedIssueCardOverview";

interface Props extends IssueRequestKeys {
  issue: SuggestedIssue;
}

const SuggestedIssueDetails = (props: Props) => {
  const boardElements: JSX.Element[][] = [
    [<SuggestedIssueCardOverview {...props} />, <CardClaims {...props} />],
    [<CardPopulations {...props} />, <CardSignalEvents {...props} />],
  ];

  return <Board elements={boardElements} />;
};

export default SuggestedIssueDetails;
