import React, { useState } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { HiDotsVertical } from "react-icons/hi";
import { IconButton } from "@mui/material";

import CreateGroup from "pages/Groups/CreateGroup/CreateGroup";
import { GroupFormData } from "pages/Groups/utils";

import DropdownSelect, {
  DropdownSelectOption,
} from "features/ui/DropdownSelect/DropdownSelect";
import { ActionType } from "features/ui/StepperModal";

export interface CreateGroupProps {
  action: ActionType;
  step?: number;
  groupData?: GroupFormData;
  groupRequestKey?: string;
}

interface Props {
  canEdit?: boolean;
  createGroupProps?: CreateGroupProps;
}

const EDIT_OPTION_ID = "edit_group";

const useDropdownOptions = (canEdit: boolean) => {
  const dropdownOptions: DropdownSelectOption[] = [];
  if (canEdit) {
    dropdownOptions.push({
      id: EDIT_OPTION_ID,
      value: "Edit",
      testId: EDIT_OPTION_ID,
    });
  }
  return dropdownOptions;
};

const CardActions = ({ canEdit = false, createGroupProps }: Props) => {
  const [selectedAction, setSelectedAction] = useState("");

  const { rbac } = useFlags();

  const editPermission = !rbac || (rbac && canEdit);

  const dropDownOptions = useDropdownOptions(editPermission);
  if (dropDownOptions.length === 0) {
    return null;
  }

  if (!createGroupProps) return null;

  return (
    <div className="flex flex-nowrap ml-auto items-start space-x-2 z-[1]">
      <DropdownSelect
        options={dropDownOptions}
        buttonClass="ml-auto"
        onSelect={(opt) => setSelectedAction(opt.id as string)}
        buttonComponent={
          <IconButton size="small" data-testid="card-action-dropdown">
            <HiDotsVertical />
          </IconButton>
        }
      />
      <CreateGroup
        {...createGroupProps}
        showButton={false}
        open={selectedAction === EDIT_OPTION_ID}
        onClose={() => setSelectedAction("")}
      />
    </div>
  );
};

export default CardActions;
