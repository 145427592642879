import { useFlags } from "launchdarkly-react-client-sdk";

import { EventSourceType } from "shared/api/serviceRecords/api";

import { getClaimsChartOptions } from "pages/utils";

import {
  cumulativeCost,
  cumulativeCPV,
  cumulativeIPTV,
  getCumulativeEntityCount,
  getRollingEntityCount,
  rollingCost,
  rollingCPV,
  rollingIPTV,
} from "features/ui/charts/actions";
import { ChartAction } from "features/ui/charts/Actions/types";

/**
 * This hook returns the available chart options for the Claims chart.
 * It is used to build the Claims Chart, and is also used by Duck to provide context
 * to the agent.
 * @returns The SelectOptions for the Claims chart, organized by action.
 */
export const useClaimsChartActions = (): ChartAction[] => {
  const { warrantyClaimsCost: warrantyClaimsCostFF } = useFlags();

  return getClaimsChartOptions(
    [
      rollingIPTV,
      cumulativeIPTV,
      warrantyClaimsCostFF && rollingCPV,
      warrantyClaimsCostFF && cumulativeCPV,
      getRollingEntityCount(EventSourceType.CLAIM),
      getCumulativeEntityCount(EventSourceType.CLAIM),
      warrantyClaimsCostFF && rollingCost,
      warrantyClaimsCostFF && cumulativeCost,
    ].filter(Boolean)
  );
};
