import { Dot, DotProps, Polygon, PolygonProps } from "recharts";
import { SymbolType } from "recharts/types/util/types";

const BASE_SIZE = 5;
const SCALE_FACTOR = 2;

interface PayloadProp {
  yOffset: number;
  count: number;
  shape: SymbolType;
  color: string;
}

interface PayloadProps {
  payload: PayloadProp;
}

const CustomShape = (props: DotProps & PolygonProps & PayloadProps) => {
  const { height, width, cx, cy, x, y, payload } = props;
  const { yOffset, count, shape, color } = payload;
  const size = BASE_SIZE + count * SCALE_FACTOR;

  const cyOffset = cy ? cy - yOffset : cy;
  const trianglePoint1 = { x: cx ? cx + size : 0, y: cy ? cy + size : 0 };
  const trianglePoint2 = { x: cx || 0, y: cy ? cy - size : 0 };
  const trianglePoint3 = { x: cx ? cx - size : 0, y: cy ? cy + size : 0 };

  const fwdParams = { width, height, cx, x, y };
  const commonShapeParams = {
    ...fwdParams,
    fill: color,
    cy: cyOffset,
  };

  return (
    <>
      {(!shape || shape === "circle") && (
        <Dot {...commonShapeParams} r={size} />
      )}
      {shape === "triangle" && (
        <Polygon
          {...commonShapeParams}
          points={[trianglePoint1, trianglePoint2, trianglePoint3]}
        />
      )}
    </>
  );
};

export default CustomShape;
