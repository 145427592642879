import SurvivalCurve from "pages/VINView/SurvivalCurve";

import StatefulTabs, { Tab } from "features/ui/StatefulTabs";

import * as config from "config/config";

import FailureModeDefinitionTab from "./FailureModeDefinitionTab";
import FailureModeVehicles from "./FailureModeVehicles";

const FM_DEFINITION_TAB_KEY = "definition";
const FM_VEHICLES_TAB_KEY = "vehicles";
const FM_SURVIVAL_CURVE_TAB_KEY = "survival-curve";

interface Props {
  id: string;
}

const FailureModeTabs = ({ id }: Props) => {
  const {
    pages: { vehicles, survivalCurves },
  } = config.get();

  const tabsItems: (Tab | boolean | undefined)[] = [
    {
      key: FM_DEFINITION_TAB_KEY,
      title: "Definition",
      content: <FailureModeDefinitionTab failureModeID={id} />,
    },
    vehicles?.enabled && {
      key: FM_VEHICLES_TAB_KEY,
      title: "Vehicles",
      content: <FailureModeVehicles failureModeID={id} />,
    },
    survivalCurves?.enabled && {
      key: FM_SURVIVAL_CURVE_TAB_KEY,
      title: "Survival Curve",
      content: <SurvivalCurve failureModeID={id} />,
    },
  ];

  const tabs = tabsItems.filter(Boolean) as Tab[];

  return <StatefulTabs tabs={tabs} tabPanelClassName="py-3" />;
};

export default FailureModeTabs;
