import { CountRequest, CountResponse, GetApiIdRequest } from "shared/api/api";
import { useAPI, usePaginatedAPI } from "shared/api/hooks";
import { Vehicle } from "shared/api/vehicles/api";

import * as api from "./api";

export const useFailureModes = (args: api.ListFailureModesRequest) =>
  usePaginatedAPI<typeof args, api.FailureMode[]>(
    api.listFailureModesRequestURI,
    args
  );

export const useFailureMode = (args: GetApiIdRequest) =>
  useAPI<typeof args, api.FailureMode>(api.getFailureModeRequestURI, args);

export const useFailureModeEvents = (args: api.ListFailureModesEventsRequest) =>
  usePaginatedAPI<typeof args, api.FailureModeEvent[]>(
    api.listFailureModeEventsRequestURI,
    args
  );

export const useFailureModeEventsCount = (
  args: api.ListFailureModesEventsRequest
) =>
  useAPI<typeof args, CountResponse>(
    api.getFailureModeEventsCountRequestURI,
    args
  );

export const useFailureModeVehiclesCount = (
  args: api.ListFailureModesVehiclesRequest
) =>
  useAPI<typeof args, CountResponse>(
    api.getFailureModeVehiclesCountRequestURI,
    args
  );

export const useListFailureModeVehicles = (
  args: api.ListFailureModesVehiclesRequest
) =>
  usePaginatedAPI<typeof args, Vehicle[]>(
    api.listFailureModeVehiclesRequestURI,
    args
  );

export const useFailureModePredictions = (
  args: api.ListFailureModesPredictionsRequest
) =>
  usePaginatedAPI<typeof args, api.FailureModePrediction[]>(
    api.listFailureModePredictionsRequestURI,
    args
  );

export const useFailureModePredictionsCount = (args: CountRequest) =>
  useAPI<typeof args, CountResponse>(
    api.listFailureModePredictionsCountRequestURI,
    args
  );

export const useFailureModeEventsTimeline = (
  args: api.FailureModeEventsTimelineRequest
) =>
  useAPI<typeof args, api.FailureModeEventsTimelineBucket[]>(
    api.getFailureModeEventsTimelineURI,
    args
  );

// ---------
// Survival curves
// ---------
export const useSurvivalCurves = (args: api.GetSurvivalCurvesRequest) =>
  useAPI<typeof args, api.SurvivalCurveResponse>(
    api.getSurvivalCurvesRequestURI,
    args
  );

// ---------
// SIMILAR VEHICLES
// ---------
export const useListSimilarVehicles = (args: api.ListSimilarVehiclesRequest) =>
  useAPI<typeof args, api.SimilarVehicle[]>(
    api.listSimilarVehiclesRequestURI,
    args
  );
