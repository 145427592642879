import React from "react";
import {
  Cell,
  PieChart as Chart,
  Label,
  Legend,
  Pie,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
} from "recharts";
import { Payload } from "recharts/types/component/DefaultLegendContent";
import { PolarViewBox } from "recharts/types/util/types";

import { formatNumber, formatPercent } from "shared/utils";

const CENTRAL_LABEL_DEFAULT = "TOTAL";
const MIN_WIDTH_HEIGHT_PX = 150;

interface TooltipContentProps extends TooltipProps<number, string> {
  sum: number;
}

interface PieChartData {
  name: string;
  value: number;
  color: string;
}
export interface PieChartProps {
  data: PieChartData[];
  size?: string | number;
  TooltipContentComponent?: React.ComponentType<TooltipContentProps>;
  centralLabel?: string;
  disableAnimation?: boolean;
}

const PieChart = ({
  data,
  size = "100%",
  TooltipContentComponent = DefaultTooltipContent,
  centralLabel = CENTRAL_LABEL_DEFAULT,
  disableAnimation = false,
}: PieChartProps) => {
  const sum = data.reduce((prev, current) => {
    return prev + current.value;
  }, 0);

  const [active, setActive] = React.useState<number>();

  const onPieEnter = (_: any, index: number) => {
    setActive(index);
  };

  const onPieLeave = (_: any, __: number) => {
    setActive(undefined);
  };

  const legendPayload = data
    .map((item) => {
      if (!item.value) return null;
      return {
        id: item.name,
        type: "circle",
        value: `${item.name} ${formatPercent(item.value / sum)}`,
        color: item.color,
      };
    })
    .filter(Boolean);

  return (
    <ResponsiveContainer
      width={size}
      height={size}
      minHeight={MIN_WIDTH_HEIGHT_PX}
      minWidth={MIN_WIDTH_HEIGHT_PX}
    >
      <Chart>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          labelLine={false}
          outerRadius={70}
          dataKey="value"
          innerRadius={45}
          paddingAngle={1}
          onMouseEnter={onPieEnter}
          onMouseLeave={onPieLeave}
          isAnimationActive={disableAnimation ? false : undefined}
        >
          <Label
            content={({ viewBox }) => {
              if (active === undefined) {
                return (
                  <CentralLabel
                    viewBox={viewBox as PolarViewBox}
                    sum={sum}
                    label={centralLabel}
                  />
                );
              }
              return (
                <CentralLabel
                  viewBox={viewBox as PolarViewBox}
                  sum={data[active]["value"]}
                  label={data[active]["name"]}
                />
              );
            }}
          />
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={entry.color}
              opacity={active === undefined || active === index ? "1" : "0.5"}
            />
          ))}
        </Pie>
        {TooltipContentComponent && (
          <Tooltip content={<TooltipContentComponent sum={sum} />} />
        )}
        <Legend
          payload={legendPayload as Payload[]}
          align="right"
          layout="vertical"
          verticalAlign="middle"
        />
      </Chart>
    </ResponsiveContainer>
  );
};

interface LabelProps {
  viewBox?: PolarViewBox;
  sum: number;
  label: string;
}

const CentralLabel = ({ viewBox, sum, label }: LabelProps) => {
  const positioningProps = {
    x: viewBox?.cx ?? 0,
    textAnchor: "middle",
    verticalAnchor: "middle",
  };

  return (
    <>
      <text
        {...positioningProps}
        y={viewBox?.cy ?? 0}
        className="font-semibold"
        data-testid="piechart-central-label_number"
      >
        {formatNumber(sum)}
      </text>
      <text
        {...positioningProps}
        y={(viewBox?.cy ?? 0) + 20}
        className="text-gray-500 text-xs"
        fill="currentColor"
        data-testid="piechart-central-label_text"
      >
        {label?.toUpperCase()}
      </text>
    </>
  );
};

const DefaultTooltipContent = ({
  active,
  payload,
  sum,
}: TooltipContentProps) => {
  if (!(active && payload && payload.length)) return null;
  return (
    <div className="inline-block p-3 text-sm text-white bg-gray-900 rounded-lg shadow-sm opacity-70 max-w-xs">
      <p className="label">{`Name: ${payload[0].name}`}</p>
      <p className="label">{`Percentage: ${formatPercent(
        (payload[0].value ?? 0) / sum
      )}`}</p>
      <p className="label">{`Count: ${formatNumber(payload[0].value ?? 0)}`}</p>
    </div>
  );
};

export default PieChart;
