import VINPreview from "./VINPreview";

interface Props {
  vins: string[];
  collectionName: string;
}

const Step3 = ({ vins, collectionName }: Props) => {
  return (
    <div>
      <h3 className="text-lg font-semibold mb-3">Please review details.</h3>
      <div>
        <div>
          Collection name: <strong>{collectionName}</strong>
        </div>
        <div>
          <VINPreview vins={vins} />
        </div>
      </div>
    </div>
  );
};

export default Step3;
