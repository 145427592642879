import { Stack } from "@mui/material";

import DuckLogo from "./duck-logo.svg";

const DuckSummary = () => (
  <Stack alignItems="center" direction="row" spacing={1}>
    <img className="h-[17px] w-[17px]" src={DuckLogo} alt="Duck Logo" />
    <div className="text-gray-600">ViaDuck Assistant</div>
  </Stack>
);

export default DuckSummary;
