import { APISuccessResponse } from "shared/api/api";
import {
  getInspection,
  GetInspectionRequest,
  Inspection,
} from "shared/api/inspections/api";
import { getRepair, GetRepairRequest, Repair } from "shared/api/repairs/api";
import { ServiceRecord } from "shared/api/serviceRecords/api";

export const getEntityFunc = ({
  sourceType,
}: ServiceRecord):
  | ((
      args: GetRepairRequest | GetInspectionRequest
    ) => Promise<APISuccessResponse<Repair | Inspection>>)
  | undefined => {
  switch (sourceType) {
    case "repair":
      return getRepair;
    case "inspection":
      return getInspection;
  }

  return;
};
