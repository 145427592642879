import { ReactNode } from "react";
import { Chip } from "@mui/material";

import Button from "features/ui/Button";
import Modal from "features/ui/Modal";
import ValuesSearchList from "features/ui/ValuesSearchList";

export const MAX_VISIBLE_CHIPS_DEFAULT = 2;

export interface Props {
  title: string;
  values?: string[] | JSX.Element[];
  maxVisible?: number;
  defaultWrapper?: boolean;
  clickableChips?: boolean;
}

const ChipList = ({
  title,
  values,
  maxVisible = MAX_VISIBLE_CHIPS_DEFAULT,
  defaultWrapper = true,
  clickableChips = false,
}: Props) => {
  if (!values || values.length === 0) {
    return null;
  }

  const valuesPerChip = values.slice(0, maxVisible);
  const valuesForMore = values.slice(maxVisible);
  const moreLabel = `+ ${valuesForMore.length} more`;

  const moreButton = (
    <Button
      size="small"
      label={moreLabel}
      key={`btn-${moreLabel}`}
      variant="text"
    />
  );

  return (
    <ConditionalWrapper condition={defaultWrapper}>
      {valuesPerChip.map((chipContent, idx) =>
        typeof chipContent === "string" ? (
          <Chip
            size="small"
            label={chipContent}
            key={`chip-${idx}`}
            clickable={clickableChips}
          />
        ) : (
          chipContent
        )
      )}
      {valuesForMore.length > 0 && (
        <Modal
          triggerElement={moreButton}
          // casting valuesForMore to string[] is ok, since we pass JSX.Element[] values only for filters,
          // but we always show all filters, never only X filters + more modal
          children={[
            <ValuesSearchList
              title={title}
              values={valuesForMore as string[]}
            />,
          ]}
        />
      )}
    </ConditionalWrapper>
  );
};

export default ChipList;

const ConditionalWrapper = ({
  condition,
  children,
}: {
  condition: boolean;
  children: ReactNode;
}) =>
  condition ? (
    <span className="inline-flex gap-2 flex-wrap items-baseline">
      {children}
    </span>
  ) : (
    <>{children}</>
  );
