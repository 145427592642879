import { PageHandler } from "duck/graph/PageHandler";
import { StructuredTool, tool } from "@langchain/core/tools";

import { VIN_VIEW_SERVICE_RECOMMENDATIONS_TAB_KEY } from "pages/VINView/constants";

import {
  GoToVinViewTabWithVin,
  goToVinViewTabWithVinSchema,
  setRouteValue,
} from "./utils";

const getGoToServiceRecommendationsTabTool = (
  pageHandler: PageHandler
): StructuredTool => {
  const goToServiceRecommendationsTab = async ({
    vin,
  }: GoToVinViewTabWithVin) => {
    console.debug(
      `[VIN]: Navigating to Service Recommendations tab with vin ${vin}`
    );
    await setRouteValue(vin, pageHandler);
    pageHandler.navigateToTab(VIN_VIEW_SERVICE_RECOMMENDATIONS_TAB_KEY);
    return "queued navigation to Service Recommendations tab";
  };

  return tool(goToServiceRecommendationsTab, {
    name: "queueGoToServiceRecommendationsTab",
    description:
      "Call this tool to queue a navigation to the Service Recommendations tab",
    schema: goToVinViewTabWithVinSchema,
  });
};

export default getGoToServiceRecommendationsTabTool;
