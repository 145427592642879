import { useFlags } from "launchdarkly-react-client-sdk";
import { ProductFruits } from "react-product-fruits";

import { useEmailFromJWT } from "shared/hooks";

const WORKSPACE_CODE = "fQINI0K3PTiOdDQ4";
const LANGUAGE_ISO_CODE = "en";

const ProductFruitsWrapper = () => {
  const { productFruits } = useFlags();
  const userEmail = useEmailFromJWT();

  if (!productFruits || !userEmail) return null;

  return (
    <ProductFruits
      workspaceCode={WORKSPACE_CODE}
      language={LANGUAGE_ISO_CODE}
      user={{ username: userEmail }}
    />
  );
};
export default ProductFruitsWrapper;
