import { formatNumber } from "shared/utils";

import VINTable, { VINTableProps } from "./VINTable";

const VINPreview = ({ vins }: VINTableProps) => (
  <div>
    <div className="mb-3">
      Found{" "}
      <span className="font-semibold text-xl text-blue-500">
        {formatNumber(vins.length, 0)}
      </span>{" "}
      unique rows.
    </div>
    <VINTable vins={vins} />
  </div>
);

export default VINPreview;
