import {
  CountResponse,
  EntityAttribute,
  ListAttributesRequest,
} from "shared/api/api";
import { DEFAULT_ATTRIBUTES_SWR_CONFIG } from "shared/api/constants";
import { useAPI, usePaginatedAPI } from "shared/api/hooks";

import * as api from "./api";

// ---------
export const useListServicePlans = (args: api.ListServicePlansRequest) =>
  usePaginatedAPI<typeof args, api.ServicePlan[]>(
    api.listServicePlansRequestURI,
    args
  );

export const useListServicePlansCount = (args: api.ListServicePlansRequest) =>
  useAPI<typeof args, CountResponse>(api.listServicePlansCountRequestURI, args);

export interface ServicePlanCustomAttribute {
  ID: string;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;
  name: string;
  type: string;
  parameters: {
    eventType?: string;
    eventFilter?: string;
  };
}

export const useServicePlan = (args: api.GetServicePlanRequest) =>
  useAPI<typeof args, api.ServicePlan>(api.getServicePlanRequestURI, args);

export const useServicePlanAttributes = (args: ListAttributesRequest) =>
  useAPI<typeof args, EntityAttribute[]>(
    api.listServicePlansAttributesRequestURI,
    args,
    DEFAULT_ATTRIBUTES_SWR_CONFIG
  );
