import Input from "features/ui/Input";

interface Props {
  collectionName: string;
  onCollectionNameChange: (str: string) => void;
  nameValid: boolean;
}

const Step1 = ({
  collectionName,
  onCollectionNameChange,
  nameValid,
}: Props) => {
  return (
    <div className="max-w-xs pt-2">
      <Input
        value={collectionName}
        label="Name your new collection"
        placeholder="Collection name"
        testId="input-collection-name"
        onChange={(e) => onCollectionNameChange(e.target.value)}
        fullWidth
      />
      {collectionName.length > 0 && !nameValid && (
        <div className="text-red-400 text-xs">
          Collection name should be between 5 and 120 characters.
        </div>
      )}
    </div>
  );
};

export default Step1;
