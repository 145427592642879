import classNames from "classnames";
import { Config, usePopperTooltip } from "react-popper-tooltip";

import { TestProps } from "shared/types";

const DEFAULT_INTERACTIVE = true;
const DEFAULT_HIDE_DELAY_MS = 50;
const DEFAULT_TRIGGER = "hover";

interface BaseTooltipProps {
  children: React.ReactElement | string;
  content?: string | JSX.Element;
  fixed?: boolean;
}

export type TooltipProps = BaseTooltipProps & Config & TestProps;

const Tooltip = ({
  children,
  content,
  testId,
  fixed,
  ...otherPopperProps
}: TooltipProps) => {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({
    trigger: DEFAULT_TRIGGER,
    interactive: DEFAULT_INTERACTIVE,
    delayHide: DEFAULT_HIDE_DELAY_MS,
    ...otherPopperProps,
  });

  const { trigger } = otherPopperProps;

  return (
    <>
      <div
        ref={setTriggerRef}
        className={classNames("inline-flex hover:opacity-80", {
          "cursor-pointer": trigger === "click",
        })}
        data-testid={testId ? `${testId}-children` : undefined}
      >
        {children}
      </div>
      {visible && content && (
        <div
          ref={setTooltipRef}
          role="tooltip"
          data-testid={testId}
          {...getTooltipProps({
            className: classNames("tooltip-container z-1 max-w-md !z-[999]", {
              "!fixed": fixed,
            }), // default is 9999 which is too high (MUI Autocomplete has ~1000)
          })}
        >
          <div {...getArrowProps({ className: "tooltip-arrow" })} />
          {content}
        </div>
      )}
    </>
  );
};

export default Tooltip;
