import { useState } from "react";

import { Step } from "features/ui/Stepper";
import StepperModal from "features/ui/StepperModal";

const CTA_TEXT = "Tutorial";

interface Props {
  steps: Step[];
}

const TutorialCTA = ({ steps }: Props) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <div className="flex">
        <button
          className="self-center hover:text-gray-500 px-3 py-2 text-sm"
          onClick={() => setModalOpen(true)}
        >
          {CTA_TEXT}
        </button>
      </div>
      <StepperModal
        steps={steps}
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
      />
    </>
  );
};

export default TutorialCTA;
