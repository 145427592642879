import React from "react";
import Skeleton from "react-loading-skeleton";

import { useGetIssueClaimStatistics } from "shared/api/issues/hooks";
import { IssueTypes, IssueVehiclePopulation } from "shared/types";
import {
  roundNonZeroToNDecimals,
  roundPercentageToNDecimals,
} from "shared/utils";

import IssueStatistics, { LabelTexts } from "pages/Issues/IssueStatistics";
import { getBaseAPIRoute, getIssueCombinedID } from "pages/Issues/utils";

import APIError from "features/ui/APIError";

interface Props {
  issue: IssueTypes;
  population: IssueVehiclePopulation;
  withComparisonPopulation: boolean;
}
const IssueClaimsPopulationStatistics = ({
  issue,
  population,
  withComparisonPopulation,
}: Props) => {
  const { updatedAt } = issue;

  const { data, isLoading, error } = useGetIssueClaimStatistics({
    IDs: getIssueCombinedID(issue),
    baseRoute: getBaseAPIRoute(issue),
    vehiclePopulation: population,
    updatedAt,
  });

  if (isLoading) {
    return <Skeleton height={200} />;
  }

  if (error) {
    return <APIError error={error} />;
  }

  if (!data) {
    return null;
  }

  const {
    claimCount,
    IPTV,
    distinctAffectedVehicles,
    percentageOfVehiclesAffected,
  } = data;

  const labelTexts: LabelTexts[] = [
    {
      mainValue: String(claimCount),
      description: "Total Claims",
    },
    {
      mainValue: `${distinctAffectedVehicles}
        ${
          percentageOfVehiclesAffected !== null &&
          percentageOfVehiclesAffected > 0
            ? ` (${roundPercentageToNDecimals(percentageOfVehiclesAffected)}%)`
            : ``
        }
      `,
      description: "distinct vehicles experienced a claim",
    },
    {
      mainValue:
        IPTV !== null ? `${roundNonZeroToNDecimals(IPTV)}/1000` : undefined,
      description: "Claims per Vehicle",
    },
  ];

  return (
    <IssueStatistics
      withComparisonPopulation={withComparisonPopulation}
      population={population}
      labelTexts={labelTexts}
    />
  );
};

export default IssueClaimsPopulationStatistics;
