import {
  APIListValuesRequest,
  APIListValuesResponse,
  APIPaginatedRequest,
  getFetcher,
  ListAttributesRequest,
} from "shared/api/api";
import { clientV1 } from "shared/api/axios";
import { createURL } from "shared/api/utils";
import { MileageUnit } from "shared/types";

const SENSORS_BASE_ROUTE = "sensors";

export type SensorType = "point-value" | "state";

export interface Sensor {
  ID: string;
  displayName: string;
  type: SensorType;
  createdAt: string;
  updatedAt: string;
  description: string | null;
  unit: string | null;
  valueLabels: Record<string | number, string> | null;
  published: boolean;
}

export interface ListSensorsRequest extends APIPaginatedRequest {}

export const listSensorsRequestURI = (params: ListSensorsRequest): string =>
  clientV1.getUri({
    method: "GET",
    url: createURL([SENSORS_BASE_ROUTE]),
    params,
  });

export const getSensors = (args: ListSensorsRequest) =>
  getFetcher<Sensor[]>(listSensorsRequestURI(args));

const listSensorsReadingsValuesRequestURI = ({
  fieldName,
  ...otherParams
}: APIListValuesRequest): string =>
  clientV1.getUri({
    method: "GET",
    url: createURL([SENSORS_BASE_ROUTE, "readings", "values", fieldName]),
    params: otherParams,
  });

export const listSensorsReadingValues = (args: APIListValuesRequest) =>
  getFetcher<APIListValuesResponse>(listSensorsReadingsValuesRequestURI(args));

export const getSensorsReadingsTimelineURI = ({
  skipRequest,
  ...params
}: SensorReadingsTimelineRequest): string =>
  skipRequest
    ? ""
    : clientV1.getUri({
        method: "GET",
        url: createURL([SENSORS_BASE_ROUTE, "readings", "timeline"]),
        params,
      });

export const sensorReadingsTimelineGroupingOptions = [
  "day",
  "hour",
  "none",
] as const;
export type SensorReadingsTimelineGrouping =
  (typeof sensorReadingsTimelineGroupingOptions)[number];

export interface SensorReadingsTimelineRequest {
  mileageUnit: MileageUnit;
  sensorReadingsFilter?: string;
  grouping?: SensorReadingsTimelineGrouping;
  skipRequest?: boolean;
}

export interface SensorReadingTimelineEntry {
  sensorID: string;
  VIN: string;
  time: string;
  value: number;
  count: number;
  trigger: string | null;
}

export interface SensorReadingTimeline {
  data: SensorReadingTimelineEntry[];
  metadata?: Sensor[];
}

export const listSensorReadingAttributesRequestURI = (
  params: ListAttributesRequest
): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([SENSORS_BASE_ROUTE, "readings", "attributes"]),
    params,
  });
