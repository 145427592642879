import { BreadcrumbMatch } from "use-react-router-breadcrumbs";

import { useAlertDefinition } from "shared/api/alertDefinitions/hooks";

interface Props {
  match: BreadcrumbMatch;
}

const AlertDefinitionBreadcrumbs = ({ match }: Props) => {
  const { data, isLoading, error } = useAlertDefinition({
    ID: match.params.id as string,
  });

  if (isLoading) {
    return <>...</>;
  }

  if (data) return <>{data.name}</>;

  if (error && match.params.id) {
    return <>{match.params.id}</>;
  }

  return <></>;
};

export default AlertDefinitionBreadcrumbs;
