import { BreadcrumbMatch } from "use-react-router-breadcrumbs";

import { useFailureMode as useFailureModeV0 } from "shared/api/v0_failureModes/hooks";

interface Props {
  match: BreadcrumbMatch;
}

const FailureModeBreadcrumb = ({ match }: Props) => {
  const {
    data: failureMode,
    isLoading,
    error,
  } = useFailureModeV0({
    id: match.params.id as string,
  });

  if (isLoading) {
    return <></>;
  }

  if (error && match.params.id) {
    return <>{match.params.id}</>;
  }

  return <>{failureMode?.name}</>;
};

export default FailureModeBreadcrumb;
