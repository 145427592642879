import { toDate } from "date-fns-tz";

import { EntityAttribute, VehicleAgeTimeline } from "shared/api/api";
import { ageGranularityOptions, Granularity } from "shared/api/constants";
import {
  DEFAULT_EXPOSURE,
  MONTH_YEAR,
  MONTH_YEAR_DAY,
  NONE_EXPOSURE,
} from "shared/constants";
import { TOP_CONTRIBUTORS_GROUP_BY_ACCESSOR } from "shared/schemas/constants";
import { EventTypeEnum } from "shared/types";
import { formatDate, pluralize, PopulationLine } from "shared/utils";

import { yAxis } from "features/ui/charts/actions";
import {
  ChartAction,
  SelectedChartOptions,
} from "features/ui/charts/Actions/types";
import { GRAPH_STROKE_WIDTH } from "features/ui/charts/constants";
import { DataElement } from "features/ui/charts/types";
import {
  getAxisLabel,
  getAxisValue,
  getColor,
  getSelectedOptionId,
} from "features/ui/charts/utils";
import { SelectOption } from "features/ui/Select";
import { RowData, SchemaEntry } from "features/ui/Table";
import { DataType } from "features/ui/Table/TableBodyCell";

import { mapByVehicleAgeExposureBuckets } from "./hooks";
import { ByVehicleAgeData } from "./types";

/**
 * Analytics pages: Claims utils
 */
export const getClaimsChartOptions = (
  yAxisOptions: SelectOption[]
): ChartAction[] => [
  {
    id: "y",
    title: yAxis,
    type: "dropdownSelect",
    options: yAxisOptions,
  },
];

export const claimsActionIds = ["y"] as const;

export type ClaimsActionIdType = (typeof claimsActionIds)[number];

export const vinViewTimelineActionIds = ["legend"] as const;

export type VinViewTimelineActionIdType =
  (typeof vinViewTimelineActionIds)[number];

/**
 * Analytics pages: By Vehicle Age utils
 */
export const formatDateBasedOnGranularity = (
  date: string,
  granularity: string
) =>
  granularity === "week"
    ? formatDate(date, MONTH_YEAR_DAY, true)
    : formatDate(date, MONTH_YEAR, true);

export const getByVehicleAgeChartOptions = (
  yAxisOptions: SelectOption[],
  xAxisOptions: SelectOption[],
  exposures: SelectOption[]
): ChartAction[] => [
  {
    id: "y",
    title: yAxis,
    type: "dropdownSelect",
    options: yAxisOptions,
  },
  {
    id: "x",
    title: "X-axis",
    type: "dropdownSelect",
    options: xAxisOptions,
    defaultOptionId: "vehicleManufacturedAt",
  },
  {
    id: "granularity",
    title: "Granularity",
    type: "dropdownSelect",
    options: ageGranularityOptions,
  },
  {
    id: "exposure",
    title: "Exposure",
    type: "dropdownSelect",
    options: exposures,
    defaultOptionId: DEFAULT_EXPOSURE,
  },
];

export const byVehicleAgeActionIds = [
  "y",
  "x",
  "granularity",
  "exposure",
] as const;

export type ByVehicleAgeActionIdType = (typeof byVehicleAgeActionIds)[number];

export const generateByVehicleAgeYAxisLines = (
  data: VehicleAgeTimeline[],
  byVehicleAgeData: ByVehicleAgeData
): PopulationLine[] => {
  const { yAxisKey, byVehicleAgeBirthday } = byVehicleAgeData;
  const uniqueIDs = Array.from(
    new Set(data.map(({ exposureBucket }) => exposureBucket))
  ).sort((a, b) => a - b);

  return uniqueIDs.map((exposureBucket: number, index: number) => {
    return {
      color: getColor(index),
      label: exposureBucket.toString(),
      dashed: false,
      key: `${yAxisKey}-${byVehicleAgeBirthday}-${exposureBucket}`,
      width: GRAPH_STROKE_WIDTH,
      dot: true,
    };
  });
};

export const prepareByVehicleAgeDataByTimestamp = (
  data: VehicleAgeTimeline[],
  byVehicleAgeData: ByVehicleAgeData
): DataElement[] => {
  const { yAxisKey, byVehicleAgeBirthday } = byVehicleAgeData;
  return (
    data
      .map((data) => {
        const { birthdayBucket, exposureBucket, ...otherData } = data;
        const keyYAxis = `${yAxisKey}-${byVehicleAgeBirthday}-${exposureBucket}`;

        const ts = toDate(birthdayBucket).getTime();

        return {
          [keyYAxis]: data[yAxisKey as keyof VehicleAgeTimeline],
          ts,
          exposureBucket,
          [exposureBucket]: otherData,
          byVehicleAgeData,
        };
      })
      // we would like to have only one entry per timestamp, so recharts does not go mad
      .reduce((acc: DataElement[], value: DataElement) => {
        const i = acc.findIndex(({ ts }) => ts === value.ts);
        if (i > -1) {
          acc[i] = { ...acc[i], ...value };
        } else {
          acc.push(value);
        }
        return acc;
      }, [])
  );
};

export const prepareVehiclesByAgeTableSchemaAndData = (
  data: VehicleAgeTimeline[],
  showCosts: boolean,
  granularity: Granularity,
  buildDateLabel: string,
  entity: EventTypeEnum
) => {
  const newData: RowData[] = [];
  const exposureList: number[] = [];

  // rewrite the below using reduce to make it nicer..
  data.forEach(
    ({
      birthdayBucket,
      cumulativeIPTV,
      cumulativeCPV,
      numVehicles,
      numEvents,
      exposureBucket,
    }) => {
      exposureList.push(exposureBucket);

      const currentRow = newData.find(
        (row) => row.birthdayBucket === birthdayBucket
      );

      if (currentRow) {
        currentRow[`IPTV_${exposureBucket}`] = cumulativeIPTV;
        currentRow[`CPV_${exposureBucket}`] = cumulativeCPV;
        currentRow[`numVehicles_${exposureBucket}`] = numVehicles;
        currentRow[`numEvents_${exposureBucket}`] = numEvents;
      } else {
        newData.push({
          birthdayBucket,
          [`IPTV_${exposureBucket}`]: cumulativeIPTV,
          [`CPV_${exposureBucket}`]: cumulativeCPV,
          [`numVehicles_${exposureBucket}`]: numVehicles,
          [`numEvents_${exposureBucket}`]: numEvents,
        });
      }
    }
  );

  const uniqueExposureValues = new Set(exposureList);
  const exposureValuesArray = Array.from(uniqueExposureValues).sort(
    (a, b) => a - b
  );

  const exposureSchemaEntries = exposureValuesArray
    .map((exposureBucket) => {
      return [
        {
          accessor: `IPTV_${exposureBucket}`,
          label: `IPTV`,
          dataType: DataType.NUMBER,
          decimals: 2,
          minDecimals: 2,
        },
        showCosts && {
          accessor: `CPV_${exposureBucket}`,
          label: `CPV`,
          dataType: DataType.NUMBER,
          decimals: 2,
          minDecimals: 2,
        },
        {
          accessor: `numVehicles_${exposureBucket}`,
          label: `Vehicles`,
          dataType: DataType.NUMBER,
        },
        {
          accessor: `numEvents_${exposureBucket}`,
          label: pluralize(entity),
          dataType: DataType.NUMBER,
        },
      ].filter(Boolean);
    })
    .flat() as SchemaEntry[];

  const schema: SchemaEntry[] = [
    {
      accessor: "birthdayBucket",
      label: buildDateLabel,
      dataType:
        granularity === Granularity.MONTH
          ? DataType.DATE_YEAR_MONTH
          : DataType.DATE_YEAR_MONTH_DAY,
    },
    ...exposureSchemaEntries,
  ];

  return {
    data: newData,
    schema,
    uniqueExposureValues,
  };
};

/**
 * Analytics pages: Top Contributors utils
 */
export const topContributorActionIds = [
  "y",
  "exposure",
  "exposureBucket",
] as const;

export type TopContributorActionIdType =
  (typeof topContributorActionIds)[number];

const getFirstBucketId = (currentExposureBuckets: SelectOption[]) =>
  currentExposureBuckets.length > 0
    ? (currentExposureBuckets[0].id as string)
    : "";

export const buildNewTopContributorsSelectedOptions = (
  selectedOptions: SelectedChartOptions[],
  currentExposureBuckets: SelectOption[]
) => {
  if (selectedOptions.find(({ id }) => id === "exposureBucket")) {
    return selectedOptions.map((option) =>
      option.id === "exposureBucket"
        ? { ...option, optionId: getFirstBucketId(currentExposureBuckets) }
        : option
    );
  }

  return [
    ...selectedOptions,
    {
      id: "exposureBucket",
      optionId: getFirstBucketId(currentExposureBuckets),
    },
  ];
};

export const prepareTopContributorsData = <
  T extends { groupByAttributeValue: string | number | boolean | null },
>(
  data: T[],
  attribute: keyof T
) =>
  data
    .map((tc) => {
      return {
        dimension: tc[attribute],
        // we use .toString() so that boolean values are presented correctly in the BarChart
        [TOP_CONTRIBUTORS_GROUP_BY_ACCESSOR]:
          tc[TOP_CONTRIBUTORS_GROUP_BY_ACCESSOR] === null
            ? null
            : tc[TOP_CONTRIBUTORS_GROUP_BY_ACCESSOR].toString(),
      };
    })
    .sort(({ dimension: dimensionA }, { dimension: dimensionB }) => {
      // nulls sort after anything else
      if (dimensionA === null) {
        return 1;
      }
      if (dimensionB === null) {
        return -1;
      }

      if (typeof dimensionA === "string" && typeof dimensionB === "string") {
        return dimensionA.localeCompare(dimensionB);
      }

      return (dimensionB as number) - (dimensionA as number);
    });

export const getTopContributorsChartActions = (
  yAxisOptions: SelectOption[],
  exposures: SelectOption[],
  exposureBuckets: SelectOption[],
  selectedExposure: string
): ChartAction[] =>
  [
    {
      id: "y",
      title: "Y Axis",
      type: "dropdownSelect",
      options: yAxisOptions,
    },
    {
      id: "exposure",
      title: "Exposure",
      type: "dropdownSelect",
      options: exposures,
      defaultOptionId: selectedExposure,
    },
    selectedExposure !== NONE_EXPOSURE && {
      id: "exposureBucket",
      title: "Less than or equal to",
      type: "dropdownSelect",
      options: exposureBuckets,
    },
  ].filter(Boolean) as ChartAction[];

export const getDefaultTopContributorsExposure = (
  pageKey: string,
  availableExposures: SelectOption[]
) => {
  const predefinedChartActions = localStorage.getItem(pageKey);
  if (!predefinedChartActions) {
    return NONE_EXPOSURE;
  }
  const availableExposureIds = availableExposures.map(
    ({ id }) => id
  ) as string[];

  try {
    const parsedChartActions = JSON.parse(
      predefinedChartActions
    ) as SelectedChartOptions[];
    if (parsedChartActions.length === 0) {
      return NONE_EXPOSURE;
    }

    const selectedExposure = parsedChartActions.find(
      ({ id }) => id === "exposure"
    );
    if (selectedExposure) {
      const exposureId = selectedExposure.optionId as string;
      return availableExposureIds.includes(exposureId)
        ? exposureId
        : NONE_EXPOSURE;
    }
  } catch (error) {
    console.error(
      `Could not parse local storage key '${pageKey}', value: ${predefinedChartActions}`
    );
    return NONE_EXPOSURE;
  }

  return NONE_EXPOSURE;
};

export const getDefaultTopContributorChartActions = (
  attributes: EntityAttribute[] | undefined,
  pageKey: string,
  yAxisOptions: SelectOption[],
  exposures: SelectOption[]
): ChartAction[] => {
  const defaultExposure = getDefaultTopContributorsExposure(pageKey, exposures);
  const defaultBuckets = mapByVehicleAgeExposureBuckets(
    attributes,
    defaultExposure
  );
  return getTopContributorsChartActions(
    yAxisOptions,
    exposures,
    defaultBuckets,
    defaultExposure
  );
};

export const getTopContributorsChartTitle = (
  actions: ChartAction[],
  selectedOptions: SelectedChartOptions[],
  exposure?: string,
  exposureBucket?: number
) => {
  const yAxisKey = getSelectedOptionId(selectedOptions, "y");
  const yAxisLabel = getAxisLabel(actions, "y", yAxisKey);
  const byVehicleAgeExposureValue = exposure
    ? getAxisValue(actions, "exposure", exposure)
    : "";
  const titleSuffix = exposure
    ? `by ${exposureBucket} ${byVehicleAgeExposureValue}`
    : "";
  return [yAxisLabel, titleSuffix].filter(Boolean).join(" ");
};
