import { useState } from "react";
import classNames from "classnames";
import { Alert } from "@mui/material";

import { DATE_FILTER_GENERIC } from "shared/filterDefinitions";
import { useVehiclesFiltersSchema } from "shared/hooks";

import { MAX_VEHICLES_PREVIEW } from "pages/CustomSignalEvents/constants";
import { SignalEventStudioState } from "pages/CustomSignalEvents/utils";
import { VEHICLES_FILTER_LABEL } from "pages/SignalEventsAnalytics/constants";

import DropdownSelect from "features/ui/DropdownSelect";
import Filters from "features/ui/Filters";
import FiltersSummary, {
  ViewFiltersButton,
} from "features/ui/Filters/FiltersSummary";
import FilterSelector from "features/ui/Filters/FilterWizard/FilterSelector";
import { getPendingFiltersKey } from "features/ui/Filters/FilterWizard/utils";
import { useFilterSortState } from "features/ui/Filters/hooks";
import { UseFilterSortState } from "features/ui/Filters/types";
import { getFilterLabel } from "features/ui/Filters/utils";
import FormSection from "features/ui/FormSection";

import SignalEventPreviewData from "./SignalEventPreviewData";

interface Props {
  signalEventStudioState: SignalEventStudioState;
  dateFilterSortState: UseFilterSortState;
  splitScreen?: boolean;
  className?: string;
}

const VEHICLES_FILTER_PAGE_KEY = "custom_signal_events_vehicles_filter";

const SignalEventPreview = ({
  signalEventStudioState,
  dateFilterSortState,
  splitScreen,
  className,
}: Props) => {
  const [filterSummaryOpen, setFilterSummaryOpen] = useState(false);

  const [vehicleFilterDropdownOpen, setVehicleFilterDropdownOpen] =
    useState(false);
  const vehiclesFiltersSchema = useVehiclesFiltersSchema();

  const pendingVehicleFiltersKey = getPendingFiltersKey(
    VEHICLES_FILTER_PAGE_KEY
  );
  const vehiclesFilterSortState = useFilterSortState({
    pageKey: VEHICLES_FILTER_PAGE_KEY,
    pendingFiltersLocalStorageKey: pendingVehicleFiltersKey,
  });

  const vehiclePopulationFilterLabel = getFilterLabel(
    VEHICLES_FILTER_LABEL,
    vehiclesFilterSortState.filters
  );
  const isAdvancedVehiclesEditor =
    vehiclesFilterSortState.isAdvancedFilterEditor;

  const { dateParam, dateType, isValidForm, formValidationErrors } =
    signalEventStudioState;

  const {
    filters: dateFilters,
    initialized: dateFiltersInitialized,
    manageFilterChange: manageOnDateFiltersChange,
  } = dateFilterSortState;

  return (
    <>
      <div className="relative">
        <div className="text-gray-600 text-sm mb-4 max-w-4xl">
          Preview occurrences of this Custom Signal Event in selection of
          vehicles over a period of time.
        </div>
      </div>
      <FormSection
        data-testid="vehicle-filters-section"
        title="Vehicles to Preview"
        text={`At most ${MAX_VEHICLES_PREVIEW} vehicles can be previewed. If more than that are returned, ${MAX_VEHICLES_PREVIEW} will be randomly selected.`}
        className={className}
      >
        <div className="flex space-x-3">
          <DropdownSelect
            testId="vehicles-filters-dropdown"
            label={vehiclePopulationFilterLabel}
            open={vehicleFilterDropdownOpen}
            onOpen={setVehicleFilterDropdownOpen}
            hasAdvancedFilters={isAdvancedVehiclesEditor}
            content={
              <FilterSelector
                schema={vehiclesFiltersSchema}
                filterSortState={vehiclesFilterSortState}
                title={VEHICLES_FILTER_LABEL}
                testId="vehicles-filters"
                pendingFiltersKey={pendingVehicleFiltersKey}
                baseEntityText="Vehicles"
                onCloseFilters={() => {
                  setVehicleFilterDropdownOpen(false);
                }}
                initialIsAdvancedFilter={isAdvancedVehiclesEditor}
              />
            }
          />
          <ViewFiltersButton
            open={filterSummaryOpen}
            onClick={() => setFilterSummaryOpen(true)}
            onClose={() => setFilterSummaryOpen(false)}
          />
          <Filters
            initialized={dateFiltersInitialized}
            schema={[
              DATE_FILTER_GENERIC({
                fieldName: dateParam,
                label: "Date",
                filterDataType: dateType,
              }),
            ]}
            onFilterChange={manageOnDateFiltersChange}
            filters={dateFilters}
            horizontal
          />
        </div>
        <FiltersSummary
          open={filterSummaryOpen}
          filterStates={[
            {
              name: VEHICLES_FILTER_LABEL,
              filters: vehiclesFilterSortState.filters,
              schema: vehiclesFiltersSchema,
            },
          ]}
        />
      </FormSection>
      {!isValidForm && splitScreen && (
        <Alert
          severity="error"
          data-testid="validation-message"
          className={classNames("w-[65rem]", className)}
        >
          <div className="font-bold mb-1">Validation errors</div>
          {formValidationErrors.map((error) => (
            <div key={error}>{error}</div>
          ))}
        </Alert>
      )}
      {isValidForm && (
        <SignalEventPreviewData
          signalEventStudioState={signalEventStudioState}
          vehiclesFilterSortState={vehiclesFilterSortState}
          dateFilterSortState={dateFilterSortState}
          dateType={dateType}
          className={className}
        />
      )}
    </>
  );
};

export default SignalEventPreview;
