import { useState } from "react";
import qs from "qs";
import Skeleton from "react-loading-skeleton";
import { generatePath, Link } from "react-router-dom";

import { useListVehiclesCount } from "shared/api/vehicles/hooks";
import { useVehiclesFiltersSchema } from "shared/hooks";

import CreateIssue from "pages/Issues/CreateIssue/CreateIssue";
import { VEHICLES_PAGE_KEY } from "pages/Vehicles/constants";

import APIError from "features/ui/APIError";
import Button from "features/ui/Button";
import FilterQueryPresentation from "features/ui/Filters/FilterBuilder/FilterQueryPresentation";
import { getFiltersKey } from "features/ui/Filters/utils";
import Label from "features/ui/Label";

import { routes } from "services/routes";

import CardActions, { CreateIssueProps } from "./CardActions";

interface Props {
  canEdit: boolean;
  populationFilter?: string;
  createIssueProps?: CreateIssueProps;
  buttonLabel?: string;
}

const LOADER_HEIGHT_PX = 200;

interface BodyProps {
  populationFilter: string;
  canEdit: boolean;
  createIssueProps?: CreateIssueProps;
}
const vehiclesPath = generatePath(routes.vehicles);
const vehiclesPageFilterKey = getFiltersKey(VEHICLES_PAGE_KEY);

const vehiclesLink = (populationFilter: string) => (
  <Link
    to={{
      pathname: vehiclesPath,
      search: qs.stringify(
        {
          [vehiclesPageFilterKey]: populationFilter,
        },
        { arrayFormat: "indices" }
      ),
    }}
    className="text-metabase-blue hover:underline sm:text-sm"
    data-testid="view-vehicles-link"
  >
    View vehicles
  </Link>
);

const CardBody = ({
  populationFilter,
  canEdit,
  createIssueProps,
}: BodyProps) => {
  const { isLoading, data, error } = useListVehiclesCount({
    filter: populationFilter,
  });

  const vehiclesSchema = useVehiclesFiltersSchema();

  if (error) {
    return <APIError error={error} />;
  }

  if (isLoading) {
    return <Skeleton height={LOADER_HEIGHT_PX} />;
  }

  if (!data) {
    return (
      <div className="py-4 text-gray-400 text-sm">No population data.</div>
    );
  }

  return (
    <>
      <div className="flex mb-3 justify-between space-x-5">
        <div className="w-1/4 text-center">
          <Label
            text="Total vehicles"
            className="text-viaduct-black !pl-0 !text-base"
          />
          <Label
            text={String(data.count)}
            className="text-viaduct-black !pl-0 !text-3xl"
          />
        </div>
        <div className="w-full">
          <Label
            text="Population definition"
            className="text-viaduct-black !pl-0 !text-base"
          />
          <FilterQueryPresentation
            filter={populationFilter}
            tableSchema={vehiclesSchema}
          />
        </div>
        <CardActions canEdit={canEdit} createIssueProps={createIssueProps} />
      </div>
      <div className="flex justify-end mr-2">
        {vehiclesLink(populationFilter)}
      </div>
    </>
  );
};

interface NoPopulationProps {
  canEdit?: boolean;
  createIssueProps?: CreateIssueProps;
  buttonLabel?: string;
}

const DEFAULT_BUTTON_LABEL = "+ Comparison Population";

export const NoPopulationBody = ({
  canEdit,
  createIssueProps,
  buttonLabel = DEFAULT_BUTTON_LABEL,
}: NoPopulationProps) => {
  const [openPopup, setOpenPopup] = useState(false);

  if (!canEdit || !createIssueProps) {
    return <div className="text-gray-400 text-sm">No data.</div>;
  }

  return (
    <>
      <div className="flex mb-3">
        <Button
          label={buttonLabel}
          color="primary"
          onClick={() => setOpenPopup(true)}
        />
      </div>
      {openPopup && (
        <CreateIssue
          {...createIssueProps}
          showButton={false}
          defaultOpen={true}
          onClose={() => setOpenPopup(false)}
          onFinally={() => setOpenPopup(false)}
        />
      )}
    </>
  );
};

const CardPopulation = ({
  canEdit,
  populationFilter,
  createIssueProps,
  buttonLabel,
}: Props) => {
  if (!populationFilter) {
    return (
      <NoPopulationBody
        canEdit={canEdit}
        createIssueProps={createIssueProps}
        buttonLabel={buttonLabel}
      />
    );
  }

  return (
    <CardBody
      populationFilter={populationFilter}
      canEdit={canEdit}
      createIssueProps={createIssueProps}
    />
  );
};

export default CardPopulation;
