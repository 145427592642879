import { format } from "date-fns";
import { TooltipProps } from "recharts";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";

import { SensorReadingsTimelineGrouping } from "shared/api/sensors/api";
import { DATE_WITH_TIME_FORMAT, SHORT_DATE_FORMAT } from "shared/constants";

interface CustomProps {
  xAxisLabel: string;
  grouping: SensorReadingsTimelineGrouping;
}

const formatValue = (
  value: any,
  name: any,
  xAxisLabel: string,
  grouping: SensorReadingsTimelineGrouping
) =>
  name === xAxisLabel
    ? format(
        value,
        grouping === "none" ? DATE_WITH_TIME_FORMAT : SHORT_DATE_FORMAT
      )
    : value;

const EventsTimelineChartTooltip = ({
  payload,
  xAxisLabel,
  grouping,
}: TooltipProps<ValueType, NameType> & CustomProps) => (
  <div className="flex flex-col bg-white shadow px-2 py-3 text-xs">
    {payload?.map(({ name, value }) => (
      <div key={name}>
        <span className="font-bold">{name}: </span>
        <span>{formatValue(value, name, xAxisLabel, grouping)}</span>
      </div>
    ))}
  </div>
);

export default EventsTimelineChartTooltip;
