import { IssueChart, IssueChartType } from "shared/types";

import LineChart from "features/ui/charts/LineChart";
import { DataElement, YAxisLine } from "features/ui/charts/types";

import { getKeysAndLabels, getTooltipProps, getXAxisProps } from "./utils";

const MARGIN = { right: 3 };

interface Props {
  data: DataElement[];
  xAxisLabel?: string;
  yAxisLabel: string;
  yAxisLines: YAxisLine[];
  chart: IssueChart;
  type: IssueChartType;
  syncId?: number | string;
  tooltipProps?: Record<string, any>;
  exposure?: string;
}

const IssueLineChart = ({
  data,
  xAxisLabel,
  yAxisLabel,
  yAxisLines,
  chart,
  type,
  syncId,
  tooltipProps,
  exposure,
}: Props) => {
  const {
    xAxisKey,
    xAxisLabel: xLabel,
    tooltipLabel,
  } = getKeysAndLabels(type, chart, xAxisLabel, exposure);
  const xAxisProps = getXAxisProps(type, data, exposure);
  const staticTooltipProps = getTooltipProps(type, tooltipLabel);

  return (
    <LineChart
      height={400}
      width="100%"
      data={data}
      xAxisKey={xAxisKey}
      xAxisLabel={xLabel}
      xAxisProps={xAxisProps}
      yAxisLabel={yAxisLabel}
      yAxisLines={yAxisLines}
      tooltipProps={{
        ...staticTooltipProps,
        ...tooltipProps,
      }}
      margin={MARGIN}
      syncId={syncId}
      enableZoom={true}
    />
  );
};

export default IssueLineChart;
