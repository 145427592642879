import { useState } from "react";
import { toast } from "react-toastify";

import {
  SuggestedIssue,
  updateSuggestedIssue,
} from "shared/api/suggestedIssues/api";
import { mutateMultipleSWRRequestKeys } from "shared/utils";

import { IssueRequestKeys } from "pages/Issues/types";

import Button from "features/ui/Button";

interface Props extends IssueRequestKeys {
  issue: SuggestedIssue;
}
const PublishIssueButton = ({ issue, issueRequestKeys }: Props) => {
  const [saving, setSaving] = useState(false);

  const { ID, published } = issue;
  const label = published ? "Unpublish" : "Publish";

  const publishSuggestedIssue = () => {
    setSaving(true);
    const payload = {
      ID,
      published: !published,
    };

    updateSuggestedIssue(payload)
      .then(() => {
        if (issueRequestKeys) {
          mutateMultipleSWRRequestKeys(issueRequestKeys);
        }
        toast.success(`Suggested issue ${label.toLowerCase()}ed`);
      })
      .catch((error) => {
        console.log(error);
        toast.error(`${label}ing suggested issue failed`);
      })
      .finally(() => {
        setSaving(false);
      });
  };

  return (
    <Button
      color="secondary"
      label={label}
      onClick={publishSuggestedIssue}
      disabled={saving}
      testId={`suggested-issue-${label.toLowerCase()}-cta`}
    />
  );
};

export default PublishIssueButton;
