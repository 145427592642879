import { Link } from "react-router-dom";

interface Props {
  path: string;
}

const GoToClaimCellData = ({ path }: Props) => {
  return (
    <Link to={path} className="text-metabase-blue hover:underline">
      Go to Claim
    </Link>
  );
};

export default GoToClaimCellData;
