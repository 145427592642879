import { EventTypeEnum } from "shared/types";

import { useEventTypeOptions } from "pages/CustomSignalEvents/hooks";

import DropdownSelect from "features/ui/DropdownSelect/DropdownSelect";

import { FunctionInputProps } from "./FunctionInput";

const DEFAULT_SELECT_LABEL = "Select event type";

const EventTypeSelect = ({
  attribute,
  onChange,
  config,
  disabled,
}: FunctionInputProps) => {
  const eventTypeOptions = useEventTypeOptions([EventTypeEnum.CUSTOM_RECORD]);

  const eventDropdownLabel = (selectedOptionID?: string): string => {
    return (
      (eventTypeOptions.find((option) => option.id === selectedOptionID)
        ?.value as string) || DEFAULT_SELECT_LABEL
    );
  };

  return (
    <DropdownSelect
      label={eventDropdownLabel(attribute[config.parameter] as string)}
      options={eventTypeOptions}
      onSelect={(selectedOption) =>
        onChange({
          ...attribute,
          [config.parameter]: selectedOption.id as string,
          eventFilter: undefined,
          field: undefined,
        })
      }
      disabled={disabled}
    />
  );
};

export default EventTypeSelect;
