import DropdownWithSearch from "features/ui/DropdownWithSearch/DropdownWithSearch";
import { FilterOperator, FilterType } from "features/ui/Filters/types";
import { getOperatorsSelectOptions } from "features/ui/Filters/utils";
import { SelectOption } from "features/ui/Select";

interface Props {
  type: FilterType;
  selected: SelectOption<FilterOperator>;
  onChange: (selected: SelectOption<FilterOperator>) => void;
  disableSelectFilters?: boolean;
  disableContainsFilters?: boolean;
  disableIsEmptyFilters?: boolean;
  disableStartsWithFilters?: boolean;
  disableIsNotFilteredFilters?: boolean;
  whitelistedFilterOperators?: FilterOperator[];
  fullWidth?: boolean;
  className?: string;
  disabled?: boolean;
}

const OperatorsDropdown = ({
  type,
  selected,
  onChange,
  disableSelectFilters = false,
  disableContainsFilters = false,
  disableIsEmptyFilters = false,
  disableStartsWithFilters = false,
  disableIsNotFilteredFilters = false,
  whitelistedFilterOperators,
  fullWidth = true,
  className,
  disabled,
}: Props) => {
  const data = getOperatorsSelectOptions({
    type,
    disableSelectFilters,
    disableContainsFilters,
    disableIsEmptyFilters,
    disableStartsWithFilters,
    disableIsNotFilteredFilters,
    whitelistedFilterOperators,
  });

  const disabledModified = data.length < 2 || disabled;

  return (
    <DropdownWithSearch
      options={[]}
      groupedOptions={data}
      selectedOption={selected}
      onSelectedOptionChange={onChange}
      fullWidth={fullWidth}
      disabled={disabledModified}
      testId="operators-dropdown-wrap"
      className={className}
      disableClearable
    />
  );
};
export default OperatorsDropdown;
