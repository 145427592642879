import { getOptionsQuery } from "shared/api/utils";
import { cloneObject } from "shared/utils";

import { OPERATORS_MAP } from "features/ui/Filters/constants";
import { FilterRowState } from "features/ui/Filters/FilterBuilder/types";
import {
  filterStateToFilterGroupState,
  getFiltersQuery,
} from "features/ui/Filters/FilterBuilder/utils";
import {
  FilterOperator,
  RelatesFilterState,
  SingleFilterState,
} from "features/ui/Filters/types";
import { SelectOption } from "features/ui/Select";

import { OPERATORS_WITHOUT_WINDOW_SELECTION } from "./constants";

export const relatedFiltersAreSame = (
  previousFilters?: RelatesFilterState,
  currentFilters?: RelatesFilterState,
  forAPI: boolean = true
): boolean => {
  if (!previousFilters || !currentFilters) return false;

  // are filters same from the API perspective - some filters on the FE map to same filter on API:
  // example: 'is_not_empty' and 'not_filtered' both map to 'isn' API filter so this logic does not
  // detect the difference
  if (forAPI) {
    return (
      encodeRelatesFilter(previousFilters) ===
      encodeRelatesFilter(currentFilters)
    );
  }
  return JSON.stringify(previousFilters) === JSON.stringify(currentFilters);
};

export const encodeRelatesFilter = (
  { options, filters, operator }: RelatesFilterState,
  withOperator: boolean = true
) => {
  const base = `{${getOptionsQuery(options)}}[${getFiltersQuery(filters)}]`;
  if (!withOperator) {
    return base;
  }
  return `${base}/${operator}`;
};

export const getSelectOptionValue = (
  id: string | number,
  options: SelectOption[]
): string => {
  const option = options.find((x) => x.id === id);
  return option?.value.toString() || "";
};

export const getBasicRelatesFilterFromSignalEventFilter = (
  signalEventFilter: SingleFilterState
): SingleFilterState | undefined => {
  if (!OPERATORS_MAP.relates.flat().includes(signalEventFilter.operator)) {
    return;
  }
  const relatesFilter: RelatesFilterState = {
    operator: "occurs",
    options: {
      windowSize: "30",
      windowDirection: "before",
      windowType: "days",
    },
    filters: filterStateToFilterGroupState({
      signalEventID: signalEventFilter,
    }),
  };

  return {
    relates: relatesFilter,
    values: [encodeRelatesFilter(relatesFilter)],
    operator: FilterOperator.RELATES,
  };
};

export const addRelatesFilterToSignalEventFilter = (
  signalEventFilter: SingleFilterState | undefined,
  newRelatesFilter: RelatesFilterState
): SingleFilterState => {
  const baseSignalEventFilter = signalEventFilter
    ? cloneObject(signalEventFilter)
    : {
        operator: FilterOperator.RELATES,
        values: [],
        relates: {} as RelatesFilterState,
      };

  if (!baseSignalEventFilter.relates) {
    baseSignalEventFilter.values = [];
  }

  baseSignalEventFilter.relates = cloneObject(newRelatesFilter);
  baseSignalEventFilter.values = [encodeRelatesFilter(newRelatesFilter)];

  return baseSignalEventFilter;
};

export const isRelatesFilterOperatorIsNotFiltered = (
  relatesFilterState: RelatesFilterState
) =>
  OPERATORS_WITHOUT_WINDOW_SELECTION.includes(
    (relatesFilterState.filters.children[0] as FilterRowState)
      .operator as FilterOperator
  );
