import { ClipboardEvent, KeyboardEvent, useState } from "react";
import { Autocomplete, Chip, TextField } from "@mui/material";

import { TestProps } from "shared/types";

import { FILTER_PASTE_DELIMITER } from "features/ui/Filters/constants";

export interface InputProps extends TestProps {
  onChange: (values: string[]) => void;
  values: string[];
  label: string;
  placeholder?: string;
  disabled?: boolean;
  fieldName: string;
  validationFunction?: (value: string) => boolean;
  validationErrorMessage?: string;
  caseInsensitive?: boolean;
}

const TagsInput = ({
  onChange,
  values,
  label,
  placeholder,
  disabled,
  fieldName,
  testId,
  validationFunction = () => true,
  validationErrorMessage = "Input is invalid",
  caseInsensitive = false,
}: InputProps) => {
  const [searchInput, setSearchInput] = useState("");
  const [error, setError] = useState("");

  const onPaste = ({ target, clipboardData }: ClipboardEvent) => {
    const text = clipboardData.getData("Text");
    const splitValues = text.split(FILTER_PASTE_DELIMITER);
    const newValues = values.concat(
      splitValues.filter(Boolean).filter(validationFunction)
    );

    // also unfocus the input so that message that asks the user to type something hides after paste
    (target as HTMLInputElement).blur();

    onChange(Array.from(new Set(newValues)));
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Enter") {
      // we are overriding default onChange handler as we want to keep input if validation do not pass
      event.stopPropagation();
      event.preventDefault();

      if (validationFunction(searchInput)) {
        // we only want unique values
        if (
          caseInsensitive &&
          !values
            .map((v) => v.toLowerCase())
            .includes(searchInput.toLowerCase())
        ) {
          onChange([...values, searchInput]);
        } else if (!caseInsensitive) {
          onChange(Array.from(new Set([...values, searchInput])));
        }

        setSearchInput(""); // Clear the input
      } else {
        setError(validationErrorMessage);
      }
    } else {
      setError("");
    }
  };

  return (
    <Autocomplete
      disabled={disabled}
      freeSolo
      multiple
      blurOnSelect={true}
      inputValue={searchInput}
      onInputChange={(_, newInputValue) => {
        setSearchInput(newInputValue);
      }}
      value={values}
      size="small"
      options={[]}
      onPaste={onPaste}
      // deletions are handled via onChange, while additions via handleKeyDown on input where we need validation
      onChange={(_, values: string[]) => onChange(values)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          inputProps={params.inputProps}
          onKeyDown={handleKeyDown}
          helperText={error}
          error={Boolean(error)}
          placeholder={placeholder}
          data-testid={testId}
        />
      )}
      renderTags={(value: readonly string[], getTagProps) =>
        value.map((value: string, index: number) => (
          <Chip
            variant="outlined"
            label={value}
            size="small"
            id={`${fieldName}-tags-input-${index}`}
            data-testid={`${fieldName}-tags-input-${index}`}
            {...getTagProps({ index })}
            className="autocomplete-selected-item"
            title={value}
          />
        ))
      }
    />
  );
};

export default TagsInput;
