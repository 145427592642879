import classNames from "classnames";

import { Issue } from "shared/api/issues/api";
import { useClaimsFiltersSchema } from "shared/hooks";
import { IssueTypes } from "shared/types";
import { toSnakeCase } from "shared/utils";

import { isSuggestedIssue } from "pages/Issues/utils";

import Card from "features/ui/Card";
import FilterQueryPresentation from "features/ui/Filters/FilterBuilder/FilterQueryPresentation";

import { CreateIssueProps } from "./CardActions";
import CardHeader from "./CardHeader";
import { NoPopulationBody } from "./CardPopulation";
import ClaimAnalyticsLink from "./ClaimAnalyticsLink";

interface Props {
  title?: string;
  issue: IssueTypes;
  createIssueProps?: CreateIssueProps;
  showActions?: boolean;
  cardClassNames?: string;
}

const ADD_CLAIMS_LABEL = "+ Claims Criteria";
const TITLE = "Claims Criteria";

const CardClaims = ({
  title = TITLE,
  issue,
  createIssueProps,
  showActions,
  cardClassNames,
}: Props) => {
  const claimsSchema = useClaimsFiltersSchema();
  const { claimFilter } = issue;
  const canEdit = isSuggestedIssue(issue) ? false : (issue as Issue).canEdit;
  return (
    <Card
      classNames={classNames("text-gray-700 !p-5", cardClassNames)}
      testId={toSnakeCase(title)}
    >
      <CardHeader
        title={title}
        canEdit={canEdit}
        createIssueProps={createIssueProps}
        showActions={showActions}
      />
      <div className="flex flex-col space-y-2">
        {!claimFilter && (
          <NoPopulationBody
            canEdit={canEdit}
            createIssueProps={createIssueProps}
            buttonLabel={ADD_CLAIMS_LABEL}
          />
        )}
        {claimFilter && (
          <FilterQueryPresentation
            filter={claimFilter}
            tableSchema={claimsSchema}
          />
        )}
        <ClaimAnalyticsLink issue={issue} />
      </div>
    </Card>
  );
};

export default CardClaims;
