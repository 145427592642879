import classNames from "classnames";
import Skeleton from "react-loading-skeleton";

import "react-loading-skeleton/dist/skeleton.css";

interface Props {
  rows?: number;
  cols?: number;
  dense?: boolean;
}

// if we allow many rows, then rendering gets slow as hell
const MAX_ROWS = 25;

const TableLoader = ({ rows = 5, cols = 5, dense = false }: Props) => (
  <>
    {[...Array(Math.min(MAX_ROWS, rows)).keys()].map((row) => (
      <tr key={`tr-${row}`}>
        {[...Array(cols).keys()].map((col) => (
          <td
            key={`td-${col}`}
            className={classNames({
              "py-4 px-7": !dense,
              "px-2": dense,
            })}
            data-testid={`loader-col-${row}-${col}`}
          >
            <Skeleton height={dense ? 15 : 21} />
          </td>
        ))}
      </tr>
    ))}
  </>
);

export default TableLoader;
