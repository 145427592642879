import { useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  newIssue,
  NewIssueRequest,
  updateIssue,
  UpdateIssueRequest,
} from "shared/api/issues/api";
import NewCTA from "shared/components/NewCTA";
import { useEmailFromJWT } from "shared/hooks";
import { mutateMultipleSWRRequestKeys, toTitleCase } from "shared/utils";

import { IssueRequestKeys } from "pages/Issues/types";

import { areFiltersEqual } from "features/ui/Filters/FilterBuilder/utils";
import { hasSomeFiltersAppliedFilterGroupState } from "features/ui/Filters/utils";
import { isValidEmail } from "features/ui/PermissionsDialog/utils";
import { Step } from "features/ui/Stepper";
import StepperModal, {
  ActionType,
  getTextForActionType,
} from "features/ui/StepperModal";

import { routes } from "services/routes";

import StepAtRiskPopulation from "./StepAtRiskPopulation";
import StepClaims from "./StepClaims";
import StepControlPopulation from "./StepComparisonPopulation";
import StepMetadata from "./StepMetadata";
import StepSignalEvents from "./StepSignalEvents";
import {
  ActionCallback,
  DEFAULT_DATA,
  IssueFormData,
  prepareRequestData,
} from "./utils";

const CTA_TEXT = "New Issue";

const actions: Record<ActionType, typeof newIssue | typeof updateIssue> = {
  create: newIssue,
  update: updateIssue,
};

const getCallback = <T extends ActionType>(aType: T): ActionCallback<T> => {
  return actions[aType] as ActionCallback<T>;
};

const steps: Step[] = [
  {
    label: "Define an at-risk vehicle population",
    content: StepAtRiskPopulation,
    validation: ({ atRiskPopulationFilter }: Record<string, any>) =>
      hasSomeFiltersAppliedFilterGroupState(atRiskPopulationFilter),
  },
  {
    label: "Define a comparison vehicle population (optional)",
    active: true,
    content: StepControlPopulation,
    validation: ({
      atRiskPopulationFilter,
      comparisonPopulationFilter,
    }: Record<string, any>) =>
      !areFiltersEqual(atRiskPopulationFilter, comparisonPopulationFilter),
  },
  {
    label: "Define Related Claims",
    content: StepClaims,
    validation: ({
      ID,
      claimFilter,
      signalEventOccurrencesFilter,
      onlyAllowCurrentStep,
    }: Record<string, any>) => {
      // Issue Create: when creating a new issue, user needs to go through all steps,
      // so it is perfectly fine is there is no claim filter defined
      if (!ID) {
        return true;
      }
      // Issue Update: if popup with only claim filter step is opened then we need to verify that either
      // claim filter or signal event occurrences filter is set before submitting changes
      else {
        if (onlyAllowCurrentStep) {
          return (
            hasSomeFiltersAppliedFilterGroupState(claimFilter) ||
            hasSomeFiltersAppliedFilterGroupState(signalEventOccurrencesFilter)
          );
        }
        // if issue update popup with all steps is opened, user can navigate further to next step in any case
        return true;
      }
    },
  },
  {
    label: "Define Signal Events of Interest",
    content: StepSignalEvents,
    validation: ({
      claimFilter,
      signalEventOccurrencesFilter,
    }: Record<string, any>) =>
      hasSomeFiltersAppliedFilterGroupState(claimFilter) ||
      hasSomeFiltersAppliedFilterGroupState(signalEventOccurrencesFilter),
  },
  {
    label: "Finalize Issue",
    content: StepMetadata,
    validation: ({ name, assignee }) => {
      return (
        name?.length > 4 && (assignee?.length < 1 || isValidEmail(assignee))
      );
    },
  },
];

interface Props extends IssueRequestKeys {
  action: ActionType;
  issueData?: IssueFormData;
  step?: number;
  showButton?: boolean;
  defaultOpen?: boolean;
  onlyAllowCurrentStep?: boolean; // if true, considers the given step as the last step (no navigation through steps allowed)
  onClose?: () => void;
  onFinally?: () => void;
  onSuccess?: () => void;
}

const CreateIssue = ({
  action = "create",
  issueRequestKeys,
  issueData = DEFAULT_DATA,
  step,
  showButton = true,
  defaultOpen = false,
  onlyAllowCurrentStep = action === "update",
  onClose,
  onFinally,
  onSuccess,
}: Props) => {
  const myEmail = useEmailFromJWT();

  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(defaultOpen);

  const [data, setData] = useState<IssueFormData>(issueData);

  const stepperTitle = `${toTitleCase(action)} an issue`;
  const [isLoading, setIsLoading] = useState(false);

  const onDataUpdated = (update: Partial<IssueFormData>) => {
    setData({ ...data, ...update });
  };

  const onHandleError = (err: Error) => {
    toast.error(`Saving issue failed`);
    console.log(err);
  };

  const onHandleFinally = () => {
    setData(DEFAULT_DATA);
    setModalOpen(false);
    setIsLoading(false);
    onFinally && onFinally();
  };

  const onHandleOpen = () => {
    setData(issueData);
    setModalOpen(true);
  };

  const onHandleClose = () => {
    setModalOpen(false);
    onClose && onClose();
    setData(issueData);
  };

  const onHandleSuccess = (issueID?: string) => {
    toast.success(`Issue saved successfully`);
    onSuccess && onSuccess();

    if (action === "create" && issueID) {
      navigate(generatePath(routes.issue, { id: issueID }));
    }
    if (issueRequestKeys) {
      mutateMultipleSWRRequestKeys(issueRequestKeys);
    }
  };

  const handleIssueCreateOrUpdate = () => {
    setIsLoading(true);
    const requestData = prepareRequestData(data, action, myEmail);

    if (action === "update") {
      getCallback(action)(requestData as UpdateIssueRequest)
        .then(() => {
          onHandleSuccess();
        })
        .catch(onHandleError)
        .finally(onHandleFinally);
    } else {
      getCallback(action)(requestData as NewIssueRequest)
        .then(({ data: { ID } }) => {
          onHandleSuccess(ID);
        })
        .catch(onHandleError)
        .finally(onHandleFinally);
    }
  };

  return (
    <>
      {showButton && (
        <NewCTA
          onClick={onHandleOpen}
          label={CTA_TEXT}
          testId="new-issue-cta"
        />
      )}
      <StepperModal
        steps={steps}
        disableUsingLocalStorage={true}
        step={step}
        isOpen={modalOpen}
        onClose={onHandleClose}
        nonLinear={false}
        title={stepperTitle}
        data={data}
        onDataUpdated={onDataUpdated}
        lastStepActionLabel={getTextForActionType(action)}
        lastStepCallback={handleIssueCreateOrUpdate}
        lastStepCallbackIsLoading={isLoading}
        action={action}
        onlyAllowCurrentStep={onlyAllowCurrentStep}
        closeOnOutsideClick={false}
        size="lg"
      />
    </>
  );
};

export default CreateIssue;
