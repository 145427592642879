import { useCustomLocalStorageState } from "shared/hooks";

import {
  useByVehicleAgeBirthdayOptions,
  useByVehicleAgeOptions,
  useInspectionByVehicleAgeExposureOptions,
} from "pages/hooks";
import { InspectionsFiltersProps } from "pages/InspectionAnalytics/InspectionAnalyticsTabs";
import { getByVehicleAgeChartOptions } from "pages/utils";

import { cumulativeIPTV } from "features/ui/charts/actions";
import { SelectedChartOptions } from "features/ui/charts/Actions/types";
import { getDefaultActions } from "features/ui/charts/utils";
import { SelectOption } from "features/ui/Select";

import ByVehicleAgeChart from "./ByVehicleAgeChart";
import ByVehicleAgeTable from "./ByVehicleAgeTable";

const CHART_KEY = "inspectionAnalyticsByVehicleAgeChartOptions";

const ByVehicleAge = ({
  inspectionsFilters,
  vehiclesFilters,
  onBadRequest,
}: InspectionsFiltersProps) => {
  const yAxisOptions: SelectOption[] = [cumulativeIPTV].filter(
    Boolean
  ) as SelectOption[];

  const actions = getByVehicleAgeChartOptions(
    yAxisOptions,
    useByVehicleAgeBirthdayOptions(),
    useInspectionByVehicleAgeExposureOptions()
  );

  const [selectedOptions, setSelectedOptions] = useCustomLocalStorageState<
    SelectedChartOptions[]
  >(CHART_KEY, { defaultValue: getDefaultActions(actions) });

  const byVehicleAgeData = useByVehicleAgeOptions(selectedOptions, actions);

  return (
    <div className="mt-5">
      <ByVehicleAgeChart
        inspectionsFilters={inspectionsFilters}
        vehiclesFilters={vehiclesFilters}
        onBadRequest={onBadRequest}
        actions={actions}
        byVehicleAgeData={byVehicleAgeData}
        selectedOptions={selectedOptions}
        setSelectedOptions={setSelectedOptions}
      />
      <ByVehicleAgeTable
        inspectionsFilters={inspectionsFilters}
        vehiclesFilters={vehiclesFilters}
        onBadRequest={onBadRequest}
        byVehicleAgeData={byVehicleAgeData}
      />
    </div>
  );
};

export default ByVehicleAge;
