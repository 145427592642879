import Skeleton from "react-loading-skeleton";

import { useFailureModeEventsTimeline } from "shared/api/failureModes/hooks";

import { formatDataForRollingGraph } from "pages/FailureModes/FailureMode/EventsTab/utils";

import APIError from "features/ui/APIError";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";
import Section from "features/ui/Section";
import SectionTitle from "features/ui/SectionTitle";

import CountBox from "./CountBox";
import { FailureModeEventsGraph } from "./EventsGraph";

interface Props {
  failureModeID: string;
  vehicleFilterState: FilterGroupState;
}

const EventsTimeline = ({ failureModeID, vehicleFilterState }: Props) => {
  const { data, isLoading, error } = useFailureModeEventsTimeline({
    id: failureModeID,
    vehiclesFilter: getFiltersQuery(vehicleFilterState),
  });

  if (error) {
    return <APIError error={error} />;
  }

  if (isLoading || !data) {
    return <Skeleton height={300} />;
  }

  return (
    <>
      <Section>
        <div className="lg:flex flex-1 lg:space-x-4 mb-5">
          <CountBox
            label="Eligible Vehicles"
            count={data?.totalCounts.totalVehicles!}
            textColorClass="text-black"
            lineColorClass="bg-white"
          />
          <CountBox
            label="Proactive Repairs"
            count={data?.totalCounts.proactiveRepairs!}
            textColorClass="text-green-600"
            lineColorClass="bg-green-600"
          />
          <CountBox
            label="Failures"
            count={data.totalCounts.failures}
            textColorClass="text-red-600"
            lineColorClass="bg-red-600"
          />
          <CountBox
            label="Total Repairs"
            count={data.totalCounts.totalRepairs}
            textColorClass="text-gray-600"
            lineColorClass="bg-gray-600"
          />
        </div>
      </Section>
      <Section>
        <SectionTitle>Events by Month</SectionTitle>
        {isLoading || !data ? (
          <Skeleton height={300} />
        ) : (
          <FailureModeEventsGraph
            data={formatDataForRollingGraph(data.monthlyCounts)}
          />
        )}
      </Section>
    </>
  );
};

export default EventsTimeline;
