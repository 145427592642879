import {
  getCaptureScreenshotAgentNode,
  getClaimAnalyticsAgentNodes,
  getDocumentRetrievalNode,
  getRagAgentNode,
  getRejectClarifyNode,
  getRespondToUserToolNode,
  getSignalEventAnalyticsAgentNodes,
  getSupervisorAgentNode,
  getVehiclesAgentNodes,
  getVinViewAgentNodes,
} from "duck/graph/nodes";
import { graphState } from "duck/graph/state";
import { DuckGraphParams } from "duck/graph/types";
import { Runnable } from "@langchain/core/runnables";
import { END, MemorySaver, START, StateGraph } from "@langchain/langgraph/web";

import { GenericToolNodeName, getNextNode, NodeNames } from "./utils";

/**
 * @summary Get DUCK's compiled state graph.
 * @param params The parameters for the agent from the UI
 * @param withMemory True to use the memory saver, false to not use memory at all
 * @returns The compiled state graph
 */
const getGraph = async (
  params: DuckGraphParams,
  withMemory: boolean = false
): Promise<Runnable> => {
  const {
    node: claimAnalyticsAgentNode,
    toolNode: claimAnalyticsAgentToolNode,
  } = await getClaimAnalyticsAgentNodes(params);

  const {
    node: signalEventAnalyticsAgentNode,
    toolNode: signalEventAnalyticsAgentToolNode,
  } = await getSignalEventAnalyticsAgentNodes(params);

  const { node: vehiclesAgentNode, toolNode: vehiclesAgentToolNode } =
    await getVehiclesAgentNodes(params);

  const { node: vinViewAgentNode, toolNode: vinViewAgentToolNode } =
    await getVinViewAgentNodes(params);

  /**
   * Define the state graph for the agent.
   * @see graph.md
   */
  const stateGraph = new StateGraph(graphState)
    .addNode(NodeNames.DOCUMENT_RETRIEVAL, getDocumentRetrievalNode(params))
    .addNode(NodeNames.SUPERVISOR, await getSupervisorAgentNode(params))
    .addNode(NodeNames.REJECT_CLARIFY, await getRejectClarifyNode(params))
    .addNode(NodeNames.RAG, await getRagAgentNode(params))
    .addNode(NodeNames.CLAIM_ANALYTICS, claimAnalyticsAgentNode)
    .addNode(NodeNames.CLAIM_ANALYTICS_TOOLS, claimAnalyticsAgentToolNode)
    .addNode(NodeNames.SIGNAL_EVENT_ANALYTICS, signalEventAnalyticsAgentNode)
    .addNode(
      NodeNames.SIGNAL_EVENT_ANALYTICS_TOOLS,
      signalEventAnalyticsAgentToolNode
    )
    .addNode(NodeNames.VIN_VIEW, vinViewAgentNode)
    .addNode(NodeNames.VIN_VIEW_TOOLS, vinViewAgentToolNode)
    .addNode(NodeNames.VEHICLES, vehiclesAgentNode)
    .addNode(NodeNames.VEHICLES_TOOLS, vehiclesAgentToolNode)
    .addNode(NodeNames.RESPOND_TO_USER, getRespondToUserToolNode(params))
    .addNode(
      NodeNames.CAPTURE_SCREENSHOT,
      await getCaptureScreenshotAgentNode(params)
    )
    .addEdge(START, NodeNames.DOCUMENT_RETRIEVAL)
    .addEdge(NodeNames.DOCUMENT_RETRIEVAL, NodeNames.SUPERVISOR)
    .addConditionalEdges(NodeNames.SUPERVISOR, getNextNode, {
      [NodeNames.RAG]: NodeNames.RAG,
      [NodeNames.REJECT_CLARIFY]: NodeNames.REJECT_CLARIFY,
      [NodeNames.CLAIM_ANALYTICS]: NodeNames.CLAIM_ANALYTICS,
      [NodeNames.SIGNAL_EVENT_ANALYTICS]: NodeNames.SIGNAL_EVENT_ANALYTICS,
      [NodeNames.VIN_VIEW]: NodeNames.VIN_VIEW,
      [NodeNames.VEHICLES]: NodeNames.VEHICLES,
      [NodeNames.RESPOND_TO_USER]: NodeNames.RESPOND_TO_USER,
      [NodeNames.CAPTURE_SCREENSHOT]: NodeNames.CAPTURE_SCREENSHOT,
      // safeguard: end the conversation if no tool call is found
      [END]: END,
    })
    .addConditionalEdges(NodeNames.RAG, getNextNode, {
      [NodeNames.RESPOND_TO_USER]: NodeNames.RESPOND_TO_USER,
      // safeguard: end the conversation if no tool call is found
      [END]: END,
    })
    .addConditionalEdges(NodeNames.REJECT_CLARIFY, getNextNode, {
      [NodeNames.RESPOND_TO_USER]: NodeNames.RESPOND_TO_USER,
      // safeguard: end the conversation if no tool call is found
      [END]: END,
    })
    .addEdge(NodeNames.RESPOND_TO_USER, END)
    .addConditionalEdges(NodeNames.CLAIM_ANALYTICS, getNextNode, {
      [GenericToolNodeName]: NodeNames.CLAIM_ANALYTICS_TOOLS,
      [NodeNames.SUPERVISOR]: NodeNames.SUPERVISOR,
      // safeguard: end the conversation if no tool call is found
      [END]: END,
    })
    .addEdge(NodeNames.CLAIM_ANALYTICS_TOOLS, NodeNames.CLAIM_ANALYTICS)
    .addConditionalEdges(NodeNames.SIGNAL_EVENT_ANALYTICS, getNextNode, {
      [GenericToolNodeName]: NodeNames.SIGNAL_EVENT_ANALYTICS_TOOLS,
      [NodeNames.SUPERVISOR]: NodeNames.SUPERVISOR,
      // safeguard: end the conversation if no tool call is found
      [END]: END,
    })
    .addEdge(
      NodeNames.SIGNAL_EVENT_ANALYTICS_TOOLS,
      NodeNames.SIGNAL_EVENT_ANALYTICS
    )
    .addConditionalEdges(NodeNames.VIN_VIEW, getNextNode, {
      [GenericToolNodeName]: NodeNames.VIN_VIEW_TOOLS,
      [NodeNames.SUPERVISOR]: NodeNames.SUPERVISOR,
      // safeguard: end the conversation if no tool call is found
      [END]: END,
    })
    .addEdge(NodeNames.VIN_VIEW_TOOLS, NodeNames.VIN_VIEW)
    .addConditionalEdges(NodeNames.VEHICLES, getNextNode, {
      [GenericToolNodeName]: NodeNames.VEHICLES_TOOLS,
      [NodeNames.SUPERVISOR]: NodeNames.SUPERVISOR,
      // safeguard: end the conversation if no tool call is found
      [END]: END,
    })
    .addEdge(NodeNames.VEHICLES_TOOLS, NodeNames.VEHICLES)
    .addConditionalEdges(NodeNames.CAPTURE_SCREENSHOT, getNextNode, {
      [NodeNames.RESPOND_TO_USER]: NodeNames.RESPOND_TO_USER,
      // safeguard: end the conversation if no tool call is found
      [END]: END,
    });

  // The MemorySaver checkpointer is not intended for production usage
  const checkpointer = withMemory ? new MemorySaver() : undefined;

  return stateGraph.compile({ checkpointer });
};

export default getGraph;
