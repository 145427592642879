import { useEffect, useMemo, useState } from "react";

import { ServiceRecord } from "shared/api/serviceRecords/api";
import {
  useListServiceRecords,
  useListServiceRecordsCount,
} from "shared/api/serviceRecords/hooks";
import { APIFilter, getSortFilter } from "shared/api/utils";
import {
  DATE_FILTER_GENERIC,
  EVENT_TYPE_FILTER,
  SERVICE_RECORD_GENERIC_FILTER,
} from "shared/filterDefinitions";
import useVehiclesSchema from "shared/schemas/vehiclesSchema";
import { SortBy } from "shared/types";
import { getTenantServiceRecordName } from "shared/utils";

import APIError from "features/ui/APIError";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";
import Filters from "features/ui/Filters/Filters";
import FiltersOverview from "features/ui/Filters/FiltersOverview/FiltersOverview";
import { useFilterSortState } from "features/ui/Filters/hooks";
import { FilterSchemaItem } from "features/ui/Filters/types";
import { OnSortParams, RowData, SchemaEntry } from "features/ui/Table";
import PaginatedTable from "features/ui/Table/PaginatedTable";
import { DataType } from "features/ui/Table/TableBodyCell";
import TableCount from "features/ui/Table/TableCount";

import * as config from "config/config";

import EventDetail from "./EventDetail";
import TypeBadge from "./TypeBadge";
import { VINEventTimelineDateLink } from "./VINEventTimelineDateLink";

const RESULTS_PER_PAGE = 10;

const formatRow = (serviceRecord: ServiceRecord) => {
  const { VIN, status, type, date } = serviceRecord;
  return {
    ...serviceRecord,
    type: (
      <>
        <TypeBadge type={type} />
        {status ? ` - ${status}` : ""}
      </>
    ),
    VIN: <VINEventTimelineDateLink VIN={VIN} date={date} />,
    externalID: <FormatExternalID serviceRecord={serviceRecord} />,
  };
};

interface FormatExternalIDProps {
  serviceRecord: ServiceRecord;
}

const FormatExternalID = ({
  serviceRecord: { externalID, externalURL },
}: FormatExternalIDProps) => {
  if (!externalURL) {
    return <>{externalID}</>;
  }
  return (
    <a
      href={externalURL}
      target="_blank"
      rel="noreferrer"
      className="text-blue-400 hover:text-blue-500"
    >
      {externalID}
    </a>
  );
};

const DEFAULT_COLUMNS = ["date", "VIN", "type", "externalID"];

interface Props {
  staticFilters: APIFilter[];
  pageKey?: string;
  columns?: string[];
  globalFilters?: FilterSchemaItem[];
}

const PAGE_KEY = "history_events";

const ServiceRecords = ({
  staticFilters,
  pageKey = PAGE_KEY,
  columns = DEFAULT_COLUMNS,
  globalFilters,
}: Props) => {
  const { pages } = config.get();

  // We use display name from vehicles as we do not have attributes endpoint for Service Records.
  // We should add that at some point
  const { getDisplayLabel } = useVehiclesSchema();

  const [selectedRow, setSelectedRow] = useState<RowData>();
  const [sortBy, setSortBy] = useState<SortBy>({ date: "desc" });

  const srStaticFilter = pages.events?.staticFilters || [];

  const schemaDefault: SchemaEntry[] = [
    {
      label: "ID",
      accessor: "externalID",
      dataType: DataType.JSX,
      filter: SERVICE_RECORD_GENERIC_FILTER({
        label: "ID",
        fieldName: "externalID",
        search: true,
      }),
    },
    {
      label: "Date",
      accessor: "date",
      dataType: DataType.DATE,
      sortable: true,
      filter: DATE_FILTER_GENERIC({
        fieldName: "date",
        label: "Date",
        filterDataType: DataType.DATE,
      }),
    },
    {
      label: getDisplayLabel("VIN", "VIN"),
      accessor: "VIN",
      dataType: DataType.JSX,
      filter: SERVICE_RECORD_GENERIC_FILTER({
        label: getDisplayLabel("VIN", "VIN"),
        fieldName: "VIN",
        search: true,
      }),
    },
    {
      label: "Type",
      accessor: "type",
      dataType: DataType.JSX,
      // 'is any of' (and maybe other) filters preload available values from the API
      // since we remove some of the available values with static filter defined in the config
      // we also need to send this filter to 'values' endpoint
      filter: EVENT_TYPE_FILTER(getFiltersQuery(undefined, srStaticFilter)),
    },
  ];

  const serviceRecordsStaticFilters = [
    ...srStaticFilter,
    ...(staticFilters || []),
  ];

  const handleSorting = ({ accessor, sort }: OnSortParams) => {
    setSortBy({ [accessor]: sort });
  };

  const {
    manageFilterChange,
    resetFilters,
    filters,
    initialized: filtersInitialized,
    resetFilterSortState,
  } = useFilterSortState({
    pageKey,
  });

  const schema = schemaDefault.filter(({ accessor }: SchemaEntry) =>
    columns.includes(accessor)
  );

  const allFiltersQuery = getFiltersQuery(filters, serviceRecordsStaticFilters);

  const { data, isLoading, headers, error, ...paginationData } =
    useListServiceRecords({
      filter: allFiltersQuery,
      limit: RESULTS_PER_PAGE,
      sort: getSortFilter(sortBy),
    });

  const {
    isLoading: countIsLoading,
    data: countData,
    error: countError,
  } = useListServiceRecordsCount({
    filter: allFiltersQuery,
  });

  // whenever data changes, re-set first row to selected
  useEffect(() => {
    if (!data) return;
    setSelectedRow(data[0]);
  }, [data]);

  // re-format the data - but only when data changes
  const formattedData = useMemo(() => data?.map(formatRow), [data]);

  const noRecordsFoundText = `No ${getTenantServiceRecordName().toLowerCase()} found.`;

  return (
    <div className="py-4">
      {globalFilters && (
        <Filters
          initialized={filtersInitialized}
          schema={globalFilters}
          onFilterChange={manageFilterChange}
          filters={filters}
          horizontal
        />
      )}
      <div className="flex flex-col lg:flex-row justify-between gap-y-5 lg:space-y-0 lg:space-x-5">
        <div className="order-last flex-1 lg:order-first">
          <div className="flex items-center mb-3">
            <FiltersOverview
              filters={filters}
              tableSchema={schema}
              onFiltersReset={resetFilters}
            />
            <TableCount
              extraClasses="ml-auto self-end"
              count={countData?.count as number}
              entityName={getTenantServiceRecordName(false).toLowerCase()}
              isLoading={countIsLoading}
              error={!!countError}
            />
          </div>
          {!error && (
            <PaginatedTable
              {...paginationData}
              isLoading={isLoading}
              loadingRows={RESULTS_PER_PAGE}
              data={formattedData}
              onRowSelect={setSelectedRow}
              schema={schema}
              sortBy={sortBy}
              onSort={handleSorting}
              filtersInitialized={filtersInitialized}
              onFiltersReset={resetFilters}
              onFilterChange={manageFilterChange}
              filters={filters}
              staticFilters={staticFilters}
            />
          )}
          {/* We get empty [] back when using filters and there's no results */}
          {(!formattedData || !formattedData.length) &&
            !isLoading &&
            !error && (
              <div className="py-4 text-gray-400 text-sm">
                {noRecordsFoundText}
              </div>
            )}
          {error && (
            <APIError error={error} onBadRequest={resetFilterSortState} />
          )}
        </div>
        {!error && selectedRow && (
          <div className="flex-1">
            <EventDetail serviceRecord={selectedRow as ServiceRecord} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ServiceRecords;
