import { AppConfig, AuthScopes, PagesConfig } from "config/config";

import pagesConfig from "./pagesConfig.json";

export const tenant = "dev";
export const host = `${tenant}.cloud.viaduct.ai`;

const analyticsConfig = {
  heap: { id: "1228852395" },
};

const authConfig = {
  name: "Viaduct",
  idp: "0oa13z7dyOysdGFnU5d6",
  clientId: "0oacl10juwyRzJ1R55d7",
  issuer: "https://viaduct-cloud.okta.com/oauth2/auscl0yzf595Eypg05d7",
  scopes: ["openid", "email", "offline_access"] as AuthScopes[],
};

// this tenant only has 1 environment, no dev vs prod
const APIConfig = {
  url: `https://api.${host}/v0`,
};

const featureFlags = {
  launchDarklyClientId: "65268dcc9a881a1252489998",
};

export const appConfig: AppConfig = {
  environment: "prod",
  pages: pagesConfig as PagesConfig,
  analytics: analyticsConfig,
  featureFlags: featureFlags,
  auth: authConfig,
  api: APIConfig,
};
