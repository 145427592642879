import { useState } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { HiDotsVertical } from "react-icons/hi";
import { IconButton } from "@mui/material";

import CreateIssue from "pages/Issues/CreateIssue/CreateIssue";
import {
  clearPendingIssueFilters,
  IssueFormData,
} from "pages/Issues/CreateIssue/utils";
import { IssueRequestKeys } from "pages/Issues/types";

import DropdownSelect, {
  DropdownSelectOption,
} from "features/ui/DropdownSelect/DropdownSelect";
import { ActionType } from "features/ui/StepperModal";

export interface CreateIssueProps extends IssueRequestKeys {
  action: ActionType;
  step?: number;
  issueData?: IssueFormData;
}

interface Props {
  canEdit?: boolean;
  createIssueProps?: CreateIssueProps;
}

const EDIT_OPTION_ID = "edit_issue";

const useDropdownOptions = (canEdit: boolean) => {
  const dropdownOptions: DropdownSelectOption[] = [];
  if (canEdit) {
    dropdownOptions.push({
      id: EDIT_OPTION_ID,
      value: "Edit",
      testId: EDIT_OPTION_ID,
    });
  }
  return dropdownOptions;
};

const CardActions = ({ canEdit = false, createIssueProps }: Props) => {
  const [selectedAction, setSelectedAction] = useState("");
  const step = createIssueProps?.step?.toString() || "";

  const { rbac } = useFlags();

  const editPermission = !rbac || (rbac && canEdit);

  const dropDownOptions = useDropdownOptions(editPermission);
  if (dropDownOptions.length === 0) {
    return null;
  }

  const handleOnSelect = (opt: DropdownSelectOption) => {
    setSelectedAction(opt.id as string);
    clearPendingIssueFilters();
  };

  return (
    <div className="flex flex-nowrap ml-auto items-start space-x-2 z-[1]">
      <DropdownSelect
        options={dropDownOptions}
        onSelect={handleOnSelect}
        buttonComponent={
          <IconButton size="small" data-testid={`card-action-dropdown-${step}`}>
            <HiDotsVertical />
          </IconButton>
        }
      />
      {createIssueProps && selectedAction === EDIT_OPTION_ID && (
        <CreateIssue
          {...createIssueProps}
          showButton={false}
          defaultOpen={true}
          // Triggered when 'Close button' is clicked
          onClose={() => {
            setSelectedAction("");
            clearPendingIssueFilters();
          }}
          // Triggered after saving/updating issue data
          onFinally={() => {
            setSelectedAction("");
            clearPendingIssueFilters();
          }}
        />
      )}
    </div>
  );
};

export default CardActions;
