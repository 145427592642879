interface Props {
  count: number;
  label: string;
  textColorClass: string;
  lineColorClass: string;
}

const CountBox = ({ count, label, textColorClass, lineColorClass }: Props) => {
  return (
    <div className="flex-1 flex flex-col justify-center items-center shadow px-4 py-3 text-center rounded">
      <div
        className={`flex w-full items-center text-3xl font-semibold ${textColorClass}`}
        data-testid={`failure-mode-${label}-count`}
      >
        <div className={`h-px w-full mr-2 ${lineColorClass}`} />
        {count}
        <div className={`h-px w-full ml-2 ${lineColorClass}`} />
      </div>
      <div className="text-gray-500">{label}</div>
    </div>
  );
};

export default CountBox;
