import { useState } from "react";
import { BiPencil as EditIcon } from "react-icons/bi";
import { IconButton } from "@mui/material";

import { useCustomLocalStorageState } from "shared/hooks";

import Checkbox from "features/ui/Checkbox";
import ConfirmationModal from "features/ui/ConfirmationModal";

interface Props {
  setEditMode: (editMode: boolean) => void;
}

const SERVICE_PLAN_EDIT_CONFIRMATION_DISMISSAL_KEY =
  "servicePlanEditConfirmationDismissalKey";

const EditAction = ({ setEditMode }: Props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [doNotShowAgain, setDoNotShowThis] = useState(false);

  const [doNotShowAgainRemembered, setDoNotShowAgainRemembered] =
    useCustomLocalStorageState(SERVICE_PLAN_EDIT_CONFIRMATION_DISMISSAL_KEY, {
      defaultValue: false,
    });

  const handleClose = (confirmed: boolean) => {
    // we prevent closing the popup when download is in progress
    if (doNotShowAgain) {
      setDoNotShowAgainRemembered(true);
    }
    if (confirmed) {
      setEditMode(true);
      return;
    }
    setModalOpen(false);
  };

  const handleEditIconClick = () => {
    if (doNotShowAgainRemembered) {
      setEditMode(true);
    } else {
      setModalOpen(true);
    }
  };

  return (
    <>
      <IconButton
        onClick={handleEditIconClick}
        size="small"
        data-testid="sps-form-edit-mode-cta"
      >
        <EditIcon />
      </IconButton>
      <ConfirmationModal
        text={
          <div className="text-sm">
            <p className="mb-2">
              Changing any of the following fields may break a Service Plan
              Alert that depends of their values:
            </p>

            <ul className="list-disc mb-2 pl-5">
              <li>Name</li>
              <li>Family</li>
              <li>Recommended Action</li>
            </ul>
            <p>Are you sure you want to edit the Service Plan?</p>
          </div>
        }
        title=""
        isOpen={modalOpen}
        onClose={handleClose}
        confirmText="Edit"
        closeText="Cancel"
        additionalAction={
          <div className="w-full justify-start pl-4">
            <Checkbox
              label="Do not show this again"
              onChange={(checked: boolean) => setDoNotShowThis(checked)}
              checked={doNotShowAgain}
              dense
            />
          </div>
        }
      />
    </>
  );
};

export default EditAction;
