export enum Reload {
  HARD = "hard",
  SOFT = "soft",
  NONE = "none",
}

export type LocationInfo = {
  reloadRequired: Reload;
  path: string;
  url: string;
};

export interface QueryStringNavigation {
  deliverLocationInfo: () => LocationInfo;
  updateLocation: () => void;
  clearLocationInfo: () => void;
  setRouteValue: (pathname: string) => void;
  setMinimumReload: (reload: Reload) => void;
  updateQueryStringParameter: (
    paramName: string,
    paramValue: string,
    reload?: Reload
  ) => void;
  navigateToTab: (tabId: string) => void;
  updateFilter: (
    filterQueryString: string,
    queryStringParameterName: string
  ) => void;
  setOmitExistingQueryParam: (paramName: string | undefined) => void;
  setOmitExistingQueryParamsStartingWith: (prefix: string | undefined) => void;
}
