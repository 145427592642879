export const MODELSPEC = {
  modelName: "gpt-4o",
  temperature: 0.2,
  modelKwargs: {
    seed: 22,
  },
};

export const LC_ENDPOINT = process.env.REACT_APP_LANGCHAIN_ENDPOINT;
export const LC_API_KEY = process.env.REACT_APP_LANGCHAIN_API_KEY;
export const LC_PROJECT_NAME = process.env.REACT_APP_LANGCHAIN_PROJECT;
export const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY;
export const DOCUMENTS_SOURCE = "Confluence";
export const DOCUMENT_RETRIEVAL_K = 5;
export const DOCUMENT_RETRIEVAL_DISTANCE_THRESHOLD = 2;
export const ATTRIBUTE_RETRIEVAL_K = 5;
export const ATTRIBUTE_RETRIEVAL_DISTANCE_THRESHOLD = 2;
export const ATTRIBUTE_SOURCES = {
  Claim: "v1/claims/attributes",
  Vehicle: "v1/vehicles/attributes",
  SignalEventOccurrences: "v1/signalEvents/occurrences/attributes",
} as const;
export const ATTRIBUTE_SOURCE_KEYS = {
  Claim: "Claim",
  Vehicle: "Vehicle",
  SignalEventOccurrences: "SignalEventOccurrences",
} as const;

export const SCREENCAPTURE_ELEMENT_ID = "duck-screenshot-element";
