import { ChartAction } from "features/ui/charts/Actions/types";

interface Props {
  action: ChartAction;
}

const LabelAction = ({ action: { id, title } }: Props) => {
  return (
    <div
      className="inline-flex text-sm text-gray-400"
      data-testid={`chart-action-label-${id}`}
      key={id + title}
    >
      {title}
    </div>
  );
};

export default LabelAction;
