import { BreadcrumbMatch } from "use-react-router-breadcrumbs";

import { useGetCustomRecord } from "shared/api/serviceRecords/hooks";
import { EventTypeEnum } from "shared/types";
import { getEntityWithExternalIDTitle } from "shared/utils";

interface Props {
  match: BreadcrumbMatch;
}

const CustomRecordBreadcrumb = ({ match }: Props) => {
  const { data, isLoading, error } = useGetCustomRecord({
    ID: match.params.id as string,
  });

  if (isLoading) {
    return <>...</>;
  }

  if (data)
    return (
      <>
        {getEntityWithExternalIDTitle(
          data.externalID,
          EventTypeEnum.CUSTOM_RECORD
        )}
      </>
    );

  if (error && match.params.id) {
    return <>{match.params.id}</>;
  }

  return <></>;
};

export default CustomRecordBreadcrumb;
