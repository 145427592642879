import { SHORT_DATE_FORMAT } from "shared/constants";
import { formatDate } from "shared/utils";

import Tooltip from "features/ui/Tooltip";

export interface CreatedUpdatedInterface {
  createdBy: string;
  createdAt: string;
  updatedBy: string;
  updatedAt: string;
}

export interface EditDetailsProps {
  props: CreatedUpdatedInterface;
}

const EditDetails = ({ props }: EditDetailsProps) => {
  const formatedUpdatedAt = formatDate(
    props.updatedAt,
    SHORT_DATE_FORMAT,
    false
  );

  const formatedCreatedAt = formatDate(
    props.createdAt,
    SHORT_DATE_FORMAT,
    false
  );

  const tooltipContent = (
    <div>
      <strong>Updated</strong> {formatedUpdatedAt} by {props.updatedBy}
      <br />
      <strong>Created</strong> {formatedCreatedAt} by {props.createdBy}
    </div>
  );

  return (
    <div className="text-xs text-gray-500 pr-3">
      <Tooltip content={tooltipContent} trigger="hover">
        <div>
          <strong>Updated</strong>: {formatedUpdatedAt}
        </div>
      </Tooltip>
    </div>
  );
};

export default EditDetails;
