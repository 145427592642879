import { TestProps } from "shared/types";

import Button from "features/ui/Button";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import {
  areFiltersEqual,
  filterRowsMatch,
  getTopLevelRowFromFilterGroupState,
} from "features/ui/Filters/FilterBuilder/utils";

interface Props extends TestProps {
  filters?: FilterGroupState;
  defaultFilters?: FilterGroupState;
  onResetToDefault?: (accessors: string[]) => void;
  fieldName?: string;
  disabled?: boolean;
}

const ResetToDefaultButton = ({
  filters,
  defaultFilters,
  testId,
  onResetToDefault,
  fieldName,
  disabled,
}: Props) => {
  if (!filters) return null;

  if (!fieldName && areFiltersEqual(filters, defaultFilters)) return null;

  if (fieldName) {
    const filterRow = getTopLevelRowFromFilterGroupState(fieldName, filters);
    const defaultFilterRow = getTopLevelRowFromFilterGroupState(
      fieldName,
      defaultFilters
    );

    if (filterRowsMatch(filterRow, defaultFilterRow)) {
      return null;
    }
  }

  const handleOnClick = () => {
    onResetToDefault && onResetToDefault([fieldName || ""].filter(Boolean));
  };

  const text = `Reset ${fieldName ? "" : "all"} to default`;

  return (
    <Button
      variant="text"
      color="primary"
      onClick={handleOnClick}
      testId={testId}
      size="small"
      disabled={disabled}
    >
      {text}
    </Button>
  );
};

export default ResetToDefaultButton;
