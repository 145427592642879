import Alert from "@mui/material/Alert";

import { DEFAULT_CONTENT, DEFAULT_TITLE } from "./constants";

export interface ErrorProps {
  title?: string;
  content?: string | JSX.Element;
  moreInfo?: string | JSX.Element;
}

const Error = ({
  title = DEFAULT_TITLE,
  content = DEFAULT_CONTENT,
  moreInfo,
}: ErrorProps) => {
  return (
    <Alert
      color="error"
      severity="error"
      data-testid="error-message"
      className=""
    >
      <div>
        <div className="font-bold mb-1">{title}</div>
        <div>{content}</div>
        {moreInfo && (
          <div className="text-gray-500 mt-4 inline-block text-xs">
            {moreInfo}
          </div>
        )}
      </div>
    </Alert>
  );
};

export default Error;
