import {
  CountResponse,
  EntityAttribute,
  ListAttributesRequest,
} from "shared/api/api";
import { useAPI, usePaginatedAPI } from "shared/api/hooks";

import "shared/api/issues/api";

import { DEFAULT_ATTRIBUTES_SWR_CONFIG } from "shared/api/constants";

import {
  GetSuggestedIssueRequest,
  getSuggestedIssueURI,
  listSuggestedIssueCountURI,
  ListSuggestedIssueRequest,
  listSuggestedIssuesAttributesRequestURI,
  listSuggestedIssuesURI,
  SuggestedIssue,
} from "./api";

export const useListSuggestedIssues = (args: ListSuggestedIssueRequest) =>
  usePaginatedAPI<typeof args, SuggestedIssue[]>(listSuggestedIssuesURI, args);

export const useListSuggestedIssuesCount = (args: ListSuggestedIssueRequest) =>
  useAPI<typeof args, CountResponse>(listSuggestedIssueCountURI, args);

export const useSuggestedIssue = (args: GetSuggestedIssueRequest) =>
  useAPI<typeof args, SuggestedIssue>(getSuggestedIssueURI, args);

export const useSuggestedIssuesAttributes = (args: ListAttributesRequest) =>
  useAPI<typeof args, EntityAttribute[]>(
    listSuggestedIssuesAttributesRequestURI,
    args,
    DEFAULT_ATTRIBUTES_SWR_CONFIG
  );
