import { SelectOption } from "features/ui/Select";

export const ASSOCIATED_CLAIMS_DEFAULT_GROUP_BY_ATTRIBUTE = "laborCode";
export const ASSOCIATED_CLAIMS_DEFAULT_GROUP_BY_ATTRIBUTE_LABEL = "Labor Code";
export const ASSOCIATED_CLAIMS_DEFAULT_GROUP_BY_SELECT_OPTION: SelectOption = {
  id: ASSOCIATED_CLAIMS_DEFAULT_GROUP_BY_ATTRIBUTE,
  value: ASSOCIATED_CLAIMS_DEFAULT_GROUP_BY_ATTRIBUTE_LABEL,
};

export const PAGE_KEY = "signalEventsAnalytics-associated-claims";

export const SIGNAL_EVENTS_ASSOCIATED_CLAIMS_GROUP_BY_OPTIONS_KEY =
  "signalEventsAnalyticsAssociatedClaimsGroupByOptions";
export const SIGNAL_EVENTS_ASSOCIATED_CLAIMS_WINDOW_SIZE_OPTIONS_KEY =
  "signalEventsAnalyticsAssociatedClaimsWindowSizeOptions";

export const WINDOW_SIZE_KEY = "windowSize";

export const SE_ASSOCIATED_CLAIMS_KEY = "signalEventsAnalyticsAssociatedClaims";
