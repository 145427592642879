import { IssueTypes } from "shared/types";

import Board from "features/ui/Board";

import AnalyzeWithComparisonPopButton from "./AnalyzeWithComparisonPopButton";
import IssueSignalEventsCharts from "./SignalEvents/IssueSignalEventsCharts";
import IssueSignalEventsStatistics from "./SignalEvents/IssueSignalEventsStatistics";
import IssueSignalEventsTable from "./SignalEvents/IssueSignalEventsTable";

interface Props {
  issue: IssueTypes;
  withComparisonPopulation: boolean;
  onToggleWithComparisonPopulation: () => void;
}

const IssueSignalEventsTab = ({
  issue,
  withComparisonPopulation,
  onToggleWithComparisonPopulation,
}: Props) => {
  const { comparisonPopulationFilter } = issue;

  const commonProps = { issue, withComparisonPopulation };

  const boardElements = [[<IssueSignalEventsStatistics {...commonProps} />]];

  return (
    <>
      <AnalyzeWithComparisonPopButton
        comparisonPopulationFilter={comparisonPopulationFilter || undefined}
        onClick={onToggleWithComparisonPopulation}
        value={withComparisonPopulation}
      />
      <Board elements={boardElements} />
      <IssueSignalEventsCharts {...commonProps} />
      <IssueSignalEventsTable {...commonProps} />
    </>
  );
};

export default IssueSignalEventsTab;
