import Skeleton from "react-loading-skeleton";

const FormLoader = () => (
  <div>
    <div className="mb-5">
      <Skeleton />
    </div>
    <div className="mb-5">
      <Skeleton height={70} />
    </div>
    <div className="mb-5">
      <Skeleton width={300} />
    </div>
    <div>
      <Skeleton height={200} />
    </div>
  </div>
);

export default FormLoader;
