import { useClaimsSchema } from "./claimsSchema";
import { useInspectionsSchema } from "./inspectionsSchema";
import { useRepairsSchema } from "./repairsSchema";
import useSignalEventOccurrencesSchema from "./signalEventOccurrencesSchema";
import { Resource } from "./types";

export const TOP_CONTRIBUTORS_GROUP_BY_ACCESSOR = "groupByAttributeValue";
export const CLAIM_DEALER_ACCESSOR = "dealer";
export const VEHICLE_ACCESSOR = "vehicle";
export const VEHICLE_LAST_KNOWN_DEALER_ACCESSOR = "lastKnownDealer";

export const USE_RESOURCE_SCHEMA_MAP: Record<
  Resource,
  (accessorsToHide?: string[]) => ReturnType<typeof useInspectionsSchema>
> = {
  claim: useClaimsSchema,
  repair: useRepairsSchema,
  inspection: useInspectionsSchema,
  signalEventOccurrence: useSignalEventOccurrencesSchema,
};
