import { PageChartSettingsState } from "features/ui/Filters/types";
import { Option, SelectOption } from "features/ui/Select";

/**
 * Determine the initial group by attribute to use.
 * @param chartSettings The chart settings that come from the query string or local storage.
 * @param defaultGroupByAttribute The setting to use if the user has not previously selected one.
 * @param groupBySelectOptions The available options for the group by attribute.
 * @param tabKey The key of the tab that this group by attribute is for.
 * @param groupByOptionsKey The key to use for the group by attribute in the chart settings.
 * @returns If the user has previously selected a group by attribute, that attribute.
 * Otherwise, the default.
 */
export const getInitialSelectedGroupByAttribute = (
  chartSettings: PageChartSettingsState | undefined,
  defaultGroupByAttribute: SelectOption<Option>,
  groupBySelectOptions: SelectOption<Option>[],
  tabKey: string,
  groupByOptionsKey: string,
  groupByAttributeKey: string
): SelectOption<Option> => {
  if (
    !chartSettings ||
    !chartSettings[tabKey] ||
    !chartSettings[tabKey][groupByOptionsKey]
  ) {
    return defaultGroupByAttribute;
  }

  const foundSetting = chartSettings[tabKey][groupByOptionsKey].find(
    (option) => option.id === groupByAttributeKey
  );

  if (!foundSetting) return defaultGroupByAttribute;

  const foundOption = groupBySelectOptions.find(
    (option) => option.id === foundSetting.optionId
  );

  if (!foundOption) return defaultGroupByAttribute;

  return {
    id: foundSetting.optionId,
    value: foundOption.value,
  };
};
