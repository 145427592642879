import { addYears, format, parseISO, subYears } from "date-fns";
import { VinViewTimelineChartOptionStrings } from "duck/graph/types";

import { API_DATE_FORMAT_W_TIME } from "shared/constants";

import { VinViewTimelineActionIdType } from "pages/utils";
import { CHART_OPTIONS_KEY } from "pages/VINView/Events/constants";

import { TabChartSettingsState } from "features/ui/Filters/types";
import { getPageKeyWithVersion, getQueryKeys } from "features/ui/Filters/utils";

export const getParamName = (
  pageKey: string,
  topic: "filters" | "chartSettings" = "filters"
): string => {
  const queryKeys = getQueryKeys(getPageKeyWithVersion(pageKey));

  if (topic === "chartSettings") {
    return queryKeys.chartSettingsKey;
  }
  return queryKeys.filtersKey;
};

const validateSensors = (sensors: string[], sensorOptions: string[]): void => {
  if (sensors.length > 2) {
    throw new Error(
      `The maximum number of sensors is 2. ${sensors.length} were sent to the tool which is not valid.`
    );
  }

  sensors.forEach((sensor) => {
    if (!sensorOptions.includes(sensor)) {
      throw new Error(
        `The sensor ${sensor} is not valid. Valid sensors are ${sensorOptions.join(
          ", "
        )}.`
      );
    }
  });
};

export const createSensorsParameter = (
  sensors: string[] | undefined,
  selectedSensorsAndTriggers: string,
  sensorOptions: string[] | undefined
): string | undefined => {
  if (!sensors) {
    return selectedSensorsAndTriggers;
  }

  if (sensors.length === 0) {
    return "";
  }

  if (sensorOptions) {
    validateSensors(sensors, sensorOptions);
  }

  return `sensorID=in:"${sensors.join('"|"')}"`;
};

export const createDatesFilterParameter = (
  fromDateString: string | undefined,
  toDateString: string | undefined,
  selectedDateRange: string
): string | undefined => {
  if (!fromDateString || !toDateString) {
    return selectedDateRange ?? undefined;
  }

  const fromDate = fromDateString ? parseISO(fromDateString) : undefined;
  const toDate = toDateString ? parseISO(toDateString) : undefined;

  if (fromDate && toDate && fromDate > toDate) {
    throw new Error(
      `The from date ${format(fromDate, API_DATE_FORMAT_W_TIME)} is after the to date ${format(toDate, API_DATE_FORMAT_W_TIME)} but it must be before it.`
    );
  }

  const nonNullFromDate = fromDate
    ? fromDate
    : toDate
      ? subYears(toDate, 1)
      : subYears(new Date(), 1);

  const nonNullToDate = toDate
    ? toDate
    : fromDate
      ? addYears(fromDate, 1)
      : addYears(new Date(), 1);

  return `recordedAt=between:"${format(nonNullFromDate, API_DATE_FORMAT_W_TIME)}"|"${format(nonNullToDate, API_DATE_FORMAT_W_TIME)}"`;
};

const validateAggregation = (
  aggregation: string,
  timelineChartOptions: VinViewTimelineChartOptionStrings
): void => {
  if (!timelineChartOptions.legend.includes(aggregation)) {
    throw new Error(
      `The indicated aggregation ${aggregation} is not valid. The valid options are: ${timelineChartOptions.legend.join(
        ", "
      )}. Please provide a valid aggregation.`
    );
  }
};

/**
 * Create the chartSettings parameter from the aggregation parameter.
 *
 * @param aggregation I wanted to type this as GoToTimelineTab["aggregation"] but exporting that
 * type from the tool definition file would have required gymnastics that weren't worth the effort.
 * Strongly typing this parameter is not as important here anyway since we are explicitly
 * validating it.
 * @param selectedTimelineChartOptions Previously selected chart setting values.
 * @param timelineChartOptions The valid values for the aggregation parameter.
 */
export const createChartSettings = (
  aggregation: string | undefined,
  selectedTimelineChartOptions: Record<VinViewTimelineActionIdType, string>,
  timelineChartOptions: VinViewTimelineChartOptionStrings | undefined
): TabChartSettingsState | undefined => {
  const nonNullAggregation = aggregation ?? selectedTimelineChartOptions.legend;
  if (!nonNullAggregation) {
    return undefined;
  }

  if (timelineChartOptions) {
    validateAggregation(nonNullAggregation, timelineChartOptions);
  }

  return {
    [CHART_OPTIONS_KEY]: [{ id: "legend", optionId: nonNullAggregation }],
  };
};
