import React, { useState } from "react";
import DP, { ReactDatePickerProps } from "react-datepicker";

import { toSnakeCase } from "shared/utils";

import "./DatePicker.css";

import { TextField } from "@mui/material";

export interface DatePickerProps extends ReactDatePickerProps {
  label?: string;
  initialDate?: Date | null;
  defaultIsOpen?: boolean;
}

const DatePicker = ({
  label,
  initialDate = null,
  placeholderText = "Select a date",
  onChange,
  defaultIsOpen,
  ...datePickerProps
}: DatePickerProps) => {
  const [selected, setSelected] = useState<Date | null>(initialDate);

  const handleDateChange = (
    date: Date | null,
    event: React.SyntheticEvent<any, Event> | undefined
  ) => {
    setSelected(date);
    if (onChange) {
      onChange(date, event);
    }
  };

  return (
    <div>
      <DP
        {...datePickerProps}
        selected={selected}
        onChange={handleDateChange}
        customInput={<TextField label={label} size="small" />}
        placeholderText={placeholderText}
        nextMonthButtonLabel=">"
        previousMonthButtonLabel="<"
        autoFocus={defaultIsOpen}
        id={label && toSnakeCase(label)}
        strictParsing
        popperPlacement="bottom-start"
        dateFormat={datePickerProps.showTimeSelect ? "Pp" : undefined}
        popperClassName={datePickerProps.showTimeSelect ? "w-96" : ""}
      />
    </div>
  );
};

export default DatePicker;
