import { useEffect, useState } from "react";

import { useGroupBySelectOptions } from "shared/schemas/hooks";
import useSignalEventOccurrencesSchema from "shared/schemas/signalEventOccurrencesSchema";

import {
  GROUP_BY_ATTRIBUTE_KEY,
  TOP_CONTRIBUTORS_TAB_KEY,
} from "pages/constants";
import { useExposures, useTopContributorsExposureOptions } from "pages/hooks";
import TopContributorsGroupBySelector from "pages/shared/TopContributorsGroupBySelector";
import { SignalEventsAnalyticsProps } from "pages/SignalEventsAnalytics/SignalEventsAnalyticsTabs";
import { getInitialSelectedGroupByAttribute } from "pages/SignalEventsAnalytics/utils";
import {
  buildNewTopContributorsSelectedOptions,
  getDefaultTopContributorChartActions,
  getTopContributorsChartActions,
} from "pages/utils";

import { SelectedChartOptions } from "features/ui/charts/Actions/types";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { SelectOption } from "features/ui/Select";

import { topContributorsChartYAxisOptions } from "./ChartActions";
import TopChart from "./TopChart";
import TopContributorsTable from "./TopContributorsTable";
import { getInitialSelectedOptions } from "./utils";

export const DEFAULT_GROUP_BY_ATTRIBUTE = "signalEventID";
const DEFAULT_GROUP_BY_ATTRIBUTE_LABEL = "ID";
export const DEFAULT_GROUP_BY_SELECT_OPTION: SelectOption = {
  id: DEFAULT_GROUP_BY_ATTRIBUTE,
  value: DEFAULT_GROUP_BY_ATTRIBUTE_LABEL,
};

export const SIGNAL_EVENTS_TOP_CONTRIBUTORS_CHART_OPTIONS_KEY =
  "signalEventsAnalyticsTopContributorsChartOptions";
// We manage the state of the group by options with a separate key from the rest of
// the chart options because they are not part of the chart options, and are set through
// a different mechanism.
export const SIGNAL_EVENTS_TOP_CONTRIBUTORS_GROUP_BY_OPTIONS_KEY =
  "signalEventsAnalyticsTopContributorsGroupByOptions";

const TopContributors = ({
  signalEventsFiltersFilterSortState,
  vehiclesFiltersFilterSortState,
}: SignalEventsAnalyticsProps) => {
  const { attributes } = useSignalEventOccurrencesSchema();

  const exposures = useTopContributorsExposureOptions("signalEventOccurrence");

  const defaultActions = getDefaultTopContributorChartActions(
    attributes,
    SIGNAL_EVENTS_TOP_CONTRIBUTORS_CHART_OPTIONS_KEY,
    topContributorsChartYAxisOptions,
    exposures
  );

  const [actions, setActions] = useState(defaultActions);

  const signalEventsFilters = signalEventsFiltersFilterSortState?.filters;
  const vehiclesFilters = vehiclesFiltersFilterSortState?.filters;

  const [selectedOptions, setSelectedOptions] = useState<
    SelectedChartOptions[]
  >(
    getInitialSelectedOptions(
      signalEventsFiltersFilterSortState?.chartSettings,
      actions
    )
  );

  const handleSelectedOptionsChange = (
    newSelectedOptions: SelectedChartOptions[]
  ) => {
    setSelectedOptions(newSelectedOptions);
    if (signalEventsFiltersFilterSortState?.manageChartSettingsChange) {
      signalEventsFiltersFilterSortState.manageChartSettingsChange(
        newSelectedOptions,
        SIGNAL_EVENTS_TOP_CONTRIBUTORS_CHART_OPTIONS_KEY
      );
    }
  };

  const groupBySelectOptions = useGroupBySelectOptions("signalEventOccurrence");
  const [generalFilters, setGeneralFilters] = useState<
    FilterGroupState | undefined
  >();

  const initialSelectedGroupByAttribute: SelectOption =
    getInitialSelectedGroupByAttribute(
      signalEventsFiltersFilterSortState?.chartSettings,
      DEFAULT_GROUP_BY_SELECT_OPTION,
      groupBySelectOptions,
      TOP_CONTRIBUTORS_TAB_KEY,
      SIGNAL_EVENTS_TOP_CONTRIBUTORS_GROUP_BY_OPTIONS_KEY,
      GROUP_BY_ATTRIBUTE_KEY
    );

  const [selectedGroupByAttribute, setSelectedGroupByAttribute] = useState(
    initialSelectedGroupByAttribute
  );

  const handleGroupByAttributeChange = (groupByAttribute: SelectOption) => {
    setSelectedGroupByAttribute(groupByAttribute);
    if (signalEventsFiltersFilterSortState?.manageChartSettingsChange) {
      signalEventsFiltersFilterSortState.manageChartSettingsChange(
        [
          {
            id: GROUP_BY_ATTRIBUTE_KEY,
            optionId: groupByAttribute.id,
          },
        ],
        SIGNAL_EVENTS_TOP_CONTRIBUTORS_GROUP_BY_OPTIONS_KEY
      );
    }
  };

  const {
    previousExposure,
    currentExposure,
    currentExposureBuckets,
    selectedExposureForAPI,
    selectedExposureBucketForAPI,
  } = useExposures(attributes, selectedOptions);

  useEffect(
    () => {
      if (previousExposure.current !== currentExposure) {
        previousExposure.current = currentExposure;

        setActions(
          getTopContributorsChartActions(
            topContributorsChartYAxisOptions,
            exposures,
            currentExposureBuckets,
            currentExposure
          )
        );

        handleSelectedOptionsChange(
          buildNewTopContributorsSelectedOptions(
            selectedOptions,
            currentExposureBuckets
          )
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedOptions]
  );

  const resetState = () => {
    handleGroupByAttributeChange(DEFAULT_GROUP_BY_SELECT_OPTION);
    signalEventsFiltersFilterSortState?.resetFilterSortState();
    vehiclesFiltersFilterSortState?.resetFilterSortState();
  };

  return (
    <>
      <TopContributorsGroupBySelector
        groupBySelectOptions={groupBySelectOptions}
        selectedGroupByAttribute={selectedGroupByAttribute}
        setSelectedGroupByAttribute={handleGroupByAttributeChange}
      />
      <TopChart
        selectedGroupByAttribute={selectedGroupByAttribute}
        vehiclesFilters={vehiclesFilters}
        signalEventsFilters={signalEventsFilters}
        filters={generalFilters}
        onSignalEventsFiltersChange={
          signalEventsFiltersFilterSortState?.manageFilterChange
        }
        onVehiclesFiltersChange={
          vehiclesFiltersFilterSortState?.manageFilterChange
        }
        actions={actions}
        selectedOptions={selectedOptions}
        setSelectedOptions={handleSelectedOptionsChange}
        selectedByVehicleAgeExposure={selectedExposureForAPI}
        selectedByVehicleAgeExposureBucket={selectedExposureBucketForAPI}
        onBadRequest={resetState}
      />
      <TopContributorsTable
        selectedGroupByAttribute={selectedGroupByAttribute}
        vehiclesFilters={vehiclesFilters}
        signalEventsFilters={signalEventsFilters}
        onFiltersUpdated={setGeneralFilters}
        onBadRequest={resetState}
        selectedByVehicleAgeExposure={selectedExposureForAPI}
        selectedByVehicleAgeExposureBucket={selectedExposureBucketForAPI}
      />
    </>
  );
};

export default TopContributors;
