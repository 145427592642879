import {
  APIListValuesRequest,
  APIListValuesResponse,
  APIPaginatedRequest,
  CountRequest,
  ExportFileType,
  ExportRequest,
  getFetcher,
} from "shared/api/api";
import client from "shared/api/axios";
import { createURL } from "shared/api/utils";
import { Vehicle } from "shared/api/vehicles/api";
import { MileageUnit } from "shared/types";

export interface APIListValuesFailureModeEventRequest
  extends APIListValuesRequest {
  failureModeId: string;
}

export type Predictions = Record<string, Prediction> | null;

interface Prediction {
  riskGroup: Risk;
  riskMultiple: number | null;
  prediction: number | null;
  riskStatus: string | null;
  repairStatus: string | null;
  repairDetails: string | null;
}

export type Risk = "low" | "medium" | "high" | null;

interface Prediction {
  riskGroup: Risk;
  riskMultiple: number | null;
  prediction: number | null;
  riskStatus: string | null;
}

// ---------
// SIMILAR VEHICLES
// ---------

export interface SimilarVehicle {
  currentVIN: string;
  similarVIN: string;
  failureModeID: string;
  date: string;
  similarity: number;
}

export interface ListSimilarVehiclesRequest extends APIPaginatedRequest {}

export const listSimilarVehiclesRequestURI = ({
  ...params
}: ListSimilarVehiclesRequest): string =>
  client.getUri({
    method: "get",
    url: createURL(["similarVehicles"]),
    params,
  });

// ---------
// Survival curves
// ---------
export interface SurvivalCurve {
  distance: number;
  probability: number;
}

export interface SurvivalCurveResponse {
  data: SurvivalCurve[];
  failureMileage99Percentile: number | null;
  failureMileageAverage: number | null;
}

export interface GetSurvivalCurvesRequest {
  failureModeID: string;
  distanceUnit: MileageUnit;
}

export const getSurvivalCurvesRequestURI = ({
  failureModeID,
  ...params
}: GetSurvivalCurvesRequest): string =>
  client.getUri({
    method: "get",
    url: createURL(["failureModes", failureModeID, "survivalCurve"]),
    params,
  });

// ---------
// Failure modes
// ---------
export type FailureModeEventType = "repair" | "failure";

export interface ListFailureModesRequest extends APIPaginatedRequest {
  skipRequest?: boolean;
}

export interface GetFailureModeRequest {
  id: string;
}

export interface ListFailureModesVehiclesRequest extends APIPaginatedRequest {
  id: string;
  mileageUnit: MileageUnit;
}

export interface ListFailureModesEventsRequest extends APIPaginatedRequest {
  id: string;
}

export interface ListFailureModesPredictionsRequest
  extends APIPaginatedRequest {}

export interface FailureMode {
  ID: string;
  name: string;
  description: string;
  status: string;
}

export interface FailureModeEvent {
  ID: string;
  VIN: string;
  eventTimestamp: string;
  eventType: FailureModeEventType;
  failureModeID: string;
  eventSourceID: string;
  eventSourceType: string;
  vehicle: Vehicle;
}

export interface FailureModePrediction {
  VIN: string;
  failureModeID: string;
  prediction: number;
  riskGroup: string;
  riskMultiple: number;
  riskStatus: string | null;
  repairStatus: string | null;
  repairDetails: string | null;
  warrantyActive: boolean | null;
}

export const listFailureModesRequestURI = ({
  skipRequest,
  ...params
}: ListFailureModesRequest): string =>
  skipRequest
    ? ""
    : client.getUri({
        method: "get",
        url: `/failureModes`,
        params,
      });

export const listFailureModes = (args: ListFailureModesRequest) =>
  getFetcher<FailureMode[]>(listFailureModesRequestURI(args));

export const getFailureModeRequestURI = ({
  id,
}: GetFailureModeRequest): string =>
  client.getUri({
    method: "get",
    url: createURL(["failureModes", id]),
  });

export const getFailureMode = (args: GetFailureModeRequest) =>
  getFetcher<FailureMode>(getFailureModeRequestURI(args));

export const listFailureModeVehiclesRequestURI = ({
  id,
  ...params
}: ListFailureModesVehiclesRequest): string =>
  client.getUri({
    method: "get",
    url: createURL(["failureModes", id, "vehicles"]),
    params,
  });

export const listFailureModeEventsRequestURI = ({
  id,
  ...params
}: ListFailureModesEventsRequest): string =>
  client.getUri({
    method: "get",
    url: createURL(["failureModes", id, "events"]),
    params,
  });

export const getFailureModeEventsCountRequestURI = ({
  id,
  ...params
}: ListFailureModesEventsRequest): string =>
  client.getUri({
    method: "get",
    url: createURL(["failureModes", id, "events", "count"]),
    params,
  });

export const getFailureModeVehiclesCountRequestURI = ({
  id,
  ...params
}: ListFailureModesEventsRequest): string =>
  client.getUri({
    method: "get",
    url: createURL(["failureModes", id, "vehicles", "count"]),
    params,
  });

export const listFailureModePredictionsRequestURI = (
  params: ListFailureModesPredictionsRequest
): string =>
  client.getUri({
    method: "get",
    url: `/failureModePredictions`,
    params,
  });

export const listFailureModePredictionsValues = (args: APIListValuesRequest) =>
  getFetcher<APIListValuesResponse>(
    listFailureModePredictionsValuesRequestURI(args)
  );

const listFailureModePredictionsValuesRequestURI = ({
  fieldName,
  ...params
}: APIListValuesRequest): string =>
  client.getUri({
    method: "get",
    url: createURL(["failureModePredictions", "values", fieldName]),
    params,
  });

export const listFailureModeEventsValues = (
  args: APIListValuesFailureModeEventRequest
) =>
  getFetcher<APIListValuesResponse>(
    listFailureModeEventsValuesRequestURI(args)
  );

const listFailureModeEventsValuesRequestURI = ({
  fieldName,
  failureModeId,
  ...params
}: APIListValuesFailureModeEventRequest): string =>
  client.getUri({
    method: "get",
    url: createURL([
      "failureModes",
      failureModeId,
      "events",
      "values",
      fieldName,
    ]),
    params,
  });

export const listFailureModePredictionsCountRequestURI = (
  params: CountRequest
): string =>
  client.getUri({
    method: "get",
    url: "/failureModePredictions/count",
    params,
  });

const getFailureModePredictionsExportRequestURI = ({
  type = "xlsx",
  ...params
}: ExportRequest): string =>
  client.getUri({
    method: "get",
    url: createURL(["failureModePredictions", "export", type]),
    params,
  });

export const getFailureModePredictionsExport = (args: ExportRequest) =>
  getFetcher<Blob>(getFailureModePredictionsExportRequestURI(args), {
    responseType: "blob",
  });

export type FailureModeEventTimelineGrouping = "day" | "month";
export interface FailureModeEventsTimelineRequest {
  failureModeID: string;
  grouping?: FailureModeEventTimelineGrouping;
  filter?: string;
}

export interface FailureModeEventsTimelineBucket {
  group: string;
  countFailures: number;
  countRepairs: number;
}

export const getFailureModeEventsTimelineURI = ({
  failureModeID,
  ...params
}: FailureModeEventsTimelineRequest): string =>
  client.getUri({
    method: "get",
    url: createURL(["failureModes", failureModeID, "eventsTimeline"]),
    params,
  });

interface FailureModeVehiclesExportRequest extends APIPaginatedRequest {
  type: ExportFileType;
  failureModeID: string;
}

const getFailureModeVehiclesExportRequestURI = ({
  type = "xlsx",
  failureModeID,
  ...params
}: FailureModeVehiclesExportRequest): string =>
  client.getUri({
    method: "get",
    url: createURL(["failureModes", failureModeID, "vehicles", "export", type]),
    params,
  });

export const getFailureModeVehiclesExport = (
  args: FailureModeVehiclesExportRequest
) =>
  getFetcher<Blob>(getFailureModeVehiclesExportRequestURI(args), {
    responseType: "blob",
  });

export interface FailureModeCampaignEventsTimelineBucket {
  group: string;
  countFailures: number;
  countRepairs: number;
}
