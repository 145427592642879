import { useEffect, useState } from "react";

import { PermissionEntry } from "shared/api/api";
import { PermissionType } from "shared/types";

import Button from "features/ui/Button";

import { isValidEmail } from "./utils";

interface Props {
  permission: PermissionEntry;
  permissions: PermissionEntry[];
  onAddPermission: () => void;
  onRemovePermission: (id: string) => void;
  permissionType: PermissionType;
}

const PermissionActions = ({
  permission,
  permissions,
  onAddPermission,
  onRemovePermission,
  permissionType,
}: Props) => {
  const { ID, email } = permission;

  if (ID) {
    return (
      <RemovePermissionButton
        id={ID}
        onRemovePermission={onRemovePermission}
        permissionType={permissionType}
      />
    );
  }

  return (
    <AddPermissionButton
      email={email?.toLowerCase() as string}
      permission={permission}
      permissions={permissions}
      onAddPermission={onAddPermission}
      permissionType={permissionType}
    />
  );
};

interface AddPermissionButtonProps {
  email: string;
  permission: PermissionEntry;
  permissions: PermissionEntry[];
  onAddPermission: () => void;
  permissionType: PermissionType;
}

const AddPermissionButton = ({
  email,
  permission,
  permissions,
  onAddPermission,
  permissionType,
}: AddPermissionButtonProps) => {
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const { groupID } = permission;

  useEffect(() => {
    if (permissionType === "user" && !isValidEmail(email)) {
      setButtonEnabled(false);
      return;
    }

    if (permissionType === "group") {
      setButtonEnabled(Boolean(groupID));
    } else {
      setButtonEnabled(
        !Boolean(
          permissions.find(
            (x) =>
              x.ID && x.email?.toLowerCase() === email.toLowerCase() && x.shown
          )
        )
      );
    }
  }, [email, groupID, permissionType, permissions]);

  return (
    <Button
      color="primary"
      label="Add"
      size="small"
      className="w-20"
      onClick={onAddPermission}
      disabled={!buttonEnabled}
      testId={`${permissionType}-permission-add-button`}
    />
  );
};

interface RemovePermissionButtonProps {
  id: string;
  onRemovePermission: (id: string) => void;
  permissionType: PermissionType;
}

const RemovePermissionButton = ({
  id,
  onRemovePermission,
  permissionType,
}: RemovePermissionButtonProps) => {
  return (
    <Button
      variant="text"
      label="Remove"
      size="small"
      onClick={() => onRemovePermission(id)}
      testId={`${permissionType}-permission-remove-button`}
    />
  );
};

export default PermissionActions;
