import {
  IssueChart,
  IssueChartGroupBy,
  IssueClaimGroupBy,
  IssueVehiclePopulation,
} from "shared/types";
import { roundToNDecimals } from "shared/utils";

import { appendTsFromDate, getChartValues } from "pages/Issues/utils";

import { COLOR_PALETTE } from "features/ui/charts/constants";
import { YAxisLine } from "features/ui/charts/types";

export interface DataLoadFuncParams {
  IDs: string[];
  baseRoute: string;
  vehiclePopulation: IssueVehiclePopulation;
  byVehicleAgeExposure?: string;
  signalEventsTimeWindow?: number;
  groupBy?: IssueClaimGroupBy;
  // updatedAt param is not required by the API but is passed to force the re-triggering of the API
  // calls after editing the issue
  updatedAt: string;
}

export interface DataLoadGroupFuncParams {
  IDs: string[];
  baseRoute: string;
  groupBy: IssueClaimGroupBy;
  vehiclePopulation: IssueVehiclePopulation;
  // updatedAt param is not required by the API but is passed to force the re-triggering of the API
  // calls after editing the issue
  updatedAt: string;
}

export const getChartLines = (
  withComparisonPopulation: boolean,
  withPreceding: boolean,
  precedingStr: string = ""
) => {
  const atRiskLine: YAxisLine = {
    key: "atRisk",
    color: COLOR_PALETTE[0],
    label: "At-Risk Vehicle Population",
  };

  const comparisonLine: YAxisLine = {
    key: "comparison",
    color: COLOR_PALETTE[1],
    label: "Comparison Vehicle Population",
  };

  if (!withPreceding) {
    if (!withComparisonPopulation) return [atRiskLine];
    return [atRiskLine, comparisonLine];
  }

  const atRiskWithPrecedingLine: YAxisLine = {
    key: "atRiskWithPreceding",
    color: COLOR_PALETTE[2],
    label: `At-Risk Vehicle Population ${precedingStr}`,
  };

  const comparisonWithPrecedingLine: YAxisLine = {
    key: "comparisonWithPreceding",
    color: COLOR_PALETTE[3],
    label: `Comparison Vehicle Population ${precedingStr}`,
  };

  return [
    atRiskLine,
    atRiskWithPrecedingLine,
    withComparisonPopulation && comparisonLine,
    withComparisonPopulation && comparisonWithPrecedingLine,
  ].filter(Boolean) as YAxisLine[];
};

export const isBarChart = (chart: IssueChart) =>
  ["RepairEfficacy_ReoccurrenceBar"].includes(chart);

export const getChartData = (
  chart: IssueChart,
  data: any[],
  xAxisKey: string,
  yAxisKey: string,
  comparisonData?: any[],
  yAxisWithPrecedingKey?: string,
  valueFormatter?: (val: any) => {},
  groupByField?: IssueChartGroupBy,
  multipleLinesYAxisKeys: string[] = []
) => {
  switch (chart) {
    case "Claims_OccurrencesByVehicleAge":
    case "Claims_OccurrencesByCalendarTime":
    case "SignalEvents_OccurrencesByVehicleAge":
    case "SignalEvents_OccurrencesByCalendarTime":
    case "RepairEfficacy_ReoccurrenceByPopulation":
      return prepareDataForPopulations(
        xAxisKey,
        yAxisKey,
        data,
        comparisonData,
        valueFormatter,
        yAxisWithPrecedingKey
      );
    case "Claims_TopXByVehicleAge":
    case "Claims_TopXByCalendarTime":
    case "SignalEvents_RateByVehicleAge":
    case "SignalEvents_RateByCalendarTime":
    case "RepairEfficacy_ReoccurrenceByAttribute":
      return prepareLineChartData(
        groupByField as IssueChartGroupBy,
        xAxisKey,
        yAxisKey,
        data,
        valueFormatter
      );
    case "Relationships_CombinedOccurrencesByVehicleAge":
    case "Relationships_CombinedOccurrencesByCalendarTime":
      return prepareMultipleLineChartData(
        xAxisKey,
        multipleLinesYAxisKeys,
        data,
        valueFormatter
      );
    case "RepairEfficacy_ReoccurrenceBar":
      return prepareBarChartData(
        groupByField as IssueChartGroupBy,
        xAxisKey,
        yAxisKey,
        data,
        comparisonData,
        valueFormatter
      );
    default:
      return [];
  }
};

// export needed for tests
export const prepareBarChartData = (
  groupBy: IssueChartGroupBy,
  xAxisKey: string,
  yAxisKey: string,
  data: Record<string, any>[],
  comparisonData?: Record<string, any>[],
  valueFormatter: (val: any) => {} = (val) => roundToNDecimals(val, 2)
) => {
  const distinctGroupByValues = getChartValues(
    groupBy,
    data,
    comparisonData,
    false
  );
  const chartData: Record<string, any>[] = [];

  distinctGroupByValues.forEach((groupByValue) => {
    const val = data?.find((x) => x[groupBy] === groupByValue)?.[yAxisKey];
    if (val !== undefined) {
      chartData.push({
        [xAxisKey]: `${groupByValue} - At Risk`,
        [yAxisKey]: valueFormatter(val as number),
      });
    }
    const compVal = comparisonData?.find((x) => x[groupBy] === groupByValue)?.[
      yAxisKey
    ];
    if (compVal !== undefined) {
      chartData.push({
        [xAxisKey]: `${groupByValue} - Comparison`,
        [yAxisKey]: valueFormatter(compVal as number),
      });
    }
  });
  return chartData;
};

// export needed for tests
export const prepareLineChartData = (
  groupBy: IssueChartGroupBy,
  xAxisKey: string,
  yAxisKey: string,
  data?: Record<string, any>[],
  valueFormatter: (val: any) => {} = (val) => roundToNDecimals(val, 2)
) => {
  if (data && data.length > 0 && Object.keys(data[0]).includes("date")) {
    data = appendTsFromDate(data);
  }

  const xAxisValues = getChartValues(xAxisKey, data);
  const yAxisLabels = getChartValues(groupBy, data, [], false);

  const yAxisValues = yAxisLabels.reduce(
    (o, key) => ({ ...o, [key]: 0.0 }),
    {}
  );

  return xAxisValues.map((xAxisValue) => {
    const chartEntry = {
      [xAxisKey]: xAxisValue,
      ...yAxisValues,
    };

    const xData = data?.filter((x) => x[xAxisKey] === xAxisValue);
    if (xData) {
      yAxisLabels.forEach((yAxisLabel) => {
        const entry = xData.find(
          (x) => x[groupBy] === yAxisLabel && x[yAxisKey] !== undefined
        );
        if (entry) {
          chartEntry[yAxisLabel] = valueFormatter(entry[yAxisKey]);
        }
      });
    }
    return chartEntry;
  });
};

const prepareMultipleLineChartData = (
  xAxisKey: string,
  yAxisKeys: string[],
  data?: Record<string, any>[],
  valueFormatter: (val: any) => {} = (val) => roundToNDecimals(val, 2)
) => {
  if (data && data.length > 0 && Object.keys(data[0]).includes("date")) {
    data = appendTsFromDate(data);
  }

  const xAxisValues = getChartValues(xAxisKey, data);
  const yAxisValues: Record<any, any> = yAxisKeys.reduce(
    (o, key) => Object.assign(o, { [key]: 0.0 }),
    {}
  );

  const chartData: Record<string, any>[] = [];

  xAxisValues.forEach((xAxisValue) => {
    const chartEntry: Record<any, any> = {
      [xAxisKey]: xAxisValue,
      ...yAxisValues,
    };
    const entry: Record<any, any> | undefined = data?.find(
      (x) => x[xAxisKey] === xAxisValue
    );
    yAxisKeys.forEach((yAxisKey) => {
      if (entry && entry[yAxisKey] !== undefined) {
        chartEntry[yAxisKey] = valueFormatter(entry[yAxisKey]);
      }
    });
    chartData.push(chartEntry);
  });
  return chartData;
};

const createObjWithXAxisKey = (
  xAxisKey: string,
  data?: Record<any, any>[]
): Record<number, Record<string, any>> => {
  if (!data) return {};
  return data.reduce((obj: Record<number, object>, val) => {
    obj[val[xAxisKey]] = val;
    return obj;
  }, {});
};

const getValueFromXData = (
  data: Record<any, any>,
  xAxisVal: number,
  key: string,
  valueFormatter: (val: any) => {}
) => {
  if (!data || !data[xAxisVal] || data[xAxisVal][key] === undefined) {
    return undefined;
  }
  return valueFormatter(data[xAxisVal][key]);
};

export const prepareDataForPopulations = (
  xAxisKey: string,
  yAxisKey: string,
  data: Record<string, any>[],
  comparisonData?: Record<string, any>[],
  valueFormatter: (val: any) => {} = (val) => roundToNDecimals(val, 2),
  yAxisWithPrecedingKey?: string
) => {
  if (xAxisKey === "ts") {
    data = appendTsFromDate(data) as any[];
    comparisonData = appendTsFromDate(comparisonData) as any[];
  }

  const perXData = createObjWithXAxisKey(xAxisKey, data);
  const perXCompData = createObjWithXAxisKey(xAxisKey, comparisonData);

  const xAxisValues = getChartValues(xAxisKey, data, comparisonData);

  return xAxisValues.map((xAxisValue) => {
    const entry: Record<string, any> = {
      [xAxisKey]: xAxisValue,
      atRisk: getValueFromXData(perXData, xAxisValue, yAxisKey, valueFormatter),
      comparison: getValueFromXData(
        perXCompData,
        xAxisValue,
        yAxisKey,
        valueFormatter
      ),
    };
    if (yAxisWithPrecedingKey) {
      entry["atRiskWithPreceding"] = getValueFromXData(
        perXData,
        xAxisValue,
        yAxisWithPrecedingKey,
        valueFormatter
      );
      entry["comparisonWithPreceding"] = getValueFromXData(
        perXCompData,
        xAxisValue,
        yAxisWithPrecedingKey,
        valueFormatter
      );
    }
    return entry;
  });
};
