import {
  ATTRIBUTE_RETRIEVAL_DISTANCE_THRESHOLD,
  ATTRIBUTE_RETRIEVAL_K,
  ATTRIBUTE_SOURCE_KEYS,
} from "duck/graph/constants";
import getUpdateClaimsFilterTool from "duck/graph/nodes/ClaimAnalyticsAgent/tools/getUpdateClaimsFilterTool";
import { validateFilter } from "duck/graph/nodes/ClaimAnalyticsAgent/utils";
import { PageHandler } from "duck/graph/PageHandler";
import getAttributeRetrievalTool from "duck/graph/tools/getAttributeRetrievalTool";
import getCaptureScreenshotTool from "duck/graph/tools/getCaptureScreenshotTool";
import getGoToAssociatedSignalEventsTabTool from "duck/graph/tools/getGoToAssociatedSignalEventsTabTool";
import getGoToAssociatedVehiclesTabTool from "duck/graph/tools/getGoToAssociatedVehiclesTabTool";
import getGoToByVehiclesAgeTabTool from "duck/graph/tools/getGoToByVehiclesAgeTabTool";
import getGoToTopContributorsTabTool from "duck/graph/tools/getGoToTopContributorsTabTool";
import getUpdateVehiclesFilterTool from "duck/graph/tools/getUpdateVehiclesFilterTool";
import { DuckGraphParams } from "duck/graph/types";
import { StructuredTool } from "@langchain/core/tools";

import {
  CLAIMS_PAGE_KEY,
  VEHICLES_PAGE_KEY,
} from "pages/ClaimAnalytics/constants";
import { CLAIM_ANALYTICS_ASSOCIATED_SIGNAL_EVENTS_PAGE_KEY } from "pages/ClaimAnalytics/tabPages/AssociatedSignalEvents";
import { BY_VEHICLE_AGE_CHART_OPTIONS_KEY } from "pages/ClaimAnalytics/tabPages/ByVehicleAge/ByVehicleAge";
import {
  CLAIM_ANALYTICS_TOP_CONTRIBUTORS_CHART_OPTIONS_KEY,
  CLAIM_ANALYTICS_TOP_CONTRIBUTORS_GROUP_BY_OPTIONS_KEY,
  DEFAULT_GROUP_BY_ATTRIBUTE,
} from "pages/ClaimAnalytics/tabPages/TopContributors/TopContributors";

import { RelatesFilterWindowDirection } from "features/ui/Filters/types";

import getGoToClaimsTabTool from "./getGoToClaimsTabTool";

/**
 * @summary Get the tools for the claim analytics agent.
 * The tools are specific to claim analytics, and are bound to the UI handlers
 * for claim analytics.
 * @param params The data from the UI layer that is necessary for the tools to function.
 * Individual tools need different elements from params.
 * @returns The full set of tools for the claim analytics agent.
 */
const getTools = (
  params: DuckGraphParams,
  pageHandler: PageHandler
): StructuredTool[] => [
  getAttributeRetrievalTool(
    ATTRIBUTE_SOURCE_KEYS.Claim,
    ATTRIBUTE_RETRIEVAL_K,
    ATTRIBUTE_RETRIEVAL_DISTANCE_THRESHOLD,
    params.uiHandlers.setEphemeralMessage
  ),
  getAttributeRetrievalTool(
    ATTRIBUTE_SOURCE_KEYS.Vehicle,
    ATTRIBUTE_RETRIEVAL_K,
    ATTRIBUTE_RETRIEVAL_DISTANCE_THRESHOLD,
    params.uiHandlers.setEphemeralMessage
  ),
  getUpdateClaimsFilterTool(pageHandler, params.uiHandlers.setEphemeralMessage),
  getUpdateVehiclesFilterTool(
    pageHandler,
    VEHICLES_PAGE_KEY,
    validateFilter,
    params.uiHandlers.setEphemeralMessage
  ),
  getGoToClaimsTabTool(
    pageHandler,
    params.availableData.claimAnalytics.claimsChartOptions,
    params.uiHandlers.setEphemeralMessage
  ),
  getGoToByVehiclesAgeTabTool({
    pageHandler,
    chartOptions: params.availableData.claimAnalytics.byVehicleAgeChartOptions,
    pageKey: CLAIMS_PAGE_KEY,
    chartOptionsKey: BY_VEHICLE_AGE_CHART_OPTIONS_KEY,
    setEphemeralMessage: params.uiHandlers.setEphemeralMessage,
  }),
  getGoToTopContributorsTabTool({
    pageHandler,
    chartOptions:
      params.availableData.claimAnalytics.topContributorsChartOptions,
    groupByOptions:
      params.availableData.claimAnalytics.topContributorsGroupByOptions,
    selectedChartOptions:
      params.currentState.claimAnalytics.selectedTopContributorsChartOptions,
    selectedGroupByAttribute:
      params.currentState.claimAnalytics.selectedGroupByAttribute,
    defaultGroupByAttribute: DEFAULT_GROUP_BY_ATTRIBUTE,
    pageKey: CLAIMS_PAGE_KEY,
    chartOptionsKey: CLAIM_ANALYTICS_TOP_CONTRIBUTORS_CHART_OPTIONS_KEY,
    groupByOptionsKey: CLAIM_ANALYTICS_TOP_CONTRIBUTORS_GROUP_BY_OPTIONS_KEY,
    setEphemeralMessage: params.uiHandlers.setEphemeralMessage,
  }),
  getGoToAssociatedVehiclesTabTool(
    pageHandler,
    params.uiHandlers.setEphemeralMessage
  ),
  getGoToAssociatedSignalEventsTabTool(
    pageHandler,
    CLAIM_ANALYTICS_ASSOCIATED_SIGNAL_EVENTS_PAGE_KEY,
    [RelatesFilterWindowDirection.BEFORE],
    params.uiHandlers.setEphemeralMessage
  ),
  getCaptureScreenshotTool(params.uiHandlers.setEphemeralMessage),
];

export default getTools;
