import { TestProps } from "shared/types";

export interface SectionProps extends TestProps {
  children: React.ReactNode | React.ReactNode[];
}

const Section = ({ testId, children }: SectionProps) => {
  return (
    <div className="text-xl mb-7" data-testid={testId}>
      {children}
    </div>
  );
};

export default Section;
