import { IssueTypes } from "shared/types";

import CardHeader from "pages/Issues/Details/CardHeader";
import { getPopulationString } from "pages/Issues/utils";

import Card from "features/ui/Card";

import IssueClaimsPopulationStatistics from "./IssueClaimsPopulationStatistics";

interface Props {
  issue: IssueTypes;
  withComparisonPopulation: boolean;
}

const AT_RISK_TITLE = `Claims: ${getPopulationString("atRisk")}`;
const PER_POPULATION_TITLE = "Claims Metrics per Population";

const IssueClaimsStatistics = (props: Props) => {
  return (
    <Card classNames="mb-3">
      <CardHeader
        title={
          props.withComparisonPopulation ? PER_POPULATION_TITLE : AT_RISK_TITLE
        }
        showActions={false}
      />
      <div className="space-y-3">
        <IssueClaimsPopulationStatistics population="atRisk" {...props} />
        {props.withComparisonPopulation && (
          <IssueClaimsPopulationStatistics population="comparison" {...props} />
        )}
      </div>
    </Card>
  );
};

export default IssueClaimsStatistics;
