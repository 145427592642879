import CardActions, { CreateIssueProps } from "./CardActions";

interface Props {
  title: string;
  canEdit?: boolean;
  createIssueProps?: CreateIssueProps;
  showActions?: boolean;
}

const CardHeader = ({
  title,
  canEdit,
  createIssueProps,
  showActions = true,
}: Props) => (
  <div className="flex justify-between mb-3 w-full">
    <h3 className="flex space-x-3 items-center font-semibold mb-1 capitalize">
      {title}
    </h3>
    {showActions && (
      <CardActions canEdit={canEdit} createIssueProps={createIssueProps} />
    )}
  </div>
);

export default CardHeader;
