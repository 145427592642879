import {
  cumulativeAffectedVINs,
  cumulativeDPTV,
  cumulativeDPTVDV,
  cumulativeOccurrences,
  rollingAffectedVINs,
  rollingDPTV,
  rollingDPTVDV,
  rollingOccurrences,
  yAxis,
} from "features/ui/charts/actions";
import { ChartAction } from "features/ui/charts/Actions/types";
import { Option, SelectOption } from "features/ui/Select";

export const Y_AXIS_OPTIONS: SelectOption[] = [
  rollingDPTVDV,
  cumulativeDPTVDV,
  rollingDPTV,
  cumulativeDPTV,
  rollingOccurrences,
  cumulativeOccurrences,
  rollingAffectedVINs,
  cumulativeAffectedVINs,
] as const;

export const signalEventsActionIds = ["y"] as const;

export type SignalEventsActionIdType = (typeof signalEventsActionIds)[number];

export const getSignalEventChartActions = (): ChartAction<Option>[] => [
  {
    id: "y",
    title: yAxis,
    type: "dropdownSelect",
    options: Y_AXIS_OPTIONS,
  },
];
