import { useEffect } from "react";
import classNames from "classnames";

import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import FiltersOverview from "features/ui/Filters/FiltersOverview/FiltersOverview";
import { useFilterSortState } from "features/ui/Filters/hooks";
import { SchemaEntry } from "features/ui/Table";

import { BASIC_FILTERS_WIDTH_CLASS } from "./constants";
import FilterSelector from "./FilterSelector";
import { getPendingFiltersKey } from "./utils";

export interface FilterWizardProps {
  schema: SchemaEntry[];
  pageKey: string;
  initialFilters?: FilterGroupState;
  onFiltersChange?: (filters: FilterGroupState) => void;
  belowRightBlockContent?: React.ReactNode;
  testId: string;
  disableUsingLocalStorage?: boolean;
}

export const OVERVIEWS_WIDTH_CLASS = "w-96";
const RIGHT_BLOCK_TITLE = "Filter Summary";

const FilterWizard = ({
  schema,
  pageKey,
  belowRightBlockContent,
  initialFilters,
  testId,
  disableUsingLocalStorage = false,
  onFiltersChange,
}: FilterWizardProps) => {
  const filterSortState = useFilterSortState({
    pageKey,
    disableUsingQuery: true,
    disableUsingLocalStorage,
    defaultFilterValues: initialFilters,
  });

  const isAdvancedEditor = filterSortState.isAdvancedFilterEditor;

  useEffect(() => {
    // TODO: also debug this as possibly it doesnt trigger as we think it does, due to filters being an object and useEffect
    // not really triggering when internal object properties change.
    onFiltersChange && onFiltersChange(filterSortState.filters);
  }, [filterSortState.filters, onFiltersChange]);

  const showBelowRightBlockContent =
    !!belowRightBlockContent && !isAdvancedEditor;

  return (
    <div className="flex flex-col" data-testid={testId}>
      <div className="flex space-x-8">
        <FilterSelector
          schema={schema}
          filterSortState={filterSortState}
          pendingFiltersKey={getPendingFiltersKey(pageKey)}
          testId={testId}
          initialIsAdvancedFilter={isAdvancedEditor}
        />
        {!isAdvancedEditor && (
          <div className={classNames(OVERVIEWS_WIDTH_CLASS, "flex flex-col")}>
            <div className="text-sm mb-1">{RIGHT_BLOCK_TITLE}</div>
            <div className="relative border p-3 flex-1">
              <FiltersOverview
                filters={filterSortState.filters}
                onFiltersReset={filterSortState.resetFilters}
                tableSchema={schema}
                testId="filters-overview"
              />
            </div>
          </div>
        )}
      </div>
      {showBelowRightBlockContent && (
        <div className="flex space-x-8">
          <div className={BASIC_FILTERS_WIDTH_CLASS} />
          <div className={classNames(OVERVIEWS_WIDTH_CLASS, "mt-1 text-sm")}>
            {belowRightBlockContent}
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterWizard;
