import { TooltipProps } from "recharts";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";

import { SHORT_DATE_FORMAT } from "shared/constants";
import { useClaimsSchema } from "shared/schemas/claimsSchema";
import { formatDate } from "shared/utils";

const ReoccurrenceProceedingAClaimChartTooltip = ({
  active,
  payload,
}: TooltipProps<ValueType, NameType>) => {
  const { getDisplayLabel } = useClaimsSchema();
  if (!active) {
    return null;
  }

  const data = payload && payload?.[0].payload;
  const isSignalEvent = data["codeType"];

  return (
    <div className="flex flex-col bg-white shadow px-2 py-3 text-sm">
      {isSignalEvent && (
        <div>
          <div style={{ color: data["color"] }}>
            <div>
              Total {data["codeType"]} Occurrences: {data["count"]}
            </div>
            <div>
              Distinct Codes Triggered:{" "}
              {data["distinctSignalEvents"].join(", ")}
            </div>
          </div>
          <div>Days Since Claim: {data["daysSinceClaim"]}</div>
        </div>
      )}
      {!isSignalEvent && (
        <div>
          {data["claimDate"] && (
            <div>
              Claim Date: {formatDate(data["claimDate"], SHORT_DATE_FORMAT)}
            </div>
          )}
          <div style={{ color: data["color"] }}>
            <div>
              {getDisplayLabel("laborCode", "Labor Code")}: {data["laborCode"]}
            </div>
          </div>
          <div style={{ color: data["color"] }}>
            <div>
              {getDisplayLabel("failedPartNumber", "Failed Part Number")}:{" "}
              {data["failedPartNumber"]}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReoccurrenceProceedingAClaimChartTooltip;
