import { useState } from "react";

import { ReoccurrenceProps } from "shared/types";
import { pluralize } from "shared/utils";

import CardHeader from "pages/Issues/Details/CardHeader";
import {
  getRelatedSignalEventsTimePeriods,
  getSignalEventReoccurrencesTimePeriods,
} from "pages/Issues/utils";

import Button from "features/ui/Button";
import Select from "features/ui/Select";

interface Props extends ReoccurrenceProps {
  title: string;
  onClose: () => void;
  onConfirm: (
    occurredWithin: number,
    priorToClaim: number,
    followingClaim: number
  ) => void;
}

const ReoccurrenceTimePeriodSelection = ({
  title,
  seOccurrenceWithin,
  seReoccurrenceStartingFrom,
  seReoccurrenceUpTo,
  onClose,
  onConfirm,
}: Props) => {
  const [occurrenceWithin, setOccurrenceWithin] = useState(seOccurrenceWithin);
  const [reoccurrenceStartingFrom, setReoccurrenceStartingFrom] = useState(
    seReoccurrenceStartingFrom
  );
  const [reoccurrenceUpTo, setReoccurrenceUpTo] = useState(seReoccurrenceUpTo);

  const occurrenceTimePeriods = getRelatedSignalEventsTimePeriods();
  const reoccurrenceTimePeriods = getSignalEventReoccurrencesTimePeriods();

  const selectedValuesError = reoccurrenceStartingFrom > reoccurrenceUpTo;

  return (
    <div>
      <CardHeader title={title} showActions={false} />
      <div className="space-y-5">
        <div className="text-gray-500">Signal event occurred within</div>
        <div className="flex items-center space-x-2">
          <div className="max-w-[100px]">
            <Select
              options={occurrenceTimePeriods}
              onSelect={({ id }) => setOccurrenceWithin(id)}
              selected={{
                id: occurrenceWithin,
                value: occurrenceWithin,
                testId: occurrenceWithin.toString(),
              }}
              testId="signal-event-occurred-within-dropdown"
            />
          </div>
          <div>{pluralize("day", occurrenceWithin)} prior to claim</div>
        </div>
        <hr />
        <div className="text-gray-500">Signal event reoccurred</div>
        <div className="flex items-center space-x-5">
          <div className="space-y-2">
            <div>Starting</div>
            <div className="flex items-center space-x-2">
              <div className="max-w-[100px]">
                <Select
                  options={reoccurrenceTimePeriods}
                  onSelect={({ id }) => setReoccurrenceStartingFrom(id)}
                  testId="signal-event-reoccurred-starting-dropdown"
                  selected={{
                    id: reoccurrenceStartingFrom,
                    value: reoccurrenceStartingFrom,
                    testId: reoccurrenceStartingFrom.toString(),
                  }}
                />
              </div>
              <div>{pluralize("day", reoccurrenceStartingFrom)}</div>
            </div>
          </div>
          <div className="space-y-2">
            <div>and up to</div>
            <div className="flex items-center space-x-2">
              <div className="max-w-[100px]">
                <Select
                  options={reoccurrenceTimePeriods}
                  onSelect={({ id }) => setReoccurrenceUpTo(id)}
                  testId="signal-event-reoccurred-ending-dropdown"
                  selected={{
                    id: reoccurrenceUpTo,
                    value: reoccurrenceUpTo,
                    testId: reoccurrenceUpTo.toString(),
                  }}
                />
              </div>
              <div>{pluralize("day", reoccurrenceUpTo)} following claim</div>
            </div>
          </div>
        </div>
        {selectedValuesError && (
          <div className="text-red-500">
            Starting number of days must be lower than "up to" number of days.
          </div>
        )}
        <div className="flex justify-end space-x-2">
          <Button color="secondary" label="Close" onClick={onClose} />
          <Button
            color="primary"
            label="Confirm"
            disabled={selectedValuesError}
            onClick={() =>
              onConfirm(
                occurrenceWithin,
                reoccurrenceStartingFrom,
                reoccurrenceUpTo
              )
            }
            testId="reoccurring-time-period-cta"
          />
        </div>
      </div>
    </div>
  );
};

export default ReoccurrenceTimePeriodSelection;
