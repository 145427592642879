import { splitByFirstOccurrence } from "shared/utils";

const JOIN_LABEL_DESCRIPTION_STRING = " - ";
const MAX_DESCRIPTION_LENGTH = 20;

interface LabelDescriptionRotatedTickProps {
  x?: number;
  y?: number;
  payload?: any;
  clickable?: boolean;
}

export const LabelDescriptionRotatedTick = ({
  x,
  y,
  payload,
  clickable,
}: LabelDescriptionRotatedTickProps) => {
  const [label, description]: string[] = splitByFirstOccurrence(
    payload.value,
    JOIN_LABEL_DESCRIPTION_STRING
  );

  const shortenedDescription =
    description.length > MAX_DESCRIPTION_LENGTH
      ? description.slice(0, MAX_DESCRIPTION_LENGTH - 3) + "..."
      : description;

  return (
    <g
      transform={`translate(${x},${y})`}
      className={clickable ? "cursor-pointer" : ""}
    >
      <text
        x={0}
        y={0}
        dy={4}
        textAnchor="end"
        fill="#666"
        transform="rotate(-70)"
        fontSize={14}
      >
        {label}
      </text>
      <text
        x={4}
        y={12}
        dy={4}
        textAnchor="end"
        fill="#666"
        transform="rotate(-70)"
        fontSize={12}
      >
        {shortenedDescription}
      </text>
    </g>
  );
};
