import { GroupUser } from "shared/api/rbac/api";

export interface GroupMemberEntry {
  ID: string;
  newEntry: boolean;
  email: string;
}

interface MembersDiff {
  create: string[];
  remove: string[];
}

export const createGroupMembersDiff = (
  oldMembers: GroupUser[],
  newMembers: GroupMemberEntry[]
): MembersDiff => {
  const oldEmails = [
    ...new Set(oldMembers.filter((m) => m.email).map((m) => m.email)),
  ];
  const newEmails = [
    ...new Set(newMembers.filter((m) => m.email && m.ID).map((m) => m.email)),
  ];

  return {
    create: newEmails.filter((x) => !oldEmails.includes(x)).sort(),
    remove: oldEmails.filter((x) => !newEmails.includes(x)).sort(),
  };
};

export const isEmptyDiff = (diff: MembersDiff) => {
  return diff.create.length === 0 && diff.remove.length === 0;
};
