import {
  APIListValuesRequest,
  APIListValuesResponse,
  APIPaginatedRequest,
  CountRequest,
  CountResponse,
  EntityAttribute,
  getFetcher,
  ListAttributesRequest,
} from "shared/api/api";
import { DEFAULT_ATTRIBUTES_SWR_CONFIG } from "shared/api/constants";
import { useAPI, usePaginatedAPI, usePostAPI } from "shared/api/hooks";

import {
  CustomSignalEvent,
  CustomSignalEventDefinition,
  CustomSignalEventsRequest,
  CustomSignalEventsRequestBody,
  CustomSignalEventsTimelineBucket,
  CustomSignalEventsTimelineRequest,
  GetCustomSignalEventDefinitionRequest,
  getCustomSignalEventDefinitionRequestURI,
  listCustomSignalEventDefinitionsAttributesRequestURI,
  listCustomSignalEventDefinitionsCountURI,
  listCustomSignalEventDefinitionsURI,
  listCustomSignalEventDefinitionsValuesRequestURI,
  listCustomSignalEventsCountURI,
  listCustomSignalEventsTimelineURI,
  listCustomSignalEventsURI,
} from "./api";

export const useListCustomSignalEvents = (
  args: CustomSignalEventsRequest,
  body: CustomSignalEventsRequestBody
) =>
  usePostAPI<typeof args, CustomSignalEvent[]>(
    listCustomSignalEventsURI,
    args,
    body
  );

export const useListCustomSignalEventsTimeline = (
  args: CustomSignalEventsTimelineRequest,
  body: CustomSignalEventsRequestBody
) =>
  usePostAPI<typeof args, CustomSignalEventsTimelineBucket[]>(
    listCustomSignalEventsTimelineURI,
    args,
    body
  );

export const useListCustomSignalEventsCount = (
  args: CustomSignalEventsRequest,
  body: CustomSignalEventsRequestBody
) =>
  usePostAPI<typeof args, CountResponse>(
    listCustomSignalEventsCountURI,
    args,
    body
  );

export const useListCustomSignalEventDefinitions = (
  args: APIPaginatedRequest
) =>
  usePaginatedAPI<typeof args, CustomSignalEventDefinition[]>(
    listCustomSignalEventDefinitionsURI,
    args
  );

export const useListCustomSignalEventDefinitionsCount = (args: CountRequest) =>
  usePaginatedAPI<typeof args, CountResponse>(
    listCustomSignalEventDefinitionsCountURI,
    args
  );

export const useCustomSignalEventDefinitionsAttributes = (
  args: ListAttributesRequest
) =>
  useAPI<typeof args, EntityAttribute[]>(
    listCustomSignalEventDefinitionsAttributesRequestURI,
    args,
    DEFAULT_ATTRIBUTES_SWR_CONFIG
  );

export const listCustomSignalEventDefinitionValues = (
  args: APIListValuesRequest
) =>
  getFetcher<APIListValuesResponse>(
    listCustomSignalEventDefinitionsValuesRequestURI(args)
  );

export const useCustomSignalEventDefinition = (
  args: GetCustomSignalEventDefinitionRequest
) =>
  useAPI<typeof args, CustomSignalEventDefinition>(
    getCustomSignalEventDefinitionRequestURI,
    args
  );
