import React from "react";
import Skeleton from "react-loading-skeleton";

import { useGetIssueRepairEfficacy } from "shared/api/issues/hooks";
import {
  IssueTypes,
  IssueVehiclePopulation,
  ReoccurrenceProps,
} from "shared/types";
import {
  roundNonZeroToNDecimals,
  roundPercentageToNDecimals,
} from "shared/utils";

import IssueStatistics, { LabelTexts } from "pages/Issues/IssueStatistics";
import { getBaseAPIRoute, getIssueCombinedID } from "pages/Issues/utils";

import APIError from "features/ui/APIError";

interface Props extends ReoccurrenceProps {
  issue: IssueTypes;
  population: IssueVehiclePopulation;
  withComparisonPopulation: boolean;
}
const IssueRepairEfficacyPopulationStatistics = ({
  issue,
  population,
  withComparisonPopulation,
  seOccurrenceWithin,
  seReoccurrenceStartingFrom,
  seReoccurrenceUpTo,
}: Props) => {
  const { updatedAt } = issue;

  const { data, isLoading, error } = useGetIssueRepairEfficacy({
    IDs: getIssueCombinedID(issue),
    baseRoute: getBaseAPIRoute(issue),
    vehiclePopulation: population,
    signalEventsTimeWindow: seOccurrenceWithin,
    signalEventsReoccurrenceTimeWindowFrom: seReoccurrenceStartingFrom,
    signalEventsReoccurrenceTimeWindowTo: seReoccurrenceUpTo,
    updatedAt,
  });

  if (isLoading) {
    return <Skeleton height={200} />;
  }

  if (error) {
    return <APIError error={error} />;
  }

  if (!data) {
    return null;
  }

  const {
    countOfClaimsWithReoccurringSignalEvent,
    percentOfClaimsWithReoccurringSignalEvent,
  } = data;

  const labelTexts: LabelTexts[] = [
    {
      mainValue:
        percentOfClaimsWithReoccurringSignalEvent !== null
          ? `${roundPercentageToNDecimals(
              percentOfClaimsWithReoccurringSignalEvent
            )}%`
          : undefined,
      secondaryValue: `(${roundNonZeroToNDecimals(
        countOfClaimsWithReoccurringSignalEvent
      )} claims)`,
      description: "of Claims were followed by reoccurring signal event",
    },
  ];

  return (
    <IssueStatistics
      withComparisonPopulation={withComparisonPopulation}
      population={population}
      labelTexts={labelTexts}
    />
  );
};

export default IssueRepairEfficacyPopulationStatistics;
