import { EntityAttribute } from "shared/api/api";
import {
  CLAIMS_GENERIC_FILTER,
  SIGNAL_EVENT_OCCURRENCES_GENERIC_FILTER,
  VEHICLES_GENERIC_FILTER,
} from "shared/filterDefinitions";

import {
  getClaimDealerDisplayNameValue,
  getClaimDealerIdValue,
} from "pages/ClaimAnalytics/utils";

import { FilterSchemaItem } from "features/ui/Filters/types";
import { getFilterType } from "features/ui/Filters/utils";
import { SelectOption } from "features/ui/Select";
import { SchemaEntry } from "features/ui/Table";

import {
  CLAIM_DEALER_ACCESSOR,
  TOP_CONTRIBUTORS_GROUP_BY_ACCESSOR,
  USE_RESOURCE_SCHEMA_MAP,
  VEHICLE_LAST_KNOWN_DEALER_ACCESSOR,
} from "./constants";
import useDealersSchema from "./dealerSchema";
import { Resource } from "./types";
import {
  filterGroupableAttributes,
  formatVehicleAttribute,
  formatVehicleEntityToSelectOption,
  getMainAttributeName,
  getSchemaType,
  getVehicleLastKnownDealerDisplayNameValue,
  getVehicleLastKnownDealerIdValue,
  sortByDisplayName,
  sortByValue,
} from "./utils";
import useVehicleECUsCombinedSchema from "./vehicleECUsCombinedSchema";
import useVehiclesSchema from "./vehiclesSchema";

export const useGroupBySelectOptions = (
  resource: Resource,
  skipVehicleAttributes: boolean = false
): SelectOption[] => {
  const options = useGroupByAttributes(resource, skipVehicleAttributes);
  return options.map(({ ID, displayName }) => ({ id: ID, value: displayName }));
};

/**
 * Get a list of groupable EntityAttributes for a given resource.
 * @param resource Resource
 * @param skipVehicleAttributes boolean
 * @returns EntityAttribute[]
 * @example useGroupByAttributes("claim", true)
 */
export const useGroupByAttributes = (
  resource: Resource,
  skipVehicleAttributes: boolean = false
): EntityAttribute[] => {
  const vehicleAttributesOptions = useVehicleGroupByAttributes();
  const { attributes: entityAttributes } = USE_RESOURCE_SCHEMA_MAP[resource]();
  const { attributes: dealerAttributes } = useDealersSchema();

  if (!entityAttributes) {
    return [];
  }

  let entityAttributesOptions: EntityAttribute[] = entityAttributes.filter(
    filterGroupableAttributes
  );

  if (resource === "claim") {
    entityAttributesOptions = addClaimDealerAttributes(
      entityAttributesOptions,
      entityAttributes.find(({ ID }) => ID === CLAIM_DEALER_ACCESSOR),
      dealerAttributes
    );
  }

  entityAttributesOptions.sort(sortByDisplayName);

  if (skipVehicleAttributes) return entityAttributesOptions;

  return [...entityAttributesOptions, ...vehicleAttributesOptions];
};

const addClaimDealerAttributes = (
  attributes: EntityAttribute[],
  claimDealerAttribute: EntityAttribute | undefined,
  dealerAttributes: EntityAttribute[] | undefined
) => {
  if (!claimDealerAttribute) return attributes;

  if (!dealerAttributes) return attributes;

  return [
    ...attributes,
    ...dealerAttributes.filter(filterGroupableAttributes).map((attr) => ({
      ...attr,
      ID: getClaimDealerIdValue(attr.ID),
      displayName: getClaimDealerDisplayNameValue(
        attr.displayName,
        claimDealerAttribute
      ),
    })),
  ];
};

/**
 * This hook returns a FilterSchemaItem for a given attributeObj and resource.
 * Just extracted out of useSchemaEntryForAttribute for readability.
 * @param resource Resource
 * @param attributeObj EntityAttribute
 * @returns FilterSchemaItem | undefined
 */
const useGroupByAttributeFilter = (
  resource: Resource,
  attributeObj?: EntityAttribute
): FilterSchemaItem | undefined => {
  const { schema: vehicleAttributeSchema } = useVehiclesSchema();
  const { schema: ECUsAttributeSchema } = useVehicleECUsCombinedSchema();
  const { schema: dealerAttributesSchema } = useDealersSchema();

  if (!attributeObj) return undefined;

  const { ID, filtering, type, displayName } = attributeObj;

  // we only support string filters on groupByAttributeValue
  if (!ID || !filtering || (!type && !type.includes("string")) || !displayName)
    return undefined;

  const isVehicleAttribute = ID.startsWith("vehicle.");
  const isDealerAttribute =
    ID.startsWith("dealer.") || ID.includes(".lastKnownDealer.");

  // we get part of ID after the last dot if nested attribute
  const fieldName =
    isVehicleAttribute || isDealerAttribute ? getMainAttributeName(ID) : ID;

  const resourceFilter =
    resource === "claim"
      ? CLAIMS_GENERIC_FILTER
      : SIGNAL_EVENT_OCCURRENCES_GENERIC_FILTER;

  const genericFilter = isVehicleAttribute
    ? VEHICLES_GENERIC_FILTER
    : resourceFilter;

  if (isVehicleAttribute) {
    // we also need ECU attributes
    vehicleAttributeSchema.push(...ECUsAttributeSchema);
    const filterFromVehiclesSchema = vehicleAttributeSchema.find(
      ({ accessor }) => accessor === fieldName
    )?.filter;

    if (filterFromVehiclesSchema) {
      return {
        ...filterFromVehiclesSchema,
        fieldName: TOP_CONTRIBUTORS_GROUP_BY_ACCESSOR,
        fieldNameForAPI: fieldName,
      };
    }
  }

  if (isDealerAttribute) {
    const filterFromDealerSchema = dealerAttributesSchema.find(
      ({ accessor }) => accessor === fieldName
    )?.filter;

    if (filterFromDealerSchema) {
      return {
        ...filterFromDealerSchema,
        fieldName: TOP_CONTRIBUTORS_GROUP_BY_ACCESSOR,
        fieldNameForAPI: fieldName,
      };
    }
  }

  const filterType = getFilterType(type);
  const filterDataType = getSchemaType(type);
  const disableSelectFilters = filterType === "number";

  return genericFilter({
    fieldName: TOP_CONTRIBUTORS_GROUP_BY_ACCESSOR,
    fieldNameForAPI: fieldName,
    label: displayName,
    filterType,
    loadDataOnOpen: false,
    search: true,
    disableFiltering: true,
    disableSelectFilters,
    filterDataType,
  });
};

/**
 * This hook returns a SchemaEntry for a given attributeId and resource. Mainly used for TopContributors table on Claim, SE, Inspection & Repair analytics.
 * @param attributeId string
 * @param resource Resource
 * @returns SchemaEntry | undefined
 */
export const useSchemaEntryForAttribute = (
  attributeId: string,
  resource: Resource,
  overrides: Partial<SchemaEntry> = {}
): SchemaEntry | undefined => {
  const options = useGroupByAttributes(resource);
  const attributeObj = options.find(({ ID }) => ID === attributeId);

  const filter = useGroupByAttributeFilter(resource, attributeObj);

  const schemaEntry = {
    label: attributeObj?.displayName || "",
    accessor: TOP_CONTRIBUTORS_GROUP_BY_ACCESSOR,
    dataType: getSchemaType(attributeObj?.type),
    sortable: false, // we dont want to allow sorting since there might be issues when sorting on numeric fields
    filter,
  };

  return { ...schemaEntry, ...overrides };
};

export const useVehicleGroupBySelectOptions = (): SelectOption[] => {
  const { attributes: vehicleAttributes } = useVehiclesSchema();
  const { attributes: dealerAttributes } = useDealersSchema();
  const { attributes: ecuCombinedAttributes } = useVehicleECUsCombinedSchema();

  const vehicleDealerAttribute = vehicleAttributes?.find(
    (a) => a.ID === VEHICLE_LAST_KNOWN_DEALER_ACCESSOR
  );

  const vehicleAttributesOptions: SelectOption[] = vehicleAttributes
    ? vehicleAttributes
        .filter(filterGroupableAttributes)
        .map(formatVehicleEntityToSelectOption)
    : [];

  const vehicleDealerAttributesOptions: SelectOption[] =
    dealerAttributes && Boolean(vehicleDealerAttribute)
      ? dealerAttributes
          .filter(filterGroupableAttributes)
          .map(({ ID, displayName }) => ({
            id: getVehicleLastKnownDealerIdValue(ID),
            value: getVehicleLastKnownDealerDisplayNameValue(
              displayName,
              vehicleDealerAttribute
            ),
          }))
      : [];

  const vehicleECUAttributesOptions: SelectOption[] = ecuCombinedAttributes
    ? ecuCombinedAttributes
        .filter(filterGroupableAttributes)
        .map(formatVehicleEntityToSelectOption)
    : [];

  vehicleAttributesOptions.push(...vehicleDealerAttributesOptions);
  vehicleAttributesOptions.sort(sortByValue);
  vehicleAttributesOptions.push(...vehicleECUAttributesOptions);

  return vehicleAttributesOptions;
};

export const useVehicleGroupByAttributes = (): EntityAttribute[] => {
  const { attributes: vehicleAttributes } = useVehiclesSchema();
  const { attributes: dealerAttributes } = useDealersSchema();
  const { attributes: ecuCombinedAttributes } = useVehicleECUsCombinedSchema();

  const vehicleDealerAttribute = vehicleAttributes?.find(
    (a) => a.ID === VEHICLE_LAST_KNOWN_DEALER_ACCESSOR
  );

  const vehicleAttributesOptions: EntityAttribute[] = vehicleAttributes
    ? vehicleAttributes
        .filter(filterGroupableAttributes)
        .map(formatVehicleAttribute)
    : [];

  const vehicleDealerAttributesOptions: EntityAttribute[] =
    dealerAttributes && Boolean(vehicleDealerAttribute)
      ? dealerAttributes
          .filter(filterGroupableAttributes)
          .map(({ ID, displayName, ...otherAttrs }) => ({
            ID: getVehicleLastKnownDealerIdValue(ID),
            displayName: getVehicleLastKnownDealerDisplayNameValue(
              displayName,
              vehicleDealerAttribute
            ),
            ...otherAttrs,
          }))
      : [];

  const vehicleECUAttributesOptions: EntityAttribute[] = ecuCombinedAttributes
    ? ecuCombinedAttributes
        .filter(filterGroupableAttributes)
        .map(formatVehicleAttribute)
    : [];

  vehicleAttributesOptions.push(...vehicleDealerAttributesOptions);
  vehicleAttributesOptions.sort(sortByDisplayName);
  vehicleAttributesOptions.push(...vehicleECUAttributesOptions);

  return vehicleAttributesOptions;
};
