import Skeleton from "react-loading-skeleton";

import { Claim } from "shared/api/claims/api";
import { Vehicle } from "shared/api/vehicles/api";
import { useVehicle } from "shared/api/vehicles/hooks";
import useVehiclesSchema from "shared/schemas/vehiclesSchema";
import { getTenantMileageUnit } from "shared/utils";

import CardHeader from "pages/Issues/Details/CardHeader";
import { VINEventTimelineDateLink } from "pages/VINView/ServiceRecords/VINEventTimelineDateLink";

import Card from "features/ui/Card";
import LabelValuePairs from "features/ui/LabelValuePairs";
import { SchemaEntry } from "features/ui/Table";

interface Props {
  data: Claim;
}

const CARD_TITLE = "Vehicle Details";

const DISPLAY_FIELDS: Array<keyof Vehicle> = [
  "vehicleMake",
  "vehicleModel",
  "vehicleModelYear",
  "vehicleManufacturedAt",
  "engineModel",
  "VIN",
];

const CardVehicleDetails = ({ data }: Props) => {
  const {
    data: vehicleData,
    isLoading,
    error,
  } = useVehicle({ vin: data.VIN, mileageUnit: getTenantMileageUnit() });
  const { schema } = useVehiclesSchema();

  const formatRow = (data: Vehicle, claim: Claim) => {
    const { VIN } = data;
    const { date } = claim;

    return {
      ...data,
      VIN: <VINEventTimelineDateLink date={date} VIN={VIN} />,
    };
  };

  if (isLoading) return <Skeleton height={300} />;

  if (error) return <div>Error</div>;

  if (!vehicleData) return <div>No data</div>;

  const formattedData = formatRow(vehicleData, data);

  return (
    <Card>
      <CardHeader title={CARD_TITLE} />
      <LabelValuePairs
        schema={schema as SchemaEntry<keyof Vehicle>[]}
        data={formattedData}
        fields={DISPLAY_FIELDS}
      />
    </Card>
  );
};

export default CardVehicleDetails;
