import { useMemo } from "react";
import classNames from "classnames";

import CardHeader from "pages/Issues/Details/CardHeader";

import Card from "features/ui/Card";

interface WithJSON {
  data: Record<string, string | undefined>;
}

export interface Props {
  title: string;
  data: WithJSON;
  alignment?: "left" | "center";
  removeCardWrapper?: boolean;
}

const DEFAULT_JSON_FIELD = "data";
const DEFAULT_ALIGNMENT = "center";
const DEFAULT_REMOVE_CARD_WRAPPER = false;

const displayData = (data: any): string => {
  if (typeof data === "object") {
    return JSON.stringify(data);
  }

  if (typeof data.toString() !== "undefined") {
    return data.toString();
  }

  return "N/A";
};

const isValidRow = (data: any): boolean =>
  typeof data.toString() !== "undefined" || typeof data === "object";

const CardWithJSONData = ({
  title,
  data,
  alignment = DEFAULT_ALIGNMENT,
  removeCardWrapper = DEFAULT_REMOVE_CARD_WRAPPER,
}: Props) => {
  const jsonData = useMemo(() => {
    return data?.[DEFAULT_JSON_FIELD] ?? null;
  }, [data]);

  if (!jsonData) return null;

  const content = (
    <>
      <CardHeader title={title} showActions={false} />
      {Object.keys(jsonData).map(
        (key) =>
          //call function isValidRow and skip if not valid
          isValidRow(jsonData[key]) && (
            <div
              key={key}
              className={classNames("flex space-x-3 border-b py-1 text-sm", {
                "grid grid-cols-[180px_auto] gap-x-3 py-2 border-b":
                  alignment === "left",
              })}
            >
              <div
                className={classNames("text-gray-400", {
                  "text-left": alignment === "left",
                  "w-1/2 md:text-right": alignment !== "left",
                })}
              >
                {key}:
              </div>
              <div
                className={classNames("break-words", {
                  "w-1/2 text-left": alignment !== "left",
                })}
              >
                {displayData(jsonData[key])}
              </div>
            </div>
          )
      )}
    </>
  );

  return removeCardWrapper ? <>{content}</> : <Card>{content}</Card>;
};
export default CardWithJSONData;
