import { useFlags } from "launchdarkly-react-client-sdk";

import { SuggestedIssue } from "shared/api/suggestedIssues/api";

import { IssueRequestKeys } from "pages/Issues/types";

import PromoteToIssueButton from "./PromoteToIssueButton";
import PublishIssueButton from "./PublishIssueButton";

interface Props extends IssueRequestKeys {
  issue: SuggestedIssue;
}

const SuggestedIssuesActions = ({ issue, issueRequestKeys }: Props) => {
  const { issuePublishing } = useFlags();
  return (
    <>
      {issuePublishing && (
        <PublishIssueButton issue={issue} issueRequestKeys={issueRequestKeys} />
      )}
      <PromoteToIssueButton issue={issue} />
    </>
  );
};

export default SuggestedIssuesActions;
