import React from "react";

import CardActions, { CreateGroupProps } from "./CardActions";

interface Props {
  title: string;
  canEdit?: boolean;
  createGroupProps?: CreateGroupProps;
  showActions?: boolean;
}

const CardHeader = ({
  title,
  canEdit,
  createGroupProps,
  showActions = true,
}: Props) => {
  return (
    <div className="flex justify-between mb-3 w-full">
      <h3 className="flex space-x-3 items-center font-semibold mb-1">
        {title}
      </h3>
      {showActions && (
        <CardActions canEdit={canEdit} createGroupProps={createGroupProps} />
      )}
    </div>
  );
};

export default CardHeader;
