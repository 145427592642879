import {
  ChartAction,
  SelectedChartOptions,
} from "features/ui/charts/Actions/types";
import Checkbox from "features/ui/Checkbox";

interface Props {
  action: ChartAction;
  selectedOptions: SelectedChartOptions[];
  onOptionChange: (selectedOptions: SelectedChartOptions[]) => void;
}

const BooleanAction = ({
  action: { id, checkboxLabel },
  selectedOptions,
  onOptionChange,
}: Props) => {
  const updateSelectedBooleanOptions = (id: string, checked: boolean) => {
    const newSelectedActions = selectedOptions.map((obj) =>
      obj.id === id
        ? {
            id,
            optionId: checked ? "true" : "false",
          }
        : obj
    );
    onOptionChange(newSelectedActions);
  };

  return (
    <Checkbox
      checked={selectedOptions?.find((o) => o.id === id)?.optionId === "true"}
      onChange={(checked) => updateSelectedBooleanOptions(id, checked)}
      label={checkboxLabel}
      dense={true}
    />
  );
};

export default BooleanAction;
