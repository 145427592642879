export const VIN_VIEW_EVENTS_TIMELINE_TAB_KEY = "events-timeline";
export const VIN_VIEW_SERVICE_RECOMMENDATIONS_TAB_KEY =
  "service-recommendations";
export const VIN_VIEW_EVENTS_TIMELINE_TAB_PAGE_PREFIX =
  "vin_event_timeline_data_";
export const VIN_VIEW_EVENTS_TIMELINE_TAB_PAGE_KEY = (vin: string) =>
  `${VIN_VIEW_EVENTS_TIMELINE_TAB_PAGE_PREFIX}${vin}_v2`;
export const MAX_LIMIT_EVENTS = 2000;

export const VIN_VIEW_HEALTH_TAB_KEY = "health";
export const VIN_VIEW_SERVICE_RECORDS_TAB_KEY = "service-records";
export const VIN_VIEW_SERVICE_SCHEDULE_TAB_KEY = "service-schedules";
export const VIN_VIEW_ECUS_TAB_KEY = "ecus";
export const VIN_VIEW_RISK_MODEL_RESULTS_TAB_KEY = "risk-model-results";
