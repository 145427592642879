import { validateSort } from "duck/graph/nodes/VehiclesAgent/tools/utils";
import { PageHandler } from "duck/graph/PageHandler";
import { StringSetter } from "duck/graph/types";
import { z } from "zod";
import { DynamicStructuredTool, tool } from "@langchain/core/tools";

import { VEHICLES_PAGE_KEY } from "pages/Vehicles/constants";
import { getTablePageKey } from "pages/Vehicles/utils";

import { getPageKeyWithVersion, getQueryKeys } from "features/ui/Filters/utils";

const sortVehiclesSchema = z.object({
  vehiclesSort: z
    .string()
    .optional()
    .describe(
      `Sort string for vehicles.
Use empty string to reset to the default sort.
Call retrieveVehicleAttributes to get the list of sortable attributes.`
    ),
});

type SortVehicles = z.infer<typeof sortVehiclesSchema>;

const RESPONSE = "Queued update vehicles sort";

const getSortVehiclesTool = (
  pageHandler: PageHandler,
  setEphemeralMessage: StringSetter
): DynamicStructuredTool => {
  const sortVehicles = async ({
    vehiclesSort,
  }: SortVehicles): Promise<string> => {
    setEphemeralMessage("queuing an updated vehicles sort");
    console.debug("sorting vehicles", { vehiclesSort });

    const { sortKey: sortKeyRoot } = getQueryKeys(
      getPageKeyWithVersion(getTablePageKey(VEHICLES_PAGE_KEY))
    );

    if (!vehiclesSort) {
      pageHandler.updateSort(sortKeyRoot, "", "");
      return RESPONSE;
    }

    const [sortKeySuffix, sortOrder] = vehiclesSort.split("=");
    await validateSort(sortKeySuffix, sortOrder);

    pageHandler.updateSort(sortKeyRoot, sortKeySuffix, sortOrder);
    return RESPONSE;
  };

  return tool(sortVehicles, {
    name: "queueUpdateVehiclesSort",
    description: `Call this tool to queue an action that updates the sort order of the vehicles
given a text query.
Only call this for vehicle attributes.`,
    schema: sortVehiclesSchema,
  });
};

export default getSortVehiclesTool;
