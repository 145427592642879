import { BreadcrumbMatch } from "use-react-router-breadcrumbs";

import { useCustomSignalEventDefinition } from "shared/api/customSignalEvents/hooks";

interface Props {
  match: BreadcrumbMatch;
}

const CustomSignalEventBreadcrumb = ({ match }: Props) => {
  const { data, isLoading, error } = useCustomSignalEventDefinition({
    id: match.params.id as string,
  });

  if (isLoading) {
    return <></>;
  }

  if (error && match.params.id) {
    return <>{match.params.id}</>;
  }

  return <>{data?.name}</>;
};

export default CustomSignalEventBreadcrumb;
