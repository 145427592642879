import React, { Component, ErrorInfo, ReactNode } from "react";
import { useLocation } from "react-router-dom";

import UnhandledError from "./UnhandledError";

interface Props {
  children?: ReactNode;
  location?: string;
}

interface State {
  hasError: boolean;
  error: string;
}

/**
 * https://react-typescript-cheatsheet.netlify.app/docs/basic/getting-started/error_boundaries/
 */

class ErrorBoundary extends Component<Props, State> {
  componentDidUpdate(prevProps: Props) {
    if (prevProps.location !== this.props.location) {
      this.setState({ hasError: false, error: "" });
    }
  }

  public state: State = {
    hasError: false,
    error: "",
  };

  public static getDerivedStateFromError(e: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: e.message };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return <UnhandledError errorMessage={this.state.error} />;
    }

    return this.props.children;
  }
}

export default withLocation(ErrorBoundary);

// This is needed as RR6 lost support for class components.
// https://reactrouter.com/en/v6.3.0/faq#what-happened-to-withrouter-i-need-it
function withLocation(Component: React.ComponentType<Props>) {
  function ComponentWithRouterProp(props: Props) {
    let location = useLocation();
    return <Component {...props} location={location.pathname} />;
  }

  return ComponentWithRouterProp;
}
