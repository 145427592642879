import { SIGNAL_EVENTS_GENERIC_FILTER } from "shared/filterDefinitions";

import { filterStateToFilterGroupState } from "features/ui/Filters/FilterBuilder/utils";
import {
  FILTER_TYPE,
  OPERATORS_WITHOUT_VALUE_SELECTION,
  SIGNAL_EVENT_ID_FIELD_NAME,
} from "features/ui/Filters/FilterTypes/RelatesFilter/constants";
import FilterTypeWrap from "features/ui/Filters/SingleFilter/FilterTypeWrap";
import OperatorsDropdown from "features/ui/Filters/SingleFilter/OperatorsDropdown";
import { FilterOperator, RelatesFilterState } from "features/ui/Filters/types";
import { SelectOption } from "features/ui/Select/Select";

interface Props {
  relatesState: RelatesFilterState;
  selectedOperator: SelectOption<FilterOperator>;
  onUpdate: (row: RelatesFilterState) => void;
  relatedSignalEventLabel?: string;
  disableIsNotFilteredFilters?: boolean;
}

const RELATED_SIGNAL_EVENT_LABEL = "Related Signal Event ID";

const getInitialValuesByFilterOperator = (filterOperator: FilterOperator) =>
  OPERATORS_WITHOUT_VALUE_SELECTION.includes(filterOperator) ? ["null"] : [];

const RelatesSignalEventForm = ({
  relatesState,
  onUpdate,
  selectedOperator,
  relatedSignalEventLabel = RELATED_SIGNAL_EVENT_LABEL,
  disableIsNotFilteredFilters,
}: Props) => {
  return (
    <div className="flex items-start space-x-2 mr-2">
      <span>{relatedSignalEventLabel}</span>
      <OperatorsDropdown
        disableIsNotFilteredFilters={disableIsNotFilteredFilters}
        type={FILTER_TYPE}
        selected={selectedOperator}
        onChange={(selectedOperator) => {
          onUpdate({
            ...relatesState,
            filters: {
              id: "group-0",
              type: "group",
              anyAll: "all",
              children: [
                {
                  id: "row-0",
                  type: "row",
                  attribute: SIGNAL_EVENT_ID_FIELD_NAME,
                  operator: selectedOperator.id as FilterOperator,
                  values: getInitialValuesByFilterOperator(selectedOperator.id),
                },
              ],
            },
          });
        }}
        className="!w-44"
        fullWidth={false}
      />
      {!OPERATORS_WITHOUT_VALUE_SELECTION.includes(selectedOperator.id) && (
        <div className="w-60">
          <FilterTypeWrap
            isActiveFilter={false}
            selectedOperatorId={selectedOperator.id}
            currentFilterSchema={SIGNAL_EVENTS_GENERIC_FILTER({
              label: "Signal Event ID",
              fieldName: SIGNAL_EVENT_ID_FIELD_NAME,
              fieldNameForAPI: "ID",
              search: true,
              disableContainsFilters: true,
            })}
            filters={filterStateToFilterGroupState(relatesState.filters)}
            hideCTA
            onValuesChanged={(values) => {
              onUpdate({
                ...relatesState,
                filters: {
                  id: "group-0",
                  type: "group",
                  anyAll: "all",
                  children: [
                    {
                      id: "row-0",
                      type: "row",
                      attribute: SIGNAL_EVENT_ID_FIELD_NAME,
                      operator: selectedOperator.id as FilterOperator,
                      values,
                    },
                  ],
                },
              });
            }}
          />
        </div>
      )}
    </div>
  );
};

export default RelatesSignalEventForm;
