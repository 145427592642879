import { MAX_FILTER_ID, MIN_FILTER_ID } from "features/ui/Filters/constants";
import { FilterSchemaItem, FilterType } from "features/ui/Filters/types";
import { getMinMaxSelectOptions } from "features/ui/Filters/utils";
import { SelectOption } from "features/ui/Select";

import { Props, State } from "./SelectFilter";

/**
 * Determine the message to display when there are no search results
 */
export const getNoResultsMessage = ({
  loading,
  options,
  search,
  input,
}: Partial<State & Props & FilterSchemaItem>): string => {
  // Display "Loading..." message if data is still loading
  if (loading) {
    return "Loading...";
  }

  // Display "No results" message if options are loaded and empty
  // note that if we're using freeSolo prop, this never actually displays and it expected not to.
  // see https://github.com/mui/material-ui/issues/37862
  if (options?.length === 0) {
    return "No results";
  }

  // Display "Type to search or paste values" message if search is active but input is empty
  if (search && !input) {
    return "Type to search or paste values";
  }

  // Display "No results" message if search is active and input is provided, or if search is not active
  return "No results";
};

/**
 * Transform options based on filterType and enableMinMaxFilters flag
 * so that we can change "min" and "max" to their respective values based on filterType
 */
export const transformOptions = (
  options: SelectOption[],
  filterType?: FilterType,
  enableMinMaxFilters?: boolean
): SelectOption[] => {
  if (enableMinMaxFilters && filterType) {
    const minMaxOptions = getMinMaxSelectOptions(filterType) || [];
    return options.map((option) =>
      [MIN_FILTER_ID, MAX_FILTER_ID].includes(option.id as string)
        ? minMaxOptions.find(({ id }) => id === option.id) || option
        : option
    );
  }
  return options;
};

/**
 * Get available options for the select dropdown, by automatically adding MIN/MAX to options list
 * based on the filterType and enableMinMaxFilters flag
 */
export const getOptions = (
  options: SelectOption[],
  filterType?: FilterType,
  enableMinMaxFilters?: boolean
) => {
  let modifiedOptions = [...options];
  // Add "MIN" and "MAX" options to the beginning of the list
  if (enableMinMaxFilters && filterType) {
    const minMaxOptions = getMinMaxSelectOptions(filterType) || [];
    modifiedOptions = [...minMaxOptions, ...options];
  }
  return modifiedOptions;
};

/**
 * Get the option to add a new item to the list when the user types a value that is not in the list (freeSolo)
 */
const NEW_ITEM_OPTION_ID = "new-item-to-add";
export const getNewItemOption = (value: string): SelectOption => {
  return {
    id: NEW_ITEM_OPTION_ID,
    value: `Add "${value}"`,
  };
};

/**
 * Removes "Add " from the value when selecting the "Add 'xyz'" option
 */
export const modifySelectedValue = (option: SelectOption): SelectOption => {
  if (option.id === NEW_ITEM_OPTION_ID) {
    const cleanValue = option.value
      .toString()
      .replace("Add ", "")
      .replaceAll('"', "");
    return {
      id: cleanValue,
      value: cleanValue,
    };
  }
  return option;
};
