import { useMaintenanceSchedule } from "shared/api/hooks";
import { getSortFilter } from "shared/api/utils";

import {
  filterStateToFilterGroupState,
  getFiltersQuery,
} from "features/ui/Filters/FilterBuilder/utils";
import { FilterOperator } from "features/ui/Filters/types";
import Table, { SchemaEntry } from "features/ui/Table";
import { DataType } from "features/ui/Table/TableBodyCell";

import { SUPPORT_EMAIL } from "config/config";

interface Props {
  transportCategoryID: string;
  vehicleCategoryID: string;
}

const schema: SchemaEntry[] = [
  {
    label: "Service",
    accessor: "service",
    dataType: DataType.STRING,
  },
  {
    label: "Miles",
    accessor: "miles",
    dataType: DataType.NUMBER,
  },
  {
    label: "Hours",
    accessor: "hours",
    dataType: DataType.NUMBER,
  },
  {
    label: "Months",
    accessor: "months",
    dataType: DataType.NUMBER,
  },
];

const LOADING_ROWS = 10;
const MAX_SCHEDULE_ROWS = 1000;

const ServiceScheduleContent = ({
  transportCategoryID,
  vehicleCategoryID,
}: Props) => {
  const filtersQuery = getFiltersQuery(
    filterStateToFilterGroupState({
      transportCategoryID: {
        operator: FilterOperator.EQUALS,
        values: [transportCategoryID],
      },
      vehicleCategoryID: {
        operator: FilterOperator.EQUALS,
        values: [vehicleCategoryID],
      },
    })
  );

  const { data, isLoading, error } = useMaintenanceSchedule({
    filter: filtersQuery,
    sort: getSortFilter({ service: "asc" }),
    limit: MAX_SCHEDULE_ROWS,
  });

  return (
    <div className="flex-grow">
      {!error && (
        <Table
          data={data}
          schema={schema}
          isLoading={isLoading}
          loadingRows={LOADING_ROWS}
        />
      )}
      {!isLoading && error && (
        <div className="shadow px-5 py-12 italic">
          Failed to fetch a service schedule. Please try to reload the page. If
          the error persists, contact{" "}
          <a href={`mailto:${SUPPORT_EMAIL}?subject=Issue: service schedule`}>
            {SUPPORT_EMAIL}
          </a>
          .
        </div>
      )}
    </div>
  );
};

export default ServiceScheduleContent;
