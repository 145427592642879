import { ContextProviderName } from "shared/contexts/ContextWrappers";
import { useAttributesContext } from "shared/contexts/GenericAttributesContextWrapper";
import { SERVICE_RECOMMENDATIONS_GENERIC_FILTER } from "shared/filterDefinitions";

import useServicePlansSchema from "./servicePlansSchema";
import { SchemaRelations, UseSchema } from "./types";
import {
  getAttributeMap,
  getDisplayLabelFunction,
  getFilterFunction,
  getSchemaEntryFunction,
  getSchemaForAccessorFunction,
  getSchemaFromAttributes,
} from "./utils";

const useServiceRecommendationsSchema = (
  accessorsToHide: string[] = [],
  servicePlansAccessorsToHide: string[] = []
): UseSchema => {
  const attributes = useAttributesContext(
    ContextProviderName.ServiceRecommendation
  );

  const attributeMap = getAttributeMap(attributes);

  const getDisplayLabel = getDisplayLabelFunction(attributeMap);

  const getFilter = getFilterFunction(
    SERVICE_RECOMMENDATIONS_GENERIC_FILTER,
    attributeMap
  );

  const servicePlanSchema = useServicePlansSchema(servicePlansAccessorsToHide);

  const relations: SchemaRelations = {
    "/v1/servicePlans": servicePlanSchema,
  };

  const getSchemaEntry = getSchemaEntryFunction(getFilter, attributeMap);

  const schema = getSchemaFromAttributes(
    attributes,
    getSchemaEntry,
    undefined,
    relations,
    accessorsToHide
  );

  const getSchemaForAccessor = getSchemaForAccessorFunction(schema);

  return {
    schema,
    getDisplayLabel,
    attributeMap,
    attributes,
    getSchemaEntry,
    whitelist: Object.keys(attributeMap || {}),
    getSchemaForAccessor,
  };
};
export default useServiceRecommendationsSchema;
