import { assertNonEmptyStringArray } from "duck/ui/utils";
import { z } from "zod";
import { DynamicStructuredTool, tool } from "@langchain/core/tools";

import {
  GROUP_BY_ATTRIBUTE_KEY,
  TOP_CONTRIBUTORS_TAB_KEY,
} from "pages/constants";

import { GoToTopContributorsTabToolParams } from "./types";

const getGoToTopContributorsTabTool = ({
  pageHandler,
  chartOptions,
  groupByOptions,
  selectedChartOptions,
  selectedGroupByAttribute,
  defaultGroupByAttribute,
  pageKey,
  chartOptionsKey,
  groupByOptionsKey,
  setEphemeralMessage,
}: GoToTopContributorsTabToolParams): DynamicStructuredTool => {
  const { y: yOptions, exposure: exposureOptions } = chartOptions;
  const exposureOptionsKeys = Object.keys(exposureOptions);
  assertNonEmptyStringArray(exposureOptionsKeys);

  const goToTopContributorsTabSchema = z.object({
    groupByAttribute: z
      .enum(groupByOptions)
      .optional()
      .describe(
        `Group by attribute. Default value: '${defaultGroupByAttribute}'.`
      ),
    y: z
      .enum(yOptions)
      .optional()
      .describe(
        `The metric to be displayed on the y-axis of the chart. Default value: '${chartOptions.y[0]}'.`
      ),
    exposure: z
      .enum(exposureOptionsKeys)
      .optional()
      .describe("The exposure of the chart. Default value: 'None'."),
    exposureBucket: z
      .string()
      .optional()
      .describe(
        `Exposure bucket if exposure is not 'None'. Default value: ''. Bucket options: ${JSON.stringify(exposureOptions)}`
      ),
  });

  type GoToTopContributorsTab = z.infer<typeof goToTopContributorsTabSchema>;

  const goToTopContributorsTab = ({
    groupByAttribute,
    y,
    exposure,
    exposureBucket,
  }: GoToTopContributorsTab) => {
    setEphemeralMessage("to top contributors tab");
    console.debug(
      "Navigating to Top Contributors tab with group by attribute:",
      { groupByAttribute, y, exposure, exposureBucket }
    );

    const nonNullExposure =
      exposure ??
      selectedChartOptions.exposure ??
      Object.keys(chartOptions.exposure)[0];

    const nonNullExposureBucket =
      exposureBucket ??
      selectedChartOptions.exposureBucket ??
      chartOptions.exposure[nonNullExposure][0] ??
      "";

    const nonNullGroupByAttribute =
      groupByAttribute ?? selectedGroupByAttribute ?? defaultGroupByAttribute;

    const nonNullY = y ?? selectedChartOptions.y ?? chartOptions.y[0];

    if (!groupByOptions.includes(nonNullGroupByAttribute)) {
      throw new Error(
        `Invalid group by attribute: '${nonNullGroupByAttribute}'. Valid options: ${groupByOptions.join(", ")}.`
      );
    }
    if (!yOptions.includes(nonNullY)) {
      throw new Error(
        `Invalid y option: '${nonNullY}'. Valid options: ${yOptions.join(", ")}.`
      );
    }
    if (!exposureOptionsKeys.includes(nonNullExposure)) {
      throw new Error(
        `Invalid exposure option: '${nonNullExposure}'. Valid options: ${exposureOptionsKeys.join(", ")}.`
      );
    }
    if (
      nonNullExposure !== "None" &&
      !exposureOptions[nonNullExposure].includes(nonNullExposureBucket)
    ) {
      throw new Error(
        `Invalid exposure bucket: '${nonNullExposureBucket}'. Valid options: ${JSON.stringify(exposureOptions[nonNullExposure])}.`
      );
    }

    pageHandler.updateTabChartSettings(pageKey, TOP_CONTRIBUTORS_TAB_KEY, {
      [chartOptionsKey]: [
        { id: "y", optionId: nonNullY },
        { id: "exposure", optionId: nonNullExposure },
        { id: "exposureBucket", optionId: nonNullExposureBucket },
      ],
      [groupByOptionsKey]: [
        {
          id: GROUP_BY_ATTRIBUTE_KEY,
          optionId: nonNullGroupByAttribute,
        },
      ],
    });

    return "queued navigation to Top Contributors tab";
  };

  return tool(goToTopContributorsTab, {
    name: "queueGoToTopContributorsTab",
    description: `Call this tool to queue a navigation to the Top Contributors tab action.`,
    schema: goToTopContributorsTabSchema,
  });
};

export default getGoToTopContributorsTabTool;
