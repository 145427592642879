import { PermissionEntry } from "shared/api/api";
import { GroupUser } from "shared/api/rbac/api";
import { PermissionID } from "shared/types";

import {
  DEFAULT_GENERAL_PERMISSION,
  DEFAULT_MEMBER_ACCESS,
} from "features/ui/PermissionsDialog/utils";
import { SelectOption } from "features/ui/Select";

import { GroupMemberEntry } from "./Details/Members/utils";

type ApiGroupMemberAccess = Exclude<PermissionID, "none"> | null;

export const NEW_GROUP_MEMBER_ENTRY = { ID: "", newEntry: true, email: "" };

export const DUMMY_GROUP_SELECT_OPTION: SelectOption = {
  id: "obfuscated",
  value: "Obfuscated",
  className: "blur-sm",
};

export interface GroupFormData {
  ID: string;
  name: string;
  description: string;
  users: string[];
  acl: PermissionEntry[];
  memberAccess: PermissionID;
}

export const DEFAULT_DATA: GroupFormData = {
  ID: "",
  name: "",
  description: "",
  users: [],
  acl: [DEFAULT_GENERAL_PERMISSION],
  memberAccess: DEFAULT_MEMBER_ACCESS,
};

export const toFEGroupMemberAccess = (
  memberAccess: PermissionID | null
): PermissionID => (memberAccess === null ? "none" : memberAccess);

export const toApiGroupMemberAccess = (
  memberAccess: PermissionID
): ApiGroupMemberAccess => (memberAccess === "none" ? null : memberAccess);

export const initGroupMemberEntries = (
  users: GroupUser[],
  canEdit: boolean
) => {
  const entries = users.map((user) => {
    return { ...user, newEntry: false, ID: user.email };
  }) as GroupMemberEntry[];
  const newMemberEntryExists = Boolean(entries.find((x) => !x.ID));
  if (canEdit && !newMemberEntryExists) {
    entries.unshift(NEW_GROUP_MEMBER_ENTRY);
  }
  return entries;
};

export const getNumberOfMembers = (members: GroupMemberEntry[]) => {
  return members.filter((x) => x.ID).length;
};
