import { UIHandlers } from "duck/graph/types";
import { captureScreenshot } from "duck/ui/utils";
import { StructuredTool, tool } from "@langchain/core/tools";

const getCaptureScreenshotTool = (
  setEphemeralMessage: UIHandlers["setEphemeralMessage"]
): StructuredTool => {
  const captureScreenshotTool = async () => {
    setEphemeralMessage("capturing screenshot");
    console.debug("capturing screenshot");

    return await captureScreenshot();
  };

  return tool(captureScreenshotTool, {
    name: "captureScreenshotTool",
    description: `The captureScreenshot tool captures a screenshot of the current page.
This allows you to learn about the content of the page and use that information to answer questions,
and to gather inputs to use with other agents and tools.
The contents extracted from a screenshot of a page can be used to navigate to other pages or to set filters or to sort data.
The information on pages can include charts and data.`,
  });
};

export default getCaptureScreenshotTool;
