import { RepairsMetrics } from "shared/api/repairs/api";
import { useRepairsMetrics } from "shared/api/repairs/hooks";
import { formatNumber } from "shared/utils";

import { RepairsFiltersProps } from "pages/RepairAnalytics/RepairAnalyticsTabs";

import APIError from "features/ui/APIError";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";
import MetricCard from "features/ui/MetricCard";

interface Metric {
  title: string;
  prop: keyof RepairsMetrics;
  valueSuffix?: string;
}

const MetricsCards = ({
  vehiclesFilters,
  repairsFilters,
  onBadRequest,
}: RepairsFiltersProps) => {
  const { data, isLoading, error } = useRepairsMetrics({
    vehiclesFilter: getFiltersQuery(vehiclesFilters),
    repairsFilter: getFiltersQuery(repairsFilters),
  });

  const metrics: Metric[] = [
    {
      title: "Total Repairs",
      prop: "totalRepairs",
    },
    {
      title: "IPTV",
      prop: "cumulativeIPTV",
    },
  ].filter(Boolean) as Metric[];

  if (error) {
    return <APIError error={error} onBadRequest={onBadRequest} />;
  }

  return (
    <div className="flex flex-col space-y-5">
      {metrics.map(({ title, prop, valueSuffix }) => {
        const value = data && (formatNumber(data[prop] as number) || undefined);
        const metricValue = [value, valueSuffix].join(" ");
        return (
          <MetricCard
            key={prop}
            value={metricValue}
            isLoading={isLoading}
            title={title}
            testId={`repair-analytics-metric-${prop}`}
          />
        );
      })}
    </div>
  );
};

export default MetricsCards;
