import { SortBy } from "shared/types";

import { ChartAction } from "features/ui/charts/Actions/types";
import { MAX_FILTER_ID } from "features/ui/Filters/constants";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { FilterOperator } from "features/ui/Filters/types";

export const ISSUE_ID_QUERY_KEY = "issueId";
export const ISSUE_RUN_DATE_QUERY_KEY = "issueRunDate";
export const ISSUE_NAME_QUERY_KEY = "issueName";
export const ISSUE_IS_SUGGESTED_QUERY_KEY = "isSuggestedIssue";
export const EDIT_ISSUE_CLAIMS_INDEX = 2;
export const EDIT_ISSUE_SIGNAL_EVENTS_INDEX = 3;
export const EDIT_ISSUE_OVERVIEW_INDEX = 4;
export const VALUES_SEPARATOR = "___";
export const DASHBOARD_GROUP_BY_KEY = "xAxisKey";
export const EMPTY_CHART_LABEL = "(empty)";
export const EMPTY_GROUP_CHART_LABEL = "(unassigned or no access)";

export const SUGGESTED_ISSUES_PAGE_KEY = "suggested_issues_list_v2";
export const DEFAULT_SUGGESTED_ISSUES_SORT: SortBy = { discovered: "desc" };
export const DEFAULT_SUGGESTED_ISSUES_FILTER: FilterGroupState = {
  id: "group-0",
  type: "group",
  anyAll: "all",
  children: [
    {
      id: "row-0",
      type: "row",
      attribute: "updated",
      operator: FilterOperator.IN,
      values: [MAX_FILTER_ID],
    },
  ],
};
export const SUGGESTED_ISSUES_DASHBOARD_KEY =
  "suggested_issues_dashboard_state";
export const SUGGESTED_ISSUES_CHART_KEY =
  "suggestedIssuesDashboardChartOptions_v3";
export const SUGGESTED_ISSUES_CHART_ACTIONS: ChartAction[] = [
  {
    id: "measure",
    title: "Measure",
    type: "issueMetric",
    defaultOptionId: "count",
    options: [
      {
        id: "count",
        label: "Suggested Issue Count",
        value: "Suggested Issue Count",
      },
      {
        id: "affected_vehicles_claims",
        label: "Affected Vehicle Count based on Claims",
        value: "Affected Vehicle Count based on Claims",
      },
      {
        id: "affected_vehicles_signal_events",
        label: "Affected Vehicle Count based on Signal Events",
        value: "Affected Vehicle Count based on Signal Events",
      },
      {
        id: "affected_vehicles_signal_events_high_priority",
        label: "Affected Vehicle Count based on High Priority Signal Events",
        value: "Affected Vehicle Count based on High Priority Signal Events",
      },
      {
        id: "warranty_cost",
        label: "Total Warranty Cost",
        value: "Total Warranty Cost",
      },
    ],
  },
  {
    id: "lookbackWindow",
    title: "Count Across",
    type: "",
    defaultOptionId: "0",
    options: [
      {
        id: "0",
        label: "All time",
        value: "All time",
      },
      {
        id: "1",
        label: "Previous Week",
        value: "Previous Week",
      },
      {
        id: "2",
        label: "Previous 2 Weeks",
        value: "Previous 2 Weeks",
      },
      {
        id: "3",
        label: "Previous 3 Weeks",
        value: "Previous 3 Weeks",
      },
      {
        id: "4",
        label: "Previous 4 Weeks",
        value: "Previous 4 Weeks",
      },
    ],
  },
  {
    id: "valueType",
    title: "",
    type: "",
    defaultOptionId: "absolute",
    options: [
      {
        id: "absolute",
        label: "Absolute",
        value: "Absolute",
      },
      {
        id: "percentage",
        label: "Percentage",
        value: "Percentage",
      },
    ],
  },
  {
    id: "groupBy",
    title: "X-axis",
    type: "dropdownSelect",
    defaultOptionId: "statusObj.value",
    options: [
      {
        id: "statusObj.value",
        label: "Status",
        value: "Status",
      },
      {
        id: "assignedGroupID",
        label: "Assigned Group",
        value: "Assigned Group",
      },
      {
        id: "statusUpdatedAt",
        label: "Age",
        value: "Age",
      },
    ],
  },
  {
    id: "bucketBy",
    title: "Cell-axis",
    type: "dropdownSelect",
    defaultOptionId: "none",
    options: [
      {
        id: "none",
        label: "None",
        value: "None",
      },
      {
        id: "statusObj.value",
        label: "Status",
        value: "Status",
      },
      {
        id: "assignedGroupID",
        label: "Assigned Group",
        value: "Assigned Group",
      },
      {
        id: "statusUpdatedAt",
        label: "Age",
        value: "Age",
      },
    ],
  },
] as ChartAction[];

export const ISSUES_PAGE_KEY = "issues_v3";
export const DEFAULT_ISSUES_SORT: SortBy = { name: "asc" };
export const DEFAULT_ISSUES_FILTER: FilterGroupState = {
  id: "group-0",
  type: "group",
  anyAll: "all",
  children: [
    {
      id: "row-0",
      type: "row",
      attribute: "statusObj.value",
      operator: FilterOperator.NOT_IN,
      values: ["Closed"],
    },
  ],
};
export const ISSUES_DASHBOARD_KEY = "issues_dashboard_state";
export const ISSUES_CHART_KEY = "issuesDashboardChartOptions_v3";
export const MEASURE_PERCENTAGE_LABELS: Record<string, string> = {
  affected_vehicles_claims:
    "Change (%) in Affected Vehicles experiencing Related Claims",
  affected_vehicles_signal_events:
    "Change (%) in Affected Vehicles experiencing Related Signal Events",
  affected_vehicles_signal_events_high_priority:
    "Change (%) in Affected Vehicles experiencing High Priority Related Signal Events",
  warranty_cost: "Change (%) in Warranty Cost",
};
export const ISSUES_CHART_ACTIONS: ChartAction[] = [
  {
    id: "measure",
    title: "Measure",
    type: "issueMetric",
    defaultOptionId: "count",
    options: [
      {
        id: "count",
        label: "Issue Count",
        value: "Issue Count",
      },
      {
        id: "affected_vehicles_claims",
        label: "Affected Vehicle Count based on Claims",
        value: "Affected Vehicle Count based on Claims",
      },
      {
        id: "affected_vehicles_signal_events",
        label: "Affected Vehicle Count based on Signal Events",
        value: "Affected Vehicle Count based on Signal Events",
      },
      {
        id: "affected_vehicles_signal_events_high_priority",
        label: "Affected Vehicle Count based on High Priority Signal Events",
        value: "Affected Vehicle Count based on High Priority Signal Events",
      },
      {
        id: "warranty_cost",
        label: "Total Warranty Cost",
        value: "Total Warranty Cost",
      },
    ],
  },
  {
    id: "lookbackWindow",
    title: "Count Across",
    type: "",
    defaultOptionId: "0",
    options: [
      {
        id: "0",
        label: "All time",
        value: "All time",
      },
      {
        id: "1",
        label: "Previous 1 Week",
        value: "Previous 1 Week",
      },
      {
        id: "2",
        label: "Previous 2 Weeks",
        value: "Previous 2 Weeks",
      },
      {
        id: "3",
        label: "Previous 3 Weeks",
        value: "Previous 3 Weeks",
      },
      {
        id: "4",
        label: "Previous 4 Weeks",
        value: "Previous 4 Weeks",
      },
    ],
  },
  {
    id: "valueType",
    title: "",
    type: "",
    defaultOptionId: "absolute",
    options: [
      {
        id: "absolute",
        label: "Absolute",
        value: "Absolute",
      },
      {
        id: "percentage",
        label: "Percentage",
        value: "Percentage",
      },
    ],
  },
  {
    id: "groupBy",
    title: "X-axis",
    type: "dropdownSelect",
    defaultOptionId: "assignedGroupID",
    options: [
      {
        id: "statusObj.value",
        label: "Status",
        value: "Status",
      },
      {
        id: "name",
        label: "Name",
        value: "Name",
      },
      {
        id: "assignedGroupID",
        label: "Assigned Group",
        value: "Assigned Group",
      },
      {
        id: "severityObj.value",
        label: "Type",
        value: "Type",
      },
      {
        id: "assignee",
        label: "Assignee",
        value: "Assignee",
      },
      {
        id: "createdBy",
        label: "Created By",
        value: "Created By",
      },
      {
        id: "statusUpdatedAt",
        label: "Age",
        value: "Age",
      },
    ],
  },
  {
    id: "splitByIssueSource",
    title: "Y-axis",
    type: "boolean",
    checkboxLabel: "Split by Issue Source",
  },
  {
    id: "bucketBy",
    title: "Cell-axis",
    type: "dropdownSelect",
    defaultOptionId: "status",
    options: [
      {
        id: "none",
        label: "None",
        value: "None",
      },
      {
        id: "statusObj.value",
        label: "Status",
        value: "Status",
      },
      {
        id: "name",
        label: "Name",
        value: "Name",
      },
      {
        id: "assignedGroupID",
        label: "Assigned Group",
        value: "Assigned Group",
      },
      {
        id: "severityObj.value",
        label: "Type",
        value: "Type",
      },
      {
        id: "assignee",
        label: "Assignee",
        value: "Assignee",
      },
      {
        id: "createdBy",
        label: "Created By",
        value: "Created By",
      },
      {
        id: "statusUpdatedAt",
        label: "Age",
        value: "Age",
      },
    ],
  },
] as ChartAction[];
