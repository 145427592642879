import { useState } from "react";
import { format } from "date-fns";
import Skeleton from "react-loading-skeleton";

import { useClaimsMetricsHistory } from "shared/api/claims/hooks";
import { MONTH_YEAR_FULL } from "shared/constants";

import { ClaimsFiltersProps } from "pages/ClaimAnalytics/ClaimAnalyticsTabs";
import { CLAIMS_TAB_KEY } from "pages/ClaimAnalytics/constants";
import { ChartTooltip } from "pages/SignalEventsAnalytics/tabPages/SignalEvents/ChartTooltip";

import APIError from "features/ui/APIError";
import Card from "features/ui/Card";
import ChartActions from "features/ui/charts/Actions/ChartActions";
import { ChartActionsWrap } from "features/ui/charts/Actions/ChartActionsWrap";
import { SelectedChartOptions } from "features/ui/charts/Actions/types";
import LineChart from "features/ui/charts/LineChart";
import {
  getAxisLabel,
  getDefaultActions,
  getSelectedOptionId,
} from "features/ui/charts/utils";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";

import { useClaimsChartActions } from "./hooks";
import { prepareMetricsHistoryDataByTimestamp } from "./utils";

const CHART_HEIGHT_PX = 400;
export const CLAIMS_CHART_OPTIONS_KEY = "claimAnalyticsClaimsChartOptions";

/**
 * An example query strings to set chart settings for this chart:
 * - chartSettings_v1.clAnClaimState=%7B%22claims%22%3A%7B%22claimAnalyticsClaimsChartOptions%22%3A%5B%7B%22id%22%3A%22y%22%2C%22optionId%22%3A%22rollingClaimCount%22%7D%5D%7D%7D
 *
 * Broken down...
 * chartSettings_v1.clAnClaimState=
 * %7B%22claims%22%3A%7B%22claimAnalyticsClaimsChartOptions%22%3A%5B%7B%22id%22%3A%22y%22%2C%22optionId%22%3A%22rollingClaimCount%22%7D%5D%7D%7D
 * The URL decoded version is:
 * {"claims":{"claimAnalyticsClaimsChartOptions":[{"id":"y","optionId":"rollingClaimCount"}]}}
 */
const ClaimsChart = ({
  claimsFilters,
  vehiclesFilters,
  chartSettings,
  manageChartSettingsChange,
  onBadRequest,
}: ClaimsFiltersProps) => {
  const actions = useClaimsChartActions();

  // The data structures used to store chart settings are the same as those used by the
  // charts themselves (like this one), so no transformation is necessary.
  const initialSelectedOptions: SelectedChartOptions[] =
    chartSettings &&
    chartSettings[CLAIMS_TAB_KEY] &&
    chartSettings[CLAIMS_TAB_KEY][CLAIMS_CHART_OPTIONS_KEY]
      ? chartSettings[CLAIMS_TAB_KEY][CLAIMS_CHART_OPTIONS_KEY]
      : getDefaultActions(actions);

  const [selectedOptions, setSelectedOptions] = useState<
    SelectedChartOptions[]
  >(initialSelectedOptions);

  const yAxisOptions = actions.length > 0 ? actions[0].options : undefined;

  const handleOptionChange = (newSelectedOptions: SelectedChartOptions[]) => {
    setSelectedOptions(newSelectedOptions);
    if (manageChartSettingsChange) {
      manageChartSettingsChange(newSelectedOptions, CLAIMS_CHART_OPTIONS_KEY);
    }
  };

  const { data, isLoading, error } = useClaimsMetricsHistory({
    claimsFilter: getFiltersQuery(claimsFilters),
    vehiclesFilter: getFiltersQuery(vehiclesFilters),
  });

  if (error) {
    return <APIError error={error} onBadRequest={onBadRequest} />;
  }

  if (isLoading) {
    return (
      <Card>
        <Skeleton height={CHART_HEIGHT_PX} />
      </Card>
    );
  }

  if (!data) {
    return (
      <Card>
        <div className="text-gray-500">No data available</div>
      </Card>
    );
  }

  const formattedChartData = prepareMetricsHistoryDataByTimestamp(data);

  const yAxisKey = getSelectedOptionId(selectedOptions, "y");
  const yAxisLabel = getAxisLabel(actions, "y", yAxisKey);

  const chartLines = [
    {
      key: yAxisKey,
      label: yAxisLabel,
    },
  ];

  return (
    <Card>
      <ChartActionsWrap>
        <ChartActions
          actions={actions}
          selectedOptions={selectedOptions}
          onOptionChange={handleOptionChange}
        />
      </ChartActionsWrap>
      <LineChart
        height={400}
        width="100%"
        data={formattedChartData}
        xAxisKey="ts"
        yAxisLines={chartLines}
        xAxisLabel="Repair Date"
        enableZoom={true}
        xAxisProps={{
          domain: ["auto", "auto"],
          tickFormatter: (unixTime) => format(unixTime, "MMM yy"),
        }}
        tooltipProps={{
          labelFormatter: (unixTime: number) =>
            format(unixTime, MONTH_YEAR_FULL),
          content: (props: any) => (
            <ChartTooltip {...props} yAxisOptions={yAxisOptions} />
          ),
        }}
      />
    </Card>
  );
};

export default ClaimsChart;
