import { APIFilter } from "shared/api/utils";
import { getTenantServiceRecordName, toSnakeCase } from "shared/utils";

import Vehicles from "pages/Vehicles";
import ServiceRecords from "pages/VINView/ServiceRecords";

import StatefulTabs, { Tab } from "features/ui/StatefulTabs";

import * as config from "config/config";

interface Props {
  fleetName: string;
}

const FLEETS_SERVICE_RECORDS_TAB_KEY = "events";
const FLEETS_VEHICLES_TAB_KEY = "vehicles";

const FleetViewTabs = ({ fleetName }: Props) => {
  const vehiclesPageKey = `vehicles_fleet_${toSnakeCase(fleetName)}`;
  const recordsPageKey = `vehicles_events_fleet_${toSnakeCase(fleetName)}`;

  const { pages } = config.get();

  // this will be removed when migrating fleets to core (and will only use vehicleStaticFilters for both)
  const serviceRecordsStaticFilters: APIFilter[] = [
    { name: "vehicle.fleet", op: "eq", value: fleetName },
  ];

  const vehicleStaticFilters: APIFilter[] = [
    { name: "fleet", op: "eq", value: fleetName },
  ];

  const tabsItems: (Tab | boolean | undefined)[] = [
    pages.vehicles?.enabled && {
      key: FLEETS_VEHICLES_TAB_KEY,
      title: "Vehicles",
      content: (
        <Vehicles
          pageKey={vehiclesPageKey}
          staticFilters={vehicleStaticFilters}
        />
      ),
    },
    pages.events?.enabled && {
      key: FLEETS_SERVICE_RECORDS_TAB_KEY,
      title: getTenantServiceRecordName(),
      content: (
        <ServiceRecords
          staticFilters={serviceRecordsStaticFilters}
          pageKey={recordsPageKey}
        />
      ),
    },
  ];

  const tabs = tabsItems.filter(Boolean) as Tab[];

  return <StatefulTabs tabs={tabs} />;
};

export default FleetViewTabs;
