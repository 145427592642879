import classNames from "classnames";
import { RiShareBoxFill as ExtLinkIcon } from "react-icons/ri";
import { NavLink, useLocation } from "react-router-dom";

import { NavGroup } from "./utils";

interface Props extends NavGroup {}

const SideMenuGroup = ({ title, links }: Props) => {
  const location = useLocation();
  return (
    <li className="mb-6">
      <span
        className="pl-6 py-1 block uppercase tracking-widest bg-gray-100 text-xs text-gray-500 mb-1 border-b border-gray-300 w-full"
        data-testid="menu-subgroup-title"
      >
        {title}
      </span>
      <ul className="px-4 lg:px-6">
        {links.map(
          ({
            icon,
            to,
            isExternal,
            text,
            isActiveFunc,
            exact,
            hasUnlistedSubroutes = false,
            ...otherLinkProps
          }) => {
            return (
              <li
                key={`${title}-${to}`}
                data-testid="menu-item"
                className="transition duration-200 cursor-pointer text-gray-600 tracking-normal py-2 hover:text-blue-400 focus:text-blue-400 focus:outline-none"
              >
                {(isExternal && (
                  <a
                    href={to}
                    target="_blank"
                    rel="noreferrer"
                    className="flex items-center space-x-2 font-medium text-sm leading-none md:leading-3"
                  >
                    {icon}
                    <span className="flex">
                      {text} <ExtLinkIcon className="inline ml-1" />
                    </span>
                  </a>
                )) || (
                  <NavLink
                    to={to}
                    className={({ isActive }) => {
                      return (
                        isActiveFunc ? isActiveFunc(true, location) : isActive
                      )
                        ? classNames(
                            "flex items-center space-x-2 font-medium text-sm leading-none md:leading-3",
                            "hover:text-blue-400 text-blue-500"
                          )
                        : "flex items-center space-x-2 font-medium text-sm leading-none md:leading-3";
                    }}
                    end={!hasUnlistedSubroutes}
                    {...otherLinkProps}
                  >
                    {icon} <span>{text}</span>
                  </NavLink>
                )}
              </li>
            );
          }
        )}
      </ul>
    </li>
  );
};

export default SideMenuGroup;
