import classNames from "classnames";
import { Alert } from "@mui/material";

import { SignalEventStudioState } from "pages/CustomSignalEvents/utils";

import BaseEventTypeSelectSection from "./BaseEventTypeSection";
import CustomAttributeDefinitionsSection from "./CustomAttributeDefinitionsSection";
import CustomAttributesFiltersSection from "./CustomAttributesFiltersSection";
import DownsamplingInput from "./DownsamplingSection";
import StandardAttributeFiltersSection from "./StandardAttributeFiltersSection";

interface Props {
  signalEventStudioState: SignalEventStudioState;
  canEditDefinition: boolean;
  splitScreen?: boolean;
  className?: string;
}

const SignalEventDefinition = ({
  signalEventStudioState,
  canEditDefinition,
  splitScreen,
  className,
}: Props) => {
  const {
    schema,
    eventTypeOptions,
    selectedEventTypeOption,
    setSelectedEventTypeOption,
    filterBuilderState,
    setFilterBuilderState,
    customAttributes,
    setCustomAttributes,
    onCustomAttributeRemoved,
    completeCustomAttributes,
    customAttributesSchema,
    customAttributeFilterBuilderState,
    setCustomAttributeFilterBuilderState,
    selectedDownsamplingOption,
    setSelectedDownsamplingOption,
    isValidForm,
    formValidationErrors,
  } = signalEventStudioState;

  return (
    <>
      <BaseEventTypeSelectSection
        options={eventTypeOptions}
        selectedOption={selectedEventTypeOption}
        onOptionSelect={(selectedOption) => {
          setSelectedEventTypeOption(selectedOption);
          setFilterBuilderState(undefined);
        }}
        disabled={!canEditDefinition}
        className={className}
      />
      {selectedEventTypeOption && (
        <>
          {schema && (
            <StandardAttributeFiltersSection
              filterBuilderState={filterBuilderState}
              filtersSchema={schema}
              onChange={setFilterBuilderState}
              disabled={!canEditDefinition}
              className={className}
            />
          )}
          <CustomAttributeDefinitionsSection
            customAttributes={customAttributes}
            onChange={setCustomAttributes}
            onCustomAttributeRemoved={onCustomAttributeRemoved}
            baseEventType={selectedEventTypeOption.id as string}
            baseEventTypeSchema={schema}
            disabled={!canEditDefinition}
            className={className}
          />
          {completeCustomAttributes.length > 0 && (
            <CustomAttributesFiltersSection
              filtersSchema={customAttributesSchema}
              filterBuilderState={customAttributeFilterBuilderState}
              onChange={setCustomAttributeFilterBuilderState}
              disabled={!canEditDefinition}
              className={className}
            />
          )}
          <DownsamplingInput
            currentValue={selectedDownsamplingOption}
            onChange={setSelectedDownsamplingOption}
            disabled={!canEditDefinition}
            className={className}
          />
          {!isValidForm && !splitScreen && (
            <Alert
              severity="error"
              data-testid="validation-message"
              className={classNames("w-[65rem]", className)}
            >
              <div className="font-bold mb-1">Validation errors</div>
              {formValidationErrors.map((error) => (
                <div key={error}>{error}</div>
              ))}
            </Alert>
          )}
        </>
      )}
    </>
  );
};

export default SignalEventDefinition;
