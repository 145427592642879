import { useState } from "react";
import { CgSpinnerTwo as LoadingIcon } from "react-icons/cg";
import { toast } from "react-toastify";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import { updateAlertDefinitionAccess } from "shared/api/alertDefinitions/api";
import {
  APISuccessResponse,
  PermissionEntry,
  updateCollectionAccess,
} from "shared/api/api";
import { updateIssueAccess } from "shared/api/issues/api";
import { updateGroupAccess } from "shared/api/rbac/api";
import { updateServicePlanAccess } from "shared/api/servicePlans/api";
import { useEmailFromJWT } from "shared/hooks";
import { EntityWithPermissions, PermissionID } from "shared/types";
import { camelCaseToTitle, mutateMultipleSWRRequestKeys } from "shared/utils";

import { toApiGroupMemberAccess } from "pages/Groups/utils";

import Button from "features/ui/Button";
import Label from "features/ui/Label";

import PermissionsTableWrapper from "./PermissionsTableWrapper";
import {
  calculatePermissionDiff,
  DEFAULT_MEMBER_ACCESS,
  formatPermissions,
  isEmptyDiff,
  isHighestPermission,
  modifyPermissionEntry,
} from "./utils";

const APPLY_SUCCESS = "Permissions successfully applied";
const APPLY_FAILED = "Applying permissions failed";

interface Props {
  entity: EntityWithPermissions;
  entityId: string;
  entityName: string;
  permissions: PermissionEntry[];
  memberAccess?: PermissionID;
  isOpen: boolean;
  onClose: (permission: PermissionID) => void;
  canEdit: boolean;
  entityRequestKeys?: string[];
}

const PermissionsDialog = ({
  entity,
  entityId,
  entityName,
  isOpen,
  permissions,
  memberAccess = DEFAULT_MEMBER_ACCESS,
  onClose,
  canEdit,
  entityRequestKeys,
}: Props) => {
  const myEmail = useEmailFromJWT();

  const [savingPermissions, setSavingPermissions] = useState(false);
  const [appliedPermissions, setAppliedPermissions] = useState(
    formatPermissions(permissions, myEmail, canEdit)
  );
  const [currentPermissions, setCurrentPermissions] = useState(
    formatPermissions(permissions, myEmail, canEdit)
  );
  const [currentMemberAccess, setCurrentMemberAccess] = useState(memberAccess);

  const UPDATE_ACCESS_FUNCTIONS: {
    [key: string]: (
      args: any
    ) => Promise<APISuccessResponse<PermissionEntry[]>>;
  } = {
    servicePlan: updateServicePlanAccess,
    collection: updateCollectionAccess,
    issue: updateIssueAccess,
    group: updateGroupAccess,
    alertDefinition: updateAlertDefinitionAccess,
  };

  const onApplyPermissions = () => {
    const permissionsDiff = calculatePermissionDiff(
      appliedPermissions,
      currentPermissions,
      myEmail
    );

    const updateAccessFunc = UPDATE_ACCESS_FUNCTIONS[entity] || undefined;

    if (!updateAccessFunc)
      throw new Error(`Updating access for entity '${entity}' not supported`);

    setSavingPermissions(true);

    const memberAccessParams =
      entity === "group" && currentMemberAccess !== memberAccess
        ? { memberAccess: toApiGroupMemberAccess(currentMemberAccess) }
        : {};

    updateAccessFunc({
      ID: entityId,
      ...permissionsDiff,
      ...memberAccessParams,
    })
      .then((data: any) => {
        toast.success(APPLY_SUCCESS);

        const updatedPermissions =
          entity === "group" ? data.data.data : data.data;
        const permissionsAfterApply = formatPermissions(
          modifyPermissionEntry(updatedPermissions),
          myEmail,
          canEdit
        );
        setCurrentPermissions([...permissionsAfterApply]);
        setAppliedPermissions([...permissionsAfterApply]);

        if (entityRequestKeys) {
          mutateMultipleSWRRequestKeys(entityRequestKeys);
        }
      })
      .catch((error: Error) => {
        toast.error(APPLY_FAILED);
        console.error(error);
      })
      .finally(() => {
        setSavingPermissions(false);
        onClose(
          currentPermissions.find((x) => x.everyone)?.access as PermissionID
        );
      });
  };

  const onClosePermissions = () => {
    // reset permission state to the last one after successful apply
    setCurrentPermissions([...appliedPermissions]);
    onClose(currentPermissions.find((x) => x.everyone)?.access as PermissionID);
  };

  const noEditPermission = !Boolean(
    currentPermissions.find(
      (x) => (x.everyone || x.ID) && isHighestPermission(x.access)
    )
  );

  const ctaIcon =
    (savingPermissions && <LoadingIcon className="animate-spin" />) ||
    undefined;

  const onPermissionsUpdate = (
    permissions: PermissionEntry[],
    memberAccess: PermissionID
  ) => {
    setCurrentPermissions(permissions);
    setCurrentMemberAccess(memberAccess);
  };

  const arePermissionsUpdated = (): boolean => {
    const emptyDiff = isEmptyDiff(
      appliedPermissions,
      currentPermissions,
      myEmail
    );
    const memberAccessUpdated = currentMemberAccess !== memberAccess;
    return !emptyDiff || memberAccessUpdated;
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClosePermissions}
      data-testid="modal-permissions"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        Permissions for {camelCaseToTitle(entity)}: <i>{entityName}</i>
      </DialogTitle>
      <DialogContent>
        <PermissionsTableWrapper
          entity={entity}
          entityId={entityId}
          permissions={currentPermissions}
          canEdit={canEdit}
          onPermissionsUpdate={onPermissionsUpdate}
          memberAccess={currentMemberAccess}
        />
        {noEditPermission && (
          <div className="flex">
            <Label
              text="At least one user or group must have edit permissions."
              className="text-red-500"
            />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={onClosePermissions}
          testId="permissions-close-button"
        >
          Close
        </Button>
        {canEdit && (
          <Button
            className="ml-2"
            color="primary"
            variant="contained"
            onClick={onApplyPermissions}
            endIcon={ctaIcon}
            disabled={
              noEditPermission || savingPermissions || !arePermissionsUpdated()
            }
            testId="permissions-apply-button"
          >
            Apply
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default PermissionsDialog;
