import { useState } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";

import { APIFilter } from "shared/api/utils";
import { useCustomLocalStorageState } from "shared/hooks";

import { SelectedChartOptions } from "features/ui/charts/Actions/types";
import { getDefaultActions } from "features/ui/charts/utils";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { getPendingFiltersKey } from "features/ui/Filters/FilterWizard/utils";
import { useFilterSortState } from "features/ui/Filters/hooks";

import {
  DEFAULT_SUGGESTED_ISSUES_FILTER,
  DEFAULT_SUGGESTED_ISSUES_SORT,
  SUGGESTED_ISSUES_CHART_ACTIONS,
  SUGGESTED_ISSUES_CHART_KEY,
  SUGGESTED_ISSUES_PAGE_KEY,
} from "./constants";
import SuggestedIssuesFilters from "./SuggestedIssues/SuggestedIssuesFilters";
import SuggestedIssuesTable from "./SuggestedIssues/SuggestedIssuesTable";
import SuggestedIssuesDashboard from "./SuggestedIssuesDashboard";

export const SUGGESTED_ISSUES_PENDING_FILTERS_LS_KEY = getPendingFiltersKey(
  SUGGESTED_ISSUES_PAGE_KEY
);

const SuggestedIssuesPage = () => {
  const { issuePublishing } = useFlags();

  const suggestedIssuesFilterSortState = useFilterSortState({
    pageKey: SUGGESTED_ISSUES_PAGE_KEY,
    defaultSort: DEFAULT_SUGGESTED_ISSUES_SORT,
    defaultFilterValues: DEFAULT_SUGGESTED_ISSUES_FILTER,
    pendingFiltersLocalStorageKey: SUGGESTED_ISSUES_PENDING_FILTERS_LS_KEY,
  });
  const [selectedBarFilters, setSelectedBarFilters] = useState<
    FilterGroupState | undefined
  >();

  const [selectedChartOptions, setSelectedChartOptions] =
    useCustomLocalStorageState<SelectedChartOptions[]>(
      SUGGESTED_ISSUES_CHART_KEY,
      {
        defaultValue: getDefaultActions(SUGGESTED_ISSUES_CHART_ACTIONS),
      }
    );

  const staticFiltersConditional: (APIFilter | boolean)[] = [
    // Users that are not allowed to publish/unpublish suggested issues should see only the published ones
    !issuePublishing && {
      name: "published",
      value: true,
      op: "eq",
    },
  ];
  const staticFilters = staticFiltersConditional.filter(Boolean) as APIFilter[];

  return (
    <>
      <SuggestedIssuesFilters
        filterSortState={suggestedIssuesFilterSortState}
      />
      <SuggestedIssuesDashboard
        filterSortState={suggestedIssuesFilterSortState}
        selectedOptions={selectedChartOptions}
        setSelectedOptions={setSelectedChartOptions}
        setSelectedBarFilters={setSelectedBarFilters}
        staticFilters={staticFilters}
      />
      <SuggestedIssuesTable
        topFilterSortState={suggestedIssuesFilterSortState}
        selectedChartOptions={selectedChartOptions}
        selectedBarFilters={selectedBarFilters}
        staticFilters={staticFilters}
      />
    </>
  );
};

export default SuggestedIssuesPage;
