import { useMemo, useState } from "react";
import qs from "qs";
import { useNavigate } from "react-router-dom";

import { SignalEventsAssociatedClaimsRequest } from "shared/api/signalEvents/api";
import { useListSignalEventsAssociatedClaims } from "shared/api/signalEvents/hooks";
import { getSortFilter } from "shared/api/utils";
import { useClaimsFiltersSchema } from "shared/hooks";
import {
  useGroupBySelectOptions,
  useSchemaEntryForAttribute,
} from "shared/schemas/hooks";
import { SortBy } from "shared/types";
import { cloneObject, pluralize } from "shared/utils";

import {
  CLAIMS_FILTER_LABEL,
  CLAIMS_PAGE_KEY,
  VEHICLES_PAGE_KEY,
} from "pages/ClaimAnalytics/constants";
import { canShowDescription } from "pages/ClaimAnalytics/tabPages/TopContributors/utils";
import { GROUP_BY_ATTRIBUTE_KEY } from "pages/constants";
import { ASSOCIATED_CLAIMS_TAB_KEY } from "pages/SignalEventsAnalytics/constants";
import { SignalEventsAnalyticsTabsProps } from "pages/SignalEventsAnalytics/SignalEventsAnalyticsTabs";
import { getInitialSelectedGroupByAttribute } from "pages/SignalEventsAnalytics/utils";

import APIError from "features/ui/APIError";
import { getCheckboxCheckedProps } from "features/ui/Checkbox/utils";
import DescriptionColumn from "features/ui/DescriptionColumn";
import DropdownSelect from "features/ui/DropdownSelect";
import DropdownWithSearch from "features/ui/DropdownWithSearch/DropdownWithSearch";
import { OPERATORS_MAP } from "features/ui/Filters/constants";
import {
  filterStateToFilterGroupState,
  getFiltersQuery,
  getTopLevelRowFromFilterGroupState,
  updateOrAddRowFilterGroupState,
} from "features/ui/Filters/FilterBuilder/utils";
import FiltersOverview from "features/ui/Filters/FiltersOverview/FiltersOverview";
import { SIGNAL_EVENT_ID_FIELD_NAME } from "features/ui/Filters/FilterTypes/RelatesFilter/constants";
import RelatesFilterActions from "features/ui/Filters/FilterTypes/RelatesFilter/RelatesFilterForm/RelatesFilterActions";
import RelatesTimeWindowForm, {
  WINDOW_DIRECTION_OPTION_AFTER,
} from "features/ui/Filters/FilterTypes/RelatesFilter/RelatesFilterForm/RelatesTimeWindowForm";
import SelectedRowsActions from "features/ui/Filters/FilterTypes/RelatesFilter/RelatesFilterForm/SelectedRowsActions";
import { addRelatesFilterToSignalEventFilter } from "features/ui/Filters/FilterTypes/RelatesFilter/utils";
import FilterSelector from "features/ui/Filters/FilterWizard/FilterSelector";
import { getPendingFiltersKey } from "features/ui/Filters/FilterWizard/utils";
import { useFilterSortState } from "features/ui/Filters/hooks";
import { FilterOperator, RelatesFilterState } from "features/ui/Filters/types";
import { getFilterLabel, getFiltersKey } from "features/ui/Filters/utils";
import { SelectOption } from "features/ui/Select";
import Table, { OnSortParams, SchemaEntry } from "features/ui/Table";
import { DataType } from "features/ui/Table/TableBodyCell";
import TableCellWithCheckbox from "features/ui/Table/TableCellWithCheckbox";

import { routes } from "services/routes";

import {
  PAGE_KEY,
  SE_ASSOCIATED_CLAIMS_KEY,
  SIGNAL_EVENTS_ASSOCIATED_CLAIMS_GROUP_BY_OPTIONS_KEY,
  SIGNAL_EVENTS_ASSOCIATED_CLAIMS_WINDOW_SIZE_OPTIONS_KEY,
  WINDOW_SIZE_KEY,
} from "./constants";
import { useSignalEventsAssociatedClaimsDefaultGroupBy } from "./hooks";
import {
  createRelatesFilter,
  getDefaultClaimFilters,
  getDefaultSchema,
  getWindowSizeFromRelatesFilter,
} from "./utils";

const DEFAULT_SORT: SortBy = { associationStrength: "desc" };
const LIMIT = 500;
const TABLE_HEIGHT_PX = 500;

const DEFAULT_WINDOW_SIZE = 30;

const SE_ASSOCIATED_CLAIMS_KEY_PENDING = getPendingFiltersKey(PAGE_KEY);

const AssociatedClaims = ({
  signalEventsFiltersFilterSortState,
  vehiclesFilters,
}: SignalEventsAnalyticsTabsProps) => {
  const navigate = useNavigate();

  const claimAnalyticsFilterKey = getFiltersKey(CLAIMS_PAGE_KEY);
  const vehicleFilterKey = getFiltersKey(VEHICLES_PAGE_KEY);

  const signalEventsFilters = signalEventsFiltersFilterSortState?.filters;

  const defaultClaimFilters = getDefaultClaimFilters();

  const groupBySelectOptions = useGroupBySelectOptions("claim", true);

  const defaultGroupBy = useSignalEventsAssociatedClaimsDefaultGroupBy();

  const PENDING_FILTERS_ASSOCIATED_CLAIMS_LS_KEY = getPendingFiltersKey(
    SE_ASSOCIATED_CLAIMS_KEY
  );

  const {
    manageFilterChange,
    resetFilters,
    filters,
    sort,
    manageOnSortChange,
    initialized: filtersInitialized,
    resetFilterSortState,
  } = useFilterSortState({
    pageKey: PAGE_KEY,
    defaultSort: DEFAULT_SORT,
  });

  const claimsFilterSortState = useFilterSortState({
    pageKey: SE_ASSOCIATED_CLAIMS_KEY,
    defaultFilterValues: defaultClaimFilters,
    pendingFiltersLocalStorageKey: PENDING_FILTERS_ASSOCIATED_CLAIMS_LS_KEY,
  });

  const initialSelectedGroupByAttribute: SelectOption =
    getInitialSelectedGroupByAttribute(
      claimsFilterSortState?.chartSettings,
      defaultGroupBy,
      groupBySelectOptions,
      ASSOCIATED_CLAIMS_TAB_KEY,
      SIGNAL_EVENTS_ASSOCIATED_CLAIMS_GROUP_BY_OPTIONS_KEY,
      GROUP_BY_ATTRIBUTE_KEY
    );

  const [selectedGroupByAttribute, setSelectedGroupByAttribute] = useState(
    initialSelectedGroupByAttribute
  );

  const handleGroupByAttributeChange = (groupByAttribute: SelectOption) => {
    setSelectedGroupByAttribute(groupByAttribute);
    if (claimsFilterSortState?.manageChartSettingsChange) {
      claimsFilterSortState.manageChartSettingsChange(
        [
          {
            id: GROUP_BY_ATTRIBUTE_KEY,
            optionId: groupByAttribute.id,
          },
        ],
        SIGNAL_EVENTS_ASSOCIATED_CLAIMS_GROUP_BY_OPTIONS_KEY
      );
    }
  };

  const [selectedValues, setSelectedValues] = useState(new Set<string>());

  const firstColumn = useSchemaEntryForAttribute(
    selectedGroupByAttribute.id as string,
    "claim"
  );

  const descriptionColumns: SchemaEntry[] = canShowDescription(
    selectedGroupByAttribute.id.toString()
  )
    ? [
        {
          label: "Description",
          accessor: "description",
          dataType: DataType.JSX,
        },
      ]
    : [];

  const claimsFiltersSchema = useClaimsFiltersSchema();

  const claimFilters = claimsFilterSortState.filters;

  const claimsFilterLabel = getFilterLabel(
    "Claim Filters",
    claimsFilterSortState.filters
  );

  const handleSorting = ({ accessor, sort }: OnSortParams) => {
    // only allow sorting by one column at the time
    manageOnSortChange({ [accessor]: sort });
  };

  const [appliedRelatesFilter, setAppliedRelatesFilter] = useState(
    createRelatesFilter(claimsFilterSortState)
  );

  const handleAppliedRelatesFilterChange = (
    appliedRelatesFilter: RelatesFilterState
  ) => {
    setAppliedRelatesFilter(appliedRelatesFilter);
    if (claimsFilterSortState?.manageChartSettingsChange) {
      claimsFilterSortState.manageChartSettingsChange(
        [
          {
            id: WINDOW_SIZE_KEY,
            optionId: appliedRelatesFilter.options.windowSize,
          },
        ],
        SIGNAL_EVENTS_ASSOCIATED_CLAIMS_WINDOW_SIZE_OPTIONS_KEY
      );
    }
  };

  const [relatesFilter, setRelatesFilter] = useState(appliedRelatesFilter);

  const selectedPeriod = `${getWindowSizeFromRelatesFilter(relatesFilter)} ${pluralize(
    "day",
    getWindowSizeFromRelatesFilter(relatesFilter)
  )}`;

  const defaultSchema = getDefaultSchema(selectedPeriod);

  const onGroupByChange = (value: SelectOption) => {
    handleGroupByAttributeChange(value);
    setSelectedValues(new Set<string>());
  };

  const onUpdateRelatesFilter = (row: RelatesFilterState) => {
    setRelatesFilter(
      createRelatesFilter(
        claimsFilterSortState,
        getWindowSizeFromRelatesFilter(row)
      )
    );
  };

  const onCancel = () => {
    const canceledRelatesFilter = createRelatesFilter(
      claimsFilterSortState,
      DEFAULT_WINDOW_SIZE
    );
    setRelatesFilter(canceledRelatesFilter);
    handleAppliedRelatesFilterChange(canceledRelatesFilter);
  };

  const onApply = () => {
    handleAppliedRelatesFilterChange(relatesFilter);
  };

  const onCloseFilters = () => {
    const windowSize = getWindowSizeFromRelatesFilter(relatesFilter);
    setRelatesFilter(createRelatesFilter(claimsFilterSortState, windowSize));

    const appliedWindowSize =
      getWindowSizeFromRelatesFilter(appliedRelatesFilter);
    handleAppliedRelatesFilterChange(
      createRelatesFilter(claimsFilterSortState, appliedWindowSize)
    );
  };

  const requestParams: SignalEventsAssociatedClaimsRequest = {
    sort: getSortFilter(sort),
    filter: getFiltersQuery(filters),
    signalEventOccurrencesFilter: getFiltersQuery(signalEventsFilters),
    vehiclesFilter: getFiltersQuery(vehiclesFilters),
    claimFilter: getFiltersQuery(claimFilters),
    limit: LIMIT,
    groupBy: selectedGroupByAttribute.id as string,
    signalEventsTimeWindow:
      getWindowSizeFromRelatesFilter(appliedRelatesFilter),
  };

  const { data, isLoading, headers, error, ...paginationData } =
    useListSignalEventsAssociatedClaims(requestParams);

  const allSelectableValues =
    data?.map(({ groupByAttributeValue }) => groupByAttributeValue as string) ||
    [];
  const { allChecked, indeterminateChecked } = getCheckboxCheckedProps(
    selectedValues,
    allSelectableValues
  );

  const toggleSelectedValues = () => {
    if (allChecked) {
      setSelectedValues(new Set<string>());
      return;
    }
    setSelectedValues(new Set<string>(allSelectableValues));
  };

  const schema: SchemaEntry[] = firstColumn
    ? [
        {
          ...firstColumn,
          dataType: DataType.JSX,
          selectable: {
            onClick: toggleSelectedValues,
            checked: allChecked,
            indeterminate: indeterminateChecked,
          },
        },
        ...descriptionColumns,
        ...defaultSchema,
      ]
    : defaultSchema;

  const formattedData = useMemo(
    () =>
      data &&
      data?.map((row) => {
        const { groupByAttributeValue } = row;
        return {
          ...row,
          groupByAttributeValue: (
            <TableCellWithCheckbox
              value={groupByAttributeValue || ""}
              selectedValues={selectedValues}
              setSelectedValues={setSelectedValues}
              testId="checkbox-associated-claim"
            />
          ),
          description: groupByAttributeValue && (
            <DescriptionColumn
              fieldName={selectedGroupByAttribute.id.toString()}
              fieldValue={groupByAttributeValue}
            />
          ),
        };
      }),
    [data, selectedGroupByAttribute.id, selectedValues]
  );

  const navigateToClaimAnalytics = () => {
    const signalEventIDFilter = getTopLevelRowFromFilterGroupState(
      "signalEventID",
      signalEventsFiltersFilterSortState?.filters
    );

    let newClaimFilters = cloneObject(claimFilters);

    if (signalEventIDFilter) {
      // Convert top level signal event ID filter to related signal event filter, but only if you can
      const operator = signalEventIDFilter.operator;
      if (OPERATORS_MAP.relates.flat().includes(operator)) {
        const newRelatesFilter: RelatesFilterState = {
          operator: "occurs",
          options: relatesFilter.options,
          filters: filterStateToFilterGroupState({
            [SIGNAL_EVENT_ID_FIELD_NAME]: {
              operator,
              values: signalEventIDFilter.values,
              relates: signalEventIDFilter.relates,
              extra: signalEventIDFilter.extra,
            },
          }),
        };

        const newRelatedSignalEventOccurrencesFilter =
          addRelatesFilterToSignalEventFilter(undefined, newRelatesFilter);

        newClaimFilters = updateOrAddRowFilterGroupState(claimFilters, {
          id: "relatedSignalEventOccurrences",
          type: "row",
          attribute: "relatedSignalEventOccurrences",
          operator: newRelatedSignalEventOccurrencesFilter.operator,
          values: newRelatedSignalEventOccurrencesFilter.values,
          relates: newRelatedSignalEventOccurrencesFilter.relates,
          extra: newRelatedSignalEventOccurrencesFilter.extra,
        });
      }
    }

    // Add selected values as filter
    newClaimFilters = updateOrAddRowFilterGroupState(newClaimFilters, {
      id: "groupBy",
      type: "row",
      attribute: selectedGroupByAttribute.id as string,
      operator: FilterOperator.IN,
      values: Array.from(selectedValues),
    });

    navigate({
      pathname: routes.claimAnalytics,
      search: qs.stringify({
        [vehicleFilterKey]: getFiltersQuery(vehiclesFilters),
        [claimAnalyticsFilterKey]: getFiltersQuery(newClaimFilters),
      }),
    });
  };

  return (
    <>
      <div className="mt-4 space-y-2">
        <div className="flex flex-wrap leading-10 space-x-2 items-end">
          <span>Claim Defined By</span>
          <DropdownSelect
            testId="claims-filters-dropdown"
            label={claimsFilterLabel}
            buttonClass="mt-6 h-[38px] mr-4"
            content={
              <FilterSelector
                schema={claimsFiltersSchema}
                filterSortState={claimsFilterSortState}
                defaultFilters={defaultClaimFilters}
                title={CLAIMS_FILTER_LABEL}
                testId="claims-filters"
                pendingFiltersKey={SE_ASSOCIATED_CLAIMS_KEY_PENDING}
                onCloseFilters={onCloseFilters}
              />
            }
          />
          <RelatesTimeWindowForm
            relatesState={relatesFilter}
            onUpdate={onUpdateRelatesFilter}
            baseEntityText="Base Signal Event"
            inFilterSelector={false}
            windowDirectionOptions={[WINDOW_DIRECTION_OPTION_AFTER]}
          />
          <RelatesFilterActions
            relatesState={relatesFilter}
            onApply={onApply}
            onCancel={onCancel}
            appliedRelatesFilter={appliedRelatesFilter}
          />
        </div>
        {groupBySelectOptions.length > 0 && (
          <DropdownWithSearch
            className="!mt-5"
            options={groupBySelectOptions}
            selectedOption={selectedGroupByAttribute}
            label="Select a dimension"
            onSelectedOptionChange={onGroupByChange}
            testId="signal-events-associated-claims-dimension"
          />
        )}
      </div>
      <div className="flex items-center my-3">
        <FiltersOverview
          filters={filters}
          tableSchema={schema}
          onFiltersReset={resetFilters}
        />
      </div>
      <SelectedRowsActions
        relatesFilter={appliedRelatesFilter}
        selectedSignalEvents={selectedValues}
        onExploreInClaimAnalyticsActionClick={navigateToClaimAnalytics}
      />
      {!error && (
        <Table
          {...paginationData}
          data={formattedData}
          schema={schema}
          isLoading={isLoading}
          loadingRows={LIMIT}
          sortBy={sort}
          onSort={handleSorting}
          filtersInitialized={filtersInitialized}
          onFiltersReset={resetFilters}
          onFilterChange={manageFilterChange}
          filters={filters}
          stickyFirstColumn={true}
          dense
          scrollHeight={TABLE_HEIGHT_PX}
          testId="associated-claims-table"
        />
      )}
      {error && <APIError error={error} onBadRequest={resetFilterSortState} />}
      {!error && !isLoading && !data?.length && (
        <div className="py-4 text-gray-400 text-sm">No results.</div>
      )}
    </>
  );
};

export default AssociatedClaims;
