import { useEffect, useState } from "react";
import useSWR from "swr";
import type { SWRConfiguration } from "swr";

import * as api from "./api";
import { EventType } from "./api";
import { DEFAULT_ATTRIBUTES_SWR_CONFIG } from "./constants";
import parseLinkHeader from "./parse-link-header";
import { hasPathTraversal } from "./utils";

export interface UseAPIState<Data> {
  data?: Data;
  headers: api.APIHeaders;
  isLoading: boolean;
  error?: api.APIErrorResponse;
  requestKey: string;
}

export const useAPI = <Args, Data>(
  uriFunc: (args: Args) => string,
  args: Args,
  swrOptions?: SWRConfiguration,
  fetcher = api.getFetcher
): UseAPIState<Data> => {
  let path: string | null = uriFunc(args);

  const isInsecureRequest = hasPathTraversal(args);

  if (isInsecureRequest) {
    console.warn(
      "Path traversal detected in url params, do not make a request."
    );
    path = null;
  }

  const {
    data: response,
    error,
    isLoading,
  } = useSWR<api.APISuccessResponse<Data>, api.APIErrorResponse>(
    path,
    fetcher,
    swrOptions
  );
  const { data, headers = {} } = response || {};

  const key = path || "";

  if (isInsecureRequest) {
    return {
      isLoading: false,
      headers,
      error: { message: "Insecure parameters detected.", code: "400" },
      requestKey: key,
    };
  }

  return { isLoading, headers, data, error, requestKey: key };
};

export const usePostAPI = <Args, Data>(
  uriFunc: (args: Args) => string,
  args: Args,
  body: Object,
  swrOptions?: SWRConfiguration
): UseAPIState<Data> => {
  let path: string | null = uriFunc(args);

  const isInsecureRequest = hasPathTraversal(args);

  if (isInsecureRequest) {
    console.warn(
      "Path traversal detected in url params, do not make a request."
    );
    path = null;
  }

  const key = path ? `${path}_${JSON.stringify(body)}` : "";

  const {
    data: response,
    error,
    isLoading,
  } = useSWR<api.APISuccessResponse<Data>, api.APIErrorResponse>(
    key,
    () => api.postFetcher(path!, body),
    swrOptions
  );
  const { data, headers = {} } = response || {};

  if (isInsecureRequest) {
    return {
      isLoading: false,
      headers,
      error: { message: "Insecure parameters detected.", code: "400" },
      requestKey: key,
    };
  }

  return { isLoading, headers, data, error, requestKey: key };
};

export interface UsePaginatedAPIState<Data> extends UseAPIState<Data> {
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  onPreviousPage: () => void;
  onNextPage: () => void;
}

export const usePaginatedAPI = <Args extends api.APIPaginatedRequest, Data>(
  uriFunc: (args: Args) => string,
  args: Args,
  swrOptions?: SWRConfiguration
): UsePaginatedAPIState<Data> => {
  const key = uriFunc(args);

  // we have to reset before & after when filters change otherwise pagination
  // propagates stay on the next vin
  useEffect(() => {
    setBefore(undefined);
    setAfter(undefined);
  }, [key]);

  const [before, setBefore] = useState(args.before);
  const [after, setAfter] = useState(args.after);
  const apiState = useAPI<Args, Data>(
    uriFunc,
    { ...args, before, after },
    swrOptions
  );

  const links = parseLinkHeader(apiState.headers.link);

  const hasPreviousPage = Boolean(links?.prev);
  const hasNextPage = Boolean(links?.next);

  const onPreviousPage = () => {
    if (!hasPreviousPage) return;

    setBefore(links?.prev.before);
    setAfter(links?.prev.after);
  };

  const onNextPage = () => {
    if (!hasNextPage) return;

    setBefore(links?.next.before);
    setAfter(links?.next.after);
  };

  return {
    ...apiState,
    hasPreviousPage,
    hasNextPage,
    onPreviousPage,
    onNextPage,
  };
};

// ---------
// FLEETS
// ---------
export const useFleets = (args: api.ListFleetsRequest) =>
  usePaginatedAPI<typeof args, api.Fleet[]>(api.listFleetsRequestURI, args);

export const useListFleetsCount = (args: api.CountRequest) =>
  useAPI<typeof args, api.CountResponse>(api.listFleetCountRequestURI, args);

// ---------
// MAINTENANCE SCHEDULES
// ---------
export const useVehicleCategory = (args: api.GetApiIdRequest) =>
  useAPI<typeof args, api.VehicleGenericCategory>(
    api.getVehicleCategoryRequestURI,
    args
  );

export const useListVehicleCategories = () =>
  useAPI<null, api.VehicleGenericCategory[]>(
    api.listVehicleCategoriesRequestURI,
    null
  );

export const useListTransportCategories = () =>
  useAPI<null, api.VehicleGenericCategory[]>(
    api.listTransportCategoriesRequestURI,
    null
  );

export const useTransportCategory = (args: api.GetApiIdRequest) =>
  useAPI<typeof args, api.VehicleGenericCategory>(
    api.getTransportCategoryRequestURI,
    args
  );

export const useMaintenanceSchedule = (
  args: api.GetMaintenanceScheduleRequest
) =>
  useAPI<typeof args, api.MaintenanceSchedule[]>(
    api.listMaintenanceScheduleRequestURI,
    args
  );

// ---------
// Authentication
// ---------
export const useAuthenticationToken = () =>
  useAPI<null, api.AuthenticationToken>(
    api.getAuthenticationTokenRequestURI,
    null
  );

// ---------
// Healthcheck
// ---------
export const useHealthcheck = () =>
  useAPI<null, api.Healthcheck>(api.getHealthcheckRequestURI, null);

// ---------
// Collections
// ---------
export const useListVehicleCollections = (args: api.ListCollectionsRequest) =>
  usePaginatedAPI<typeof args, api.Collection[]>(
    api.listVehicleCollectionsRequestURI,
    args
  );

export const useListVehicleCollectionsCount = (args: api.CountRequest) =>
  usePaginatedAPI<typeof args, api.CountResponse>(
    api.listVehicleCollectionsCountRequestURI,
    args
  );

export const useListLaborCodes = (args: api.ListLaborCodesRequest) =>
  usePaginatedAPI<typeof args, api.LaborCode[]>(api.listLaborCodesURI, args);

export const useListParts = (args: api.ListPartsRequest) =>
  usePaginatedAPI<typeof args, api.Part[]>(api.listPartsURI, args);

export const useVehicleECUsAttributes = (args: api.ListAttributesRequest) =>
  useAPI<typeof args, api.EntityAttribute[]>(
    api.listVehicleECUsAttributesRequestURI,
    args,
    DEFAULT_ATTRIBUTES_SWR_CONFIG
  );

export const useListECUs = (args: api.APIPaginatedRequest) =>
  useAPI<typeof args, api.ECU[]>(api.listECUsRequestURI, args);

export const useListVehiclesECUs = (args: api.APIPaginatedRequest) =>
  usePaginatedAPI<typeof args, api.VehicleECU[]>(
    api.listVehiclesECUsRequestURI,
    args
  );

export const useEventRegistry = () =>
  useAPI<null, EventType[]>(
    api.listEventRegistryURI,
    null,
    DEFAULT_ATTRIBUTES_SWR_CONFIG
  );
