import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import HeadingRenderer from "pages/Docs/HeadingRenderer";

import "pages/Docs/Docs.css";

interface MarkdownSectionProps {
  content: string;
  wrapperClasses?: string;
}

const MarkdownSection = ({
  content,
  wrapperClasses = "",
}: MarkdownSectionProps) => {
  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      children={content}
      className={`Docs ${wrapperClasses}`}
      components={{
        h1: HeadingRenderer,
        h2: HeadingRenderer,
        h3: HeadingRenderer,
        h4: HeadingRenderer,
        h5: HeadingRenderer,
      }}
    />
  );
};

export default MarkdownSection;
