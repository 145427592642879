import { Dialog } from "@mui/material";

import { useFailureMode } from "shared/api/v0_failureModes/hooks";

import SimilarityModalContent from "./SimilarityModalContent";
import SimilarityModalTitle from "./SimilarityModalTitle";

interface Props {
  vin: string;
  failureModeID: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const SimilarityModal = ({ vin, failureModeID, isOpen, setIsOpen }: Props) => {
  const closeModal = () => setIsOpen(false);

  const { data } = useFailureMode({ id: failureModeID });
  const failureModeName = data?.name;

  return (
    <Dialog open={isOpen} onClose={closeModal} fullWidth maxWidth="lg">
      {failureModeName && (
        <>
          <SimilarityModalTitle
            vin={vin}
            failureModeName={failureModeName}
            closeModal={closeModal}
          />
          <SimilarityModalContent
            currentVIN={vin}
            failureModeID={failureModeID}
            failureModeName={failureModeName}
          />
        </>
      )}
    </Dialog>
  );
};
export default SimilarityModal;
