import * as config from "config/config";

const ChatBot = () => {
  const { pages } = config.get();

  const url = pages.chatBot?.url;

  return (
    <iframe
      title="ChatBot"
      src={url}
      height="450"
      style={{ width: "100%", height: "100%", border: "none" }}
    ></iframe>
  );
};

export default ChatBot;
