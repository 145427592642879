import { RiskLabel } from "shared/api/failureModes/api";
import { useFailureModeRiskModelPredictionsCount } from "shared/api/failureModes/hooks";

import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";

interface UseRisksCountsProps {
  vehiclesFilter?: FilterGroupState;
  failureModeID: string;
}

export const useRiskCounts = ({
  vehiclesFilter,
  failureModeID,
}: UseRisksCountsProps) => {
  const { data: dataLow, isLoading: isLoadingLow } =
    useFailureModeRiskModelPredictionsCount({
      id: failureModeID,
      vehiclesFilter: getFiltersQuery(vehiclesFilter),
      filter: getFiltersQuery(undefined, [
        { name: "riskLabel", op: "in", value: [RiskLabel.LOW] },
      ]),
    });
  const { data: dataMedium, isLoading: isLoadingMedium } =
    useFailureModeRiskModelPredictionsCount({
      id: failureModeID,
      vehiclesFilter: getFiltersQuery(vehiclesFilter),
      filter: getFiltersQuery(undefined, [
        { name: "riskLabel", op: "in", value: [RiskLabel.MEDIUM] },
      ]),
    });
  const { data: dataHigh, isLoading: isLoadingHigh } =
    useFailureModeRiskModelPredictionsCount({
      id: failureModeID,
      vehiclesFilter: getFiltersQuery(vehiclesFilter),
      filter: getFiltersQuery(undefined, [
        { name: "riskLabel", op: "in", value: [RiskLabel.HIGH] },
      ]),
    });

  return {
    riskCounts: {
      high: dataHigh?.count,
      medium: dataMedium?.count,
      low: dataLow?.count,
    },
    isLoading: isLoadingLow || isLoadingMedium || isLoadingHigh,
  };
};
