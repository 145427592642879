import { useMemo } from "react";
import Skeleton from "react-loading-skeleton";

import { UseAPIState } from "shared/api/hooks";
import {
  IssueChart,
  IssueChartGroupBy,
  IssueChartType,
  IssueClaimGroupBy,
  IssueTypes,
  IssueVehiclePopulation,
} from "shared/types";

import IssueLineChart from "pages/Issues/IssueLineChart";
import {
  getAxisKeyLabelFromActions,
  getBaseAPIRoute,
  getIssueCombinedID,
  getKeysAndLabels,
  prepareLineDefinitions,
} from "pages/Issues/utils";

import APIError from "features/ui/APIError";
import {
  ChartAction,
  SelectedChartOptions,
} from "features/ui/charts/Actions/types";
import { getSelectedOptionId } from "features/ui/charts/utils";

import { DataLoadGroupFuncParams, getChartData } from "./utils";

interface Props {
  issue: IssueTypes;
  population: IssueVehiclePopulation;
  chartType: IssueChartType;
  chart: IssueChart;
  dataLoadHook: (props: DataLoadGroupFuncParams) => UseAPIState<any>;
  dataLoadHookParams?: Record<string, any>;
  selectedOptions: SelectedChartOptions[];
  actions: ChartAction[];
  groupByField: IssueChartGroupBy;
  xAxisLabel?: string;
  syncId?: number | string;
  exposure?: string;
}

const GroupByChart = ({
  issue,
  population,
  chartType,
  chart,
  dataLoadHook,
  dataLoadHookParams,
  selectedOptions,
  actions,
  groupByField,
  xAxisLabel,
  syncId,
  exposure,
}: Props) => {
  const selectedGroupByOptionId = getSelectedOptionId(
    selectedOptions,
    "legend"
  );

  const { updatedAt } = issue;

  const { data, isLoading, error } = dataLoadHook({
    IDs: getIssueCombinedID(issue),
    baseRoute: getBaseAPIRoute(issue),
    groupBy: selectedGroupByOptionId as IssueClaimGroupBy,
    vehiclePopulation: population,
    ...dataLoadHookParams,
    updatedAt,
  });

  const {
    axisKey: yAxisKey,
    axisValue: yAxisValue,
    valueFormatter,
    tooltipValueFormatter,
  } = getAxisKeyLabelFromActions(selectedOptions, actions, "y");

  const { xAxisKey } = getKeysAndLabels(chartType, chart);

  const chartData = useMemo(() => {
    if (!data || data.length === 0) {
      return [];
    }

    return getChartData(
      chart,
      data,
      xAxisKey,
      yAxisKey,
      [],
      undefined,
      valueFormatter,
      groupByField
    );
  }, [chart, data, groupByField, valueFormatter, xAxisKey, yAxisKey]);

  if (isLoading) {
    return <Skeleton height={300} />;
  }

  if (error) {
    return <APIError error={error} />;
  }

  if (!data || data.length === 0) {
    return <div className="text-gray-400 text-sm mt-5">No data.</div>;
  }

  const values = [
    ...new Set(data.map((x: Record<any, any>) => x[groupByField])),
  ] as string[];

  const lines = prepareLineDefinitions(values);

  const tooltipProps = tooltipValueFormatter
    ? { formatter: tooltipValueFormatter }
    : {};

  return (
    <IssueLineChart
      data={chartData}
      xAxisLabel={xAxisLabel}
      yAxisLabel={yAxisValue}
      yAxisLines={lines}
      chart={chart}
      type={chartType}
      syncId={syncId}
      tooltipProps={tooltipProps}
      exposure={exposure}
    />
  );
};

export default GroupByChart;
