import { FrequencyOptions } from "pages/AlertDefinitions/constants";
import { AlertDefinitionState } from "pages/AlertDefinitions/types";

import { DEFAULT_FILTER_BUILDER_STATE } from "features/ui/Filters/FilterBuilder/constants";
import { isFilterBuilderStateValid } from "features/ui/Filters/FilterBuilder/utils";
import { isValidEmail } from "features/ui/PermissionsDialog/utils";

export const validateForm = (
  alertDefinition: AlertDefinitionState
): { valid: boolean; message?: string } => {
  if (!alertDefinition.name) {
    return { valid: false, message: "Please enter name." };
  }

  if (
    !(alertDefinition.vehiclesFilter === DEFAULT_FILTER_BUILDER_STATE) &&
    !isFilterBuilderStateValid(alertDefinition.vehiclesFilter)
  ) {
    return {
      valid: false,
      message: "Please enter valid Vehicles filter.",
    };
  }

  if (
    !(alertDefinition.eventFilter === DEFAULT_FILTER_BUILDER_STATE) &&
    !isFilterBuilderStateValid(alertDefinition.eventFilter)
  ) {
    return {
      valid: false,
      message: "Please enter valid Service Recommendation filter.",
    };
  }

  if (
    !Object.values(FrequencyOptions).includes(
      alertDefinition.frequency as FrequencyOptions
    )
  ) {
    return { valid: false, message: "Please select valid frequency." };
  }

  if (alertDefinition.emails.some((email) => !isValidEmail(email))) {
    return { valid: false, message: "Please enter valid email!" };
  }

  if (
    alertDefinition.groups.length === 0 &&
    alertDefinition.emails.length === 0
  ) {
    return {
      valid: false,
      message: "Please select at least one group or email recipient.",
    };
  }

  return { valid: true, message: undefined };
};

export const getDefaultTimeScheduledOption = () =>
  FrequencyOptions.HOURLY.toString();
