import { VEHICLES_COLLECTION_FILTER } from "shared/filterDefinitions";
import { getTenantServiceRecordName } from "shared/utils";

import SR from "pages/VINView/ServiceRecords";

import PageHeaderWrapper from "features/ui/PageHeaderWrapper";
import Title from "features/ui/Title";

const GLOBAL_FILTERS = [VEHICLES_COLLECTION_FILTER];

const ServiceRecords = () => (
  <>
    <PageHeaderWrapper>
      <Title text={getTenantServiceRecordName()} />
    </PageHeaderWrapper>
    <SR
      pageKey="service_records_feed"
      staticFilters={[]}
      globalFilters={GLOBAL_FILTERS}
    />
  </>
);

export default ServiceRecords;
