import { IssueTypes } from "shared/types";

import Board from "features/ui/Board";

import OccurrencesByCalendarTimeChart from "./Charts/OccurrencesByCalendarTimeChart";
import OccurrencesByVehicleAgeChart from "./Charts/OccurrencesByVehicleAgeChart";
import TopXByCalendarTimeChart from "./Charts/TopXByCalendarTimeChart";
import TopXByVehicleAgeChart from "./Charts/TopXByVehicleAgeChart";

interface Props {
  issue: IssueTypes;
  withComparisonPopulation: boolean;
}

const IssueClaimsCharts = (props: Props) => {
  const boardElements: JSX.Element[][] = [
    [
      <OccurrencesByCalendarTimeChart
        {...props}
        key="OccurrencesByCalendarTimeChart"
      />,
      <TopXByCalendarTimeChart {...props} key="TopXByCalendarTimeChart" />,
    ],
    [
      <OccurrencesByVehicleAgeChart {...props} key="OccurrencesByMISChart" />,
      <TopXByVehicleAgeChart {...props} key="TopXByMISChart" />,
    ],
  ];

  return (
    <div className="mb-3">
      <Board elements={boardElements} />
    </div>
  );
};

export default IssueClaimsCharts;
