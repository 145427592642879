import { useState } from "react";

import { useVehiclesFiltersSchema } from "shared/hooks";

import DropdownSelect from "features/ui/DropdownSelect";
import Filters from "features/ui/Filters";
import { removeAttributesFromFilterGroupState } from "features/ui/Filters/FilterBuilder/utils";
import FiltersSummary, {
  ViewFiltersButton,
} from "features/ui/Filters/FiltersSummary";
import FilterSelector from "features/ui/Filters/FilterWizard/FilterSelector";
import { getPendingFiltersKey } from "features/ui/Filters/FilterWizard/utils";
import { UseFilterSortState } from "features/ui/Filters/types";
import { getFilterLabel } from "features/ui/Filters/utils";

import { GLOBAL_FILTERS, VEHICLES_PAGE_KEY } from "./constants";

interface Props {
  vehiclesFilterSortState: UseFilterSortState;
  hideCollectionFilter?: boolean;
}

export const VEHICLE_PENDING_FILTERS_LS_KEY =
  getPendingFiltersKey(VEHICLES_PAGE_KEY);

const LABEL = "Vehicle Filters";
const ATTRIBUTES_TO_IGNORE = ["collection"];

const VehicleFilters = ({
  vehiclesFilterSortState,
  hideCollectionFilter,
}: Props) => {
  const [filterSummaryOpen, setFilterSummaryOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const vehicleFiltersSchema = useVehiclesFiltersSchema();

  const filteredFilters = removeAttributesFromFilterGroupState(
    vehiclesFilterSortState.filters,
    ATTRIBUTES_TO_IGNORE
  );

  const label = getFilterLabel(LABEL, filteredFilters);

  const isAdvancedEditor = vehiclesFilterSortState.isAdvancedFilterEditor;

  return (
    <div className="mb-3">
      <div className="flex items-center space-x-3">
        {!hideCollectionFilter && (
          <Filters
            initialized={vehiclesFilterSortState.initialized}
            schema={GLOBAL_FILTERS}
            onFilterChange={vehiclesFilterSortState.manageFilterChange}
            filters={vehiclesFilterSortState.filters}
            horizontal
          />
        )}
        <DropdownSelect
          label={label}
          testId="vehicles-filters-dropdown"
          open={dropdownOpen}
          onOpen={(open) => {
            setDropdownOpen(open);
          }}
          hasAdvancedFilters={isAdvancedEditor}
          content={
            <FilterSelector
              schema={vehicleFiltersSchema}
              filterSortState={{
                ...vehiclesFilterSortState,
                filters: filteredFilters,
              }}
              title="Vehicle Filters"
              testId="vehicles-filters"
              pendingFiltersKey={VEHICLE_PENDING_FILTERS_LS_KEY}
              onCloseFilters={() => {
                setDropdownOpen(false);
              }}
              initialIsAdvancedFilter={isAdvancedEditor}
            />
          }
        />
        <ViewFiltersButton
          open={filterSummaryOpen}
          onClick={() => setFilterSummaryOpen(true)}
          onClose={() => setFilterSummaryOpen(false)}
        />
      </div>
      <FiltersSummary
        open={filterSummaryOpen}
        filterStates={[
          {
            name: LABEL,
            filters: filteredFilters,
            schema: vehicleFiltersSchema,
          },
        ]}
      />
    </div>
  );
};

export default VehicleFilters;
