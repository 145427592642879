import {
  ChartAction,
  SelectedChartOptions,
} from "features/ui/charts/Actions/types";
import { getSelectedChartActionOptionName } from "features/ui/charts/utils";
import DropdownSelect from "features/ui/DropdownSelect";

interface Props {
  action: ChartAction;
  selectedOptions: SelectedChartOptions[];
  onOptionChange: (selectedOptions: SelectedChartOptions[]) => void;
}

const DropdownAction = ({ action, selectedOptions, onOptionChange }: Props) => {
  const updateSelectedDropdownOptions = (
    id: string,
    selectedOptionId?: string
  ) => {
    const newSelectedActions = selectedOptions.map((obj) =>
      obj.id === id
        ? {
            id,
            optionId: selectedOptionId as string,
          }
        : obj
    );
    onOptionChange(newSelectedActions);
  };

  const selectedActionName = getSelectedChartActionOptionName(
    selectedOptions,
    action
  );

  const { id, options } = action;

  return (
    <DropdownSelect
      label={selectedActionName ? selectedActionName.toString() : undefined}
      options={options}
      onSelect={(selectedOption) =>
        updateSelectedDropdownOptions(id, selectedOption.id as string)
      }
      testId={`chart-action-dropdown-${id}`}
    />
  );
};

export default DropdownAction;
