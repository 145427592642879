import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { mutate } from "swr";

import { Group, updateGroup, UpdateGroupRequest } from "shared/api/rbac/api";
import { areArraysEqual } from "shared/utils";

import { initGroupMemberEntries } from "pages/Groups/utils";
import CardHeader from "pages/Issues/Details/CardHeader";

import Button from "features/ui/Button";
import Card from "features/ui/Card";

import MembersTable from "./Members/MembersTable";
import { createGroupMembersDiff, isEmptyDiff } from "./Members/utils";

type Props = {
  group: Group;
  groupRequestKey: string;
};

// export needed for tests
export const TITLE = "Members";
export const DESCRIPTION =
  "Members of this group will receive access to and notifications for anything this group is given permissions for.";
export const ADMIN_DESCRIPTION = "Admin group must have at least 1 member.";

const MEMBERS_UPDATED_SUCCESS_TEXT = "Group members updated successfully";
const UPDATING_MEMBERS_FAILED_TEXT = "Updating group members failed";

const GroupMembers = ({ group, groupRequestKey }: Props) => {
  const { ID, name, description, users, canEdit, admin } = group;
  const [memberEmails, setMemberEmails] = useState(users.map((u) => u.email));
  const [groupMembers, setGroupMembers] = useState(
    initGroupMemberEntries(users, canEdit)
  );
  const [savingData, setSavingData] = useState(false);

  const newMemberEntryEmail = groupMembers.find((x) => !x.ID)?.email;
  const membersDiff = createGroupMembersDiff(users, groupMembers);

  useEffect(() => {
    const userEmails = users.map((x) => x.email);
    if (areArraysEqual(memberEmails, userEmails)) {
      return;
    }

    setMemberEmails(userEmails);
    setGroupMembers(initGroupMemberEntries(users, canEdit));
    // no need to re-render when 'memberEmails' change since they are managed only inside this useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users, canEdit]);

  const onApplyChanges = () => {
    if (isEmptyDiff(membersDiff)) {
      return;
    }

    const requestData: UpdateGroupRequest = {
      ID,
      name,
      description,
      users: membersDiff,
    };

    setSavingData(true);
    updateGroup(requestData)
      .then(({ data }) => {
        toast.success(MEMBERS_UPDATED_SUCCESS_TEXT);
        groupRequestKey && mutate(groupRequestKey);
      })
      .catch((err: Error) => {
        toast.success(UPDATING_MEMBERS_FAILED_TEXT);
        console.log(err);
      })
      .finally(() => {
        setSavingData(false);
      });
  };

  const onResetChanges = () => {
    setGroupMembers(initGroupMemberEntries(group.users, canEdit));
  };

  const resetButtonDisabled =
    savingData || (isEmptyDiff(membersDiff) && !Boolean(newMemberEntryEmail));
  const applyButtonDisabled = savingData || isEmptyDiff(membersDiff);

  return (
    <Card classNames="!p-5" testId="group-members-card">
      <CardHeader title={TITLE} />
      <div className="space-y-3">
        <p>{DESCRIPTION}</p>
        {admin && (
          <p>
            <i>{ADMIN_DESCRIPTION}</i>
          </p>
        )}
        <MembersTable
          group={group}
          groupMembers={groupMembers}
          onUpdate={setGroupMembers}
        />
        {canEdit && (
          <div className="flex justify-end">
            <Button
              label="Reset"
              color="secondary"
              onClick={onResetChanges}
              disabled={resetButtonDisabled}
            />
            <Button
              label="Apply"
              className="ml-2"
              color="primary"
              onClick={onApplyChanges}
              disabled={applyButtonDisabled}
              testId="apply-group-member-changes-cta"
            />
          </div>
        )}
      </div>
    </Card>
  );
};

export default GroupMembers;
