import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";

import {
  Bookmark as BookmarkModel,
  deleteBookmark,
  updateBookmark,
  UpdateBookmarkRequest,
} from "shared/api/bookmarks/api";
import { useListBookmarks } from "shared/api/bookmarks/hooks";

import { LANDING_PAGE_BOOKMARKS_TITLE } from "pages/LandingPage/constants";

import APIError from "features/ui/APIError";
import Card from "features/ui/Card";

import Bookmark from "./Bookmark";

const ROWS_PER_PAGE = 999;
const DEFAULT_SORT = "createdAt:desc";
const SUCCESS_DELETE_TEXT = "Bookmark deleted successfully";
const SUCCESS_UPDATE_TEXT = "Bookmark updated successfully";

const Bookmarks = () => {
  const [refreshKey, setRefreshKey] = useState(false);
  const { data, isLoading, error } = useListBookmarks({
    sort: DEFAULT_SORT,
    limit: ROWS_PER_PAGE,
    refreshKey: refreshKey,
  });

  const onDelete = async (bookmark: BookmarkModel) => {
    await deleteBookmark({ id: bookmark.ID });
    toast.success(SUCCESS_DELETE_TEXT);
    setRefreshKey(!refreshKey);
  };

  const onUpdate = async (bookmark: UpdateBookmarkRequest) => {
    await updateBookmark(bookmark);
    toast.success(SUCCESS_UPDATE_TEXT);
    setRefreshKey(!refreshKey);
  };

  if (isLoading) return <Skeleton height={200} />;

  if (error) return <APIError error={error} />;

  if (data)
    return (
      <Card>
        <div className="flex flex-col justify-between w-full max-h-30">
          <h3 className="flex space-x-3 items-center font-semibold mb-3 capitalize">
            {LANDING_PAGE_BOOKMARKS_TITLE}
          </h3>
          <div
            className={
              "bg-gray-100 flex flex-row gap-2 p-3 overflow-x-auto z-10"
            }
          >
            {data.length === 0 && (
              <span className={"text-sm"}>No quick starts available.</span>
            )}
            {data.map((bookmark) => (
              <Bookmark
                key={bookmark.ID}
                bookmark={bookmark}
                onDelete={onDelete}
                onUpdate={onUpdate}
              />
            ))}
          </div>
        </div>
      </Card>
    );
};
export default Bookmarks;
