import React, { createContext, useContext } from "react";

import { EntityAttribute } from "shared/api/api";
import { UseAPIState } from "shared/api/hooks";

import { ContextName, ContextWrapComponentProps } from "./ContextWrappers";

export type UseAttributesHook = (args?: any) => UseAPIState<EntityAttribute[]>;

interface ContextRegistry {
  [key: string]: React.Context<EntityAttribute[] | undefined>;
}

export const contextRegistry: ContextRegistry = {};

export const createGenericContextWrapper = (
  useAttributesHook: UseAttributesHook,
  contextName: ContextName
) => {
  const Context = createContext<EntityAttribute[] | undefined>(undefined);
  contextRegistry[contextName] = Context;

  const GenericContextWrapper: React.FC<ContextWrapComponentProps> = ({
    children,
  }) => {
    const { data } = useAttributesHook();
    return <Context.Provider value={data}>{children}</Context.Provider>;
  };

  return GenericContextWrapper;
};

export const useAttributesContext = (
  contextName: ContextName
): EntityAttribute[] | undefined => {
  const Context = contextRegistry[contextName];
  if (!Context) {
    throw new Error(`Context for ${contextName} not found`);
  }
  return useContext(Context);
};
