import React from "react";
import { usePopperTooltip } from "react-popper-tooltip";

import "react-popper-tooltip/dist/styles.css";

import classNames from "classnames";
import {
  AiFillInfoCircle as FillInfoIcon,
  AiOutlineInfoCircle as OutlineInfoIcon,
} from "react-icons/ai";

import styles from "./Info.module.css";

interface BaseProps {
  /**
   * Text to show on hover
   */
  text: string | JSX.Element;
  /**
   * Outline
   */
  outlined?: boolean;
  /**
   * Icon size
   */
  size?: "xs" | "sm" | "md" | "lg";
}

const iconSizes = {
  xs: 14,
  sm: 18,
  md: 24,
  lg: 30,
};

const DEFAULT_ICON_COLOR = "#ccc"; // light gray
const DEFAULT_HIDE_DELAY_MS = 350;
const INTERACTIVE = true;
const DEFAULT_SIZE = "sm";
const DEFAULT_OUTLINED = true;

export type InfoIconProps = BaseProps;

/**
 * Primary UI component for user interaction (hover only)
 */
const InfoIcon = ({
  text,
  outlined = DEFAULT_OUTLINED,
  size = DEFAULT_SIZE,
}: InfoIconProps) => {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({
    interactive: INTERACTIVE,
    delayHide: DEFAULT_HIDE_DELAY_MS,
  });

  const classNameIcon = classNames(styles.icon, `text-${size}`);

  const classNameHasTooltip = classNames(
    "inline-block",
    "align-middle",
    styles["has-tooltip"]
  );

  const IconComponent = outlined ? OutlineInfoIcon : FillInfoIcon;

  return (
    <div className={classNameHasTooltip} data-testid="icon-element">
      <div ref={setTriggerRef} role="img">
        <IconComponent
          className={classNameIcon}
          color={DEFAULT_ICON_COLOR}
          size={iconSizes[size]}
        />
      </div>
      {visible && text && (
        <div
          ref={setTooltipRef}
          role="tooltip"
          {...getTooltipProps({
            className: "tooltip-container max-w-md flex",
          })}
          // without this, click is registered to a parent component (usually select dropdown)
          // and gives impression tooltip has click-through functionality
          onClick={(e) => e.preventDefault()}
        >
          <div {...getArrowProps({ className: "tooltip-arrow" })} />
          <div className="text-sm">{text}</div>
        </div>
      )}
    </div>
  );
};

export default InfoIcon;
