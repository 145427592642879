import { SignalEventsOccurrence } from "shared/api/signalEvents/api";
import useSignalEventOccurrencesSchema from "shared/schemas/signalEventOccurrencesSchema";

import CardHeader from "pages/Issues/Details/CardHeader";
import { VINEventTimelineDateLink } from "pages/VINView/ServiceRecords/VINEventTimelineDateLink";

import Card from "features/ui/Card";
import LabelValuePairs from "features/ui/LabelValuePairs";
import { SchemaEntry } from "features/ui/Table";

interface Props {
  data: SignalEventsOccurrence;
}

const CARD_TITLE = "Signal Event Occurrence Details";

const DISPLAY_FIELDS: string[] = [
  "VIN",
  "status",
  "symptom",
  "signalEventType",
  "description",
  "recordedAt",
  "occurrenceCount",
  "SPN",
  "FMI",
  "obd2Code",
  "extCode",
  "currentFault",
  "ecuFamily",
  "ecuVersion",
  "ecuSoftwareVersion",
  "ecuPartNumber",
  "ecuEditionNumber",
  "ecuSupplier",
  "milLampStatus",
  "redLampStatus",
  "amberLampStatus",
  "protectLampStatus",
  "trigger",
  "totalEngineHours",
  "odometer",
  "daysInService",
  "monthsInService",
  "newOccurrence",
  "highPriority",
];

const CardSignalEventOccurrenceDetails = ({ data }: Props) => {
  const { schema } = useSignalEventOccurrencesSchema();

  const formatRow = (row: SignalEventsOccurrence) => {
    const { VIN, recordedAt } = row;

    return {
      ...row,
      VIN: <VINEventTimelineDateLink VIN={VIN} date={recordedAt} />,
    };
  };

  const formattedData = formatRow(data);

  return (
    <Card>
      <CardHeader title={CARD_TITLE} />
      <LabelValuePairs
        schema={schema as SchemaEntry<keyof SignalEventsOccurrence>[]}
        data={formattedData}
        fields={DISPLAY_FIELDS}
      />
    </Card>
  );
};

export default CardSignalEventOccurrenceDetails;
