import DuckSummary from "duck/ui/DuckSummary";

const DuckError = () => (
  <div className="bg-slate-300 pl-6 py-3 rounded-tr-xl">
    <DuckSummary />
    <p className="pl-6 italic">Unavailable</p>
  </div>
);

export default DuckError;
