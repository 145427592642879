import FilterQueryPresentation from "features/ui/Filters/FilterBuilder/FilterQueryPresentation";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { hasSomeFiltersAppliedFilterGroupState } from "features/ui/Filters/utils";

interface Props {
  filters: FilterGroupState;
}

const FiltersPreview = ({ filters }: Props) => {
  if (!hasSomeFiltersAppliedFilterGroupState(filters)) return null;

  return (
    <div className="mt-4 text-sm text-gray-500">
      <h4 className="mb-1">Filters selected:</h4>
      <FilterQueryPresentation filterState={filters} />
    </div>
  );
};

export default FiltersPreview;
