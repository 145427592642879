import classNames from "classnames";
import { Checkbox as C, CheckboxProps as CProps } from "@mui/material";

import { TestProps } from "shared/types";

export interface CheckboxProps extends TestProps {
  label?: string;
  checked?: CProps["checked"];
  onChange: (checked: boolean) => void;
  disabled?: CProps["disabled"];
  indeterminate?: CProps["indeterminate"];
  dense?: boolean;
}

const Checkbox = ({
  label,
  checked,
  onChange,
  disabled,
  indeterminate,
  testId,
  dense = false,
}: CheckboxProps) => {
  return (
    <label className="flex items-center space-x-2">
      <C
        className={classNames(
          { "!p-0": dense },
          {
            "cursor-not-allowed opacity-50": disabled,
          }
        )}
        checked={checked}
        size="small"
        disabled={disabled}
        indeterminate={indeterminate}
        onChange={(e) => onChange(e.target.checked)}
        data-testid={testId}
      />
      <span>{label}</span>
    </label>
  );
};

export default Checkbox;
