import Input from "features/ui/Input";
import { InputProps } from "features/ui/Input/Input";

interface Props extends InputProps {
  fieldName: string;
  label?: string;
  value?: string | number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputFilter = ({
  fieldName,
  label,
  value,
  type = "text",
  onChange,
  ...otherProps
}: Props) => (
  <Input
    type={type}
    label={label}
    value={value}
    testId={`input-filter-${fieldName}`}
    onChange={onChange}
    placeholder={`Enter ${type}`}
    margin="dense"
    {...otherProps}
  />
);

export default InputFilter;
