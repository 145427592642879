import { Chip, ChipProps } from "@mui/material";

import { TestProps } from "shared/types";
import { toSnakeCase, toTitleCase } from "shared/utils";

import ChipList from "features/ui/ChipList";
import { OPERATOR_TO_SIGN } from "features/ui/Filters/constants";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import {
  getFilterGroupStateTopLevelRowAttributes,
  getTopLevelRowFromFilterGroupState,
} from "features/ui/Filters/FilterBuilder/utils";
import FilterDescriptionTable, {
  SUPPORTED_DESCRIPTION_TABLE_FIELD_NAMES,
} from "features/ui/Filters/other/FilterDescriptionTable";
import FilterLabel from "features/ui/Filters/other/FilterLabel";
import {
  FilterOperator,
  FilterOverviewFormat,
} from "features/ui/Filters/types";
import {
  getFilterChipColorClass,
  getFilterKeyMapValues,
} from "features/ui/Filters/utils";
import { SchemaEntry } from "features/ui/Table";

const FIELD_NAMES_WITH_TRANSFORMATION_REQUIRED_STARTS_WITH = [
  "predictions.",
  "collection",
];
const MAX_VISIBLE_FILTERS = 2000;

/**
 * Renders a list of filters in format specified.
 *
 * @param filters - Filters to render
 * @param onFiltersReset - Callback to reset filters
 * @param tableSchema - Table schema to get field names from
 * @param removable - If filters are removable
 * @param hideClearAll - If clear all button should be hidden
 * @param filtersToHide - A List of accessors to hide
 * @param showValuesIfMultiple - If multiple values should be shown. If false, we show 1 value & when multiple we provide a modal
 * @param badgeColor - Color of the badge
 * @param badgeSize - Size of the badge
 * @param className - Class name for the container
 * @param format - Format of the filter, "label" | "badge" | "label-inline" | "label-block"
 * @param noModalForMultipleValues - Do not show a modal even if multiple values are present
 * @param tableForCodes - Attribute names (accessor or fieldName key) for which we should show a field/ID description table (note that "description" field is expected to exist on the resource)
 * @param clickable - If the chips are clickable
 * @param searchString - Search string for description table (see tableForCodes above)
 * @param keysUpdated - List of keys (accessors) that are in a pending-update state
 * @param keysRemoved - List of keys (accessors) that are in a pending-removal state
 * @param baseEntityText - Text to use for the base entity (ie. "Failure Mode" for "failureModeID")
 */
interface Props {
  filters: FilterGroupState;
  onFiltersReset?: (fieldNames?: string[]) => void;
  tableSchema?: SchemaEntry[];
  removable?: boolean;
  hideClearAll?: boolean;
  filtersToHide?: string[];
  showValuesIfMultiple?: boolean;
  badgeColor?: ChipProps["color"];
  badgeSize?: ChipProps["size"];
  className?: string;
  format?: FilterOverviewFormat;
  noModalForMultipleValues?: boolean;
  tableForCodes?: string[];
  clickable?: boolean;
  searchString?: string;
  keysUpdated?: string[];
  keysRemoved?: string[];
  baseEntityText?: string;
  dense?: boolean;
}

const FiltersOverview = ({
  filters,
  onFiltersReset,
  tableSchema,
  removable = true,
  hideClearAll = false,
  filtersToHide = [],
  showValuesIfMultiple = false,
  badgeColor = "default",
  badgeSize = "small",
  className = "inline-flex gap-x-3 gap-y-1 flex-wrap items-baseline w-full",
  format = "badge",
  noModalForMultipleValues = false,
  tableForCodes = [],
  clickable = false,
  searchString = "",
  testId = "filters-overview",
  keysUpdated,
  keysRemoved,
  baseEntityText,
  dense = false,
}: Props & TestProps) => {
  // Where FiltersOverview is used, we should never really support anything other than top-level attributes.
  // If we need to support nested attributes, we should use FilterQueryPresentation component instead.
  const filterAttributes = getFilterGroupStateTopLevelRowAttributes(filters);

  const filtersCount = filterAttributes.filter(
    (attr) => !filtersToHide.includes(attr)
  ).length;

  const filtersKeyMap: Record<string, string> = getFilterKeyMapValues(
    tableSchema || []
  );

  if (filtersCount === 0) return null;

  const showClearAll = filtersCount > 1 && removable && !hideClearAll;

  const filterFields = filterAttributes.filter(
    (f) => !filtersToHide.includes(f)
  );

  // TODO https://viaduct-ai.atlassian.net/browse/ENG-5405: Filtering components are currently a mess. Rewrite them to separate value formatting logic (dates, ...)
  // from presenting logic (chip, inline, ...)
  const allFilterEntries = filterFields.sort().map((fieldName) => {
    // Hack to avoid showing ie. "predictions.<hash>.days.30.riskStatus" until the name changes to actual FM name ..
    // This is now needed because our keyNameMap comes in later due to failure modes being feature flagged.
    // - we don't wanna show "Predictions 23 1a he f2 ..." in the filter label for a split second there!
    if (
      FIELD_NAMES_WITH_TRANSFORMATION_REQUIRED_STARTS_WITH.filter((startText) =>
        fieldName.startsWith(startText)
      ).length > 0 &&
      !filtersKeyMap[fieldName]
    ) {
      return false;
    }

    const chipColorClass = getFilterChipColorClass(
      fieldName,
      keysUpdated,
      keysRemoved
    );

    const label = (
      <FilterLabel
        key={fieldName}
        filters={filters}
        fieldName={fieldName}
        keyNameMap={filtersKeyMap}
        showValuesIfMultiple={showValuesIfMultiple}
        format={format}
        noModalForMultipleValues={noModalForMultipleValues}
        baseEntityText={baseEntityText}
        tableSchema={tableSchema}
        dense={dense}
      />
    );

    const childRow = getTopLevelRowFromFilterGroupState(fieldName, filters);

    if (
      tableForCodes.includes(fieldName) &&
      SUPPORTED_DESCRIPTION_TABLE_FIELD_NAMES.includes(fieldName) &&
      (childRow?.operator === FilterOperator.IN ||
        childRow?.operator === FilterOperator.NOT_IN)
    ) {
      return (
        <FilterDescriptionTable
          title={`${
            filtersKeyMap[fieldName] || toTitleCase(toSnakeCase(fieldName))
          } ${OPERATOR_TO_SIGN[childRow.operator]}`}
          fieldName={fieldName}
          values={childRow.values}
          searchString={searchString}
          key={fieldName}
        />
      );
    }

    if (format === "badge") {
      return (
        <Chip
          key={fieldName}
          variant="filled"
          label={label}
          onDelete={
            removable
              ? () => onFiltersReset && onFiltersReset([fieldName])
              : undefined
          }
          size={badgeSize}
          color={badgeColor}
          classes={{ root: chipColorClass }}
          data-testid={`${fieldName}-filter`}
          data-pending-removal={keysRemoved?.includes(fieldName) || undefined}
          data-pending-update={keysUpdated?.includes(fieldName) || undefined}
          clickable={clickable}
        />
      );
    }

    return label;
  });

  if (allFilterEntries.filter(Boolean).length === 0) return null;

  return (
    <div className={className} data-testid={testId}>
      {format === "badge" && (
        <ChipList
          title=""
          maxVisible={MAX_VISIBLE_FILTERS}
          values={allFilterEntries.filter(Boolean) as JSX.Element[]}
          defaultWrapper={false}
        />
      )}
      {format !== "badge" && allFilterEntries}
      {showClearAll && (
        <div
          className="inline-block cursor-pointer text-blue-400 hover:text-blue-300 text-xs"
          onClick={() => onFiltersReset && onFiltersReset(filterFields)}
          data-testid="clear-all-filters"
        >
          Clear all
        </div>
      )}
    </div>
  );
};

export default FiltersOverview;
