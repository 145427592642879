import {
  CountResponse,
  EntityAttribute,
  ListAttributesRequest,
  VehicleAgeTimeline,
} from "shared/api/api";
import { DEFAULT_ATTRIBUTES_SWR_CONFIG } from "shared/api/constants";
import { useAPI, usePaginatedAPI } from "shared/api/hooks";
import { CountRequestWithVehiclesFilter } from "shared/api/vehicles/api";

import {
  Claim,
  ClaimAssociatedSignalEvent,
  ClaimAssociatedSignalEventsRequest,
  ClaimAssociatedVehicle,
  ClaimAssociatedVehiclesRequest,
  ClaimFiltersByVehicleAgeRequest,
  ClaimFiltersRequest,
  ClaimsMetrics,
  ClaimsMetricsHistory,
  ClaimsTopContributor,
  ClaimsTopContributorsRequest,
  countAssociatedVehiclesRequestURI,
  GetClaimRequest,
  getClaimRequestURI,
  getClaimsByVehicleAgeTimelineRequestURI,
  getClaimsMetricsHistoryRequestURI,
  getClaimsMetricsRequestURI,
  getClaimsTopContributorsRequestURI,
  listAssociatedSignalEventsRequestURI,
  listAssociatedVehiclesRequestURI,
  listClaimAttributesRequestURI,
  listClaimsCountRequestURI,
  ListClaimsRequest,
  listClaimsRequestURI,
} from "./api";

export const useListClaims = (args: ListClaimsRequest) =>
  usePaginatedAPI<typeof args, Claim[]>(listClaimsRequestURI, args);

export const useListClaimsCount = (args: CountRequestWithVehiclesFilter) =>
  usePaginatedAPI<typeof args, CountResponse>(listClaimsCountRequestURI, args);

export const useGetClaim = (args: GetClaimRequest) =>
  useAPI<typeof args, Claim>(getClaimRequestURI, args);

export const useClaimsMetrics = (args: ClaimFiltersRequest) =>
  useAPI<typeof args, ClaimsMetrics>(getClaimsMetricsRequestURI, args);

export const useClaimsMetricsHistory = (args: ClaimFiltersRequest) =>
  useAPI<typeof args, ClaimsMetricsHistory[]>(
    getClaimsMetricsHistoryRequestURI,
    args
  );

export const useClaimAttributes = (args: ListAttributesRequest) =>
  useAPI<typeof args, EntityAttribute[]>(
    listClaimAttributesRequestURI,
    args,
    DEFAULT_ATTRIBUTES_SWR_CONFIG
  );

export const useListClaimsTopContributors = (
  args: ClaimsTopContributorsRequest
) =>
  usePaginatedAPI<typeof args, ClaimsTopContributor[]>(
    getClaimsTopContributorsRequestURI,
    args
  );

export const useListClaimsByVehicleAgeTimeline = (
  args: ClaimFiltersByVehicleAgeRequest
) =>
  useAPI<typeof args, VehicleAgeTimeline[]>(
    getClaimsByVehicleAgeTimelineRequestURI,
    args
  );

export const useListClaimsAssociatedSignalEvents = (
  args: ClaimAssociatedSignalEventsRequest
) =>
  usePaginatedAPI<typeof args, ClaimAssociatedSignalEvent[]>(
    listAssociatedSignalEventsRequestURI,
    args
  );

export const useListClaimsAssociatedVehicles = (
  args: ClaimAssociatedVehiclesRequest
) =>
  usePaginatedAPI<typeof args, ClaimAssociatedVehicle[]>(
    listAssociatedVehiclesRequestURI,
    args
  );

export const useCountClaimsAssociatedVehicles = (
  args: ClaimAssociatedVehiclesRequest
) =>
  useAPI<typeof args, CountResponse>(countAssociatedVehiclesRequestURI, args);
