import { toTitleCase } from "shared/utils";

interface StatusBadgeProps {
  status: string;
}

// using full classes because of https://tailwindcss.com/docs/optimizing-for-production#writing-purgeable-html
const colorMap: Record<string, string> = {
  active: "text-green-500",
  archived: "text-red-500",
  under_review: "",
};

const StatusBadge = ({ status }: StatusBadgeProps) => (
  <span className={colorMap[status]}>{toTitleCase(status)}</span>
);

export default StatusBadge;
