import DropdownWithSearch from "features/ui/DropdownWithSearch/DropdownWithSearch";
import { SelectOption } from "features/ui/Select";

interface GroupBySelectorProps {
  groupBySelectOptions: SelectOption[];
  selectedGroupByAttribute: SelectOption;
  setSelectedGroupByAttribute: (option: SelectOption) => void;
}

export const TopContributorsGroupBySelector = ({
  groupBySelectOptions,
  selectedGroupByAttribute,
  setSelectedGroupByAttribute,
}: GroupBySelectorProps) => (
  <div className="mt-4">
    {groupBySelectOptions.length > 0 && (
      <div className="mb-5">
        <div className="text-sm mb-1">
          Select a dimension to update the x-axis of the chart and first column
          of the table:
        </div>
        {selectedGroupByAttribute && (
          <DropdownWithSearch
            options={groupBySelectOptions}
            selectedOption={selectedGroupByAttribute}
            label="Group by attribute"
            onSelectedOptionChange={setSelectedGroupByAttribute}
            className="mt-4"
            testId="top-contributors-group-by-dropdown"
          />
        )}
      </div>
    )}
  </div>
);

export default TopContributorsGroupBySelector;
