import loadPrompt from "duck/graph/nodes/loadPrompt";
import { promptNames } from "duck/graph/nodes/types";
import {
  createStrictToolCallingAgent,
  createToolMessageFromAIMessageToolCall,
  getEphemeralMessageForNode,
  NodeOutputType,
  NodeType,
} from "duck/graph/nodes/utils";
import { GraphStateType } from "duck/graph/state";
import getRespondToUserTool from "duck/graph/tools/getRespondToUserTool";
import { DuckGraphParams } from "duck/graph/types";
import { formatDocs, getLLM, NodeNames } from "duck/graph/utils";
import { captureScreenshot } from "duck/ui/utils";
import { HumanMessage } from "@langchain/core/messages";
import { RunnableConfig } from "@langchain/core/runnables";

/**
 * This custom node captures a screenshot and then invokes the respondToUser tool.
 */
const getNode = async (params: DuckGraphParams): Promise<NodeType> => {
  const prompt = await loadPrompt(promptNames.CAPTURE_SCREENSHOT_AGENT);
  const llm = getLLM();
  const respondToUserTool = getRespondToUserTool(
    params.uiHandlers.setAgentResponse,
    params.uiHandlers.setEphemeralMessage
  );
  const agent = createStrictToolCallingAgent(llm, [respondToUserTool], prompt);

  return async (
    state: GraphStateType,
    config: RunnableConfig = {}
  ): Promise<NodeOutputType> => {
    params.uiHandlers.setEphemeralMessage(
      getEphemeralMessageForNode(NodeNames.CAPTURE_SCREENSHOT)
    );

    const { messages, pageState, documents } = state;

    const toolMessage = createToolMessageFromAIMessageToolCall(messages);

    const screenshot = await captureScreenshot();
    // Images can't be included with AIMessages. They can with HumanMessages.
    const screenshotMessage = new HumanMessage({
      content: [
        {
          type: "text",
          text: "This is the screenshot of the current page. Refer to this for answering questions regarding charts or data and providing insights.",
        },
        {
          type: "image_url",
          image_url: {
            url: screenshot,
          },
        },
      ],
      name: "SYSTEM",
    });

    const agentMessage = await agent.invoke(
      {
        messages: [...messages, ...toolMessage, screenshotMessage],
        current_state: JSON.stringify(pageState),
        context: formatDocs(documents),
      },
      config
    );
    agentMessage.name = NodeNames.CAPTURE_SCREENSHOT;

    return {
      messages: [...toolMessage, screenshotMessage, agentMessage],
    };
  };
};

export default getNode;
