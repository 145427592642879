import { useState } from "react";

import { toSnakeCase } from "shared/utils";

import DatePicker from "features/ui/DatePicker";

export interface DatePickerRangeProps {
  startDateLabel?: string;
  endDateLabel?: string;
  startPlaceholder?: string;
  endPlaceholder?: string;
  initialDateStart?: Date | null;
  initialDateEnd?: Date | null;
  defaultIsOpen?: boolean;
  onChange?: (dateObjectStart: Date | null, dateObjectTo: Date | null) => void;
  disabled?: boolean;
  showTimeSelect?: boolean;
}

const DatePickerRange = ({
  startDateLabel,
  endDateLabel,
  startPlaceholder = "Start date",
  endPlaceholder = "End date",
  initialDateStart = null,
  initialDateEnd = null,
  defaultIsOpen = false,
  showTimeSelect = false,
  onChange,
  disabled,
}: DatePickerRangeProps) => {
  const [startDate, setStartDate] = useState<Date | null>(initialDateStart);
  const [endDate, setEndDate] = useState<Date | null>(initialDateEnd);

  const onChangeStart = (date: Date) => {
    setStartDate(date);
    if (onChange) {
      onChange(date, endDate);
    }
  };

  const onChangeEnd = (date: Date) => {
    setEndDate(date);
    if (onChange) {
      onChange(startDate, date);
    }
  };

  return (
    <div className="flex flex-nowrap space-x-2">
      <div>
        <DatePicker
          initialDate={startDate}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          label={startDateLabel}
          placeholderText={startPlaceholder}
          onChange={onChangeStart}
          defaultIsOpen={defaultIsOpen}
          id={startDateLabel && toSnakeCase(startDateLabel)}
          disabled={disabled}
          showTimeSelect={showTimeSelect}
        />
      </div>
      <div>
        <DatePicker
          initialDate={endDate}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          label={endDateLabel}
          placeholderText={endPlaceholder}
          onChange={onChangeEnd}
          id={endDateLabel && toSnakeCase(endDateLabel)}
          disabled={disabled}
          showTimeSelect={showTimeSelect}
        />
      </div>
    </div>
  );
};

export default DatePickerRange;
