import { OktaAuth } from "@okta/okta-auth-js";

import * as config from "config/config";

const oktaLogout = async (oktaAuth: OktaAuth) => {
  const {
    auth: { postLogoutRedirectUri },
  } = config.get();

  const signOutProps = postLogoutRedirectUri
    ? {
        postLogoutRedirectUri,
      }
    : undefined;

  try {
    await oktaAuth.signOut(signOutProps);
  } catch (err) {
    console.error(err);
  }
};

export default oktaLogout;
