import { APISuccessResponse, PermissionEntry } from "shared/api/api";
import {
  Issue,
  NewIssueRequest,
  UpdateIssueRequest,
} from "shared/api/issues/api";

import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";
import { getPendingFiltersKey } from "features/ui/Filters/FilterWizard/utils";
import { getPageKeyWithVersion } from "features/ui/Filters/utils";
import {
  calculatePermissionToSubmit,
  DEFAULT_GENERAL_PERMISSION,
} from "features/ui/PermissionsDialog/utils";
import { ActionType } from "features/ui/StepperModal";

import { PAGE_KEY as StepAtRiskPopulation_KEY } from "./StepAtRiskPopulation";
import { PAGE_KEY as StepClaims_KEY } from "./StepClaims";
import { PAGE_KEY as StepComparisonPopulation_KEY } from "./StepComparisonPopulation";
import { PAGE_KEY as StepSignalEvents_KEY } from "./StepSignalEvents";

interface IssueFormFilterStates {
  atRiskPopulationFilter: FilterGroupState | undefined;
  comparisonPopulationFilter: FilterGroupState | undefined;
  claimFilter: FilterGroupState | undefined;
  signalEventOccurrencesFilter: FilterGroupState | undefined;
}

export interface IssueFormData extends IssueFormFilterStates {
  ID: string;
  name: string;
  description: string;
  notes?: string;
  statusID?: string;
  severityID?: string;
  assignee: string;
  assignedGroupID?: string;
  externalID?: string;
  promotedFromID?: string;
  acl: PermissionEntry[];
}

export const DEFAULT_DATA: IssueFormData = {
  ID: "",
  atRiskPopulationFilter: undefined,
  comparisonPopulationFilter: undefined,
  claimFilter: undefined,
  signalEventOccurrencesFilter: undefined,
  name: "",
  description: "",
  statusID: undefined,
  severityID: undefined,
  assignee: "",
  acl: [DEFAULT_GENERAL_PERMISSION],
};

export type ActionCallback<T> = T extends "create"
  ? (d: NewIssueRequest) => Promise<APISuccessResponse<Issue>>
  : T extends "update"
    ? (d: UpdateIssueRequest) => Promise<APISuccessResponse<Issue>>
    : never;

type ActionProps<T> = T extends "create"
  ? NewIssueRequest
  : T extends "update"
    ? UpdateIssueRequest
    : never;

export const prepareRequestData = (
  data: Record<string, any>,
  action: ActionType,
  myEmail: string
): ActionProps<ActionType> => {
  const {
    atRiskPopulationFilter,
    comparisonPopulationFilter,
    claimFilter,
    signalEventOccurrencesFilter,
    ID,
    acl,
    assignee,
    ...otherData
  } = data;

  const requestData = {
    ...otherData,
    ID: ID,
    atRiskPopulationFilter: getFiltersQuery(atRiskPopulationFilter),
    comparisonPopulationFilter: getFiltersQuery(comparisonPopulationFilter),
    claimFilter: getFiltersQuery(claimFilter),
    signalEventOccurrencesFilter: getFiltersQuery(signalEventOccurrencesFilter),
    assignee,
    acl: calculatePermissionToSubmit(acl, [assignee, myEmail]),
  };

  return removeFalsyFromRecord(requestData, action) as ActionProps<
    typeof action
  >;
};

const UNSUPPORTED_PATCH_FIELDS = new Set<string>(["acl"]);

export const removeFalsyFromRecord = (
  obj: Record<string, any>,
  action: ActionType
) => {
  let newObj: Record<string, any> = {};
  Object.keys(obj)
    .filter((k) => action !== "update" || !UNSUPPORTED_PATCH_FIELDS.has(k))
    .forEach((key) => {
      if (obj[key]) {
        newObj[key] = obj[key];
      }
      if (!obj[key] && action === "update") {
        newObj[key] = null;
      }
    });
  return newObj;
};

const PENDING_FILTERS_LS_KEYS = [
  StepAtRiskPopulation_KEY,
  StepComparisonPopulation_KEY,
  StepClaims_KEY,
  StepSignalEvents_KEY,
].map((pageKey) => getPendingFiltersKey(pageKey));

export const clearPendingIssueFilters = () => {
  PENDING_FILTERS_LS_KEYS.forEach((pendingFilterLSKey) => {
    localStorage.removeItem(getPageKeyWithVersion(pendingFilterLSKey));
  });
};
