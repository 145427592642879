import { InspectionsFiltersProps } from "pages/InspectionAnalytics/InspectionAnalyticsTabs";
import InspectionsChart from "pages/InspectionAnalytics/tabPages/Inspections/InspectionsChart";
import InspectionsTable from "pages/InspectionAnalytics/tabPages/Inspections/InspectionsTable";
import MetricsCards from "pages/InspectionAnalytics/tabPages/Inspections/MetricsCards";

const Inspections = ({
  inspectionsFilters,
  vehiclesFilters,
  onBadRequest,
}: InspectionsFiltersProps) => (
  <div className="mt-5">
    <div className="flex flex-col lg:flex-row space-y-5 lg:space-y-0 lg:space-x-5">
      <div className="grow">
        <InspectionsChart
          inspectionsFilters={inspectionsFilters}
          vehiclesFilters={vehiclesFilters}
          onBadRequest={onBadRequest}
        />
      </div>
      <MetricsCards
        inspectionsFilters={inspectionsFilters}
        vehiclesFilters={vehiclesFilters}
        onBadRequest={onBadRequest}
      />
    </div>
    <InspectionsTable
      inspectionsFilters={inspectionsFilters}
      vehiclesFilters={vehiclesFilters}
      onBadRequest={onBadRequest}
    />
  </div>
);

export default Inspections;
