import React, { useState } from "react";

import { ReoccurrenceProps } from "shared/types";

import Button from "features/ui/Button";
import Modal from "features/ui/Modal";

import ReoccurrenceTimePeriodSelection from "./ReoccurrenceTimePeriodSelection";

interface Props extends ReoccurrenceProps {
  title: string;
  onConfirm: (
    occurredWithin: number,
    priorToClaim: number,
    followingClaim: number
  ) => void;
}

const ReoccurrenceTimePeriodModal = ({
  title,
  seOccurrenceWithin,
  seReoccurrenceStartingFrom,
  seReoccurrenceUpTo,
  onConfirm,
}: Props) => {
  const [seTimePeriodsModalOpened, setSeTimePeriodsModalOpened] =
    useState(false);

  const onCloseTimePeriods = () => {
    setSeTimePeriodsModalOpened(false);
  };

  const onOpenTimePeriods = () => {
    setSeTimePeriodsModalOpened(true);
  };

  const onConfirmTimePeriods = (
    seOccurrenceWithin: number,
    seReoccurrenceStartingFrom: number,
    seReoccurrenceUpTo: number
  ) => {
    onConfirm(
      seOccurrenceWithin,
      seReoccurrenceStartingFrom,
      seReoccurrenceUpTo
    );
    onCloseTimePeriods();
  };

  return (
    <Modal
      isOpened={seTimePeriodsModalOpened}
      onOpen={onOpenTimePeriods}
      triggerElement={
        <Button
          color="secondary"
          label={title}
          testId="reoccurrence-time-period-selection-cta"
        />
      }
      children={[
        <ReoccurrenceTimePeriodSelection
          title={title}
          seOccurrenceWithin={seOccurrenceWithin}
          seReoccurrenceStartingFrom={seReoccurrenceStartingFrom}
          seReoccurrenceUpTo={seReoccurrenceUpTo}
          onClose={onCloseTimePeriods}
          onConfirm={onConfirmTimePeriods}
          key="ReoccurrenceTimePeriodSelection"
        />,
      ]}
    />
  );
};

export default ReoccurrenceTimePeriodModal;
