import {
  createRelatedSignalEventFilter,
  validateSignalEventFilterOperatorValues,
  validateSignalEventOccurrencesWindowSize,
} from "duck/graph/nodes/utils";
import { PageHandler } from "duck/graph/PageHandler";
import { StringSetter } from "duck/graph/types";
import { assertNonEmptyStringArray } from "duck/ui/utils";
import { z } from "zod";
import { DynamicStructuredTool, tool } from "@langchain/core/tools";

import { ASSOCIATED_SIGNAL_EVENTS_TAB_KEY } from "pages/constants";

import {
  DEFAULT_WINDOW_SIZE,
  MAX_WINDOW_SIZE,
  MIN_WINDOW_SIZE,
} from "features/ui/Filters/FilterTypes/RelatesFilter/constants";
import {
  FilterOperator,
  RelatesFilterWindowDirection,
  RelatesFilterWindowDirectionType,
  signalEventsFilterOperators,
} from "features/ui/Filters/types";
import { getPageKeyWithVersion, getQueryKeys } from "features/ui/Filters/utils";

const getGoToAssociatedSignalEventsTabTool = (
  pageHandler: PageHandler,
  pageKey: string,
  windowDirectionOptions: RelatesFilterWindowDirectionType[],
  setEphemeralMessage: StringSetter
): DynamicStructuredTool => {
  assertNonEmptyStringArray(windowDirectionOptions);

  const windowDirectionDescriptionExtra = windowDirectionOptions.includes(
    RelatesFilterWindowDirection.BOTH
  )
    ? " Note: 'both' means before or after the reference event."
    : "";

  const goToAssociatedSignalEventsTabSchema = z.object({
    operator: z
      .enum(signalEventsFilterOperators)
      .optional()
      .describe(
        `Operator to filter signal event IDs. Valid operators: ${signalEventsFilterOperators.join(", ")}.`
      ),
    values: z
      .array(z.string())
      .optional()
      .describe(
        "List of values to filter signal event IDs. Use ['null'] if the operator is 'not_filtered' or 'is_not_empty'."
      ),
    windowSize: z
      .number()
      .optional()
      .describe(
        `Window size in days. Range: ${MIN_WINDOW_SIZE}-${MAX_WINDOW_SIZE}. Default: ${DEFAULT_WINDOW_SIZE}.`
      ),
    windowDirection: z
      .enum(windowDirectionOptions)
      .optional()
      .describe(
        `Window direction. Default: ${RelatesFilterWindowDirection.BEFORE}. ${windowDirectionDescriptionExtra}`
      ),
  });

  type GoToAssociatedSignalEventsTab = z.infer<
    typeof goToAssociatedSignalEventsTabSchema
  >;

  const goToAssociatedSignalEventsTab = ({
    operator,
    values,
    windowSize,
    windowDirection,
  }: GoToAssociatedSignalEventsTab) => {
    console.debug(
      `Navigating to Associated Signal Events tab: operator=${operator}, values=${values}, windowSize=${windowSize}, windowDirection=${windowDirection}`
    );
    setEphemeralMessage("to associated signal events tab");

    const nonNullOperator = operator ?? FilterOperator.NOT_FILTERED;
    const nonNullValues = values ?? ["null"];
    const nonNullWindowSize = windowSize ?? DEFAULT_WINDOW_SIZE;
    const nonNullWindowDirection =
      windowDirection ?? RelatesFilterWindowDirection.BEFORE;
    const { operator: validatedOperator, values: validatedValues } =
      validateSignalEventFilterOperatorValues(nonNullOperator, nonNullValues);
    validateSignalEventOccurrencesWindowSize(nonNullWindowSize);

    // get relates filter and filter key
    const relatesFilter = createRelatedSignalEventFilter(
      validatedOperator,
      validatedValues,
      nonNullWindowSize,
      nonNullWindowDirection
    );
    const pageKeyWithVersion = getPageKeyWithVersion(pageKey);
    const { relatedSignalEventsFilterKey } = getQueryKeys(pageKeyWithVersion);

    // update filter (also navigates to tab)
    pageHandler.updateTabRelatesFilter(
      ASSOCIATED_SIGNAL_EVENTS_TAB_KEY,
      relatesFilter,
      relatedSignalEventsFilterKey
    );

    return "queued navigation to Associated Signal Events tab";
  };

  return tool(goToAssociatedSignalEventsTab, {
    name: "queueGoToAssociatedSignalEventsTab",
    description:
      "Queues navigation to the Associated Signal Events tab, displaying events with signalEventID {operator} {values} within {windowSize} days {windowDirection} the reference event occurrence date.",
    schema: goToAssociatedSignalEventsTabSchema,
  });
};

export default getGoToAssociatedSignalEventsTabTool;
