import { format } from "date-fns-tz";
import { TooltipProps } from "recharts";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";

import { DATE_WITH_TIME_FORMAT } from "shared/constants";

interface CustomProps {
  dataKey: string;
}

const StateSensorChartTooltip = ({
  payload,
  label,
  dataKey,
}: TooltipProps<ValueType, NameType> & CustomProps) => (
  <div className="flex flex-col bg-white shadow px-2 py-3 text-xs">
    <div>Date: {label && format(label, DATE_WITH_TIME_FORMAT)}</div>
    {payload && payload?.length > 0 && (
      <div>
        <span style={{ color: payload[0]?.color }}>
          {payload[0]?.payload[dataKey]}
        </span>
        <span>: {payload[0]?.payload?.label}</span>
      </div>
    )}
  </div>
);

export default StateSensorChartTooltip;
