import { MouseEventHandler } from "react";
import { Link } from "react-router-dom";

import { TestProps } from "shared/types";

import Button from "features/ui/Button";

interface Props extends TestProps {
  to?: string;
  label: string;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
}

const NewCTA = ({ to, label, onClick, testId }: Props) => (
  <Button
    to={to}
    color="primary"
    variant="contained"
    size="small"
    testId={testId}
    component={Link}
    label={label}
    onClick={onClick}
  />
);

export default NewCTA;
