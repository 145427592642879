import { ServicePlanRuleState } from "pages/ServicePlans/types";
import { getFilterStateFromRecommendation } from "pages/ServicePlans/utils";
import RecommendationCount from "pages/ServiceRecommendations/RecommendationCount";

import FilterBuilder from "features/ui/Filters/FilterBuilder";
import FilterQueryPresentation from "features/ui/Filters/FilterBuilder/FilterQueryPresentation";
import FormSection from "features/ui/FormSection";
import Input from "features/ui/Input";
import { SchemaEntry } from "features/ui/Table";

interface Props {
  servicePlanId?: string;
  rule: ServicePlanRuleState;
  onRuleChange: (rule: ServicePlanRuleState) => void;
  schema: SchemaEntry[];
  disabledInput: boolean;
}

const RuleTab = ({
  servicePlanId,
  rule,
  onRuleChange,
  schema,
  disabledInput,
}: Props) => {
  const showCount = disabledInput && servicePlanId;

  return (
    <>
      <div className="text-gray-600 max-w-3xl text-sm mb-4">
        {disabledInput
          ? "If there are multiple recommendations, only one can be triggered. "
          : "You can create multiple recommendations for each plan, but only one can be triggered. "}
        The recommendations are evaluated in order. If the conditions are met,
        the recommendation is triggered, and the rest are skipped.
      </div>
      <FormSection
        title="Conditions for Recommendation"
        text={
          disabledInput
            ? undefined
            : "Specify the conditions which must be true to trigger a recommendation."
        }
      >
        {disabledInput ? (
          <FilterQueryPresentation
            filterState={rule.filter}
            tableSchema={schema}
            baseEntityText=""
            relatesBeforeNow
          />
        ) : (
          <FilterBuilder
            filterBuilderState={rule.filter}
            filtersSchema={schema}
            onChange={(filterState) =>
              onRuleChange({ ...rule, filter: filterState })
            }
            inline
            disabled={disabledInput}
            attributePlaceholder="Select condition"
            testId="recommendation-conditions-filter-builder"
          />
        )}
      </FormSection>
      <FormSection
        title="Recommendation"
        text={
          disabledInput
            ? undefined
            : "Specify the recommended action if the conditions are met."
        }
        extraTitleComponent={
          showCount && (
            <RecommendationCount
              filterState={getFilterStateFromRecommendation(
                rule.recommendation,
                servicePlanId
              )}
            />
          )
        }
      >
        <div className="mb-5 flex">
          <div className="mr-4 leading-9 text-left shrink-0">
            Recommended Action
          </div>
          <Input
            value={rule.recommendation}
            onChange={({ target: { value } }) =>
              onRuleChange({ ...rule, recommendation: value })
            }
            testId="sps-rule-recommendation"
            disabled={disabledInput}
          />
        </div>
      </FormSection>
    </>
  );
};

export default RuleTab;
