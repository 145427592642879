import { useFlags } from "launchdarkly-react-client-sdk";
import { FiTrash2 as DeleteIcon } from "react-icons/fi";
import { IconButton } from "@mui/material";

import { Collection } from "shared/api/api";
import { VEHICLE_COLLECTION_FILTER_KEY } from "shared/filterDefinitions";

import { ACTION_ICON_SIZE } from "pages/Collections/constants";

import PermissionsSettingsAction from "features/ui/PermissionsDialog/PermissionsSettingsAction";

interface Props {
  collection: Collection;
  onDelete: (id: string, name: string) => void;
  requestKeys?: string[];
}

const RowActions = ({ collection, onDelete, requestKeys }: Props) => {
  const { rbac } = useFlags();

  return (
    <>
      {rbac && (
        <PermissionsSettingsAction
          entity={VEHICLE_COLLECTION_FILTER_KEY}
          entityId={collection.ID}
          entityName={collection.name}
          permissions={collection.access}
          canEdit={collection.canEdit}
          iconSize={ACTION_ICON_SIZE + 2}
          entityRequestKeys={requestKeys}
        />
      )}
      {(!rbac || (rbac && collection.canEdit)) && (
        <IconButton
          className="hover:text-red-600"
          onClick={() => onDelete(collection.ID, collection.name)}
          data-testid="collection-delete-btn"
        >
          <DeleteIcon size={ACTION_ICON_SIZE} />
        </IconButton>
      )}
    </>
  );
};

export default RowActions;
