import { DropdownSelectOption } from "features/ui/DropdownSelect/DropdownSelect";
import { getTopLevelRowFromFilterGroupState } from "features/ui/Filters/FilterBuilder/utils";
import {
  FILTER_TYPE,
  OPERATORS_WITHOUT_WINDOW_SELECTION,
  SIGNAL_EVENT_ID_FIELD_NAME,
} from "features/ui/Filters/FilterTypes/RelatesFilter/constants";
import {
  FilterOperator,
  RelatesFilterState,
  RelatesFilterWindowDirectionType,
} from "features/ui/Filters/types";
import { operatorToSelectOption } from "features/ui/Filters/utils";

import RelatesSignalEventForm from "./RelatesSignalEventForm";
import RelatesTimeWindowForm from "./RelatesTimeWindowForm";

interface Props {
  relatesState: RelatesFilterState;
  onUpdate: (row: RelatesFilterState) => void;
  baseEntityText: string;
  relatedSignalEventLabel?: string;
  inFilterSelector: boolean;
  windowDirectionOptions?: DropdownSelectOption<RelatesFilterWindowDirectionType>[];
  disableSelectingWindowDirection?: boolean;
  disableIsNotFilteredFilters?: boolean;
}

const RelatesFilterForm = ({
  relatesState,
  onUpdate,
  relatedSignalEventLabel,
  baseEntityText,
  inFilterSelector,
  windowDirectionOptions,
  disableSelectingWindowDirection,
  disableIsNotFilteredFilters,
}: Props) => {
  const signalEventIDOperator =
    getTopLevelRowFromFilterGroupState(
      SIGNAL_EVENT_ID_FIELD_NAME,
      relatesState.filters
    )?.operator || FilterOperator.IN;

  const selectedOperator = operatorToSelectOption(
    FILTER_TYPE,
    signalEventIDOperator
  );

  return (
    <div className="flex flex-wrap leading-10">
      <RelatesSignalEventForm
        relatesState={relatesState}
        selectedOperator={selectedOperator}
        onUpdate={onUpdate}
        relatedSignalEventLabel={relatedSignalEventLabel}
        disableIsNotFilteredFilters={disableIsNotFilteredFilters}
      />
      {!OPERATORS_WITHOUT_WINDOW_SELECTION.includes(selectedOperator.id) && (
        <RelatesTimeWindowForm
          relatesState={relatesState}
          onUpdate={onUpdate}
          baseEntityText={baseEntityText}
          inFilterSelector={inFilterSelector}
          windowDirectionOptions={windowDirectionOptions}
          disableSelectingWindowDirection={disableSelectingWindowDirection}
        />
      )}
    </div>
  );
};

export default RelatesFilterForm;
