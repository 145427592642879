import React, { useEffect, useRef, useState } from "react";

import Button from "features/ui/Button";

import { SUPPORT_EMAIL } from "config/config";

import { sendFeedback } from "./api";
import ExitButton from "./ExitButton";
import FeedbackQuestion from "./FeedbackQuestion";

interface Props {
  questions: string[];
  onCloseForm: () => void;
  pressedSubmit: boolean;
  setPressedSubmit: (pressed: boolean) => void;
  errorSubmit: boolean;
  setErrorSubmit: (error: boolean) => void;
}

const FeedbackForm = ({
  questions,
  onCloseForm,
  pressedSubmit,
  setPressedSubmit,
  errorSubmit,
  setErrorSubmit,
}: Props) => {
  const [responses, setResponses] = useState(Array(questions.length).fill(""));
  // ref for scrolling component so we can reset it later
  const scrollView = useRef<HTMLDivElement>(null);

  // reset responses if user pressed submit
  useEffect(() => {
    setResponses(Array(questions.length).fill(""));

    // reset scroll position after submit
    if (scrollView.current) {
      scrollView.current.scrollTo(0, 0);
    }
  }, [pressedSubmit, questions]);

  // TODO: Consider adding Immer for immutable updates https://immerjs.github.io/immer/
  const modifyResponses = (text: string, index: number) => {
    const newResponses = responses.map((r, i) => {
      return i === index ? text : r;
    });
    setResponses(newResponses);
  };

  const hasFeedback = Boolean(responses.length > 0 && responses[0]);

  const submitFeedback = async () => {
    const customerFeedback = {
      responses: questions.map((q, i) => ({
        question: q,
        response: responses[i] && responses[i].trim(),
      })),
    };

    // Update state regardless of success
    setPressedSubmit(true);
    try {
      await sendFeedback(customerFeedback);
    } catch (e) {
      setErrorSubmit(true);
      console.error(e);
    }
  };

  const questionList = questions.map((q, i) => (
    <FeedbackQuestion
      key={i}
      question={q}
      text={responses[i]}
      onTextChange={(text: string) => {
        modifyResponses(text, i);
      }}
    />
  ));

  const questionForm = (
    <div
      ref={scrollView}
      className="flex flex-col justify-between items-center space-y-5 h-80 py-2 px-5"
    >
      {questionList}
      <Button
        label="Submit"
        color="secondary"
        disabled={!hasFeedback}
        onClick={submitFeedback}
      />
    </div>
  );

  const thankYouScreen = (
    <div className="flex flex-col justify-between items-center space-y-5 h-80 py-2 px-5">
      <div className="text-xl text-center text-white ">Thank you!</div>
      <Button color="secondary" label="Close" onClick={onCloseForm} />
    </div>
  );

  const errorScreen = (
    <div className="flex flex-col justify-between items-center space-y-5 h-80 py-2 px-5">
      <div className="font-bold text-center text-red-500">
        Ooops, something went wrong.
        <br />
        Your feedback was not sent!
      </div>
      <div className="text-center text-red-500">
        Please send us an email <br /> to{" "}
        <a href={`mailto:${SUPPORT_EMAIL}`} className="hover:underline">
          {SUPPORT_EMAIL}
        </a>{" "}
        instead.
      </div>
      <Button color="secondary" label="Close" onClick={onCloseForm} />
    </div>
  );

  const responseScreen = errorSubmit ? errorScreen : thankYouScreen;
  const content = pressedSubmit ? responseScreen : questionForm;

  return (
    <div
      id="feedback-box"
      className="flex flex-col items-center space-y-5 shadow-2xl rounded-t w-112 py-2 mt-2"
    >
      <div className="absolute top-3 right-2 flex">
        <ExitButton onExit={onCloseForm} />
      </div>
      {content}
    </div>
  );
};
export default FeedbackForm;
