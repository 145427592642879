import classNames from "classnames";
import { useFlags } from "launchdarkly-react-client-sdk";
import Skeleton from "react-loading-skeleton";

import { useGetClaim } from "shared/api/claims/hooks";
import { SHORT_DATE_FORMAT } from "shared/constants";
import { useClaimsSchema } from "shared/schemas/claimsSchema";
import {
  formatDate,
  formatNumber,
  getTenantMileageUnit,
  roundToNDecimals,
} from "shared/utils";

import StringList from "features/ui/StringList";

import { RecordProps } from "./EventDetail";
import InformationWrapper from "./InformationWrapper";
import Notes from "./Notes";

interface AccessorValuePair {
  accessor: string;
  value: any;
  suffix?: string;
  label?: string;
}

const ClaimDetail = ({ serviceRecord, onClose }: RecordProps) => {
  const { warrantyClaimsCost } = useFlags();

  const { data, isLoading } = useGetClaim({
    ID: serviceRecord.ID,
    mileageUnit: getTenantMileageUnit(),
  });

  const { getDisplayLabel, whitelist } = useClaimsSchema();

  if (isLoading) {
    return <Skeleton count={3} />;
  }

  if (!data) {
    return <div></div>;
  }

  const information: AccessorValuePair[] = [
    {
      label: "Type",
      value: "Claim",
      accessor: "",
    },
    {
      accessor: "date",
      value: formatDate(data.date, SHORT_DATE_FORMAT, true, false),
    },
    {
      accessor: "mileage",
      value:
        data.mileage && `${formatNumber(data.mileage, 2)} ${data.mileageUnit}`,
    },
    { accessor: "laborCode", value: data.laborCode },
    {
      accessor: "laborCode",
      value: data.laborCodeInformation?.description,
      suffix: "Description",
    },
    { accessor: "dealer", value: data?.dealer?.name, suffix: "Name" },
    { accessor: "dealer", value: data?.dealer?.ID, suffix: "ID" },
    {
      accessor: "dealer",
      value: data?.dealer?.countryCode,
      suffix: "Country Code",
    },
    {
      accessor: "dealer",
      value: data?.dealer?.provinceCode,
      suffix: "Province Code",
    },
    { accessor: "notes", value: <Notes notes={data.notes} /> },
    { accessor: "failedPartNumber", value: data.failedPartNumber },
    {
      accessor: "failedPartNumber",
      value: data.failedPartInformation?.description,
      suffix: "Description",
    },
    { accessor: "status", value: data.status },
    { accessor: "repairOrderNumber", value: data.repairOrderNumber },
    {
      accessor: "parts",
      value:
        data.parts.length > 0 ? (
          <StringList
            title={getDisplayLabel("parts", "Parts")}
            values={data.parts}
          />
        ) : undefined,
    },
    warrantyClaimsCost && {
      accessor: "costLabor",
      value:
        data.costLabor &&
        `${roundToNDecimals(data.costLabor, 2)} ${data.currencyCode}`,
    },
    warrantyClaimsCost && {
      accessor: "costParts",
      value:
        data.costParts &&
        `${roundToNDecimals(data.costParts, 2)} ${data.currencyCode}`,
    },
    warrantyClaimsCost && {
      accessor: "costOther",
      value:
        data.costOther &&
        `${roundToNDecimals(data.costOther, 2)} ${data.currencyCode}`,
    },
    warrantyClaimsCost && {
      accessor: "costTotal",
      value:
        data.costTotal &&
        `${roundToNDecimals(data.costTotal, 2)} ${data.currencyCode}`,
    },
    { accessor: "campaignID", value: data.campaignID },
    { accessor: "monthsInService", value: data.monthsInService },
    { accessor: "daysInService", value: data.daysInService },
    { accessor: "componentDetail", value: data.componentDetail },
    { accessor: "group", value: data.group },
    { accessor: "subgroup", value: data.subgroup },
    { accessor: "causalDetail", value: data.causalDetail },
    { accessor: "repairDetail", value: data.repairDetail },
    { accessor: "notesCustomer", value: data.notesCustomer },
    { accessor: "notesTechnician", value: data.notesTechnician },
    { accessor: "notesCorrection", value: data.notesCorrection },
    { accessor: "transactionCategory", value: data.transactionCategory },
    {
      accessor: "mentionedSignalEvents",
      value:
        data.mentionedSignalEvents && data.mentionedSignalEvents.length > 0 ? (
          <StringList
            title={getDisplayLabel("mentionedSignalEvents", "")}
            values={data.mentionedSignalEvents?.map((x) => x.ID)}
          />
        ) : undefined,
    },
    {
      accessor: "mentionedPhrases",
      value:
        data.mentionedPhrases && data.mentionedPhrases.length > 0 ? (
          <StringList
            title={getDisplayLabel("mentionedPhrases", "")}
            values={data.mentionedPhrases as string[]}
          />
        ) : undefined,
    },
  ].filter(Boolean) as AccessorValuePair[];

  return (
    <InformationWrapper
      externalID={serviceRecord.externalID || ""}
      claimID={serviceRecord.ID}
      onClose={onClose}
    >
      {information
        .filter(
          (item) =>
            (whitelist?.includes(item.accessor) || item.label) && item.value
        )
        .map(({ accessor, value, suffix, label }, i, { length }) => {
          return (
            <div
              key={accessor + suffix + label}
              className={classNames(
                "grid grid-cols-[180px_auto] gap-x-3 py-2",
                {
                  "border-b": i < length - 1,
                }
              )}
            >
              <span className="text-gray-400">
                {label || getDisplayLabel(accessor, "", suffix)}
              </span>
              <span className="break-words">{value}</span>
            </div>
          );
        })}
    </InformationWrapper>
  );
};

export default ClaimDetail;
