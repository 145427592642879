import { useMemo } from "react";
import { generatePath, Link } from "react-router-dom";

import { getIssuesExport, Issue } from "shared/api/issues/api";
import { useListIssues, useListIssuesCount } from "shared/api/issues/hooks";
import { applyAdditionalSorting, getSortFilter } from "shared/api/utils";
import useIssuesSchema from "shared/schemas/issuesSchema";
import { ValueType } from "shared/types";

import IssueGroupCell from "pages/Issues/IssueGroupCell";

import APIError from "features/ui/APIError";
import { SelectedChartOptions } from "features/ui/charts/Actions/types";
import DownloadAction from "features/ui/DownloadAction";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import {
  getFiltersQuery,
  mergeFilterGroupStates,
} from "features/ui/Filters/FilterBuilder/utils";
import FiltersOverview from "features/ui/Filters/FiltersOverview";
import { useFilterSortState } from "features/ui/Filters/hooks";
import { UseFilterSortState } from "features/ui/Filters/types";
import { OnSortParams, SchemaEntry } from "features/ui/Table";
import PaginatedTable from "features/ui/Table/PaginatedTable";
import { DataType } from "features/ui/Table/TableBodyCell";
import TableCount from "features/ui/Table/TableCount";

import { routes } from "services/routes";

import { ISSUES_CHART_ACTIONS } from "./constants";
import {
  ClaimFilterPresenter,
  PopulationFilterPresenter,
  SignalEventFilterPresenter,
} from "./IssuePresenters";
import {
  getAxisKeyLabelFromActions,
  getMeasureLabel,
  getMetricsRequestParams,
} from "./utils";

const formatRow = (issue: Issue) => {
  const {
    ID,
    name,
    atRiskPopulationFilter,
    comparisonPopulationFilter,
    assignedGroupID,
    assignedGroup,
    promotedFromID,
    statusObj,
    severityObj,
  } = issue;

  return {
    ...issue,
    name: (
      <Link
        to={{
          pathname: generatePath(routes.issue, {
            id: encodeURIComponent(ID),
          }),
        }}
        className="text-metabase-blue hover:underline"
      >
        {name}
      </Link>
    ),
    assignedGroupID: (
      <IssueGroupCell
        assignedGroupID={assignedGroupID}
        assignedGroup={assignedGroup}
      />
    ),
    atRiskPopulationFilter: (
      <PopulationFilterPresenter populationFilter={atRiskPopulationFilter} />
    ),
    comparisonPopulationFilter: (
      <PopulationFilterPresenter
        populationFilter={comparisonPopulationFilter}
      />
    ),
    claimFilter: <ClaimFilterPresenter claimFilter={issue.claimFilter} />,
    signalEventOccurrencesFilter: (
      <SignalEventFilterPresenter
        signalEventOccurrencesFilter={issue.signalEventOccurrencesFilter}
      />
    ),
    promotedFromID: promotedFromID ? (
      <Link
        to={generatePath(routes.suggestedIssueLatestRun, {
          id: promotedFromID,
        })}
        className="text-metabase-blue hover:underline"
      >
        {promotedFromID}
      </Link>
    ) : (
      ""
    ),
    statusObj: statusObj?.value,
    severityObj: severityObj?.value,
  };
};

const ROWS_PER_PAGE = 10;
const PAGE_KEY = "issues-table";
const NO_DATA_TEXT = "No Issues yet.";
const MAX_ISSUES_DOWNLOAD_LIMIT = 2000;

interface Props {
  topFilterSortState: UseFilterSortState;
  selectedChartOptions: SelectedChartOptions[];
  selectedBarFilters?: FilterGroupState | undefined;
}

const IssuesTable = ({
  topFilterSortState,
  selectedChartOptions,
  selectedBarFilters,
}: Props) => {
  const { axisKey: measureKey, axisValue: measureLabel } =
    getAxisKeyLabelFromActions(
      selectedChartOptions,
      ISSUES_CHART_ACTIONS,
      "measure"
    );

  const { axisKey: valueType } = getAxisKeyLabelFromActions(
    selectedChartOptions,
    ISSUES_CHART_ACTIONS,
    "valueType"
  );

  const valueTypeKey = valueType as ValueType;

  const { schema } = useIssuesSchema();

  const nameIdx = schema.findIndex(({ accessor }) => accessor === "name");
  if (measureKey !== "count" && nameIdx !== -1) {
    const metricSchemaEntry: SchemaEntry = {
      label: getMeasureLabel(
        ISSUES_CHART_ACTIONS,
        valueTypeKey,
        measureKey,
        undefined,
        measureLabel
      ),
      accessor: "metric.value",
      dataType:
        valueTypeKey === "percentage"
          ? DataType.RATIO_PERCENTAGE
          : DataType.NUMBER,
      sortable: true,
    };

    schema.splice(nameIdx + 1, 0, metricSchemaEntry);
  }

  const {
    manageOnSortChange,
    sort,
    filters: topFilters,
    resetFilterSortState,
  } = topFilterSortState;

  const {
    manageFilterChange,
    resetFilters,
    filters: tableFilters,
    initialized: filtersInitialized,
  } = useFilterSortState({
    pageKey: PAGE_KEY,
  });

  const allFilters = mergeFilterGroupStates(
    topFilters,
    tableFilters,
    selectedBarFilters
  );
  const allFiltersQuery = getFiltersQuery(allFilters);

  const handleSorting = ({ accessor, sort }: OnSortParams) => {
    // only allow sorting by one column at the time
    manageOnSortChange({ [accessor]: sort });
  };

  const metricsRequestParams = getMetricsRequestParams(
    selectedChartOptions,
    ISSUES_CHART_ACTIONS
  );

  const { data, isLoading, headers, error, ...paginationData } = useListIssues({
    sort: getSortFilter(applyAdditionalSorting(sort), schema),
    filter: allFiltersQuery,
    limit: ROWS_PER_PAGE,
    ...metricsRequestParams,
  });

  // re-format the data - but only when data changes
  const formattedData = useMemo(() => data?.map(formatRow), [data]);

  const {
    isLoading: countIsLoading,
    data: countData,
    error: countError,
  } = useListIssuesCount({
    filter: allFiltersQuery,
  });

  const downloadDisabled = !formattedData || formattedData.length === 0;

  return (
    <>
      <div data-testid="issues-table">
        <div className="flex items-center mt-3 mb-1">
          <FiltersOverview
            filters={tableFilters}
            tableSchema={schema}
            onFiltersReset={resetFilters}
          />
          <TableCount
            extraClasses="ml-auto"
            count={countData?.count as number}
            entityName="issue"
            isLoading={countIsLoading}
            error={!!countError}
          />
          <DownloadAction
            disabled={downloadDisabled}
            fileName="issues"
            requestParams={{
              filter: allFiltersQuery,
              limit: MAX_ISSUES_DOWNLOAD_LIMIT,
            }}
            count={countData?.count as number}
            entityName="issue"
            downloadFunc={getIssuesExport}
            filters={allFilters}
          />
        </div>
        {!error && (
          <PaginatedTable
            {...paginationData}
            key={paginationData.requestKey}
            dense
            data={formattedData}
            schema={schema}
            isLoading={isLoading}
            loadingRows={ROWS_PER_PAGE}
            sortBy={sort}
            onSort={handleSorting}
            filtersInitialized={filtersInitialized}
            onFiltersReset={resetFilters}
            onFilterChange={manageFilterChange}
            filters={tableFilters}
          />
        )}
        {error && (
          <APIError error={error} onBadRequest={resetFilterSortState} />
        )}
        {!error && !isLoading && !formattedData?.length && (
          <div className="py-4 text-gray-400 text-sm">{NO_DATA_TEXT}</div>
        )}
      </div>
    </>
  );
};

export default IssuesTable;
