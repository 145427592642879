import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useLocation } from "react-router-dom";

import MarkdownSection from "pages/Docs/MarkdownSection";

import * as config from "config/config";

import allContent from "./content/allContent.md";
import oldContent from "./content/oldContent.md";
import toc from "./content/tableOfContents.md";

const Product = () => {
  const {
    pages: { docs },
  } = config.get();
  const { hash } = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [tocIsLoading, setTocIsLoading] = useState(true);
  const [mdContent, setMdContent] = useState("");
  const [tocContent, setTocContent] = useState("");

  useEffect(() => {
    if (!hash) return;
    const titleElement = document.getElementById(hash.replace("#", ""));
    titleElement?.scrollIntoView();
  }, [hash]);

  useEffect(() => {
    if (tocContent) return;

    setTocIsLoading(true);
    // fetch tocContent
    fetch(toc)
      .then((res) => res.text())
      .then((content) => {
        setTocContent(content);
      })
      .finally(() => setTocIsLoading(false));
  }, [docs?.tableOfContents, tocContent]);

  useEffect(() => {
    // this is an array so we can provide multiple markdown files (ie. sections) if we wish and all will be displayed in this order
    const allMdContentOrdered = docs?.tableOfContents
      ? [allContent]
      : [oldContent];

    Promise.all(
      allMdContentOrdered.map((c) => fetch(c).then((res) => res.text()))
    ).then((content) => {
      setMdContent(content.join("\n"));
      setIsLoading(false);
    });
  }, [docs?.tableOfContents]);

  return (
    <div className="lg:flex lg:min-h-screen">
      {docs?.tableOfContents && (
        <aside className="lg:h-screen md:sticky lg:top-0 px-5 bg-gray-50 lg:overflow-y-auto lg:shrink-0">
          {tocIsLoading && <Skeleton width={300} count={50} />}
          {!tocIsLoading && tocContent && (
            <MarkdownSection
              content={tocContent}
              wrapperClasses="toc-wrapper"
            />
          )}
        </aside>
      )}
      {isLoading && (
        <div className="w-full px-5">
          <Skeleton count={100} width="100%" />
        </div>
      )}
      {mdContent && !isLoading && (
        <MarkdownSection content={mdContent} wrapperClasses="px-7 pb-7" />
      )}
    </div>
  );
};

export default Product;
