// This is required for our CI pipeline
//  - Concourse will check this version to know the deployment went through successfully.
//  - CI for API will use it to make sure to test the correct version of the UI

// It would be better if we could use public/manifests.json
// to expose REACT_APP_VERSION in the future
// see https://github.com/facebook/create-react-app/issues/7953
const json = {
  version: process.env.REACT_APP_VERSION,
};

const Version = () => {
  return <div id="version">{JSON.stringify(json)}</div>;
};

export default Version;
