import { DEFAULT_FILTER_TYPE } from "features/ui/Filters/constants";
import { FILTER_INDENT_MARGIN_PX } from "features/ui/Filters/FilterBuilder/constants";
import { FilterRowState } from "features/ui/Filters/FilterBuilder/types";
import { EditableRowProperty } from "features/ui/Filters/FilterBuilder/utils";
import { FilterType, RelatesFilterState } from "features/ui/Filters/types";
import { SchemaEntry } from "features/ui/Table";

import AttributeSelect from "./AttributeSelect";
import DefaultFilterFields from "./DefaultFilterFields";
import RelatesFilterFields from "./RelatesFilterFields";
import RowActions from "./RowActions";

export type OnRowDataChangeHandle = (
  id: string,
  property: EditableRowProperty,
  values?: string | string[] | RelatesFilterState
) => void;

interface Props extends FilterRowState {
  onNewRow: (id: string) => void;
  onNewGroup: (id: string) => void;
  onDelete?: (id: string) => void;
  onRowDataChange: OnRowDataChangeHandle;
  disableDelete?: boolean;
  schema: SchemaEntry<string>[];
  disabled?: boolean;
  attributePlaceholder?: string;
}

const FilterRow = ({
  id,
  attribute,
  operator,
  values,
  onNewRow,
  onNewGroup,
  onDelete,
  onRowDataChange,
  disableDelete,
  schema,
  type,
  relates,
  disabled,
  attributePlaceholder,
}: Props) => {
  const currentAttributeSchema: SchemaEntry<string> | undefined = attribute
    ? schema.find((s) => s.filter?.fieldName === attribute)
    : undefined;

  const currentAttributeType: FilterType =
    currentAttributeSchema?.filter?.filterType || DEFAULT_FILTER_TYPE;

  return (
    <div
      key={id}
      data-testid="filter-row"
      className="flex items-start gap-2 mt-2 group/row"
      style={{ marginLeft: FILTER_INDENT_MARGIN_PX }}
    >
      {/* Filter attribute */}
      <AttributeSelect
        selected={attribute}
        onChange={(selected) => onRowDataChange(id, "attribute", selected)}
        schema={schema}
        disabled={disabled}
        attributePlaceholder={attributePlaceholder}
      />
      {/* Filter operators & values */}
      {currentAttributeType === "relates" && (
        <RelatesFilterFields
          id={id}
          relates={relates}
          attributeSchema={currentAttributeSchema}
          onRowDataChange={onRowDataChange}
          disabled={disabled}
        />
      )}
      {currentAttributeType !== "relates" && (
        <DefaultFilterFields
          id={id}
          attribute={attribute}
          attributeSchema={currentAttributeSchema}
          type={type}
          operator={operator}
          values={values}
          onRowDataChange={onRowDataChange}
          disabled={disabled}
        />
      )}
      {/* Filter actions */}
      <RowActions
        id={id}
        onNewRow={onNewRow}
        onNewGroup={onNewGroup}
        onDelete={disableDelete ? undefined : onDelete}
        disabled={disabled}
      />
    </div>
  );
};

export default FilterRow;
