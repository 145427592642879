import { FiChevronLeft as ChevronLeft } from "react-icons/fi";
import { generatePath, Link } from "react-router-dom";

import { useQuery } from "services/hooks";
import { routes } from "services/routes";

import {
  ISSUE_ID_QUERY_KEY,
  ISSUE_IS_SUGGESTED_QUERY_KEY,
  ISSUE_NAME_QUERY_KEY,
  ISSUE_RUN_DATE_QUERY_KEY,
} from "./constants";

const BackToIssueBreadcrumb = () => {
  const query = useQuery();

  if (!query[ISSUE_ID_QUERY_KEY]) return null;

  const issueId = query[ISSUE_ID_QUERY_KEY].toString();

  const isSuggestedIssue = query[ISSUE_IS_SUGGESTED_QUERY_KEY] === "true";

  if (isSuggestedIssue && !query[ISSUE_RUN_DATE_QUERY_KEY]) return null;

  const issueRunDate = query[ISSUE_RUN_DATE_QUERY_KEY]?.toString() ?? "";

  const issueName = query[ISSUE_NAME_QUERY_KEY]
    ? query[ISSUE_NAME_QUERY_KEY].toString()
    : "Suggested Issue";

  const issueDetailPathname = isSuggestedIssue
    ? generatePath(routes.suggestedIssue, {
        id: issueId,
        date: issueRunDate,
      })
    : generatePath(routes.issue, { id: issueId });

  return (
    <Link
      to={issueDetailPathname}
      className="flex items-center mb-4 hover:text-blue-400 text-blue-500 text-xs"
      data-testid="breadcrumb-back-to-issue"
    >
      <ChevronLeft size="12" />
      <span className="ml-2">Back to {issueName}</span>
    </Link>
  );
};

export default BackToIssueBreadcrumb;
