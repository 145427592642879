import Skeleton from "react-loading-skeleton";

import { Granularity } from "shared/api/constants";
import {
  getSignalEventsByVehicleAgeExport,
  SignalEventByVehicleAgeFiltersRequest,
} from "shared/api/signalEvents/api";
import { useListSignalEventsByVehicleAgeTimeline } from "shared/api/signalEvents/hooks";
import { MAX_ROWS_DOWNLOAD_LIMIT, NO_DATA } from "shared/constants";
import { EventTypeEnum } from "shared/types";

import { SignalEventsByVehicleAgeTableProps } from "pages/SignalEventsAnalytics/SignalEventsAnalyticsTabs";
import { prepareVehiclesByAgeTableSchemaAndData } from "pages/utils";

import APIError from "features/ui/APIError";
import DownloadAction from "features/ui/DownloadAction";
import {
  getFiltersQuery,
  mergeFilterGroupStates,
} from "features/ui/Filters/FilterBuilder/utils";
import Table from "features/ui/Table";
import MISColumnHeaders from "features/ui/Table/MISColumnHeaders";

const STICKY_FIRST_COLUMN = true;
const TABLE_HEIGHT_PX = 500;

const ByVehicleAgeTable = ({
  signalEventsFilters,
  vehiclesFilters,
  onBadRequest,
  byVehicleAgeData,
}: SignalEventsByVehicleAgeTableProps) => {
  const {
    byVehicleAgeBirthday,
    byVehicleAgeBirthdayValue,
    byVehicleAgeExposure,
    byVehicleAgeExposureValue,
    granularity,
  } = byVehicleAgeData;

  const requestParams: SignalEventByVehicleAgeFiltersRequest = {
    signalEventOccurrencesFilter:
      signalEventsFilters && getFiltersQuery(signalEventsFilters),
    vehiclesFilter: vehiclesFilters && getFiltersQuery(vehiclesFilters),
    byVehicleAgeBirthday,
    byVehicleAgeExposure,
    granularity,
  };

  const { data, isLoading, error } =
    useListSignalEventsByVehicleAgeTimeline(requestParams);

  if (isLoading) {
    return <Skeleton height={400} className="mt-5" />;
  }

  if (!data) return NO_DATA;

  const {
    data: formattedData,
    schema,
    uniqueExposureValues,
  } = prepareVehiclesByAgeTableSchemaAndData(
    data,
    false,
    granularity as Granularity,
    byVehicleAgeBirthdayValue,
    EventTypeEnum.SIGNAL_EVENT
  );

  const extraRowHeaders = (
    <MISColumnHeaders
      stickyFirstColumn={STICKY_FIRST_COLUMN}
      label={byVehicleAgeExposureValue}
      uniqueExposureValues={uniqueExposureValues}
      eventType={EventTypeEnum.SIGNAL_EVENT}
    />
  );

  const downloadDisabled = !formattedData || formattedData.length === 0;

  return (
    <>
      {!error && (
        <div className="flex justify-end mt-5">
          <DownloadAction
            disabled={downloadDisabled}
            downloadFunc={getSignalEventsByVehicleAgeExport}
            fileName="signal-events-by-vehicle-age"
            requestParams={{
              ...requestParams,
              limit: MAX_ROWS_DOWNLOAD_LIMIT,
            }}
            entityName="signal event"
            filters={mergeFilterGroupStates(
              signalEventsFilters,
              vehiclesFilters
            )}
          />
        </div>
      )}
      <div>
        {!error && (
          <Table
            data={formattedData}
            schema={schema}
            isLoading={isLoading}
            stickyFirstColumn={STICKY_FIRST_COLUMN}
            dense
            scrollHeight={TABLE_HEIGHT_PX}
            extraHeaderRowContent={extraRowHeaders}
          />
        )}
        {error && <APIError error={error} onBadRequest={onBadRequest} />}
        {!error && !isLoading && !formattedData?.length && (
          <div className="py-4 text-gray-400 text-sm">No results.</div>
        )}
      </div>
    </>
  );
};

export default ByVehicleAgeTable;
