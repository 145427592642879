import { Collapse } from "@mui/material";

import Card from "features/ui/Card";
import FilterQueryPresentation from "features/ui/Filters/FilterBuilder/FilterQueryPresentation";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { hasSomeFiltersAppliedFilterGroupState } from "features/ui/Filters/utils";
import { SchemaEntry } from "features/ui/Table";

export interface FilterStateElement {
  name: string;
  filters: FilterGroupState;
  schema: SchemaEntry[];
  baseEntityText?: string;
}

interface Props {
  open: boolean;
  filterStates: FilterStateElement[];
}

const NO_FILTERS_TEXT = "No filters have been specified.";

const FiltersSummary = ({ open, filterStates }: Props) => {
  return (
    <Collapse in={open}>
      <Card classNames="mt-3 !text-base !bg-gray-50">
        <div className="flex space-x-3">
          {filterStates.map(({ name, filters, schema, baseEntityText }) => {
            return (
              <div
                className="flex-1"
                key={name}
                data-testid="filters-overview-summary"
              >
                <div className="text-gray-500 font-bold mb-2">{name}</div>
                {(hasSomeFiltersAppliedFilterGroupState(filters) && (
                  <FilterQueryPresentation
                    filterState={filters}
                    tableSchema={schema}
                    baseEntityText={baseEntityText}
                    testId="summary-filter-presentation"
                  />
                )) || <div className="text-sm">{NO_FILTERS_TEXT}</div>}
              </div>
            );
          })}
        </div>
      </Card>
    </Collapse>
  );
};

export default FiltersSummary;
