import { useEffect } from "react";

import { APIErrorResponse } from "shared/api/api";

import Error from "features/ui/Error";

import * as config from "config/config";

import SupportContent from "./SupportContent";

interface Props {
  error: APIErrorResponse;
  onBadRequest?: () => void;
}

const APIError = ({ error: { message, response }, onBadRequest }: Props) => {
  const supportEmail = config.get().pages?.notFound?.email;

  useEffect(() => {
    if (response?.status === 400 && onBadRequest) {
      onBadRequest();
    }
    // we need this exception as otherwise it would produce infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  const messageToUse = response?.statusText
    ? `${message}: ${response.statusText}`
    : message;

  const content = supportEmail ? (
    <SupportContent email={supportEmail} />
  ) : undefined;

  return <Error content={content} moreInfo={messageToUse} />;
};

export default APIError;
