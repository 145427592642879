import { TestProps } from "shared/types";

import ToggleShownCheckbox from "features/ui/ToggleShownCheckbox";

interface Props extends TestProps {
  value: string;
  selectedValues: Set<string>;
  setSelectedValues: (events: Set<string>) => void;
}

const TableCellWithCheckbox = ({
  value,
  selectedValues,
  setSelectedValues,
  testId,
}: Props) => (
  <div className="flex items-center space-x-1 flex-nowrap">
    <ToggleShownCheckbox
      rowKey={value}
      setFunction={setSelectedValues}
      values={selectedValues}
      testId={testId}
    />

    <span>{value}</span>
  </div>
);

export default TableCellWithCheckbox;
