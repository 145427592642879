import loadPrompt from "duck/graph/nodes/loadPrompt";
import { promptNames } from "duck/graph/nodes/types";
import {
  createStrictToolCallingAgent,
  getEphemeralMessageForNode,
  NodeOutputType,
  NodeType,
} from "duck/graph/nodes/utils";
import { GraphStateType } from "duck/graph/state";
import getTheRespondToUserTool from "duck/graph/tools/getRespondToUserTool";
import { DuckGraphParams } from "duck/graph/types";
import { getLLM, NodeNames } from "duck/graph/utils";
import { AIMessage } from "@langchain/core/messages";
import { RunnableConfig } from "@langchain/core/runnables";

export const rejectClarifyValidActions = ["reject", "clarify"] as const;
export type RejectClarifyActionType =
  (typeof rejectClarifyValidActions)[number];

const defaultReason =
  "Sorry, I couldn’t provide an answer. Please try again with more specifics — the clearer the request, the better I can help!";

const getNode = async (params: DuckGraphParams): Promise<NodeType> => {
  return async (
    state: GraphStateType,
    config: RunnableConfig = {}
  ): Promise<NodeOutputType> => {
    params.uiHandlers.setEphemeralMessage(
      getEphemeralMessageForNode(NodeNames.REJECT_CLARIFY)
    );

    const { messages, userInput } = state;

    // we get routed here if the last message is an AI message with a tool_call named "rejectClarify"
    const lastMessage = messages[messages.length - 1] as AIMessage;
    const toolCall = lastMessage.tool_calls
      ? lastMessage.tool_calls[0]
      : undefined;

    // this should never happen but just in case
    if (!toolCall || toolCall.name !== NodeNames.REJECT_CLARIFY) {
      throw new Error(
        `Invalid state: we arrived in the RejectClarifyAgent without calling the "${NodeNames.REJECT_CLARIFY}" tool. The last message was ${JSON.stringify(lastMessage)}`
      );
    }

    const { toolCallAction, toolCallReason } = toolCall.args;

    const action: RejectClarifyActionType = rejectClarifyValidActions.includes(
      toolCallAction
    )
      ? toolCallAction
      : "clarify";
    const reason: string = toolCallReason || defaultReason;

    const prompt = await loadPrompt(promptNames.REJECT_CLARIFY_AGENT);
    const llm = getLLM();
    const respondToUserTool = getTheRespondToUserTool(
      params.uiHandlers.setAgentResponse,
      params.uiHandlers.setEphemeralMessage
    );
    const agent = createStrictToolCallingAgent(
      llm,
      [respondToUserTool],
      prompt
    );

    const response = await agent.invoke(
      {
        request: userInput,
        action,
        reason,
      },
      config
    );
    response.name = NodeNames.REJECT_CLARIFY;

    return {
      messages: response,
    };
  };
};

export default getNode;
