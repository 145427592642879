import { useState } from "react";

import { useCustomLocalStorageState } from "shared/hooks";

import { SelectedChartOptions } from "features/ui/charts/Actions/types";
import { getDefaultActions } from "features/ui/charts/utils";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { getPendingFiltersKey } from "features/ui/Filters/FilterWizard/utils";
import { useFilterSortState } from "features/ui/Filters/hooks";

import {
  DEFAULT_ISSUES_SORT,
  ISSUES_CHART_ACTIONS,
  ISSUES_CHART_KEY,
  ISSUES_PAGE_KEY,
} from "./constants";
import IssuesDashboard from "./IssuesDashboard";
import IssuesFilters from "./IssuesFilters";
import IssuesTable from "./IssuesTable";
import { getDefaultIssueFilter } from "./utils";

export const ISSUES_PENDING_FILTERS_LS_KEY =
  getPendingFiltersKey(ISSUES_PAGE_KEY);

const IssuesPage = () => {
  const filterSortState = useFilterSortState({
    pageKey: ISSUES_PAGE_KEY,
    defaultFilterValues: getDefaultIssueFilter(),
    defaultSort: DEFAULT_ISSUES_SORT,
    pendingFiltersLocalStorageKey: ISSUES_PENDING_FILTERS_LS_KEY,
  });
  const [selectedBarFilters, setSelectedBarFilters] = useState<
    FilterGroupState | undefined
  >();

  const [selectedChartOptions, setSelectedChartOptions] =
    useCustomLocalStorageState<SelectedChartOptions[]>(ISSUES_CHART_KEY, {
      defaultValue: getDefaultActions(ISSUES_CHART_ACTIONS),
    });

  return (
    <>
      <IssuesFilters filterSortState={filterSortState} />
      <IssuesDashboard
        filterSortState={filterSortState}
        selectedOptions={selectedChartOptions}
        setSelectedOptions={setSelectedChartOptions}
        setSelectedBarFilters={setSelectedBarFilters}
      />
      <IssuesTable
        topFilterSortState={filterSortState}
        selectedChartOptions={selectedChartOptions}
        selectedBarFilters={selectedBarFilters}
      />
    </>
  );
};

export default IssuesPage;
