import { BreadcrumbMatch } from "use-react-router-breadcrumbs";

import { useServicePlan } from "shared/api/servicePlans/hooks";

interface Props {
  match: BreadcrumbMatch;
}

const ServicePlanBreadcrumb = ({ match }: Props) => {
  const { data, isLoading, error } = useServicePlan({
    id: match.params.id as string,
  });

  if (isLoading) {
    return <>...</>;
  }

  if (data) return <>{data.name}</>;

  if (error && match.params.id) {
    return <>{match.params.id}</>;
  }

  return <></>;
};

export default ServicePlanBreadcrumb;
