import { format } from "date-fns";
import Skeleton from "react-loading-skeleton";

import { useInspectionsMetricsHistory } from "shared/api/inspections/hooks";
import { EventSourceType } from "shared/api/serviceRecords/api";
import { MONTH_YEAR_FULL, MONTH_YEAR_SHORT } from "shared/constants";
import { useCustomLocalStorageState } from "shared/hooks";

import { prepareMetricsHistoryDataByTimestamp } from "pages/ClaimAnalytics/tabPages/Claims/utils";
import { InspectionsFiltersProps } from "pages/InspectionAnalytics/InspectionAnalyticsTabs";
import { ChartTooltip } from "pages/SignalEventsAnalytics/tabPages/SignalEvents/ChartTooltip";

import APIError from "features/ui/APIError";
import Card from "features/ui/Card";
import {
  cumulativeIPTV,
  getCumulativeEntityCount,
  getRollingEntityCount,
  rollingIPTV,
  yAxis,
} from "features/ui/charts/actions";
import ChartActions from "features/ui/charts/Actions/ChartActions";
import { ChartActionsWrap } from "features/ui/charts/Actions/ChartActionsWrap";
import {
  ChartAction,
  SelectedChartOptions,
} from "features/ui/charts/Actions/types";
import LineChart from "features/ui/charts/LineChart";
import {
  getAxisLabel,
  getDefaultActions,
  getSelectedOptionId,
} from "features/ui/charts/utils";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";
import { SelectOption } from "features/ui/Select";

const CHART_HEIGHT_PX = 400;
const CHART_OPTIONS_KEY = "inspectionAnalyticsInspectionsChartOptions";

const InspectionsChart = ({
  inspectionsFilters,
  vehiclesFilters,
  onBadRequest,
}: InspectionsFiltersProps) => {
  const yAxisOptions: SelectOption[] = [
    rollingIPTV,
    cumulativeIPTV,
    getRollingEntityCount(EventSourceType.INSPECTION),
    getCumulativeEntityCount(EventSourceType.INSPECTION),
  ].filter(Boolean) as SelectOption[];

  const actions: ChartAction[] = [
    {
      id: "y",
      title: yAxis,
      type: "dropdownSelect",
      options: yAxisOptions,
    },
  ];

  const [selectedOptions, setSelectedOptions] = useCustomLocalStorageState<
    SelectedChartOptions[]
  >(CHART_OPTIONS_KEY, { defaultValue: getDefaultActions(actions) });

  const { data, isLoading, error } = useInspectionsMetricsHistory({
    inspectionsFilter: getFiltersQuery(inspectionsFilters),
    vehiclesFilter: getFiltersQuery(vehiclesFilters),
  });

  if (error) {
    return <APIError error={error} onBadRequest={onBadRequest} />;
  }

  if (isLoading) {
    return (
      <Card>
        <Skeleton height={CHART_HEIGHT_PX} />
      </Card>
    );
  }

  if (!data) {
    return (
      <Card>
        <div className="text-gray-500">No data available</div>
      </Card>
    );
  }

  const formattedChartData = prepareMetricsHistoryDataByTimestamp(data);

  const yAxisKey = getSelectedOptionId(selectedOptions, "y");
  const yAxisLabel = getAxisLabel(actions, "y", yAxisKey);

  const chartLines = [
    {
      key: yAxisKey,
      label: yAxisLabel,
    },
  ];

  return (
    <Card>
      <ChartActionsWrap>
        <ChartActions
          actions={actions}
          selectedOptions={selectedOptions}
          onOptionChange={setSelectedOptions}
        />
      </ChartActionsWrap>
      <LineChart
        height={400}
        width="100%"
        data={formattedChartData}
        xAxisKey="ts"
        yAxisLines={chartLines}
        xAxisLabel="Inspection Date"
        enableZoom={true}
        xAxisProps={{
          domain: ["auto", "auto"],
          tickFormatter: (unixTime) => format(unixTime, MONTH_YEAR_SHORT),
        }}
        tooltipProps={{
          labelFormatter: (unixTime: number) =>
            format(unixTime, MONTH_YEAR_FULL),
          content: (props: any) => (
            <ChartTooltip {...props} yAxisOptions={yAxisOptions} />
          ),
        }}
      />
    </Card>
  );
};

export default InspectionsChart;
