import { useState } from "react";

import {
  useSignalEventOccurrencesFiltersSchema,
  useVehiclesFiltersSchema,
} from "shared/hooks";

import UseFiltersMenu from "pages/ClaimAnalytics/UseFilters";
import { DEFAULT_DATA } from "pages/Issues/CreateIssue/utils";

import DropdownSelect from "features/ui/DropdownSelect";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import FiltersSummary, {
  ViewFiltersButton,
} from "features/ui/Filters/FiltersSummary";
import FilterSelector from "features/ui/Filters/FilterWizard/FilterSelector";
import { UseFilterSortState } from "features/ui/Filters/types";
import { getFilterLabel } from "features/ui/Filters/utils";

import { SIGNAL_EVENTS_FILTER_LABEL, VEHICLES_FILTER_LABEL } from "./constants";
import {
  PENDING_FILTERS_SE_LS_KEY,
  PENDING_FILTERS_VEHICLES_LS_KEY,
} from "./SignalEventsAnalytics";

interface Props {
  signalEventsFilterSortState: UseFilterSortState;
  vehiclesFilterSortState: UseFilterSortState;
  defaultSignalEventFilters: FilterGroupState;
  defaultVehicleFilters: FilterGroupState;
}

const SignalEventsAnalyticsFilters = ({
  signalEventsFilterSortState,
  vehiclesFilterSortState,
  defaultSignalEventFilters,
  defaultVehicleFilters,
}: Props) => {
  const [filterSummaryOpen, setFilterSummaryOpen] = useState(false);

  const isAdvancedSEEditor = signalEventsFilterSortState.isAdvancedFilterEditor;
  const isAdvancedVehiclesEditor =
    vehiclesFilterSortState.isAdvancedFilterEditor;

  const signalEventsOccurrencesFiltersSchema =
    useSignalEventOccurrencesFiltersSchema();
  const vehiclesFiltersSchema = useVehiclesFiltersSchema();

  const signalEventFilterLabel = getFilterLabel(
    SIGNAL_EVENTS_FILTER_LABEL,
    signalEventsFilterSortState.filters
  );
  const vehiclePopulationFilterLabel = getFilterLabel(
    VEHICLES_FILTER_LABEL,
    vehiclesFilterSortState.filters
  );

  const [seFilterDropdownOpen, setSeFilterDropdownOpen] = useState(false);
  const [vehicleFilterDropdownOpen, setVehicleFilterDropdownOpen] =
    useState(false);

  return (
    <div>
      <div className="flex space-x-3">
        <DropdownSelect
          testId="signal-events-filters-dropdown"
          label={signalEventFilterLabel}
          open={seFilterDropdownOpen}
          onOpen={(open) => {
            setSeFilterDropdownOpen(open);
            setVehicleFilterDropdownOpen(false);
          }}
          hasAdvancedFilters={isAdvancedSEEditor}
          content={
            <FilterSelector
              schema={signalEventsOccurrencesFiltersSchema}
              filterSortState={signalEventsFilterSortState}
              defaultFilters={defaultSignalEventFilters}
              title={SIGNAL_EVENTS_FILTER_LABEL}
              testId="signal-events-filters"
              pendingFiltersKey={PENDING_FILTERS_SE_LS_KEY}
              onCloseFilters={() => {
                setSeFilterDropdownOpen(false);
              }}
              initialIsAdvancedFilter={isAdvancedSEEditor}
            />
          }
        />
        <DropdownSelect
          testId="vehicles-filters-dropdown"
          label={vehiclePopulationFilterLabel}
          open={vehicleFilterDropdownOpen}
          onOpen={(open) => {
            setVehicleFilterDropdownOpen(open);
            setSeFilterDropdownOpen(false);
          }}
          hasAdvancedFilters={isAdvancedVehiclesEditor}
          content={
            <FilterSelector
              schema={vehiclesFiltersSchema}
              filterSortState={vehiclesFilterSortState}
              defaultFilters={defaultVehicleFilters}
              title={VEHICLES_FILTER_LABEL}
              testId="vehicles-filters"
              pendingFiltersKey={PENDING_FILTERS_VEHICLES_LS_KEY}
              onCloseFilters={() => {
                setVehicleFilterDropdownOpen(false);
              }}
              initialIsAdvancedFilter={isAdvancedVehiclesEditor}
            />
          }
        />
        <ViewFiltersButton
          open={filterSummaryOpen}
          onClick={() => setFilterSummaryOpen(true)}
          onClose={() => setFilterSummaryOpen(false)}
        />
        <UseFiltersMenu
          issueData={{
            ...DEFAULT_DATA,
            atRiskPopulationFilter: vehiclesFilterSortState.filters,
            signalEventOccurrencesFilter: signalEventsFilterSortState.filters,
          }}
          currentPage="signalEventsAnalytics"
        />
      </div>
      <FiltersSummary
        open={filterSummaryOpen}
        filterStates={[
          {
            name: SIGNAL_EVENTS_FILTER_LABEL,
            filters: signalEventsFilterSortState.filters,
            schema: signalEventsOccurrencesFiltersSchema,
            baseEntityText: "Base Signal Events",
          },
          {
            name: VEHICLES_FILTER_LABEL,
            filters: vehiclesFilterSortState.filters,
            schema: vehiclesFiltersSchema,
          },
        ]}
      />
    </div>
  );
};

export default SignalEventsAnalyticsFilters;
