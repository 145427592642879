import { useEffect, useState } from "react";

import { DataElement } from "features/ui/charts/types";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { mergeFilterGroupStates } from "features/ui/Filters/FilterBuilder/utils";
import { SelectOption } from "features/ui/Select";

import { DASHBOARD_GROUP_BY_KEY } from "./constants";
import { getFilterFromColumns, handleSelectedColumnsUpdate } from "./utils";

interface Props {
  selectedGroupByAttribute: SelectOption;
  selectedBucketByKey: string;
  splitByIssueSource?: boolean;
  menuOffsetElement?: Element | null;
  nameIDMapping: { [key: string]: string };
  updateFilters?: (filters: FilterGroupState) => void;
  existingFilters?: FilterGroupState;
}

export interface BarTuple {
  value: string;
  promotedIssue?: boolean;
}

export interface ColumnTuple {
  value: string;
  promotedIssue?: boolean;
}

export const useBarSelection = ({
  selectedGroupByAttribute,
  selectedBucketByKey,
  splitByIssueSource,
  menuOffsetElement,
  nameIDMapping,
  updateFilters,
  existingFilters,
}: Props) => {
  // reset selected bars when group by attribute changes
  useEffect(() => {
    resetSelectedBars();
  }, [selectedGroupByAttribute, splitByIssueSource]);

  // reset selected columns when group/bucket by attribute changes
  useEffect(() => {
    resetSelectedColumns();
  }, [selectedBucketByKey, selectedGroupByAttribute, splitByIssueSource]);

  // selectedBars refers to bar groups and selectedColumns refers to single bars inside the bar group
  const [selectedBars, setSelectedBars] = useState<BarTuple[]>([]);
  const [selectedColumns, setSelectedColumns] = useState<{
    [key: string]: ColumnTuple[];
  }>({});
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState<{
    x: number;
    y: number;
  }>({ x: 0, y: 0 });

  // Toggle currently selected bars
  // make sure to stopPropagation so that the chart doesn't get the click event fired, which resets selected bars
  const handleOnBarClick = (
    row: DataElement,
    event: React.MouseEvent<SVGPathElement, MouseEvent>,
    promotedIssue?: boolean
  ) => {
    event.stopPropagation();

    const clickedBar: string = row[DASHBOARD_GROUP_BY_KEY];
    const clickedBarTuple: BarTuple = { value: clickedBar, promotedIssue };
    setSelectedBars((prevSelectedBars) => {
      if (!prevSelectedBars) return [clickedBarTuple];

      const seletedIndex = prevSelectedBars.findIndex(
        (barTuple) =>
          barTuple.value === clickedBar &&
          barTuple.promotedIssue === promotedIssue
      );
      if (seletedIndex !== -1) {
        prevSelectedBars.splice(seletedIndex, 1);
        return prevSelectedBars;
      } else {
        return [...prevSelectedBars, clickedBarTuple];
      }
    });

    const clickedColumn: string = row?.["tooltipPayload"]?.[0]?.["name"] ?? "";
    const clickedColumnTuple: ColumnTuple = {
      value: clickedColumn,
      promotedIssue,
    };
    setSelectedColumns((prevSelectedColumns) =>
      handleSelectedColumnsUpdate(
        prevSelectedColumns,
        clickedBar,
        clickedColumnTuple
      )
    );
    setShowContextMenu(false);
  };

  const resetSelectedBars = () => {
    setSelectedBars([]);
  };

  const resetSelectedColumns = () => {
    setSelectedColumns({});
  };

  const handleOnChartClick = () => {
    resetSelectedBars();
    resetSelectedColumns();
    // we close the context menu on any mouse click anywhere on the chart..
    if (showContextMenu) setShowContextMenu(false);
  };

  const onSelectedBarsApplyAsFilter = () => {
    if (Object.keys(selectedColumns).length > 0 && updateFilters) {
      const newColumnsFilter = getFilterFromColumns(
        selectedColumns,
        selectedGroupByAttribute.id.toString(),
        selectedBucketByKey,
        splitByIssueSource,
        nameIDMapping
      );

      const mergedFilters = mergeFilterGroupStates(
        newColumnsFilter,
        existingFilters
      );
      updateFilters(mergedFilters);
    }

    resetSelectedBars();
    resetSelectedColumns();
    setShowContextMenu(false);
  };

  const onBarRightClick = (
    _x: DataElement,
    _y: number,
    event: React.MouseEvent<SVGPathElement, MouseEvent>
  ) => {
    event.preventDefault();

    // If the user right-clicks on a bar and no bars are selected, automatically select the right-clicked bar
    if (
      selectedBars.length === 0 ||
      Object.keys(selectedColumns).length === 0
    ) {
      handleOnBarClick(_x, event);
    }

    const elementRect = menuOffsetElement?.getBoundingClientRect();
    const offsetX = elementRect ? elementRect.left : 0;
    const offsetY = elementRect ? elementRect.top : 0;

    setContextMenuPosition({
      x: event.clientX - offsetX,
      y: event.clientY - offsetY,
    });
    setShowContextMenu(true);
  };

  const contextMenu = (
    <div
      className="bg-white shadow border px-4 py-3 absolute z-10"
      style={{ top: contextMenuPosition.y, left: contextMenuPosition.x }}
    >
      <ul className="flex flex-col space-y-2">
        <li
          onClick={onSelectedBarsApplyAsFilter}
          className="text-sm hover:opacity-75 cursor-pointer"
        >
          Include selected values as page filter
        </li>
      </ul>
    </div>
  );

  return {
    selectedBars,
    selectedColumns,
    showContextMenu,
    handleOnBarClick,
    onBarRightClick,
    contextMenu,
    handleOnChartClick,
  };
};
