import Skeleton from "react-loading-skeleton";
import { generatePath, useNavigate, useParams } from "react-router-dom";

import { useSuggestedIssue } from "shared/api/suggestedIssues/hooks";

import APIError from "features/ui/APIError";

import { routes } from "services/routes";

type Params = {
  id: string;
};

const SuggestedIssueLatestRun = () => {
  const navigate = useNavigate();
  const { id } = useParams<Params>();

  const { data, isLoading, error } = useSuggestedIssue({ id: id as string });

  if (error) {
    return <APIError error={error!} />;
  }

  if (isLoading) {
    return <Skeleton height={300} />;
  }

  if (!data) {
    return (
      <div className="py-4 text-gray-400 text-sm">
        No data for Suggested Issue {id}.
      </div>
    );
  }

  const { updated } = data;

  navigate(
    generatePath(routes.suggestedIssue, {
      id: encodeURIComponent(id as string),
      date: encodeURIComponent(updated),
    })
  );
};

export default SuggestedIssueLatestRun;
