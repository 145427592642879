import { DuckGraphParams } from "duck/graph/types";
import { StructuredTool } from "@langchain/core/tools";

export const promptNames = {
  CLAIM_ANALYTICS_AGENT: "duck_claim_analytics_agent",
  SIGNAL_EVENT_ANALYTICS_AGENT: "duck_se_analytics_agent",
  VIN_VIEW_AGENT: "duck_vin_view_agent",
  VEHICLES_AGENT: "duck_vehicles_agent",
  REJECT_CLARIFY_AGENT: "duck_reject_clarify",
  SUPERVISOR_AGENT: "duck_supervisor",
  RAG_AGENT: "duck_rag_agent",
  CAPTURE_SCREENSHOT_AGENT: "duck_capture_screenshot_agent",
} as const;

export type PromptName = (typeof promptNames)[keyof typeof promptNames];

export const promptTags = {
  DEV: "development",
  PROD: "production",
} as const;

export type PromptTag = (typeof promptTags)[keyof typeof promptTags];

export const tagMapping: Record<typeof process.env.NODE_ENV, PromptTag> = {
  test: promptTags.DEV,
  development: promptTags.DEV,
  production: promptTags.PROD,
};

export interface InternalGetTools {
  (params: DuckGraphParams): StructuredTool[];
}
