import { FailureModeStatus } from "shared/api/failureModes/api";

import { FilterOperator } from "features/ui/Filters/types";

export const DEFINITION_TAB_KEY = "definition";
export const EVENTS_TAB_KEY = "events";
export const RISK_MODEL_RESULTS_TAB_KEY = "risk-model-results";
export const NOMINAL_RISK_MODEL_PERFORMANCE_KEY =
  "nominal-risk-model-performance";

export const DEFINITION_TAB_TITLE = "Definition";
export const EVENTS_TAB_TITLE = "Failure Mode Events";
export const RISK_MODEL_RESULTS_TAB_TITLE = "Risk Model Results";
export const NOMINAL_RISK_MODEL_PERFORMANCE_TITLE =
  "Nominal Risk Model Performance";

export const FAILURE_MODES_TABS = {
  [DEFINITION_TAB_KEY]: DEFINITION_TAB_TITLE,
  [EVENTS_TAB_KEY]: EVENTS_TAB_TITLE,
  [RISK_MODEL_RESULTS_TAB_KEY]: RISK_MODEL_RESULTS_TAB_TITLE,
  [NOMINAL_RISK_MODEL_PERFORMANCE_KEY]: NOMINAL_RISK_MODEL_PERFORMANCE_TITLE,
};

export const EVIDENCE_OF_FAILURE_TAB_KEY = "evidence-of-failure";
export const EVIDENCE_OF_REPAIR_TAB_KEY = "evidence-of-repair";

export const EVIDENCE_OF_FAILURE_TAB_TITLE = "Evidence of Failure";
export const EVIDENCE_OF_REPAIR_TAB_TITLE = "Evidence of Repair";

export const FAILURE_MODES_DEFINITION_TABS = {
  [EVIDENCE_OF_FAILURE_TAB_KEY]: EVIDENCE_OF_FAILURE_TAB_TITLE,
  [EVIDENCE_OF_REPAIR_TAB_KEY]: EVIDENCE_OF_REPAIR_TAB_TITLE,
};

export const DEFAULT_NON_ARCHIVED_FAILURE_MODES_FILTER = {
  status: {
    operator: FilterOperator.NOT_IN,
    values: [FailureModeStatus.ARCHIVED],
  },
};
