export interface VINTableProps {
  vins: string[];
}

const NUM_ROWS = 5;

const VINTable = ({ vins }: VINTableProps) => (
  <table className="mt-2 w-full text-xs text-gray-400">
    <tbody>
      {vins.slice(0, NUM_ROWS).map((v, i) => {
        return (
          <tr key={v}>
            <td className="py-1 px-2 border">#{i + 1}</td>
            <td className="py-1 px-2 border">{v}</td>
          </tr>
        );
      })}
      <tr>
        <td className="py-1 px-2 border">#{NUM_ROWS + 1}</td>
        <td className="py-1 px-2 border"> ... </td>
      </tr>
    </tbody>
  </table>
);

export default VINTable;
