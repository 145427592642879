import * as qs from "qs";
import { useLocation } from "react-router-dom";

export const QS_PARSE_ARRAY_LIMIT = 10000;

export const useQuery = () =>
  qs.parse(useLocation().search, {
    ignoreQueryPrefix: true,
    arrayLimit: QS_PARSE_ARRAY_LIMIT,
    // We need higher depth because of the relates filter (default is 5)
    depth: 10,
  });
