import { EventType } from "shared/api/api";
import { EventTypeEnum } from "shared/types";

import { CalculatedAttributeFunction } from "pages/CalculatedAttributes/constants";
import { useEventTypeOptions } from "pages/CustomSignalEvents/hooks";

import DropdownSelect from "features/ui/DropdownSelect/DropdownSelect";

import { FunctionInputProps } from "./FunctionInput";

const DEFAULT_EVENT_TYPE_LABEL = "Select event type";

const IGNORED_EVENT_TYPES: EventTypeEnum[] = [
  EventTypeEnum.SENSOR_READING,
  EventTypeEnum.CUSTOM_RECORD,
];

const REQUIRED_EVENT_ATTRIBUTE_BY_TYPE: Record<
  CalculatedAttributeFunction,
  keyof EventType
> = {
  [CalculatedAttributeFunction.TIME_SINCE_EVENT]: "dateAttribute",
  [CalculatedAttributeFunction.MILEAGE_SINCE_EVENT]: "mileageAttribute",
  [CalculatedAttributeFunction.ENGINE_HOURS_SINCE_EVENT]:
    "engineHoursAttribute",
};

const EventTypeSelect = ({
  attribute,
  onChange,
  config,
  disabled,
}: FunctionInputProps) => {
  const eventTypeOptions = useEventTypeOptions(
    IGNORED_EVENT_TYPES,
    REQUIRED_EVENT_ATTRIBUTE_BY_TYPE[
      attribute.type as CalculatedAttributeFunction
    ]
  );

  const eventDropdownLabel = (selectedOptionID?: string): string =>
    (eventTypeOptions.find((option) => option.id === selectedOptionID)
      ?.value as string) || DEFAULT_EVENT_TYPE_LABEL;

  return (
    <DropdownSelect
      disabled={disabled}
      label={eventDropdownLabel(attribute[config.parameter] as string)}
      options={eventTypeOptions}
      onSelect={(selectedOption) =>
        onChange({
          ...attribute,
          [config.parameter]: selectedOption.id as string,
          eventFilter: undefined,
        })
      }
    />
  );
};

export default EventTypeSelect;
