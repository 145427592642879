import { BreadcrumbMatch } from "use-react-router-breadcrumbs";

import { DATE_WITH_TIME_FORMAT } from "shared/constants";
import { formatDate } from "shared/utils";

interface Props {
  match: BreadcrumbMatch;
}

const SignalEventOccurrenceBreadcrumb = ({ match }: Props) => {
  const { id, recordedAt } = match.params;

  if (!id || !recordedAt) {
    return null;
  }

  return (
    <span>
      {id} at {formatDate(recordedAt, DATE_WITH_TIME_FORMAT)}
    </span>
  );
};

export default SignalEventOccurrenceBreadcrumb;
