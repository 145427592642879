import { useState } from "react";

import {
  useInspectionsFiltersSchema,
  useVehiclesFiltersSchema,
} from "shared/hooks";

import { VEHICLES_FILTER_LABEL } from "pages/SignalEventsAnalytics/constants";

import DropdownSelect from "features/ui/DropdownSelect";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import FiltersSummary, {
  ViewFiltersButton,
} from "features/ui/Filters/FiltersSummary";
import FilterSelector from "features/ui/Filters/FilterWizard/FilterSelector";
import { getPendingFiltersKey } from "features/ui/Filters/FilterWizard/utils";
import { UseFilterSortState } from "features/ui/Filters/types";
import { getFilterLabel } from "features/ui/Filters/utils";

import {
  INSPECTIONS_FILTER_LABEL,
  INSPECTIONS_PAGE_KEY,
  INSPECTIONS_VEHICLES_PAGE_KEY,
} from "./constants";

// These are not in constants.ts so we avoid circular dependencies with the util being used.
export const PENDING_FILTERS_INSPECTIONS_LS_KEY =
  getPendingFiltersKey(INSPECTIONS_PAGE_KEY);

export const PENDING_FILTERS_VEHICLES_LS_KEY = getPendingFiltersKey(
  INSPECTIONS_VEHICLES_PAGE_KEY
);

interface Props {
  inspectionsFilterSortState: UseFilterSortState;
  vehiclesFilterSortState: UseFilterSortState;
  defaultInspectionFilters: FilterGroupState;
  defaultVehicleFilters: FilterGroupState;
}

const InspectionAnalyticsFilters = ({
  inspectionsFilterSortState,
  vehiclesFilterSortState,
  defaultInspectionFilters,
  defaultVehicleFilters,
}: Props) => {
  const [filterSummaryOpen, setFilterSummaryOpen] = useState(false);

  const inspectionsFiltersSchema = useInspectionsFiltersSchema();
  const vehiclesFiltersSchema = useVehiclesFiltersSchema();

  const isAdvancedInspectionsEditor =
    inspectionsFilterSortState.isAdvancedFilterEditor;
  const isAdvancedVehiclesEditor =
    vehiclesFilterSortState.isAdvancedFilterEditor;

  const inspectionFilterLabel = getFilterLabel(
    INSPECTIONS_FILTER_LABEL,
    inspectionsFilterSortState.filters
  );
  const vehiclePopulationFilterLabel = getFilterLabel(
    VEHICLES_FILTER_LABEL,
    vehiclesFilterSortState.filters
  );

  const [inspectionFilterDropdownOpen, setInspectionFilterDropdownOpen] =
    useState(false);
  const [vehicleFilterDropdownOpen, setVehicleFilterDropdownOpen] =
    useState(false);

  return (
    <div>
      <div className="flex space-x-3">
        <DropdownSelect
          testId="inspections-filters-dropdown"
          label={inspectionFilterLabel}
          open={inspectionFilterDropdownOpen}
          onOpen={(open) => {
            setInspectionFilterDropdownOpen(open);
            setVehicleFilterDropdownOpen(false);
          }}
          hasAdvancedFilters={isAdvancedInspectionsEditor}
          content={
            <FilterSelector
              schema={inspectionsFiltersSchema}
              filterSortState={inspectionsFilterSortState}
              defaultFilters={defaultInspectionFilters}
              title={INSPECTIONS_FILTER_LABEL}
              testId="inspections-filters"
              pendingFiltersKey={PENDING_FILTERS_INSPECTIONS_LS_KEY}
              baseEntityText="Inspections"
              onCloseFilters={() => {
                setInspectionFilterDropdownOpen(false);
              }}
              initialIsAdvancedFilter={
                inspectionsFilterSortState.isAdvancedFilterEditor
              }
            />
          }
        />
        <DropdownSelect
          testId="vehicles-filters-dropdown"
          label={vehiclePopulationFilterLabel}
          open={vehicleFilterDropdownOpen}
          onOpen={(open) => {
            setVehicleFilterDropdownOpen(open);
            setInspectionFilterDropdownOpen(false);
          }}
          hasAdvancedFilters={isAdvancedVehiclesEditor}
          content={
            <FilterSelector
              schema={vehiclesFiltersSchema}
              filterSortState={vehiclesFilterSortState}
              defaultFilters={defaultVehicleFilters}
              title={VEHICLES_FILTER_LABEL}
              testId="vehicles-filters"
              pendingFiltersKey={PENDING_FILTERS_VEHICLES_LS_KEY}
              baseEntityText="Vehicles"
              onCloseFilters={() => {
                setVehicleFilterDropdownOpen(false);
              }}
              initialIsAdvancedFilter={isAdvancedVehiclesEditor}
            />
          }
        />
        <ViewFiltersButton
          open={filterSummaryOpen}
          onClick={() => setFilterSummaryOpen(true)}
          onClose={() => setFilterSummaryOpen(false)}
        />
      </div>
      <FiltersSummary
        open={filterSummaryOpen}
        filterStates={[
          {
            name: INSPECTIONS_FILTER_LABEL,
            filters: inspectionsFilterSortState.filters,
            schema: inspectionsFiltersSchema,
          },
          {
            name: VEHICLES_FILTER_LABEL,
            filters: vehiclesFilterSortState.filters,
            schema: vehiclesFiltersSchema,
          },
        ]}
      />
    </div>
  );
};

export default InspectionAnalyticsFilters;
