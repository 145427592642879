import { useClaimsSchema } from "shared/schemas/claimsSchema";

import * as config from "config/config";

import { ASSOCIATED_CLAIMS_DEFAULT_GROUP_BY_SELECT_OPTION } from "./constants";

const {
  pages: { signalEventsAnalytics },
} = config.get();

export const useSignalEventsAssociatedClaimsDefaultGroupBy = () => {
  const { getDisplayLabel } = useClaimsSchema();

  if (signalEventsAnalytics?.defaultClaimGroupByAttribute) {
    return {
      id: signalEventsAnalytics.defaultClaimGroupByAttribute?.id,
      value: getDisplayLabel(
        signalEventsAnalytics.defaultClaimGroupByAttribute?.id,
        signalEventsAnalytics?.defaultClaimGroupByAttribute?.defaultLabel
      ),
    };
  }

  return ASSOCIATED_CLAIMS_DEFAULT_GROUP_BY_SELECT_OPTION;
};
