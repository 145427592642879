import { CgSpinnerTwo as LoadingIcon } from "react-icons/cg";
import MUIButton, { ButtonProps } from "@mui/material/Button";

import { TestProps } from "shared/types";

interface BaseProps {
  label?: string;
  download?: string;
  isLoading?: boolean;
  to?: string;
}

export type Props = BaseProps & ButtonProps & TestProps;

const Button = ({
  children,
  testId,
  isLoading,
  disabled,
  label,
  color = "secondary",
  ...otherProps
}: Props) => {
  const isDisabled = isLoading || disabled;
  return (
    <MUIButton
      data-testid={testId}
      color={color}
      disabled={isDisabled}
      sx={{
        // https://github.com/material-components/material-components-web/issues/4894
        whiteSpace: "nowrap",
      }}
      {...otherProps}
    >
      {children ? children : label} {isLoading && <LoadingIcon />}
    </MUIButton>
  );
};

export default Button;
