import {
  APIListValuesRequest,
  APIListValuesResponse,
  APIPaginatedRequest,
  getFetcher,
  ListAttributesRequest,
} from "shared/api/api";
import { clientV1 } from "shared/api/axios";
import { ServicePlan } from "shared/api/servicePlans/api";
import { createURL } from "shared/api/utils";

const SERVICE_RECOMMENDATIONS_BASE_ROUTE = "serviceRecommendations";

export interface ServiceRecommendation {
  VIN: string;
  servicePlanID: string;
  servicePlan: ServicePlan;
  recommendation: string;
  resolvedAt: string;
  createdAt: string;
  updatedAt: string;
}

export interface ListServiceRecommendationsRequest extends APIPaginatedRequest {
  vehiclesFilter?: string;
}

export const listServiceRecommendationsRequestURI = (
  params: ListServiceRecommendationsRequest
): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([SERVICE_RECOMMENDATIONS_BASE_ROUTE]),
    params,
  });

export const listServiceRecommendationsCountRequestURI = (
  params: ListServiceRecommendationsRequest
): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([SERVICE_RECOMMENDATIONS_BASE_ROUTE, "count"]),
    params,
  });

const listServiceRecommendationsValuesRequestURI = ({
  fieldName,
  ...params
}: APIListValuesRequest): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([SERVICE_RECOMMENDATIONS_BASE_ROUTE, "values", fieldName]),
    params,
  });

export const listServiceRecommendationsValues = (args: APIListValuesRequest) =>
  getFetcher<APIListValuesResponse>(
    listServiceRecommendationsValuesRequestURI(args)
  );

export const listServiceRecommendationsAttributesRequestURI = (
  params: ListAttributesRequest
): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([SERVICE_RECOMMENDATIONS_BASE_ROUTE, "attributes"]),
    params,
  });
