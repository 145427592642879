import { MutableRefObject, useRef } from "react";

import { EntityAttribute, VehicleAgeTimeline } from "shared/api/api";
import { NONE_EXPOSURE } from "shared/constants";
import { useClaimsSchema } from "shared/schemas/claimsSchema";
import { USE_RESOURCE_SCHEMA_MAP } from "shared/schemas/constants";
import { useInspectionsSchema } from "shared/schemas/inspectionsSchema";
import { useRepairsSchema } from "shared/schemas/repairsSchema";
import useSignalEventOccurrencesSchema from "shared/schemas/signalEventOccurrencesSchema";
import { Resource } from "shared/schemas/types";
import { sortByValue } from "shared/schemas/utils";
import useVehiclesSchema from "shared/schemas/vehiclesSchema";

import {
  ChartAction,
  SelectedChartOptions,
} from "features/ui/charts/Actions/types";
import { getAxisValue, getSelectedOptionId } from "features/ui/charts/utils";
import { SelectOption } from "features/ui/Select";

import {
  BY_VEHICLE_AGE_VEHICLE_MANUFACTURED_AT,
  MONTHS_IN_SERVICE_EXPOSURE,
  MONTHS_IN_SERVICE_EXPOSURE_BUCKET,
  TOP_CONTRIBUTORS_NONE_EXPOSURE,
} from "./constants";
import { ByVehicleAgeData } from "./types";

export const mapByVehicleAgeExposureAttributes = (
  attributes: EntityAttribute[] | undefined
): SelectOption[] => {
  if (!attributes || attributes.length === 0) {
    return [MONTHS_IN_SERVICE_EXPOSURE];
  }

  return attributes
    ?.filter((attr) => attr.byVehicleAgeExposure)
    .map(
      ({ ID, displayName }) =>
        ({
          id: ID,
          value: displayName,
          testId: `top-contrib-exposure-${ID}`,
        }) as SelectOption
    )
    .sort(sortByValue);
};

export const mapByVehicleAgeExposureBuckets = (
  attributes: EntityAttribute[] | undefined,
  byVehicleAgeExposure: string
): SelectOption[] => {
  if (!attributes || attributes.length === 0) {
    return [MONTHS_IN_SERVICE_EXPOSURE_BUCKET];
  }

  const ageExposureBuckets =
    attributes.find((attr) => attr.ID === byVehicleAgeExposure)
      ?.byVehicleAgeExposureBuckets || [];
  return ageExposureBuckets.map(
    (bucket) =>
      ({
        id: bucket,
        value: bucket,
        testId: `top-contrib-exposureBucket-${bucket}`,
      }) as SelectOption
  );
};

export const useByVehicleAgeBirthdayOptions = (): SelectOption[] => {
  const { attributes } = useVehiclesSchema();

  if (!attributes || attributes.length === 0) {
    return [BY_VEHICLE_AGE_VEHICLE_MANUFACTURED_AT];
  }

  return attributes
    .filter((attr) => attr.byVehicleAgeBirthday)
    .map(({ ID, displayName }) => ({
      id: ID,
      value: displayName,
    }))
    .sort(sortByValue);
};

export const useClaimByVehicleAgeExposureOptions = (): SelectOption[] => {
  const { attributes } = useClaimsSchema();
  return mapByVehicleAgeExposureAttributes(attributes);
};

export const useInspectionByVehicleAgeExposureOptions = (): SelectOption[] => {
  const { attributes } = useInspectionsSchema();
  return mapByVehicleAgeExposureAttributes(attributes);
};

export const useRepairByVehicleAgeExposureOptions = (): SelectOption[] => {
  const { attributes } = useRepairsSchema();
  return mapByVehicleAgeExposureAttributes(attributes);
};

export const useSEByVehicleAgeExposureOptions = (): SelectOption[] => {
  const { attributes } = useSignalEventOccurrencesSchema();
  return mapByVehicleAgeExposureAttributes(attributes);
};

interface ExposureData {
  previousExposure: MutableRefObject<string>;
  currentExposure: string;
  currentExposureBuckets: SelectOption[];
  selectedExposureForAPI: string | undefined;
  selectedExposureBucketForAPI: number | undefined;
}

export const useExposures = (
  attributes: EntityAttribute[] | undefined,
  selectedOptions: SelectedChartOptions[]
): ExposureData => {
  const previousExposure = useRef(
    getSelectedOptionId(selectedOptions, "exposure")
  );
  const currentExposure = getSelectedOptionId(selectedOptions, "exposure");
  const currentExposureBucket =
    currentExposure !== NONE_EXPOSURE
      ? getSelectedOptionId(selectedOptions, "exposureBucket")
      : undefined;
  const currentExposureBuckets =
    currentExposure !== NONE_EXPOSURE
      ? mapByVehicleAgeExposureBuckets(attributes, currentExposure)
      : [];
  const selectedExposureForAPI =
    currentExposure === NONE_EXPOSURE ? undefined : currentExposure;
  const selectedExposureBucketForAPI = currentExposureBucket
    ? parseInt(currentExposureBucket)
    : undefined;

  return {
    previousExposure,
    currentExposure,
    currentExposureBuckets,
    selectedExposureForAPI,
    selectedExposureBucketForAPI,
  };
};

export const useByVehicleAgeOptions = (
  selectedOptions: SelectedChartOptions[],
  actions: ChartAction[]
): ByVehicleAgeData => {
  const byVehicleAgeBirthday = getSelectedOptionId(selectedOptions, "x");
  const byVehicleAgeBirthdayValue = getAxisValue(
    actions,
    "x",
    byVehicleAgeBirthday
  );
  const byVehicleAgeExposure = getSelectedOptionId(selectedOptions, "exposure");
  const byVehicleAgeExposureValue = getAxisValue(
    actions,
    "exposure",
    byVehicleAgeExposure
  );
  const granularity = getSelectedOptionId(selectedOptions, "granularity");

  const yAxisKey = getSelectedOptionId(
    selectedOptions,
    "y"
  ) as keyof VehicleAgeTimeline;
  const yAxisValue = getAxisValue(actions, "y", yAxisKey);

  return {
    byVehicleAgeBirthday,
    byVehicleAgeBirthdayValue,
    byVehicleAgeExposure,
    byVehicleAgeExposureValue,
    granularity,
    yAxisKey,
    yAxisValue,
  };
};

export const useTopContributorsExposureOptions = (
  eventType: Resource
): SelectOption[] => {
  const { attributes } = USE_RESOURCE_SCHEMA_MAP[eventType]();
  return [
    ...mapByVehicleAgeExposureAttributes(attributes),
    TOP_CONTRIBUTORS_NONE_EXPOSURE,
  ];
};
