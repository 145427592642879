import { createContext, useCallback, useState } from "react";
import { StringSetter } from "duck/graph/types";
import { DUCK_MESSAGES_KEY, DUCK_WELCOME_MESSAGE } from "duck/ui/constants";

export interface DuckMessage {
  author: "human" | "agent";
  message: string;
}

interface DuckMessagesContextInterface {
  messages: DuckMessage[];
  addMessage: (message: DuckMessage) => void;
  clearMessages: () => void;
  ephemeralMessage: string;
  setEphemeralMessage: StringSetter;
}

const DEFAULT_CONTEXT: DuckMessagesContextInterface = {
  messages: [],
  addMessage: () => {},
  clearMessages: () => {},
  ephemeralMessage: "",
  setEphemeralMessage: () => {},
};

export const DuckMessagesContext =
  createContext<DuckMessagesContextInterface>(DEFAULT_CONTEXT);

interface DuckMessagesContextWrapperProps {
  children: JSX.Element;
}

const getInitialMessages = (): DuckMessage[] => {
  try {
    const messagesString = sessionStorage.getItem(DUCK_MESSAGES_KEY);
    if (messagesString) {
      return JSON.parse(messagesString);
    }
    return [DUCK_WELCOME_MESSAGE];
  } catch (error) {
    return [DUCK_WELCOME_MESSAGE];
  }
};

/**
 * @summary This context manages the messages in a Duck session.
 * These messages are visualized in the UI and are also sent with each request to the agent.
 * @returns The context provides a list of messages, a function to add a message,
 * and a function to clear all messages.
 */
const DuckMessagesContextWrapper = ({
  children,
}: DuckMessagesContextWrapperProps) => {
  const [messages, setMessages] = useState<DuckMessage[]>(getInitialMessages());
  const [ephemeralMessage, setEphemeralMessage] = useState("");

  const addMessage = useCallback((message: DuckMessage) => {
    setMessages((previousMessages) => {
      const newMessages = [...previousMessages, message];
      sessionStorage.setItem(DUCK_MESSAGES_KEY, JSON.stringify(newMessages));
      return newMessages;
    });
  }, []);

  const clearMessages = useCallback(() => {
    setMessages([DUCK_WELCOME_MESSAGE]);
    sessionStorage.removeItem(DUCK_MESSAGES_KEY);
  }, []);

  const contextValue: DuckMessagesContextInterface = {
    messages,
    addMessage,
    clearMessages,
    ephemeralMessage,
    setEphemeralMessage,
  };

  return (
    <DuckMessagesContext.Provider value={contextValue}>
      {children}
    </DuckMessagesContext.Provider>
  );
};

export default DuckMessagesContextWrapper;
