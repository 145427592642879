import qs from "qs";
import { BsQuestionCircle as QMarkIcon } from "react-icons/bs";
import { Link } from "react-router-dom";

import { DOCS_PRODUCT_GUIDE_TAB_KEY } from "pages/Docs/Docs";

import { routes } from "services/routes";

export interface TitleProps {
  text: string;
  docsSectionId?: string;
}

const Title = ({ text, docsSectionId }: TitleProps) => {
  return (
    <h1 data-testid="v-title">
      <span className="text-2xl font-semibold">{text}</span>

      {/* Add docs link only if we can link to relevant section and FF is enabled */}
      {docsSectionId && <DocsSectionLink docsSectionId={docsSectionId} />}
    </h1>
  );
};

const QIcon = () => (
  <div className="flex items-center space-x-1 text-sm transition-colors text-gray-300 hover:text-blue-400">
    <QMarkIcon size="18" />
  </div>
);

const DocsSectionLink = ({ docsSectionId }: { docsSectionId: string }) => (
  <Link
    to={{
      pathname: routes.docs,
      search: qs.stringify({ tab: DOCS_PRODUCT_GUIDE_TAB_KEY }),
      hash: docsSectionId,
    }}
    className="inline-block ml-3"
    data-testid="v-title_help"
  >
    <QIcon />
  </Link>
);

export default Title;
