import {
  useByVehicleAgeBirthdayOptions,
  useSEByVehicleAgeExposureOptions,
} from "pages/hooks";
import { getByVehicleAgeChartOptions } from "pages/utils";

import { cumulativeDPTV, cumulativeDPTVDV } from "features/ui/charts/actions";
import { ChartAction } from "features/ui/charts/Actions/types";
import { Option } from "features/ui/Select";

export const useGetByVehicleAgeChartActions = (): ChartAction<Option>[] =>
  getByVehicleAgeChartOptions(
    [cumulativeDPTV, cumulativeDPTVDV],
    useByVehicleAgeBirthdayOptions(),
    useSEByVehicleAgeExposureOptions()
  );
