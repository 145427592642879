import { FailureMode, Predictions } from "shared/api/v0_failureModes/api";
import { VEHICLES_GENERIC_FILTER } from "shared/filterDefinitions";
import { intersection } from "shared/utils";

import { SchemaEntry } from "features/ui/Table";
import { DataType } from "features/ui/Table/TableBodyCell";

import {
  repairStatusDescription,
  riskGroupDescription,
  riskMultipleDescription,
  riskStatusDescription,
} from "./content";
import RiskBadge from "./RiskBadge";

export const getFailureModePredictionAccessor = (
  ID: string,
  property: string
) => `predictions.${ID}.${property}`;

export const getAccessorsForVisibleFMColumns = (
  allFailureModesIds: string[],
  visibleColumns: string[],
  additionalColumnsOptionsValues: string[]
): string[] => {
  const failureModeColumnsToShow = intersection(
    allFailureModesIds,
    visibleColumns
  );

  const failureModePropsToShow = intersection(
    additionalColumnsOptionsValues,
    visibleColumns
  );

  return failureModeColumnsToShow.reduce((acc, fmId) => {
    return failureModePropsToShow.reduce((accessors, prop) => {
      accessors.push(getFailureModePredictionAccessor(fmId, prop));
      return accessors;
    }, acc);
  }, []);
};

export const formatFailureModeToTableSchema = (
  { name, ID }: FailureMode,
  showColumnSettings?: boolean
): SchemaEntry[] => {
  const riskGroupAccessor = getFailureModePredictionAccessor(ID, "riskGroup");

  const riskStatusAccessor = getFailureModePredictionAccessor(ID, "riskStatus");

  const riskMultipleAccessor = getFailureModePredictionAccessor(
    ID,
    "riskMultiple"
  );

  const repairStatusAccessor = getFailureModePredictionAccessor(
    ID,
    "repairStatus"
  );

  const repairDetailsAccessor = getFailureModePredictionAccessor(
    ID,
    "repairDetails"
  );

  return [
    {
      label: "Risk Group",
      accessor: riskGroupAccessor,
      dataType: DataType.JSX,
      toggleable: showColumnSettings,
      sortable: true,
      description: riskGroupDescription,
      filter: VEHICLES_GENERIC_FILTER({
        label: `${name}: Risk Group`,
        fieldName: riskGroupAccessor,
      }),
    },
    {
      label: "Risk Status",
      accessor: riskStatusAccessor,
      dataType: DataType.STRING,
      toggleable: showColumnSettings,
      description: riskStatusDescription,
      filter: VEHICLES_GENERIC_FILTER({
        label: `${name}: Risk Status`,
        fieldName: riskStatusAccessor,
      }),
    },
    {
      label: "Risk Multiple",
      accessor: riskMultipleAccessor,
      dataType: DataType.NUMBER,
      toggleable: showColumnSettings,
      sortable: true,
      description: riskMultipleDescription,
    },
    {
      label: "Repair Status",
      accessor: repairStatusAccessor,
      dataType: DataType.STRING,
      toggleable: showColumnSettings,
      description: repairStatusDescription,
      filter: VEHICLES_GENERIC_FILTER({
        label: `${name}: Repair Status`,
        fieldName: repairStatusAccessor,
      }),
    },
    {
      label: "Repair Details",
      accessor: repairDetailsAccessor,
      dataType: DataType.STRING,
      toggleable: showColumnSettings,
      filter: VEHICLES_GENERIC_FILTER({
        label: `${name}: Repair Details`,
        fieldName: repairDetailsAccessor,
      }),
    },
  ];
};

export const getPredictionsFormatted = (
  predictions: Predictions
): Predictions => {
  if (!predictions) return {};
  return Object.keys(predictions).reduce((prevVal, ID) => {
    return {
      ...prevVal,
      [ID]: {
        ...predictions[ID],
        riskGroup: <RiskBadge risk={predictions[ID].riskGroup} />,
      },
    };
  }, {});
};

export const getColumnsToShow = (schema: SchemaEntry[], columns?: string[]) => {
  const columnsToShow = columns
    ? schema.filter((s) => columns?.includes(s.accessor))
    : schema;

  return columnsToShow.map(({ accessor }) => accessor);
};

export const getTablePageKey = (pageKey: string) => `${pageKey}_table`;
