import { BsCircleFill } from "react-icons/bs";
import { Stack } from "@mui/material";

const ThoughtBubbleTail = () => (
  <Stack direction="column" alignContent="center">
    <BsCircleFill className="text-gray-200 w-1.5 h-1.5 mr-0.5" />
    <BsCircleFill className="text-gray-200 w-1 h-1 mr-0.5" />
    <BsCircleFill className="text-gray-200 w-0.5 h-0.5" />
  </Stack>
);

export default ThoughtBubbleTail;
