import { Claim } from "shared/api/claims/api";
import { useClaimsSchema } from "shared/schemas/claimsSchema";
import { formatNumber } from "shared/utils";

import CardHeader from "pages/Issues/Details/CardHeader";

import Card from "features/ui/Card";
import LabelValuePairs from "features/ui/LabelValuePairs";
import StringList from "features/ui/StringList";
import { SchemaEntry } from "features/ui/Table";

interface Props {
  data: Claim;
}

const CARD_TITLE = "Repair Details";

const DISPLAY_FIELDS: string[] = [
  "repairDetail",
  "laborCode",
  "laborCodeInformation.description",
  "failedPartNumber",
  "failedPartInformation.description",
  "date",
  "complaintDetail",
  "parts",
  "repairOrderNumber",
  "campaignID",
  "dealer",
  "monthsInService",
  "daysInService",
  "componentDetail",
  "causalDetail",
  "causalCode",
  "binIssue",
  "group",
  "subgroup",
  "transactionCategory",
  "mileage",
];

const CardRepairDetails = ({ data }: Props) => {
  const { schema, getDisplayLabel } = useClaimsSchema();

  const formatRow = (row: Claim) => {
    const { mileage, mileageUnit, dealer, parts } = row;
    return {
      ...row,
      mileage: mileage && `${formatNumber(mileage)} ${mileageUnit}`,
      dealer: dealer && dealer.name,
      parts:
        parts.length > 0 ? (
          <StringList
            title={getDisplayLabel("parts", "Non-Causal Parts")}
            values={parts}
            maxVisible={1}
          />
        ) : undefined,
    };
  };

  const formattedData = formatRow(data);

  return (
    <Card>
      <CardHeader title={CARD_TITLE} />
      <LabelValuePairs
        schema={schema as SchemaEntry<keyof Claim>[]}
        data={formattedData}
        fields={DISPLAY_FIELDS}
      />
    </Card>
  );
};

export default CardRepairDetails;
