/**
 * Since we do not have /attributes API for failure modes, we
 * are using the failure modes API to get the failure modes and
 * then build schemas manually.
 */

import { useFlags } from "launchdarkly-react-client-sdk";

import { useFailureModes } from "shared/api/failureModes/hooks";
import { getSortFilter } from "shared/api/utils";
import { FAILURE_MODE_RISK_MODEL_PREDICTIONS_GENERIC_FILTER } from "shared/filterDefinitions";

import { DEFAULT_NON_ARCHIVED_FAILURE_MODES_FILTER } from "pages/FailureModes/constants";

import {
  filterStateToFilterGroupState,
  getFiltersQuery,
} from "features/ui/Filters/FilterBuilder/utils";
import { FilterSchemaItem } from "features/ui/Filters/types";
import { SchemaEntry } from "features/ui/Table";
import { DataType } from "features/ui/Table/TableBodyCell";

import { useRiskModelPredictionsSchema } from "./riskModelPredictionsSchema";
import { getFilterPropertiesFromAttribute } from "./utils";

const DISPLAYED_FIELDS = ["riskStatus", "riskScore", "riskLabel"];
const RISK_MODEL_PREDICTION_PREFIX = "riskModelPrediction";

const useFailureModesRiskModelPredictionsSchema = (): SchemaEntry[] => {
  const { failureModesV1 } = useFlags();
  const { data: failureModesData } = useFailureModes({
    skipRequest: !failureModesV1,
    sort: getSortFilter({ status: "desc" }),
    filter: getFiltersQuery(
      filterStateToFilterGroupState(DEFAULT_NON_ARCHIVED_FAILURE_MODES_FILTER)
    ),
  });

  const { attributes } = useRiskModelPredictionsSchema(
    (item: FilterSchemaItem) =>
      FAILURE_MODE_RISK_MODEL_PREDICTIONS_GENERIC_FILTER("", item)
  );

  if (!failureModesData || failureModesData.length === 0) {
    return [];
  }

  return failureModesData.reduce((acc, failureMode) => {
    return [
      ...acc,
      ...(attributes || [])
        .filter((attribute) => DISPLAYED_FIELDS.includes(attribute.ID))
        .map((attribute) => ({
          label: `${failureMode.name} ${attribute.displayName}`,
          accessor: `${RISK_MODEL_PREDICTION_PREFIX}.${failureMode.ID}.${attribute.ID}`,
          dataType: DataType.STRING,
          filter: FAILURE_MODE_RISK_MODEL_PREDICTIONS_GENERIC_FILTER(
            failureMode.ID,
            {
              label: `${failureMode.name}: ${attribute.displayName}`,
              fieldName: `${RISK_MODEL_PREDICTION_PREFIX}.${failureMode.ID}.${attribute.ID}`,
              fieldNameForAPI: attribute.ID,
              ...getFilterPropertiesFromAttribute(attribute),
            }
          ),
          dropdownGroup: "Failure Modes",
        })),
    ];
  }, [] as SchemaEntry[]);
};

export default useFailureModesRiskModelPredictionsSchema;
