import React from "react";
import { MdPersonOutline as PersonIcon } from "react-icons/md";

import Input from "features/ui/Input";

interface Props {
  ID: string;
  newEntry: boolean;
  email: string;
  myEmail: string;
  canEdit: boolean;
  onUpdateEmail: (email: string) => void;
}

const UserEmail = ({
  ID,
  newEntry,
  email,
  myEmail,
  canEdit,
  onUpdateEmail,
}: Props) => {
  if (!ID) {
    return (
      <Input
        label=""
        onChange={({ target: { value } }) => {
          onUpdateEmail(value);
        }}
        type="email"
        value={email}
        placeholder="email@company.com"
        disabled={!canEdit}
        testId="user-email-input"
      />
    );
  }

  const myEmailText = email === myEmail ? `${email} (Me)` : email;

  return (
    <span className="flex items-center">
      <PersonIcon size="2em" className="m-1.5" />
      {myEmailText}
      {newEntry && <span className="ml-2 text-green-500">New</span>}
    </span>
  );
};

export default UserEmail;
