import {
  loadSensorReadingsValuesFunc,
  loadSensorsFunc,
  transformInitialSelectedSensors,
} from "shared/api/filter.utils";
import { APIFilter } from "shared/api/utils";
import { SENSORS_LIMIT } from "shared/constants";

import { FILTER_OPERATOR_TO_API_FILTER } from "features/ui/Filters/constants";
import { getTopLevelRowFromFilterGroupState } from "features/ui/Filters/FilterBuilder/utils";
import SelectFilter from "features/ui/Filters/FilterTypes/SelectFilter";
import { FilterOperator, UseFilterSortState } from "features/ui/Filters/types";
import { SelectOption } from "features/ui/Select";

const MAX_SELECTED_SENSORS = 2;
const TRIGGERS_LIMIT = 1000;
const MAX_DATA_POINTS_DISPLAYED = 1000; // this is the same as sensorReadingsTimelineResponseLimit on the API

interface Props {
  vin: string;
  filterState: UseFilterSortState;
}

const SensorAndTriggerFilters = ({ vin, filterState }: Props) => {
  const childRowSensorID = getTopLevelRowFromFilterGroupState(
    "sensorID",
    filterState.filters
  );
  const childRowTrigger = getTopLevelRowFromFilterGroupState(
    "trigger",
    filterState.filters
  );

  const hasSensorIDFilter = childRowSensorID
    ? childRowSensorID.values.length > 0
    : false;

  const staticTriggerFilters: APIFilter[] | undefined =
    childRowSensorID && hasSensorIDFilter
      ? [
          {
            name: "sensorID",
            op: FILTER_OPERATOR_TO_API_FILTER[childRowSensorID.operator],
            value: childRowSensorID.values,
          },
          {
            name: "VIN",
            op: FilterOperator.EQUALS,
            value: [vin],
          },
        ]
      : undefined;

  const initialSelectedSensors: SelectOption[] = childRowSensorID
    ? childRowSensorID.values.map((v) => ({ id: v, value: v }))
    : [];

  const initialSelectedTriggers: SelectOption[] = childRowTrigger
    ? childRowTrigger.values.map((v) => ({ id: v, value: v }))
    : [];

  return (
    <div className="flex space-x-2 items-end">
      <SelectFilter
        label="Sensors"
        fieldName="sensorID"
        onFilterChange={filterState.manageFilterChange}
        search={false}
        initialSelected={initialSelectedSensors}
        loadDataOnOpen={true}
        loadOptionsFunc={loadSensorsFunc}
        transformInitialSelectedFunc={transformInitialSelectedSensors}
        multiple={true}
        maxValues={MAX_SELECTED_SENSORS}
        placeholder={`Select up to ${MAX_SELECTED_SENSORS} sensors`}
        description={`You can select up to ${MAX_SELECTED_SENSORS} sensors. Maximum of ${MAX_DATA_POINTS_DISPLAYED} most recent data points will be displayed on the chart.`}
        defaultLoadLimit={SENSORS_LIMIT}
        fullWidth={false}
      />
      {hasSensorIDFilter && (
        <SelectFilter
          label="Triggers"
          fieldName="trigger"
          onFilterChange={filterState.manageFilterChange}
          search={true}
          initialSelected={initialSelectedTriggers}
          multiple={true}
          staticFilters={staticTriggerFilters}
          loadDataOnOpen={true}
          loadOptionsFunc={loadSensorReadingsValuesFunc}
          placeholder="Select triggers"
          defaultLoadLimit={TRIGGERS_LIMIT}
          fullWidth={false}
        />
      )}
    </div>
  );
};
export default SensorAndTriggerFilters;
