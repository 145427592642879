import { useEffect, useState } from "react";
import DAFCF from "imgs/daf/DAF-CF.png";
import DAFXD from "imgs/daf/DAF-XD.png";
import DAFXF from "imgs/daf/DAF-XF.png";
import DAFXG from "imgs/daf/DAF-XG.png";
import DAFXGPlus from "imgs/daf/DAF-XG+.png";
import GenericCar from "imgs/generic-car.png";
import BuickEnclaveImage from "imgs/gm/buick_enclave.png";
import ChevroletBlazerImage from "imgs/gm/chevrolet_blazer.png";
import ChevroletColoradoImage from "imgs/gm/chevrolet_colorado.png";
import ChevroletTraverseImage from "imgs/gm/chevrolet_traverse.png";
import GMCArcadiaImage from "imgs/gm/gmc_arcadia.png";
import GMCCanyonImage from "imgs/gm/gmc_canyon.png";
import KenworthImage from "imgs/Kenworth.jpg";
import NikolaImage from "imgs/Nikola.png";
import NissanARIYA from "imgs/NissanARIYA.png";
import NissanPATHFINDER from "imgs/NissanPATHFINDER.png";
import NissanQX60 from "imgs/NissanQX60.png";
import NissanROGUE from "imgs/NissanROGUE.png";
import PeterbiltImage from "imgs/Peterbilt.png";

import { getTransportCategory } from "shared/api/api";
import { Vehicle } from "shared/api/vehicles/api";
import { useVehicle } from "shared/api/vehicles/hooks";
import { getTenantMileageUnit } from "shared/utils";

// useVehicleImage is a fake hook for
// getting the image associated with a vehicle
export const useVehicleImage = (data: Partial<Vehicle> = {}): string => {
  let { vehicleMake, vehicleModel } = data;

  // handle missing values
  if (!vehicleMake) {
    vehicleMake = "";
  }
  if (!vehicleModel) {
    vehicleModel = "";
  }

  // TODO: let's add all these to tenant config at one point
  // and use a CDN or API photo

  // PACCAR custom images
  if (vehicleMake.toLowerCase() === "peterbilt") {
    return PeterbiltImage;
  }
  if (vehicleMake.toLowerCase() === "kenworth") {
    return KenworthImage;
  }

  // GM custom images
  if (vehicleMake === "Chevrolet" && vehicleModel === "Colorado") {
    return ChevroletColoradoImage;
  }
  if (vehicleMake === "Chevrolet" && vehicleModel === "Blazer") {
    return ChevroletBlazerImage;
  }
  if (vehicleMake === "Chevrolet" && vehicleModel === "Traverse C1") {
    return ChevroletTraverseImage;
  }
  if (vehicleMake === "Buick" && vehicleModel === "Enclave C1") {
    return BuickEnclaveImage;
  }
  if (vehicleMake === "GMC" && vehicleModel === "Acadia C1") {
    return GMCArcadiaImage;
  }
  if (vehicleMake === "GMC" && vehicleModel === "Canyon") {
    return GMCCanyonImage;
  }

  // Nissan custom images
  if (vehicleMake.toLowerCase() === "nissan" && vehicleModel === "ROGUE") {
    return NissanROGUE;
  }
  if (vehicleMake.toLowerCase() === "nissan" && vehicleModel === "ARIYA") {
    return NissanARIYA;
  }
  if (vehicleMake.toLowerCase() === "nissan" && vehicleModel === "PATHFINDER") {
    return NissanPATHFINDER;
  }
  if (vehicleMake.toLowerCase() === "infiniti" && vehicleModel === "QX60") {
    return NissanQX60;
  }

  // DAF custom images
  if (vehicleModel === "CF") {
    return DAFCF;
  }
  if (vehicleModel === "XD") {
    return DAFXD;
  }
  if (vehicleModel === "XF") {
    return DAFXF;
  }
  if (vehicleModel === "XG") {
    return DAFXG;
  }
  if (vehicleModel === "XG+") {
    return DAFXGPlus;
  }

  // Nikola custom images
  if (vehicleMake === "NikolaTruck") {
    return NikolaImage;
  }

  return GenericCar;
};

export const useVehicleTransportCategoryDisplayName = (
  vin: string
): string | undefined => {
  const [transportCategory, setTransportCategory] = useState<string>();
  const { data } = useVehicle({ vin, mileageUnit: getTenantMileageUnit() });

  // when vin changed and thus inferred category, reset inferred category first
  useEffect(() => {
    setTransportCategory("");
  }, [vin]);

  useEffect(() => {
    if (!data) return;

    const { transportCategoryID } = data;

    if (!transportCategoryID) return;

    getTransportCategory({ id: transportCategoryID })
      .then(({ data: { name } }) => setTransportCategory(name))
      .catch((err) => {
        console.error(err);
      });
  }, [data]);

  return transportCategory;
};
