import { VoidFunctionComponent } from "react";

import { getValueByAccessor } from "features/ui/Table/utils";

export type InfoDataMapType = "number" | "date" | "percent" | "jsx";
export type InfoDataMapComponent = VoidFunctionComponent<{ value: InfoValue }>;
export type InfoValue = string | number | null | string[];

export interface InfoDataMap {
  label: string;
  key: string;
  whitelistKey?: string;
  description?: string;
  type?: InfoDataMapType;
  // there is definitely a better way to type this,
  // such that component is only defined IF type='jsx'
  Component?: InfoDataMapComponent;
  suffix?: string;
}

export interface InfoCardMap {
  title: string;
  data: InfoDataMap[];
}

export const shouldDisplayInfoCard = (
  schema: InfoDataMap[],
  whitelistedAttributes?: string[],
  data?: Record<string, any>
) => {
  if (!data) {
    return false;
  }
  return schema.some((schemaItem: InfoDataMap) => {
    if (
      schemaItem.whitelistKey &&
      !whitelistedAttributes?.includes(schemaItem.whitelistKey)
    ) {
      return false;
    }
    if (
      !schemaItem.whitelistKey &&
      !whitelistedAttributes?.includes(schemaItem.key)
    ) {
      return false;
    }

    const value = getValueByAccessor(schemaItem.key, data);
    if (Array.isArray(value) && value.length < 1) {
      return false;
    }
    return !["", undefined, null].includes(value as any);
  });
};
