import { useCallback } from "react";

import { useSignalEventOccurrencesFiltersSchema } from "shared/hooks";

import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import FilterWizard from "features/ui/Filters/FilterWizard";
import { hasSomeFiltersAppliedFilterGroupState } from "features/ui/Filters/utils";
import { StepProps } from "features/ui/Stepper";

import FormErrorBox from "./FormErrorBox";
import SignalEventOccurrencesCount from "./SignalEventOccurrencesCount";

export const PAGE_KEY = "issue-signal-event-filter-selection";

const StepSignalEvents = ({
  data,
  onDataUpdated,
  disableUsingLocalStorage,
}: StepProps) => {
  const onFiltersChange = useCallback((filters: FilterGroupState) => {
    onDataUpdated && onDataUpdated({ signalEventOccurrencesFilter: filters });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const schema = useSignalEventOccurrencesFiltersSchema();

  if (!data) return null;

  const currentFilters: FilterGroupState = data.signalEventOccurrencesFilter;
  const controlPopulationFilter: FilterGroupState =
    data.comparisonPopulationFilter;
  const atRiskPopulationFilter: FilterGroupState = data.atRiskPopulationFilter;

  const showSignalEventsCount =
    hasSomeFiltersAppliedFilterGroupState(currentFilters);

  const showAtRiskPopulationFilterCount = hasSomeFiltersAppliedFilterGroupState(
    atRiskPopulationFilter
  );

  const showControlPopulationFilterCount =
    hasSomeFiltersAppliedFilterGroupState(controlPopulationFilter);

  const showErrorBox =
    !showSignalEventsCount &&
    !hasSomeFiltersAppliedFilterGroupState(data.claimFilter);

  return (
    <>
      {showErrorBox && (
        <FormErrorBox
          title="Define Signal Events or Claims"
          text="To create an issue, you must define either a set
        of signal event occurrences or a set of claims."
        />
      )}
      <FilterWizard
        schema={schema}
        initialFilters={currentFilters}
        onFiltersChange={onFiltersChange}
        disableUsingLocalStorage={disableUsingLocalStorage}
        pageKey={PAGE_KEY}
        testId={PAGE_KEY}
        belowRightBlockContent={
          showSignalEventsCount && (
            <div className="flex flex-col">
              {showAtRiskPopulationFilterCount && (
                <SignalEventOccurrencesCount
                  label="Signal event occurrences in at-risk population"
                  filters={currentFilters}
                  vehicleFilters={atRiskPopulationFilter}
                />
              )}
              {showControlPopulationFilterCount && (
                <SignalEventOccurrencesCount
                  label="Signal event occurrences in comparison population"
                  filters={currentFilters}
                  vehicleFilters={controlPopulationFilter}
                />
              )}
            </div>
          )
        }
      />
    </>
  );
};

export default StepSignalEvents;
