import { RepairsFiltersProps } from "pages/RepairAnalytics/RepairAnalyticsTabs";
import MetricsCards from "pages/RepairAnalytics/tabPages/Repairs/MetricsCards";

import RepairsChart from "./RepairsChart";
import RepairsTable from "./RepairsTable";

const Repairs = ({
  repairsFilters,
  vehiclesFilters,
  onBadRequest,
}: RepairsFiltersProps) => (
  <div className="mt-5">
    <div className="flex flex-col lg:flex-row space-y-5 lg:space-y-0 lg:space-x-5">
      <div className="grow">
        <RepairsChart
          repairsFilters={repairsFilters}
          vehiclesFilters={vehiclesFilters}
          onBadRequest={onBadRequest}
        />
      </div>
      <MetricsCards
        repairsFilters={repairsFilters}
        vehiclesFilters={vehiclesFilters}
        onBadRequest={onBadRequest}
      />
    </div>
    <RepairsTable
      repairsFilters={repairsFilters}
      vehiclesFilters={vehiclesFilters}
      onBadRequest={onBadRequest}
    />
  </div>
);

export default Repairs;
