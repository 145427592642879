import { useEffect, useState } from "react";
import { AiOutlineWarning as WarningIcon } from "react-icons/ai";
import { toast } from "react-toastify";

import { deleteCollection } from "shared/api/api";

import ConfirmationModal from "features/ui/ConfirmationModal";

const CONFIRM_TEXT = "Yes, delete";

interface Props {
  isOpen: boolean;
  collectionId: string;
  collectionName: string;
  onClose: () => void;
  onSubmit: () => void;
}

const ConfirmationQuestion = ({ name }: { name: string }) => (
  <span>
    Please confirm you want to delete <strong>{name}</strong>.
  </span>
);

const getErrorText = (name: string, code?: string) => {
  if (code === "0005") {
    return (
      <span>
        <WarningIcon
          className="inline align-bottom text-red-400 mr-1"
          size={25}
        />
        <strong>{name}</strong> cannot be deleted because it is used elsewhere
        in the application.
      </span>
    );
  }
  return (
    <span>
      Unfortunately we could not remove <strong>{name}</strong>. Please try
      later or contact us directly.
    </span>
  );
};

const DeleteCollectionModal = ({
  isOpen,
  collectionId,
  collectionName,
  onClose,
  onSubmit,
}: Props) => {
  const [hideModalCta, setHideModalCta] = useState(false);
  const [modalText, setModalText] = useState<string | JSX.Element>("");
  const [modalTitle, setModalTitle] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setHideModalCta(false);
      setModalText(<ConfirmationQuestion name={collectionName} />);
      // Sets the default title which is handled within ConfirmationModal
      setModalTitle(undefined);
    }
  }, [isOpen, collectionName]);

  const handleConfirmModalClose = (confirm: boolean) => {
    if (!confirm) {
      onClose();
      return;
    }
    handleDeleteConfirm();
  };

  const handleDeleteConfirm = () => {
    setIsLoading(true);
    deleteCollection({ id: collectionId })
      .then(() => {
        onSubmit();
      })
      .catch(
        ({
          response: {
            data: { code },
          },
        }) => {
          toast.error(getErrorText(collectionName, code));
        }
      )
      .finally(() => {
        setModalTitle("");
        setIsLoading(false);
        onClose();
      });
  };

  return (
    <ConfirmationModal
      confirmText={CONFIRM_TEXT}
      hideCTA={hideModalCta}
      loading={isLoading}
      text={modalText}
      title={modalTitle}
      isOpen={isOpen}
      onClose={handleConfirmModalClose}
    />
  );
};

export default DeleteCollectionModal;
