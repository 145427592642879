import React from "react";
import Skeleton from "react-loading-skeleton";

import { UseAPIState } from "shared/api/hooks";
import {
  IssueChart,
  IssueChartType,
  IssueTypes,
  IssueVehiclePopulation,
} from "shared/types";

import {
  getBaseAPIRoute,
  getIssueCombinedID,
  getKeysAndLabels,
  getTooltipProps,
  getXAxisProps,
} from "pages/Issues/utils";

import APIError from "features/ui/APIError";
import LineChart from "features/ui/charts/LineChart/LineChart";
import { YAxisLine } from "features/ui/charts/types";

import { DataLoadFuncParams, getChartData } from "./utils";

const MARGIN = { right: 3 };

interface Props {
  issue: IssueTypes;
  population?: IssueVehiclePopulation;
  yAxisWithPrecedingKey?: string;
  yAxisLabel: string;
  yAxisLabelRight?: string;
  chartType: IssueChartType;
  chart: IssueChart;
  dataLoadHook: (props: DataLoadFuncParams) => UseAPIState<any>;
  dataLoadHookParams?: Record<string, any>;
  syncId?: number | string;
  timePeriod?: number;
  valueFormatter?: (val: any) => {};
  tooltipValueFormatter?: (val: any) => {};
  tooltipLabelFormatter?: (val: any) => {};
  yAxisLines: YAxisLine[];
  xAxisLabel?: string;
  exposure?: string;
}

const MultipleLinesChart = ({
  issue,
  population = "atRisk",
  yAxisWithPrecedingKey,
  yAxisLabel,
  yAxisLabelRight,
  chartType,
  chart,
  dataLoadHook,
  dataLoadHookParams,
  syncId,
  timePeriod,
  valueFormatter,
  tooltipValueFormatter,
  tooltipLabelFormatter,
  yAxisLines,
  xAxisLabel,
  exposure,
}: Props) => {
  const { updatedAt } = issue;

  const { data, isLoading, error } = dataLoadHook({
    IDs: getIssueCombinedID(issue),
    baseRoute: getBaseAPIRoute(issue),
    vehiclePopulation: population,
    signalEventsTimeWindow: timePeriod,
    ...dataLoadHookParams,
    updatedAt,
  });

  if (isLoading) {
    return <Skeleton height={300} />;
  }

  if (error) {
    return <APIError error={error} />;
  }

  if (!data || data.length === 0) {
    return <div className="text-gray-400 text-sm">No data.</div>;
  }

  const {
    xAxisKey,
    xAxisLabel: xLabel,
    tooltipLabel,
  } = getKeysAndLabels(chartType, chart, xAxisLabel, exposure);

  const chartData = getChartData(
    chart,
    data,
    xAxisKey,
    "",
    [],
    yAxisWithPrecedingKey,
    valueFormatter,
    undefined,
    yAxisLines?.map((v) => v.key)
  );

  const tooltipProps: Record<string, any> = {};
  if (tooltipValueFormatter) {
    tooltipProps.formatter = tooltipValueFormatter;
  }
  if (tooltipValueFormatter) {
    tooltipProps.labelFormatter = tooltipLabelFormatter;
  }

  const xAxisProps = getXAxisProps(chartType, chartData, exposure);
  const staticTooltipProps = getTooltipProps(chartType, tooltipLabel);

  return (
    <LineChart
      height={400}
      width="100%"
      data={chartData}
      xAxisKey={xAxisKey}
      xAxisLabel={xLabel}
      xAxisProps={xAxisProps}
      yAxisLabel={yAxisLabel}
      yAxisLines={yAxisLines}
      yAxisLabelRight={yAxisLabelRight}
      tooltipProps={{
        ...staticTooltipProps,
        ...tooltipProps,
      }}
      margin={MARGIN}
      syncId={syncId}
      enableZoom={true}
    />
  );
};

export default MultipleLinesChart;
