interface Props {
  children: React.ReactNode;
}

const PageHeaderActionsWrapper = ({ children }: Props) => (
  <div className="flex items-center flex-nowrap ml-auto space-x-2">
    {children}
  </div>
);

export default PageHeaderActionsWrapper;
