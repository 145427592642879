import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";

import { useIssue } from "shared/api/issues/hooks";

import APIError from "features/ui/APIError";
import PageHeaderActionsWrapper from "features/ui/PageHeaderActionsWrapper";
import PageHeaderWrapper from "features/ui/PageHeaderWrapper";
import Title from "features/ui/Title";

import IssueActions from "./IssueActions";
import IssueDetails from "./IssueDetails";
import IssueTabs from "./IssueTabs";

type Params = {
  id: string;
};

const Issue = () => {
  const { id } = useParams<Params>();

  const {
    data,
    isLoading,
    error,
    requestKey: issueRequestKey,
  } = useIssue({
    id: id as string,
  });

  if (error) {
    return <APIError error={error} />;
  }

  if (isLoading) {
    return <Skeleton height={300} count={2} />;
  }

  if (!data) {
    return (
      <div className="py-4 text-gray-400 text-sm">No data for Issue {id}.</div>
    );
  }

  return (
    <>
      <PageHeaderWrapper>
        <Title text={data.name} />
        <PageHeaderActionsWrapper>
          <IssueActions issue={data} issueRequestKeys={[issueRequestKey]} />
        </PageHeaderActionsWrapper>
      </PageHeaderWrapper>
      <IssueDetails issue={data} issueRequestKeys={[issueRequestKey]} />
      <IssueTabs issue={data} />
    </>
  );
};

export default Issue;
