import { LC_API_KEY, LC_ENDPOINT } from "duck/graph/constants";
import * as hub from "langchain/hub";
import { ChatPromptTemplate } from "@langchain/core/prompts";

import { PromptName, PromptTag, tagMapping } from "./types";

const cachedPrompts: Record<PromptName, ChatPromptTemplate> = {} as Record<
  PromptName,
  ChatPromptTemplate
>;

const loadPrompt = async (
  promptName: PromptName
): Promise<ChatPromptTemplate> => {
  // caching using the prompt name is sufficient since the environment will always be consistent during runtime.
  if (cachedPrompts[promptName]) {
    return cachedPrompts[promptName];
  }

  // load the prompt based on the environment
  const tag: PromptTag = tagMapping[process.env.NODE_ENV];
  const promptNameWithTag = `${promptName}:${tag}`;
  const prompt = await hub.pull<ChatPromptTemplate>(promptNameWithTag, {
    apiKey: LC_API_KEY,
    apiUrl: LC_ENDPOINT,
  });

  cachedPrompts[promptName] = prompt;
  return prompt;
};

export default loadPrompt;
