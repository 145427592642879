import axios, { AxiosRequestHeaders } from "axios";

import { oktaAuth } from "services/okta";
import * as config from "config/config";

const client = axios.create({
  baseURL: config.get().api.url,
});

// temporary client until we version our endpoint and
// remove version from endpoint config.
const clientWithoutV0Prefix = axios.create({
  baseURL: config.get().api.url.replace("/v0", ""),
});

const clientV1 = axios.create({
  baseURL: config.get().api.url.replace("/v0", "/v1"),
});

interface AuthHeaders {
  Authorization: string;
}

const defaultHeaders = {
  "Content-Type": "application/json",
};

const getAuthHeaders = (): AuthHeaders => {
  const token = oktaAuth.getAccessToken();

  if (!token) {
    console.error("missing user access token");
  }

  return {
    Authorization: `Bearer ${token}`,
  };
};

client.interceptors.request.use((config) => ({
  ...config,
  headers: {
    ...defaultHeaders,
    ...getAuthHeaders(),
    ...config.headers,
  } as AxiosRequestHeaders,
  withCredentials: true,
}));

//see getOpenAPISpec() where we use this client..
clientWithoutV0Prefix.interceptors.request.use((config) => ({
  ...config,
  headers: {
    ...defaultHeaders,
    ...getAuthHeaders(),
    ...config.headers,
  } as AxiosRequestHeaders,
  withCredentials: true,
}));

clientV1.interceptors.request.use((config) => ({
  ...config,
  headers: {
    ...defaultHeaders,
    ...getAuthHeaders(),
    ...config.headers,
  } as AxiosRequestHeaders,
  withCredentials: true,
}));

export { clientWithoutV0Prefix, clientV1 };

export default client;
