import { Vehicle } from "shared/api/vehicles/api";
import { pluralize, roundToNDecimals } from "shared/utils";

import Card from "features/ui/Card";
import { DAY_MS } from "features/ui/charts/utils";
import {
  FilterGroupState,
  FilterRowState,
} from "features/ui/Filters/FilterBuilder/types";

interface Props {
  vehicles: Vehicle[];
  dateFilter: FilterGroupState;
  numAllOccurrences: number;
}

const OCCURRENCES_PER_DAYS = 1000;
const ROUND_TO_DECIMALS = 2;

const SignalEventPreviewIPTV = ({
  vehicles,
  dateFilter,
  numAllOccurrences,
}: Props) => {
  const dateRowFilter = dateFilter.children[0] as FilterRowState;
  const fromDate = dateRowFilter.values?.[0]!;
  const endDate = new Date(dateRowFilter.values?.[1]!).getTime();

  const allVehicleDays = vehicles
    .map(({ vehicleStartedDrivingAt }) => {
      let vehicleStartDate = fromDate as string;
      if (vehicleStartedDrivingAt && vehicleStartedDrivingAt > fromDate) {
        vehicleStartDate = vehicleStartedDrivingAt;
      }
      const startDate = new Date(vehicleStartDate as string);

      const diffInMs: number = endDate - startDate.getTime();
      const diffInDays: number = diffInMs / DAY_MS;
      return Math.floor(diffInDays);
    })
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  // allVehicleDays should be always greater than 0 but lets make sure that we do not divide by 0
  const occurrencesPerVehicleDays =
    allVehicleDays > 0
      ? roundToNDecimals(
          (OCCURRENCES_PER_DAYS * numAllOccurrences) / allVehicleDays,
          ROUND_TO_DECIMALS
        )
      : 0;

  const occurrencesString = `${occurrencesPerVehicleDays} ${pluralize("occurrence", occurrencesPerVehicleDays)} per ${OCCURRENCES_PER_DAYS} vehicle ${pluralize("day", OCCURRENCES_PER_DAYS)}`;
  const occurrencesDescription = `Expected occurrence rate based on current definition and set of ${vehicles.length} previewed ${pluralize("vehicle", vehicles.length)}`;

  return (
    <Card classNames="!h-fit">
      <div className="flex-col flex-1 space-y-2">
        <h3 className="items-center font-semibold">{occurrencesString}</h3>
        <div>{occurrencesDescription}</div>
      </div>
    </Card>
  );
};

export default SignalEventPreviewIPTV;
