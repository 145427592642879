import React from "react";

import "typeface-lato";
import "@stoplight/elements/styles.min.css";
import "react-loading-skeleton/dist/skeleton.css";
import "./index.css";

import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import ContextWrappers from "shared/contexts";

import Root from "./Root";
import * as serviceWorker from "./serviceWorker";

const router = createBrowserRouter([{ path: "*", Component: Root }]);

const theme = createTheme({
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          textTransform: "inherit",
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          padding: 3,
        },
      },
    },
    // Name of the component
    MuiButtonBase: {
      defaultProps: {
        // The props to change the default for.
        disableRipple: true, // No more ripple, on the whole application 💣!
        //
      },
    },
  },
  palette: {
    text: {
      primary: "#242424",
    },
    primary: {
      main: "#5d9ae5",
      contrastText: "#fff",
    },
    secondary: {
      main: "#7a7a7a",
      contrastText: "#fff",
    },
    success: {
      main: "#05c779",
    },
  },
});

export default function App() {
  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <ContextWrappers>
          <RouterProvider router={router} />
        </ContextWrappers>
      </ThemeProvider>
    </React.StrictMode>
  );
}

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
