import { PageHandler } from "duck/graph/PageHandler";
import { StructuredTool, tool } from "@langchain/core/tools";

import { VIN_VIEW_HEALTH_TAB_KEY } from "pages/VINView/constants";

import {
  GoToVinViewTabWithVin,
  goToVinViewTabWithVinSchema,
  setRouteValue,
} from "./utils";

const getGoToHealthTabTool = (pageHandler: PageHandler): StructuredTool => {
  const goToHealthTab = async ({ vin }: GoToVinViewTabWithVin) => {
    console.debug(`[VIN]: Navigating to Health tab with vin ${vin}`);
    await setRouteValue(vin, pageHandler);

    pageHandler.navigateToTab(VIN_VIEW_HEALTH_TAB_KEY);
    return "queued navigation to Health tab";
  };

  return tool(goToHealthTab, {
    name: "queueGoToHealthTab",
    description:
      "Call this tool to queue a navigation to the Health tab of the VIN View page",
    schema: goToVinViewTabWithVinSchema,
  });
};

export default getGoToHealthTabTool;
