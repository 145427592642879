import { EventSourceType, ServiceRecord } from "shared/api/serviceRecords/api";

import ClaimDetail from "./ClaimDetail";
import CustomRecordDetail from "./CustomRecordDetail";
import GenericDetail from "./GenericDetail";

export type RecordProps = {
  serviceRecord: ServiceRecord;
  onClose?: () => void;
};

const EventDetail = ({ serviceRecord, onClose }: RecordProps) => {
  switch (serviceRecord.sourceType) {
    case EventSourceType.CLAIM:
      return <ClaimDetail serviceRecord={serviceRecord} onClose={onClose} />;
    case EventSourceType.INSPECTION:
    case EventSourceType.REPAIR:
      return <GenericDetail serviceRecord={serviceRecord} onClose={onClose} />;
    default:
      return (
        <CustomRecordDetail serviceRecord={serviceRecord} onClose={onClose} />
      );
  }
};

export default EventDetail;
