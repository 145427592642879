interface Props {
  title: string;
  text: string;
}

const FormErrorBox = ({ title, text }: Props) => (
  <div className="w-full bg-red-100 border-l-4 border-red-600 text-sm p-4 flex mb-4 text-viaduct-black">
    <div className="w-4 h-4 bg-red-600 rounded-full m-0.5 flex items-center justify-center shrink-0">
      <div className="w-2 bg-white h-px rotate-45"></div>
    </div>
    <div className="ml-2">
      <div className="font-semibold">{title}</div>
      <div>{text}</div>
    </div>
  </div>
);

export default FormErrorBox;
