import { AiOutlineGroup as CollectionsIcon } from "react-icons/ai";
import { toast } from "react-toastify";
import { IconButton } from "@mui/material";

import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { getFilterGroupStateTopLevelRowAttributes } from "features/ui/Filters/FilterBuilder/utils";
import { hasSomeFiltersAppliedFilterGroupState } from "features/ui/Filters/utils";

interface Props {
  filters: FilterGroupState;
  count?: number;
  onClick: () => void;
}

const ICON_SIZE = 25;

const filterKeysToDisallowRegex = ["predictions.*", "collection"];
const regex = new RegExp(filterKeysToDisallowRegex.join("|"), "g");

const FILTERS_NOT_ALLOWED_TEXT = (
  <div className="text-xs">
    Some filters are not allowed when creating a collection:
    <ul className="list-disc pl-4">
      <li>Other collection filters</li>
      <li>Maximum Risk</li>
      <li>All Failure Mode related filters like Risk Group & Risk Status</li>
    </ul>
    Please de-select these filters first.
  </div>
);

const AT_LEAST_ONE_FILTER_REQUIRED_TEXT =
  "You need to select at least 1 filter to create a collection from.";
const AT_LEAST_ONE_RESULT_ITEM_REQUIRED_TEXT =
  "You need some results to create a collection. Please adjust the filters.";

const NewCollectionButton = ({ filters, count, onClick }: Props) => {
  const intersection = getFilterGroupStateTopLevelRowAttributes(filters).filter(
    (k) => k.match(regex)
  );

  const handleOnNewCollectionClick = () => {
    if (intersection.length) {
      return toast.info(FILTERS_NOT_ALLOWED_TEXT, { autoClose: 15000 });
    }

    if (!hasSomeFiltersAppliedFilterGroupState(filters)) {
      return toast.info(AT_LEAST_ONE_FILTER_REQUIRED_TEXT);
    }

    if (!count || count < 1) {
      return toast.info(AT_LEAST_ONE_RESULT_ITEM_REQUIRED_TEXT);
    }

    onClick();
  };

  return (
    <IconButton
      title="Save as Collection"
      data-testid="btn-save-as-collection"
      onClick={handleOnNewCollectionClick}
      size="small"
    >
      <CollectionsIcon size={ICON_SIZE} />
    </IconButton>
  );
};

export default NewCollectionButton;
