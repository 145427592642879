import { Claim } from "shared/api/claims/api";
import { useClaimsSchema } from "shared/schemas/claimsSchema";

import { formatRow } from "pages/ClaimAnalytics/tabPages/Claims/ClaimsTable";
import CardHeader from "pages/Issues/Details/CardHeader";

import Card from "features/ui/Card";
import LabelValuePairs from "features/ui/LabelValuePairs";
import { SchemaEntry } from "features/ui/Table";

interface Props {
  data: Claim;
}

const CARD_TITLE = "Incident Categorization";

const fields: (keyof Claim)[] = [
  "binComponent",
  "binSymptom",
  "binProblem",
  "binLocation",
  "binResponsibility",
];

const CardIncidentCategorization = ({ data }: Props) => {
  const { schema, getDisplayLabel } = useClaimsSchema();
  const formattedData = formatRow(data, getDisplayLabel);

  const shouldShowCard = fields.some((accessor) => data[accessor]);
  if (!shouldShowCard) {
    return null;
  }

  return (
    <Card>
      <CardHeader title={CARD_TITLE} />
      <LabelValuePairs
        schema={schema as SchemaEntry<keyof Claim>[]}
        data={formattedData}
        fields={fields}
      />
    </Card>
  );
};

export default CardIncidentCategorization;
