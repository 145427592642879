import React from "react";

interface Props {
  onWidgetClick: () => void;
}

const FeedbackWidget = ({ onWidgetClick }: Props) => {
  return (
    <button
      id="feedback-box"
      className="transition duration-200 shadow-2xl text-white text-sm text-center hover:text-blue-400 rounded-t w-auto h-auto px-3 py-2 cursor-pointer focus:outline-none"
      onClick={onWidgetClick}
    >
      Contact Us
    </button>
  );
};
export default FeedbackWidget;
