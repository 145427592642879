import React from "react";

import FilterQueryPresentation from "features/ui/Filters/FilterBuilder/FilterQueryPresentation";
import FLabel from "features/ui/Filters/other/FLabel";
import {
  FilterOperator,
  FilterOverviewFormat,
  RelatesFilterState,
} from "features/ui/Filters/types";
import { SchemaEntry } from "features/ui/Table";
import { DataType } from "features/ui/Table/TableBodyCell";

interface Props {
  relates: RelatesFilterState;
  format: FilterOverviewFormat;
  fieldNameFormatted: string;
  baseEntityText?: string;
  dense?: boolean;
  relatesBeforeNow?: boolean;
  tableSchema?: SchemaEntry[];
}

const windowDirectionToLabel = (windowDirection: string) => {
  if (windowDirection === "both") {
    return "before or after";
  }
  return windowDirection;
};

const operatorToLabel = (operator: string) => {
  if (operator === "noccurs") {
    return "does not occur";
  }
  return operator;
};

const RelatesFilterLabel = ({
  fieldNameFormatted,
  relates,
  format,
  baseEntityText = "Base Object",
  relatesBeforeNow = false,
  dense,
  tableSchema,
}: Props) => {
  const { filters, options, operator: relatesOperator } = relates;

  const timeWindowText = (
    <span className="font-normal">
      {operatorToLabel(relatesOperator)} within{" "}
      {relatesBeforeNow && "the last "}
      <strong>
        {options.windowSize} {options.windowType}{" "}
        {!relatesBeforeNow &&
          windowDirectionToLabel(options.windowDirection as string)}
      </strong>{" "}
      {baseEntityText}
    </span>
  );

  const renderFilterPresentation = () => (
    <FilterQueryPresentation
      filterState={filters}
      tableSchema={tableSchema}
      baseEntityText={baseEntityText}
      relatesBeforeNow={relatesBeforeNow}
    />
  );

  const fieldValue = (
    <>
      <span className="font-normal">defined by</span>
      <div className={format === "label-inline" ? "ml-1" : "mt-1"}>
        {renderFilterPresentation()}
      </div>
      {timeWindowText}
    </>
  );

  return (
    <FLabel
      fieldName={fieldNameFormatted}
      operator={FilterOperator.RELATES}
      fieldValue={fieldValue}
      format={format}
      dense={dense}
      dataType={DataType.STRING}
    />
  );
};

export default RelatesFilterLabel;
