import classNames from "classnames";

interface RiskBadgeProps {
  risk: string | null;
}

const colorMap: Record<string, string> = {
  low: "text-green-500",
  medium: "text-orange-500",
  high: "text-red-500",
};

const RiskBadge = (props: RiskBadgeProps) => {
  const { risk } = props;
  const colorClass = (risk && colorMap[risk]) || undefined;

  if (!risk) return null;

  return (
    <span className={classNames(colorClass, "font-semibold")}>{risk}</span>
  );
};

export default RiskBadge;
