import Button from "features/ui/Button";
import { relatedFiltersAreSame } from "features/ui/Filters/FilterTypes/RelatesFilter/utils";
import { RelatesFilterState } from "features/ui/Filters/types";

interface Props {
  onApply?: () => void;
  onCancel?: () => void;
  relatesState: RelatesFilterState;
  appliedRelatesFilter?: RelatesFilterState;
}

const RelatesFilterActions = ({
  onApply,
  onCancel,
  relatesState,
  appliedRelatesFilter,
}: Props) => {
  const buttonsDisabled = relatedFiltersAreSame(
    appliedRelatesFilter,
    relatesState,
    false
  );

  return (
    <div className="space-x-2">
      <Button
        label="Cancel"
        size="small"
        testId="relates-filter-cancel-cta"
        onClick={onCancel}
        disabled={buttonsDisabled}
        color="secondary"
      />
      <Button
        label="Apply"
        size="small"
        testId="relates-filter-apply-cta"
        onClick={onApply}
        color="primary"
        disabled={buttonsDisabled}
        variant="contained"
      />
    </div>
  );
};

export default RelatesFilterActions;
