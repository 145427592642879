import "shared/filterDefinitions";

import { ContextProviderName } from "shared/contexts/ContextWrappers";
import { useAttributesContext } from "shared/contexts/GenericAttributesContextWrapper";

import { FilterSchemaItem } from "features/ui/Filters/types";
import { SchemaEntry } from "features/ui/Table";

import { UseSchema } from "./types";
import {
  getAttributeMap,
  getDisplayLabelFunction,
  getFilterFunction,
  getSchemaEntryFunction,
  getSchemaForAccessorFunction,
  getSchemaFromAttributes,
} from "./utils";

export const useFailureModeEventsSchema = (
  filterGenerator: (item: FilterSchemaItem) => FilterSchemaItem,
  accessorsToHide: string[] = []
): UseSchema => {
  const attributes = useAttributesContext(
    ContextProviderName.FailureModeEvents
  );

  const attributeMap = getAttributeMap(attributes);

  const getDisplayLabel = getDisplayLabelFunction(attributeMap);

  const getFilter = getFilterFunction(filterGenerator, attributeMap);

  const getSchemaEntry = getSchemaEntryFunction(getFilter, attributeMap);

  const schema: SchemaEntry[] = getSchemaFromAttributes(
    attributes,
    getSchemaEntry,
    undefined,
    undefined,
    accessorsToHide,
    true
  );

  const getSchemaForAccessor = getSchemaForAccessorFunction(schema);

  return {
    schema,
    attributes,
    getDisplayLabel,
    attributeMap,
    whitelist: Object.keys(attributeMap || {}),
    getSchemaEntry,
    getSchemaForAccessor,
  };
};
