import { useEffect, useState } from "react";
import { BiPencil as EditIcon } from "react-icons/bi";
import { CgSpinnerTwo as LoadingIcon } from "react-icons/cg";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Alert, IconButton } from "@mui/material";

import { DeleteRequest } from "shared/api/api";
import {
  CalculatedAttribute as CalculatedAttributeModel,
  CalculatedAttributeRequest,
  deleteCalculatedAttribute,
  getCalculatedAttribute,
  newCalculatedAttribute,
  updateCalculatedAttribute,
} from "shared/api/calculatedAttributes/api";

import {
  CANCEL_CTA_TEXT,
  SUBMIT_CTA_EDIT,
  SUBMIT_CTA_NEW,
} from "pages/constants";

import Button from "features/ui/Button";
import DeleteAction from "features/ui/DeleteAction";
import {
  filterBuilderQueryToFilterBuilderState,
  getFiltersQuery,
} from "features/ui/Filters/FilterBuilder/utils";
import EditDetails from "features/ui/Form/EditDetails";
import FormSection from "features/ui/FormSection";
import Input from "features/ui/Input";
import StatefulTabs, { Tab } from "features/ui/StatefulTabs";

import { routes } from "services/routes";

import AttributeParameters from "./AttributeParameters";
import {
  CalculatedAttributeFunction,
  DEFAULT_ATTRIBUTE_PARAMETERS,
  DEFAULT_UNIT_PER_FUNCTION_TYPE,
  DEFINITION_TAB,
  DESCRIPTION_LABEL,
  NAME_LABEL,
  ON_SUCCESS_CREATE_TEXT,
  PREVIEW_TAB,
  UPDATED_SUCCESSFULLY_MESSAGE,
} from "./constants";
import FormLoader from "./FormLoader";
import {
  CalculatedAttributeParameters,
  CalculatedAttributeParams,
} from "./types";
import { validateForm } from "./utils";
import VehiclePreview from "./VehiclePreview";

const CalculatedAttribute = () => {
  const navigate = useNavigate();
  const { id: calculatedAttributeID } = useParams<CalculatedAttributeParams>();

  const isCreatePage = !calculatedAttributeID;

  const [editMode, setEditMode] = useState(isCreatePage);

  const [isLoading, setIsLoading] = useState(false);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const [parameters, setParameters] = useState<CalculatedAttributeParameters>(
    DEFAULT_ATTRIBUTE_PARAMETERS
  );

  const [initialCalculatedAttribute, setInitialCalculatedAttribute] =
    useState<CalculatedAttributeModel>();

  const [createdUpdated, setCreatedUpdated] = useState({
    createdBy: initialCalculatedAttribute?.createdBy || "",
    createdAt: initialCalculatedAttribute?.createdAt || "",
    updatedBy: initialCalculatedAttribute?.updatedBy || "",
    updatedAt: initialCalculatedAttribute?.updatedAt || "",
  });

  const setCalculatedAttributeData = (
    newCalculatedAttribute: CalculatedAttributeModel
  ) => {
    const {
      name,
      description,
      type,
      eventFilter,
      eventType,
      unit,
      createdAt,
      createdBy,
      updatedAt,
      updatedBy,
    } = newCalculatedAttribute;

    setCreatedUpdated({ createdAt, createdBy, updatedAt, updatedBy });

    setName(name);
    if (description) {
      setDescription(description);
    }
    if (
      type &&
      eventFilter &&
      filterBuilderQueryToFilterBuilderState(eventFilter) !== undefined &&
      eventType
    ) {
      setParameters({
        type,
        eventType,
        eventFilter: filterBuilderQueryToFilterBuilderState(eventFilter)!,
        unit:
          unit ||
          DEFAULT_UNIT_PER_FUNCTION_TYPE[type as CalculatedAttributeFunction],
      });
    }
  };

  const setAndRememberInitialCalculatedAttribute = (
    attribute: CalculatedAttributeModel
  ) => {
    setInitialCalculatedAttribute(attribute);
    setCalculatedAttributeData(attribute);
  };

  const handleCreate = (data: CalculatedAttributeRequest) => {
    newCalculatedAttribute({ ...data })
      .then(({ data: { ID } }) => {
        toast.success(ON_SUCCESS_CREATE_TEXT);
        setEditMode(false);
        navigate(generatePath(routes.calculatedAttribute, { id: ID }));
      })
      .catch((error) => {
        setValidationErrors(error.message);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleUpdate = (data: CalculatedAttributeRequest) => {
    updateCalculatedAttribute({ ID: calculatedAttributeID!, ...data })
      .then(() => {
        setEditMode(false);
        toast.success(UPDATED_SUCCESSFULLY_MESSAGE);
      })
      .catch((error) => {
        setValidationErrors(error.message);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const submitCallback = isCreatePage ? handleCreate : handleUpdate;

  const handleOnSubmit = () => {
    if (!valid) {
      setShowStaticValidationErrors(true);
      return;
    }

    setValidationErrors("");
    setIsSubmitting(true);
    submitCallback({
      name,
      description,
      type: parameters.type!,
      eventType: parameters.eventType!,
      eventFilter: getFiltersQuery(parameters!.eventFilter) || "",
      unit: parameters!.unit,
    });
  };

  const handleOnCancel = () => {
    if (isCreatePage) {
      navigate(routes.calculatedAttributes);
    } else {
      resetToInitialAttribute();
      setEditMode(false);
    }
  };

  const resetToInitialAttribute = () => {
    if (!initialCalculatedAttribute) return;
    setInitialCalculatedAttribute(initialCalculatedAttribute);
  };

  // If we are on the Edit page, we retrieve the current plan data
  // and set the form values to these ..
  useEffect(() => {
    if (isCreatePage) return;

    setIsLoading(true);

    getCalculatedAttribute({ id: calculatedAttributeID as string })
      .then(({ data }) => setAndRememberInitialCalculatedAttribute(data))
      .catch((err) => {
        navigate(routes.calculatedAttributes);
      })
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreatePage, calculatedAttributeID]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const isFormDisabled = isSubmitting || !editMode;

  const [validationErrors, setValidationErrors] = useState("");

  const [showStaticValidationErrors, setShowStaticValidationErrors] =
    useState(!isCreatePage);

  const handleOnDelete = (args: DeleteRequest) =>
    deleteCalculatedAttribute(args).then((response) => {
      navigate(routes.calculatedAttributes);
      return response;
    });

  const ctaIcon =
    (isSubmitting && <LoadingIcon className="animate-spin" />) || undefined;

  const SUBMIT_CTA_TEXT = isCreatePage ? SUBMIT_CTA_NEW : SUBMIT_CTA_EDIT;

  const { valid, message: staticErrorMessage } = validateForm(
    name,
    description,
    parameters
  );

  const showError =
    editMode &&
    (validationErrors || (showStaticValidationErrors && staticErrorMessage));

  const tabsItems: Tab[] = [
    {
      key: DEFINITION_TAB,
      title: "Definition",
      content: (
        <FormSection title="Definition">
          <AttributeParameters
            attribute={parameters}
            onChange={setParameters}
            disabled={isFormDisabled}
          />
        </FormSection>
      ),
    },
    {
      key: PREVIEW_TAB,
      title: "Preview values on vehicles",
      content: <VehiclePreview ID={calculatedAttributeID!} name={name} />,
      disabled: editMode,
      disabledText: "Please save the calculated attribute to preview values",
    },
  ];

  return (
    <div>
      {isLoading && <FormLoader />}
      {!isLoading && (
        <div className="max-w-5xl">
          {showError && (
            <Alert
              severity="error"
              data-testid="calculated-attribute-form-validation-errors"
              className="mb-5"
            >
              <div className="font-bold mb-1">Validation errors</div>
              {validationErrors && <div>{validationErrors}</div>}
              {staticErrorMessage && <div>{staticErrorMessage}</div>}
            </Alert>
          )}
          <div className="mb-5 flex justify-between">
            <div className="flex">
              <div className="w-36 mr-4 leading-9 text-right">{NAME_LABEL}</div>
              <Input
                value={name}
                onChange={({ target: { value } }) => setName(value)}
                testId="calculated-attribute-name"
                disabled={isFormDisabled}
                fullWidth={false}
                className="w-80"
                tabIndex={0}
              />
            </div>
            <div className="flex items-center ml-auto">
              {!isCreatePage && <EditDetails props={createdUpdated} />}
              {editMode && (
                <>
                  <Button
                    color="secondary"
                    variant="outlined"
                    label={CANCEL_CTA_TEXT}
                    onClick={handleOnCancel}
                    isLoading={isSubmitting}
                    endIcon={ctaIcon}
                    disabled={isSubmitting}
                    testId="calculated-attribute-form-submit-cta"
                    size="medium"
                    className="!mr-4"
                    tabIndex={-1}
                  />
                  <Button
                    color="primary"
                    variant="contained"
                    label={SUBMIT_CTA_TEXT}
                    onClick={handleOnSubmit}
                    isLoading={isSubmitting}
                    endIcon={ctaIcon}
                    disabled={isSubmitting}
                    testId="calculated-attribute-form-submit-cta"
                    size="medium"
                    tabIndex={-1}
                  />
                </>
              )}
              {!editMode && !isCreatePage && (
                <>
                  <DeleteAction
                    data={{
                      ID: calculatedAttributeID!,
                      name: initialCalculatedAttribute?.name || name,
                    }}
                    entityName="calculated attribute"
                    deleteCallback={handleOnDelete}
                    entityRequestKeys={[]}
                    iconOnly={true}
                  />
                  <IconButton
                    onClick={() => setEditMode(true)}
                    size="small"
                    data-testid="calculated-attribute-form-edit-mode-cta"
                  >
                    <EditIcon />
                  </IconButton>
                </>
              )}
            </div>
          </div>
          <div className="mb-5 flex">
            <div className="w-36 mr-4 leading-9 text-right shrink-0">
              {DESCRIPTION_LABEL}
            </div>
            <Input
              value={description}
              onChange={({ target: { value } }) => setDescription(value)}
              testId="calculated-attribute-description"
              disabled={isFormDisabled}
              tabIndex={1}
            />
          </div>
          <StatefulTabs tabs={tabsItems} />
        </div>
      )}
    </div>
  );
};

export default CalculatedAttribute;
