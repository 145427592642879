import React from "react";

import { Group } from "shared/api/rbac/api";

import Card from "features/ui/Card";
import Label from "features/ui/Label";

import { CreateGroupProps } from "./CardActions";
import CardHeader from "./CardHeader";

interface Props {
  group: Group;
  createGroupProps: CreateGroupProps;
}

const CardOverview = ({ group, createGroupProps }: Props) => {
  const { description, users, canEdit, admin } = group;

  return (
    <Card classNames="!p-5" testId="group-overview-card">
      <CardHeader
        title="Overview"
        canEdit={canEdit}
        createGroupProps={createGroupProps}
      />
      <div className="flex flex-row justify-between mb-3">
        <div className="relative">
          <Label text="Members" />
          <Label
            text={users.length}
            className="text-viaduct-black"
            testId="group-members-count"
          />
        </div>
        {admin && (
          <div className="relative text-blue-500 text-sm">Admin Group</div>
        )}
      </div>
      <div className="flex flex-row justify-between mb-3">
        <div className="relative">
          <Label text="Description" />
          <Label
            id="group_description"
            text={description}
            className="text-viaduct-black"
          />
        </div>
      </div>
    </Card>
  );
};

export default CardOverview;
