import NewCSVCollectionForm from "pages/Collections/NewCollectionForm/CSVUploadForm";

import NewCollectionModalWrapper from "./NewCollectionModalWrapper";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit?: () => void;
}

const NewCSVCollectionModal = ({ isOpen, onClose, onSubmit }: Props) => {
  return (
    <NewCollectionModalWrapper isOpen={isOpen} onClose={onClose}>
      <NewCSVCollectionForm onSubmit={onSubmit} />
    </NewCollectionModalWrapper>
  );
};

export default NewCSVCollectionModal;
