import { APIFilter } from "shared/api/utils";

import NewCollectionForm from "pages/Collections/NewCollectionForm/FilterForm";

import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";

import NewCollectionModalWrapper from "./NewCollectionModalWrapper";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  filters: FilterGroupState;
  staticFilters?: APIFilter[];
}

const NewStaticFilterCollectionModal = ({
  isOpen,
  onClose,
  filters,
  staticFilters,
}: Props) => (
  <NewCollectionModalWrapper isOpen={isOpen} onClose={onClose}>
    <NewCollectionForm filters={filters} staticFilters={staticFilters} />
  </NewCollectionModalWrapper>
);

export default NewStaticFilterCollectionModal;
