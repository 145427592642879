import {
  CountRequest,
  CountResponse,
  EntityAttribute,
  GetRequest,
  ListAttributesRequest,
  VehicleAgeTimeline,
} from "shared/api/api";
import { DEFAULT_ATTRIBUTES_SWR_CONFIG } from "shared/api/constants";
import { useAPI, usePaginatedAPI } from "shared/api/hooks";
import { CountRequestWithVehiclesFilter } from "shared/api/vehicles/api";

import {
  AssociatedSignalEvent,
  getSignalEventRequestURI,
  getSignalEventsByVehicleAgeTimelineRequestURI,
  getSignalEventsOccurrencesMetricsHistoryRequestURI,
  getSignalEventsOccurrencesMetricsRequestURI,
  getSignalEventsTopContributorsRequestURI,
  listDealerAttributesRequestURI,
  ListSignalEventAssociatedSignalEventsRequest,
  listSignalEventAssociatedSignalEventsRequestURI,
  listSignalEventOccurrenceAttributesRequestURI,
  ListSignalEventOccurrencesRequest,
  listSignalEventOccurrencesVINAggregateCountRequestURI,
  ListSignalEventOccurrencesVINAggregateRequest,
  listSignalEventOccurrencesVINAggregateRequestURI,
  ListSignalEventOccurrencesVINTimelineRequest,
  listSignalEventOccurrencesVINTimelineRequestURI,
  listSignalEventsAssociatedClaimsRequestURI,
  listSignalEventsAssociatedVehiclesCountRequestURI,
  listSignalEventsAssociatedVehiclesRequestURI,
  listSignalEventsOccurrencesCountRequestURI,
  listSignalEventsOccurrencesRequestURI,
  ListSignalEventsRequest,
  listSignalEventsRequestURI,
  SignalEvent,
  SignalEventAssociatedClaim,
  SignalEventByVehicleAgeFiltersRequest,
  SignalEventOccurrencesMetricsHistory,
  SignalEventOccurrencesVINAggregateBucket,
  SignalEventOccurrencesVINTimelineBucket,
  SignalEventsAssociatedClaimsRequest,
  SignalEventsAssociatedVehicle,
  SignalEventsAssociatedVehiclesRequest,
  SignalEventsFiltersRequest,
  SignalEventsOccurrence,
  SignalEventsOccurrencesMetrics,
  SignalEventsTopContributor,
  SignalEventsTopContributorsRequest,
} from "./api";

export const useListSignalEvents = (args: ListSignalEventsRequest) =>
  usePaginatedAPI<typeof args, SignalEvent[]>(listSignalEventsRequestURI, args);

export const useSignalEvent = (args: GetRequest) =>
  useAPI<typeof args, SignalEvent>(getSignalEventRequestURI, args);

export const useListSignalEventsOccurrences = (
  args: ListSignalEventOccurrencesRequest
) =>
  usePaginatedAPI<typeof args, SignalEventsOccurrence[]>(
    listSignalEventsOccurrencesRequestURI,
    args
  );

export const useListSignalEventsOccurrencesCount = (
  args: CountRequestWithVehiclesFilter
) =>
  usePaginatedAPI<typeof args, CountResponse>(
    listSignalEventsOccurrencesCountRequestURI,
    args
  );

export const useSignalEventsOccurrencesMetrics = (
  args: SignalEventsFiltersRequest
) =>
  useAPI<typeof args, SignalEventsOccurrencesMetrics>(
    getSignalEventsOccurrencesMetricsRequestURI,
    args
  );

export const useSignalEventsOccurrencesMetricsHistory = (
  args: SignalEventsFiltersRequest
) =>
  useAPI<typeof args, SignalEventOccurrencesMetricsHistory[]>(
    getSignalEventsOccurrencesMetricsHistoryRequestURI,
    args
  );

export const useSignalEventsOccurrencesAttributes = (
  args: ListAttributesRequest
) =>
  useAPI<typeof args, EntityAttribute[]>(
    listSignalEventOccurrenceAttributesRequestURI,
    args,
    DEFAULT_ATTRIBUTES_SWR_CONFIG
  );

export const useListSignalEventsAssociatedClaims = (
  args: SignalEventsAssociatedClaimsRequest
) =>
  usePaginatedAPI<typeof args, SignalEventAssociatedClaim[]>(
    listSignalEventsAssociatedClaimsRequestURI,
    args
  );

export const useListSignalEventsByVehicleAgeTimeline = (
  args: SignalEventByVehicleAgeFiltersRequest
) =>
  useAPI<typeof args, VehicleAgeTimeline[]>(
    getSignalEventsByVehicleAgeTimelineRequestURI,
    args
  );

export const useListSignalEventsTopContributors = (
  args: SignalEventsTopContributorsRequest
) =>
  usePaginatedAPI<typeof args, SignalEventsTopContributor[]>(
    getSignalEventsTopContributorsRequestURI,
    args
  );

export const useSignalEventsAssociatedVehicles = (
  args: SignalEventsAssociatedVehiclesRequest
) =>
  usePaginatedAPI<typeof args, SignalEventsAssociatedVehicle[]>(
    listSignalEventsAssociatedVehiclesRequestURI,
    args
  );

interface SignalEventsAssociatedVehiclesCountRequest extends CountRequest {
  signalEventOccurrencesFilter?: string;
}

export const useSignalEventsAssociatedVehiclesCount = (
  args: SignalEventsAssociatedVehiclesCountRequest
) =>
  usePaginatedAPI<typeof args, CountResponse>(
    listSignalEventsAssociatedVehiclesCountRequestURI,
    args
  );

export const useDealerAttributes = (args: ListAttributesRequest) =>
  useAPI<typeof args, EntityAttribute[]>(
    listDealerAttributesRequestURI,
    args,
    DEFAULT_ATTRIBUTES_SWR_CONFIG
  );

export const useListSignalEventOccurrencesVINTimeline = (
  args: ListSignalEventOccurrencesVINTimelineRequest
) =>
  usePaginatedAPI<typeof args, SignalEventOccurrencesVINTimelineBucket[]>(
    listSignalEventOccurrencesVINTimelineRequestURI,
    args
  );

export const useListSignalEventOccurrencesVINAggregate = (
  args: ListSignalEventOccurrencesVINAggregateRequest
) =>
  usePaginatedAPI<typeof args, SignalEventOccurrencesVINAggregateBucket[]>(
    listSignalEventOccurrencesVINAggregateRequestURI,
    args
  );

export const useListSignalEventOccurrencesVINAggregateCount = (
  args: CountRequest
) =>
  usePaginatedAPI<typeof args, CountResponse>(
    listSignalEventOccurrencesVINAggregateCountRequestURI,
    args
  );

export const useListSignalEventAssociatedSignalEvents = (
  args: ListSignalEventAssociatedSignalEventsRequest
) =>
  usePaginatedAPI<typeof args, AssociatedSignalEvent[]>(
    listSignalEventAssociatedSignalEventsRequestURI,
    args
  );
