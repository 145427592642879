import { useState } from "react";

import { IssueRepairEfficacyVINTimeline } from "shared/api/issues/api";
import { IssueTypes, ReoccurrenceProps } from "shared/types";

import { useOptionsForAccessors } from "pages/Issues/Claims/Charts/utils";
import { getChartValues } from "pages/Issues/utils";

import Card from "features/ui/Card";
import ChartActions from "features/ui/charts/Actions/ChartActions";
import { ChartActionsWrap } from "features/ui/charts/Actions/ChartActionsWrap";
import {
  ChartAction,
  SelectedChartOptions,
} from "features/ui/charts/Actions/types";
import { getDefaultActions } from "features/ui/charts/utils";

import * as config from "config/config";

import ReoccurrenceProceedingAClaimTimelineChart from "./ReoccurrenceProceedingAClaimTimelineChart";

interface Props extends ReoccurrenceProps {
  issue: IssueTypes;
  withComparisonPopulation: boolean;
}

const CHART_NAME = "RepairEfficacy_ReoccurrenceProceedingAClaim";
const COMMON_TITLE =
  "Population Experiencing a Reoccurring Signal Event Proceeding a Claim";
const AT_RISK_TITLE = `At-Risk ${COMMON_TITLE}`;
const COMPARISON_TITLE = `Comparison ${COMMON_TITLE}`;

const ReoccurrenceProceedingAClaimChart = ({
  issue,
  withComparisonPopulation,
  seOccurrenceWithin,
  seReoccurrenceStartingFrom,
  seReoccurrenceUpTo,
}: Props) => {
  const [atRiskData, setAtRiskData] = useState(
    [] as IssueRepairEfficacyVINTimeline[]
  );
  const [comparisonData, setComparisonData] = useState(
    [] as IssueRepairEfficacyVINTimeline[]
  );
  const [dataFilter, setDataFilter] = useState({});

  const onUpdateAttrNameValueOptions = (
    attrName: string,
    attrValues: string[]
  ) => {
    setDataFilter({ [attrName]: attrValues });
  };

  const {
    pages: { issues },
  } = config.get();

  const legendAccessors = issues?.topXChartsOptions || [
    "laborCode",
    "failedPartNumber",
  ];
  const legendOptions = useOptionsForAccessors(legendAccessors);

  const actions: ChartAction[] = [
    {
      id: "legend",
      title: "Filter Claim Events By",
      type: "label",
      options: [],
    },
    {
      id: "y",
      title: "",
      type: "attrNameValue",
      options: [
        ...legendOptions,
        {
          id: "VIN",
          value: "VIN",
        },
      ],
      defaultOptionId: legendAccessors[0],
      secondaryOptions: [
        {
          id: "failedPartNumber",
          values: getChartValues(
            "failedPartNumber",
            atRiskData,
            comparisonData,
            false
          ),
        },
        {
          id: "laborCode",
          values: getChartValues(
            "laborCode",
            atRiskData,
            comparisonData,
            false
          ),
        },
        {
          id: "VIN",
          values: getChartValues("VIN", atRiskData, comparisonData, false),
        },
      ],
      onUpdate: onUpdateAttrNameValueOptions,
    },
  ];

  const [selectedOptions, setSelectedOptions] = useState<
    SelectedChartOptions[]
  >(getDefaultActions(actions));

  const commonChartProps = {
    issue,
    seOccurrenceWithin,
    seReoccurrenceStartingFrom,
    seReoccurrenceUpTo,
    dataFilter,
  };

  return (
    <Card>
      <div className="space-y-5">
        <div>
          <ChartActionsWrap id={CHART_NAME} chartTitle={AT_RISK_TITLE}>
            <ChartActions
              actions={actions}
              selectedOptions={selectedOptions}
              onOptionChange={setSelectedOptions}
            />
          </ChartActionsWrap>
          <ReoccurrenceProceedingAClaimTimelineChart
            population="atRisk"
            onDataLoad={setAtRiskData}
            {...commonChartProps}
          />
        </div>
        {withComparisonPopulation && (
          <div>
            <ChartActionsWrap
              id={CHART_NAME}
              chartTitle={COMPARISON_TITLE}
              showActions={false}
            />
            <ReoccurrenceProceedingAClaimTimelineChart
              onDataLoad={setComparisonData}
              population="comparison"
              {...commonChartProps}
            />
          </div>
        )}
      </div>
    </Card>
  );
};

export default ReoccurrenceProceedingAClaimChart;
