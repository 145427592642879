import { EntityAttribute, ListAttributesRequest } from "shared/api/api";
import { DEFAULT_ATTRIBUTES_SWR_CONFIG } from "shared/api/constants";
import { useAPI, usePaginatedAPI } from "shared/api/hooks";

import {
  getSensorsReadingsTimelineURI,
  listSensorReadingAttributesRequestURI,
  ListSensorsRequest,
  listSensorsRequestURI,
  Sensor,
  SensorReadingsTimelineRequest,
  SensorReadingTimeline,
} from "./api";

export const useListSensors = (args: ListSensorsRequest) =>
  usePaginatedAPI<typeof args, Sensor[]>(listSensorsRequestURI, args);

export const useSensorsReadingsTimeline = (
  args: SensorReadingsTimelineRequest
) =>
  useAPI<typeof args, SensorReadingTimeline>(
    getSensorsReadingsTimelineURI,
    args
  );

export const useSensorReadingsAttributes = (args: ListAttributesRequest) =>
  useAPI<typeof args, EntityAttribute[]>(
    listSensorReadingAttributesRequestURI,
    args,
    DEFAULT_ATTRIBUTES_SWR_CONFIG
  );
