import { PageHandler } from "duck/graph/PageHandler";
import { StructuredTool, tool } from "@langchain/core/tools";

import { VIN_VIEW_SERVICE_SCHEDULE_TAB_KEY } from "pages/VINView/constants";

import {
  GoToVinViewTabWithVin,
  goToVinViewTabWithVinSchema,
  setRouteValue,
} from "./utils";

const getGoToServiceScheduleTabTool = (
  pageHandler: PageHandler
): StructuredTool => {
  const goToServiceScheduleTab = async ({ vin }: GoToVinViewTabWithVin) => {
    console.debug(`[VIN]: Navigating to Service Schedule tab with vin ${vin}`);
    await setRouteValue(vin, pageHandler);
    pageHandler.navigateToTab(VIN_VIEW_SERVICE_SCHEDULE_TAB_KEY);
    return "queued navigation to Service Schedule tab";
  };

  return tool(goToServiceScheduleTab, {
    name: "queueGoToServiceScheduleTab",
    description:
      "Call this tool to queue a navigation to the Service Schedule tab",
    schema: goToVinViewTabWithVinSchema,
  });
};

export default getGoToServiceScheduleTabTool;
