import { useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import { TooltipProps, XAxisProps } from "recharts";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import { Margin } from "recharts/types/util/types";

import { APIErrorResponse } from "shared/api/api";
import {
  Sensor,
  SensorReadingsTimelineGrouping,
  SensorReadingTimelineEntry,
} from "shared/api/sensors/api";
import { ServiceRecord } from "shared/api/serviceRecords/api";
import { formatNumber } from "shared/utils";

import APIError from "features/ui/APIError";
import {
  DEFAULT_Y_AXIS_ID,
  DEFAULT_Y_AXIS_ID_R,
  REFERENCE_LINE_COLOR,
  TIMESTAMP_X_AXIS_KEY,
} from "features/ui/charts/constants";
import CustomLegend from "features/ui/charts/CustomLegend";
import LineChart from "features/ui/charts/LineChart";
import { LineChartProps } from "features/ui/charts/LineChart/LineChart";
import { VehicleReferenceLineType } from "features/ui/charts/ScatterChart/ScatterChart";
import {
  LegendConfig,
  LegendConfigLabel,
  ReferenceElement,
  YAxisLine,
  ZoomProps,
} from "features/ui/charts/types";
import { getColor } from "features/ui/charts/utils";
import { getValuesForAttributeFromFilterState } from "features/ui/Filters/FilterBuilder/utils";
import { UseFilterSortState } from "features/ui/Filters/types";

import { LEGEND_CONFIG } from "./EventsTimelineGraph";
import PointValueSensorChartTooltip from "./PointValueSensorChartTooltip";
import StateSensorChartTooltip from "./StateSensorChartTooltip";
import {
  buildYAxisLabel,
  getLabelForPointValueSensor,
  prepareSensorsChartData,
  prepareStateSensorsChartData,
  ProcessedVehicleEvent,
} from "./utils";

const PX_TO_JOIN_CHARTS_VERTICALLY = 51;
const MIN_HEIGHT_STATE_CHART_PX = 130;

interface Props {
  filterState: UseFilterSortState;
  grouping: SensorReadingsTimelineGrouping;
  endDate: string;
  vehicleHistoryEvents?: VehicleReferenceLineType[];
  serviceRecordReferenceLines?: ProcessedVehicleEvent[];
  onReferenceLineClick?: (data: ServiceRecord) => void;
  margin: Margin;
  pointValueSensorsData?: SensorReadingTimelineEntry[];
  stateSensorsData?: SensorReadingTimelineEntry[];
  pointValueSensors?: Sensor[];
  stateSensors?: Sensor[];
  isLoading?: boolean;
  error?: APIErrorResponse;
  showEventTimelineChart: boolean;
  sharedXAxisProps: XAxisProps;
}

const stateSensorChartTooltipProps = (dataKey: string) => ({
  content: (props: TooltipProps<ValueType, NameType>) => (
    <StateSensorChartTooltip {...props} dataKey={dataKey} />
  ),
});

const pointValueSensorChartTooltipProps = (
  grouping: SensorReadingsTimelineGrouping,
  pointValueSensors?: Sensor[]
) => ({
  content: (props: TooltipProps<ValueType, NameType>) => (
    <PointValueSensorChartTooltip
      {...props}
      grouping={grouping}
      pointValueSensors={pointValueSensors}
    />
  ),
});

const SensorsCharts = ({
  filterState,
  grouping,
  endDate,
  currentZoom,
  onMouseMove,
  sharedXAxisProps,
  cursorX,
  vehicleHistoryEvents,
  serviceRecordReferenceLines,
  onReferenceLineClick,
  margin,
  onZoom,
  onZoomOut,
  onSyncDateWithZoom,
  onZoomReferenceAreaChange,
  zoomReferenceAreaOverride,
  pointValueSensorsData,
  stateSensorsData,
  pointValueSensors,
  stateSensors,
  isLoading,
  error,
  showEventTimelineChart,
}: Props & ZoomProps) => {
  const selectedSensors = getValuesForAttributeFromFilterState(
    "sensorID",
    filterState.filters
  );

  const dataKeyStateDurationSensors = grouping === "day" ? "count" : "value";

  const [formattedDataPointValueSensors, formattedDataStateSensors] =
    useMemo(() => {
      if (!pointValueSensors || !stateSensors) return [];
      return [
        pointValueSensorsData &&
          pointValueSensors &&
          prepareSensorsChartData(pointValueSensorsData, pointValueSensors),
        stateSensorsData &&
          prepareStateSensorsChartData(
            stateSensorsData,
            endDate,
            stateSensors,
            dataKeyStateDurationSensors
          ),
      ];
    }, [
      pointValueSensors,
      stateSensors,
      pointValueSensorsData,
      stateSensorsData,
      endDate,
      dataKeyStateDurationSensors,
    ]);

  const yAxisLinesStateSensors: YAxisLine[] = useMemo(() => {
    if (!formattedDataStateSensors) return [];

    const colorMap: Record<string, string> = {};

    return formattedDataStateSensors.map(({ sensorID, data }, index) => {
      if (!colorMap[sensorID]) {
        colorMap[sensorID] = getColor(index);
      }

      return {
        key: `${sensorID}-${index}`,
        dataKey: dataKeyStateDurationSensors,
        label: sensorID,
        color: colorMap[sensorID],
        dashed: false,
        dot: true,
        data,
      };
    });
  }, [formattedDataStateSensors, dataKeyStateDurationSensors]);

  if (selectedSensors.length < 1) return null;

  if (isLoading) return <Skeleton height={400} />;

  if (error) return <APIError error={error} />;

  const yAxisLinesPointValueSensors: YAxisLine[] =
    pointValueSensors?.map(({ ID }, index) => ({
      key: `value-${ID}`,
      label: ID,
      color: getColor(index),
      dashed: true,
      type: "linear",
      dot: true,
      orientation: index === 0 ? DEFAULT_Y_AXIS_ID : DEFAULT_Y_AXIS_ID_R,
      yAxisId: index === 0 ? DEFAULT_Y_AXIS_ID : DEFAULT_Y_AXIS_ID_R,
    })) || [];

  const yAxisLabelLeft = buildYAxisLabel(0, grouping, pointValueSensors);
  const yAxisLabelRight = buildYAxisLabel(1, grouping, pointValueSensors);

  const referenceLines: ReferenceElement[] =
    vehicleHistoryEvents?.map(({ key, x, color }: VehicleReferenceLineType) => {
      return {
        xAxisValue: x,
        color,
        label: key,
        key,
      };
    }) || [];

  const referenceLinesServiceEvents: ReferenceElement[] =
    serviceRecordReferenceLines?.map(
      ({ key, x, data }: ProcessedVehicleEvent) => {
        return {
          xAxisValue: x,
          label: key,
          key,
          color: REFERENCE_LINE_COLOR,
          onClick: () => {
            onReferenceLineClick && onReferenceLineClick(data);
          },
          className: "cursor-pointer",
        };
      }
    ) || [];

  const allRefLines = [...referenceLines, ...referenceLinesServiceEvents];

  const showPointValueSensorsChart =
    formattedDataPointValueSensors && formattedDataPointValueSensors.length > 0;

  const showStateSensorsChart =
    formattedDataStateSensors && formattedDataStateSensors.length > 0;

  const pointValueChartIsBottomMost =
    !showEventTimelineChart && !showStateSensorsChart;

  const stateChartIsBottomMost =
    (!showEventTimelineChart && !showPointValueSensorsChart) ||
    !showEventTimelineChart;

  // we already display the x-axis on event timeline chart, so we only need to add it
  // when the event timeline is not visible - and only on the bottom-most of the two
  const xAxisPropsPointValue: LineChartProps["xAxisProps"] = {
    ...sharedXAxisProps,
    scale: "auto",
    visibility: pointValueChartIsBottomMost ? "visible" : "hidden",
  };

  const xAxisPropsState: LineChartProps["xAxisProps"] = {
    ...sharedXAxisProps,
    scale: "auto",
    visibility: stateChartIsBottomMost ? "visible" : "hidden",
  };

  const pointValueSensorLegendLabels: LegendConfigLabel[] =
    pointValueSensors?.map((sensor, index) => ({
      label: getLabelForPointValueSensor(grouping, sensor)!,
      color: getColor(index),
      dotted: true,
      shape: "line",
    })) || [];

  const legendConfig: LegendConfig = {
    ...LEGEND_CONFIG,
    labels: [...LEGEND_CONFIG.labels, ...pointValueSensorLegendLabels],
  };

  return (
    <>
      {legendConfig &&
        (showPointValueSensorsChart || showStateSensorsChart) && (
          <div className="mt-3">
            <CustomLegend legendConfig={legendConfig} />
          </div>
        )}
      {showPointValueSensorsChart && (
        <div
          style={{
            marginBottom:
              stateSensors && stateSensors.length > 0
                ? -PX_TO_JOIN_CHARTS_VERTICALLY
                : undefined,
          }}
        >
          <LineChart
            margin={margin}
            key="sensors-chart-point-value"
            zoomOverride={currentZoom}
            enableZoom={true}
            hideZoomOutControl={!pointValueChartIsBottomMost}
            onZoom={onZoom}
            onZoomOut={onZoomOut}
            onSyncDateWithZoom={onSyncDateWithZoom}
            onZoomReferenceAreaChange={onZoomReferenceAreaChange}
            zoomReferenceAreaOverride={zoomReferenceAreaOverride}
            height={200}
            width="100%"
            data={formattedDataPointValueSensors}
            xAxisKey={TIMESTAMP_X_AXIS_KEY}
            yAxisLines={yAxisLinesPointValueSensors}
            yAxisLabel={yAxisLabelLeft}
            yAxisLabelRight={yAxisLabelRight}
            references={allRefLines}
            yAxisProps={{
              tickFormatter: (value: number) => {
                const num = formatNumber(value);
                return num || value.toString();
              },
            }}
            xAxisProps={xAxisPropsPointValue}
            hideLegend={Boolean(legendConfig)}
            connectNulls={true}
            cursorX={cursorX}
            onMouseMove={onMouseMove}
            coloredYAxisLabels={true}
            tooltipProps={pointValueSensorChartTooltipProps(
              grouping,
              pointValueSensors
            )}
          />
        </div>
      )}
      {showStateSensorsChart && (
        <LineChart
          margin={margin}
          key="sensors-chart-state-value"
          zoomOverride={currentZoom}
          enableZoom={true}
          hideZoomOutControl={!stateChartIsBottomMost}
          onZoom={onZoom}
          onZoomOut={onZoomOut}
          onSyncDateWithZoom={onSyncDateWithZoom}
          onZoomReferenceAreaChange={onZoomReferenceAreaChange}
          zoomReferenceAreaOverride={zoomReferenceAreaOverride}
          height={
            stateSensors && stateSensors.length > 1
              ? (stateSensors.length * MIN_HEIGHT_STATE_CHART_PX) / 2
              : MIN_HEIGHT_STATE_CHART_PX
          }
          width="100%"
          xAxisKey={TIMESTAMP_X_AXIS_KEY}
          yAxisLines={yAxisLinesStateSensors}
          hideLegend={true}
          yAxisProps={{
            dataKey: dataKeyStateDurationSensors,
            type: "category",
            allowDuplicatedCategory: false,
          }}
          references={allRefLines}
          xAxisProps={xAxisPropsState}
          cursorX={cursorX}
          onMouseMove={onMouseMove}
          tooltipProps={stateSensorChartTooltipProps(
            dataKeyStateDurationSensors
          )}
        />
      )}
    </>
  );
};

export default SensorsCharts;
