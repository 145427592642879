export const routes = Object.freeze({
  root: "/",
  docs: "/docs",
  loginCallback: "/login/callback",
  explorer: "/explorer",
  failureModes: "/failure-modes",
  failureMode: "/failure-modes/:id",
  failureModesV1: "/failure-modes-v1",
  failureModeV1: "/failure-modes-v1/:id",
  claimDetails: "/claims/:id",
  claimAnalytics: "/claim-analytics",
  claimAnalyticsDetails: "/claim-analytics/:id",
  repairAnalytics: "/repair-analytics",
  repairAnalyticsDetails: "/repair-analytics/:id",
  inspectionAnalytics: "/inspection-analytics",
  inspectionAnalyticsDetails: "/inspection-analytics/:id",
  customRecords: "/custom-records",
  customRecordDetails: "/custom-records/:id",
  signalEventAnalytics: "/signal-event-analytics",
  signalEventsAnalyticsDetails: "/signal-event-analytics/:id/:recordedAt",
  dashboard: "/dashboard/:id",
  predictions: "/predictions",
  vehicles: "/vehicles",
  vinView: "/vehicles/:vin",
  fleets: "/fleets",
  fleetView: "/fleets/:fleetName",
  servicePlans: "/service-plans",
  newServicePlan: "/service-plans/new",
  serviceRecords: "/service-records",
  servicePlan: "/service-plans/:id",
  servicePlanEdit: "/service-plans/:id/edit",
  serviceRecommendations: "/service-recommendations",
  calculatedAttributes: "/calculated-attributes",
  newCalculatedAttribute: "/calculated-attributes/new",
  calculatedAttribute: "/calculated-attributes/:id",
  alertDefinitions: "/alert-definitions",
  alertDefinition: "/alert-definitions/:id",
  newAlertDefinition: "/alert-definitions/new",
  collections: "/collections",
  issues: "/issues",
  issue: "/issues/:id",
  suggestedIssues: "/suggested-issues",
  suggestedIssueLatestRun: "/suggested-issues/:id",
  suggestedIssue: "/suggested-issues/:id/:date",
  customSignalEvents: "/custom-signal-events",
  newCustomSignalEvent: "/custom-signal-events/new",
  customSignalEvent: "/custom-signal-events/:id",
  version: "/version",
  settings: "/settings",
  groups: "/groups",
  group: "/groups/:id",
  chatBot: "/chat-bot",
  notFound: "*", // catch-all at the bottom of Routes.tsx
});

export type RoutesValues = (typeof routes)[keyof typeof routes];

// we keep a list of all routes we have changed and want to keep the old links working
export const redirects = Object.freeze({
  "/campaigns/tracking": "/campaigns",
  "/campaigns/scoping": "/vehicles",
  "/campaign-scoping": "/vehicles",
  "/service-events": "/service-records",
  "/signal-event-studio": "/custom-signal-events",
});
