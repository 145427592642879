import Skeleton from "react-loading-skeleton";

import { GetApiIdRequest } from "shared/api/api";
import { UseAPIState } from "shared/api/hooks";
import { toTitleCase } from "shared/utils";

interface Props {
  id: string;
  hook: (req: GetApiIdRequest) => UseAPIState<any>;
}

const SKELETON_HEIGHT = 12;

const TableAsyncCellValue = ({ id, hook }: Props) => {
  const { data, isLoading } = hook({ id });
  if (isLoading) {
    return <Skeleton height={SKELETON_HEIGHT} />;
  }
  return <span>{data && toTitleCase(data.name)}</span>;
};

export default TableAsyncCellValue;
