import Table, { TableProps } from "features/ui/Table";
import TablePageControl from "features/ui/Table/TablePageControl";

interface PaginatedTableProps extends TableProps {
  onNextPage: () => void;
  onPreviousPage: () => void;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

// styling taken from https://tailwindui.com/components/application-ui/lists/tables
const PaginatedTable = ({
  onNextPage,
  onPreviousPage,
  hasNextPage,
  hasPreviousPage,
  ...tableProps
}: PaginatedTableProps) => (
  <div className="w-full flex flex-col space-y-2">
    <Table {...tableProps} />
    {(hasNextPage || hasPreviousPage) && (
      <TablePageControl
        onNextPage={onNextPage}
        onPreviousPage={onPreviousPage}
        hasNextPage={hasNextPage}
        hasPreviousPage={hasPreviousPage}
      />
    )}
  </div>
);

export default PaginatedTable;
