import { format } from "date-fns";
import {
  LC_API_KEY,
  LC_ENDPOINT,
  LC_PROJECT_NAME,
  MODELSPEC,
} from "duck/graph/constants";
import { DuckGraphParams } from "duck/graph/types";
import { hasAllEnvVarsForViaDuck } from "duck/ui/utils";
import { Client } from "langsmith";
import {
  AIMessage,
  HumanMessage,
  SystemMessage,
} from "@langchain/core/messages";
import { LangChainTracer } from "@langchain/core/tracers/tracer_langchain";

import * as config from "config/config";

import getGraph from "./getGraph";

// Create Langchain client and tracer
const client = new Client({ apiKey: LC_API_KEY, apiUrl: LC_ENDPOINT });
const tracer = new LangChainTracer({ client, projectName: LC_PROJECT_NAME });

// Main function to call agent
const callAgent = async (params: DuckGraphParams): Promise<void> => {
  if (!hasAllEnvVarsForViaDuck()) {
    console.warn(
      "Missing environment variables. Please set REACT_APP_LANGCHAIN_ENDPOINT, REACT_APP_LANGCHAIN_API_KEY, REACT_APP_LANGCHAIN_PROJECT, and REACT_APP_OPENAI_API_KEY."
    );
    throw new Error("Missing environment variables.");
  }

  const agentExecutor = await getGraph(params, false);

  const messages = params.messageHistory.map((message) =>
    message.author === "human"
      ? new HumanMessage(message.message, { name: "USER" })
      : new AIMessage(message.message, { name: "DUCK" })
  );

  const formattedDate = format(new Date(), "EEEE MMMM d, yyyy");
  messages.push(
    new SystemMessage(`The current date is ${formattedDate}`, {
      name: "SYSTEM",
    })
  );

  messages.push(new HumanMessage(params.text, { name: "USER" }));

  const { environment } = config.get();

  const duckGraphVersion = require("./version.json").version;

  const graphRunnableConfig = {
    configurable: { thread_id: params.threadId },
    callbacks: [tracer],
    runName: "DUCK Graph",
    tags: [duckGraphVersion],
    metadata: {
      environment,
      tenant: params.tenant,
      user: params.user,
      duckGraphVersion,
      nodeEnv: process.env.NODE_ENV,
      lsModelName: MODELSPEC.modelName,
      lsProvider: "openai",
      lsTemperature: MODELSPEC.temperature,
    },
  };

  const response = await agentExecutor.invoke(
    {
      userInput: params.text,
      messages,
      pageState: params.currentState,
    },
    graphRunnableConfig
  );

  console.log({ response });
};

export default callAgent;
